<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div class="d-flex align-items-center justify-content-start">
      Your campaign will be
      <select v-model="value.criteria" class="form-control form-control-sm d-inline w-auto mx-1">
        <option value="is">displayed</option>
        <option value="is-not">not displayed</option>
      </select>
      if visitor is from
    </div>

    <div class="row mb-3" :key="rowsKey">
      <div v-for="(location, key) in value.geolocations" :key="key" class="my-3 col-12">
        <div class="card card-body">
          <div>
            <label>Country:</label>
            <searchable-dropdown
              placeholder="All"
              search-input-placeholder="Search..."
              v-model="location.country"
              @input="makeNullAfter(key, 'country')"
            >
              <option value="null" :selected="location.country === null">All</option>
              <option
                v-for="(country, iso) in geotargetingLocations.countries"
                :key="iso"
                :value="iso"
                :selected="location.country === iso"
              >
                {{ country.name }}
              </option>
            </searchable-dropdown>
          </div>

          <div class="mt-2"
               v-if="location.country !== null && geotargetingLocations.countries[location.country].sub_divs">
            <label>Subdivision 1:</label>
            <searchable-dropdown
              placeholder="All"
              search-input-placeholder="Search..."
              v-model="location.sub_div1"
              @input="makeNullAfter(key, 'sub_div1')"
            >
              <option value="null" :selected="location.sub_div1 === null">All</option>
              <option
                v-for="(subDiv, iso) in geotargetingLocations.countries[location.country].sub_divs"
                :key="iso"
                :value="iso"
                :selected="location.sub_div1 === iso"
              >
                {{ subDiv.name }}
              </option>
            </searchable-dropdown>
          </div>

          <div
            class="mt-2"
            v-if="location.sub_div1 !== null && geotargetingLocations.countries[location.country].sub_divs[location.sub_div1].sub_divs"
          >
            <label>Subdivision 2:</label>
            <searchable-dropdown
              placeholder="All"
              search-input-placeholder="Search..."
              v-model="location.sub_div2"
              @input="makeNullAfter(key, 'sub_div2')"
            >
              <option value="null" :selected="location.sub_div2 === null">All</option>
              <option
                v-for="(subdiv, iso) in geotargetingLocations.countries[location.country].sub_divs[location.sub_div1].sub_divs"
                :selected="location.sub_div2 === iso"
                :key="iso"
                :value="iso"
              >
                {{ subdiv.name }}
              </option>
            </searchable-dropdown>
          </div>

          <div
            class="mt-2"
            v-else-if="location.sub_div1 !== null && geotargetingLocations.countries[location.country].sub_divs[location.sub_div1].cities"
          >
            <label>City:</label>
            <searchable-dropdown placeholder="All" search-input-placeholder="Search..." v-model="location.city">
              <option value="null" :selected="location.city === null">All</option>
              <option
                v-for="(cityName, geoId) in geotargetingLocations.countries[location.country].sub_divs[location.sub_div1].cities"
                :key="geoId"
                :value="geoId"
                :selected="location.city === geoId"
              >
                {{ cityName }}
              </option>
            </searchable-dropdown>
          </div>

          <div class="mt-2" v-if="location.sub_div2 !== null">
            <label>City:</label>
            <searchable-dropdown placeholder="All" search-input-placeholder="Search..." v-model="location.city">
              <option value="null" :selected="location.city === null">All</option>
              <option
                v-for="(cityName, geoId) in geotargetingLocations.countries[location.country].sub_divs[location.sub_div1].sub_divs[
                  location.sub_div2
                ].cities"
                :key="geoId"
                :value="geoId"
                :selected="location.city === geoId"
              >
                {{ cityName }}
              </option>
            </searchable-dropdown>
          </div>

          <button
            v-if="value.geolocations.length > 1"
            class="btn btn-sm btn-icon btn-soft-danger mx-auto mt-2"
            type="button"
            @click="removeLocation(key)"
          >
            <i class="tio-remove"></i>
          </button>
        </div>
      </div>
      <button class="btn btn-soft-primary btn-sm btn-icon mx-auto" type="button" @click="addLocation">
        <i class="tio-add"></i>
      </button>
    </div>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"
import { mapState } from "vuex"
import SearchableDropdown from "@/components/common/SearchableDropdown"
import targetingRuleDefaultValues from "@/components/campaign-targeting-rules/targetingRuleDefaultValues"
import randomId from "@/lib/utils/random-id"

const mixin = targetingRuleModalMixin("who", targetingRuleNames.whoRules.location)
export default {
  name: "LocationModal",
  components: { SearchableDropdown, ModalCampaignTargetingRule },
  data() {
    return {
      rowsKey: "key"
    }
  },
  computed: {
    ...mapState("campaignSettings", ["geotargetingLocations"])
  },
  mixins: [mixin],
  methods: {
    makeNullAfter(index, key) {
      const location = this.value.geolocations[index]
      if (key === "country") {
        location.city = null
        location.sub_div1 = null
        location.sub_div2 = null
      }
      if (key === "sub_div1") {
        location.city = null
        location.sub_div2 = null
      }
      if (key === "sub_div2") {
        location.city = null
      }
    },
    addLocation() {
     console.log({
       value: targetingRuleDefaultValues.who[targetingRuleNames.whoRules.location].geolocations[0]
     })
      this.value.geolocations.push({ ...targetingRuleDefaultValues.who[targetingRuleNames.whoRules.location].geolocations[0] })
    },
    removeLocation(key) {
      this.value.geolocations.splice(key, 1)
      this.rowsKey = randomId()
    }
  }
}
</script>
