function multiPurposeStyleVariables() {
  function getDefaultImage(position, width, height) {
    let key = `--visual-asset-${position}-image`
    return {
      [`${key}-width`]: width,
      [`${key}-height`]: height,
      [`${key}-object-fit`]: "cover",
      [`${key}-opacity`]: "1",
      [`${key}-margin`]: "0px 0px 0px 0px",
      [`${key}-transform`]: "none"
    }
  }

  function getDefaultVideo(position, width) {
    let key = `--visual-asset-${position}-video`
    return {
      [`${key}-width`]: width,
      [`${key}-margin`]: "0px 0px 0px 0px"
    }
  }

  function getDesktopAssets() {
    return {
      ...getDefaultImage("right", "320px", "100%"),
      ...getDefaultImage("left", "320px", "100%"),
      ...getDefaultImage("top", "100%", "240px"),
      ...getDefaultImage("bottom", "100%", "240px"),

      ...getDefaultVideo("right", "320px"),
      ...getDefaultVideo("left", "320px"),
      ...getDefaultVideo("top", "100%"),
      ...getDefaultVideo("bottom", "100%")
    }
  }

  function getMobileAssets() {
    return {
      ...getDefaultImage("right", "100px", "100%"),
      ...getDefaultImage("left", "100px", "100%"),
      ...getDefaultImage("top", "100%", "240px"),
      ...getDefaultImage("bottom", "100%", "240px"),

      ...getDefaultVideo("right", "160px"),
      ...getDefaultVideo("left", "160px"),
      ...getDefaultVideo("top", "100%"),
      ...getDefaultVideo("bottom", "100%")
    }
  }

  return {
    style: {
      desktop: {
        "--overlay-backdrop-blur": "0px",
        "--overlay-color": "rgba(0,0,0,.2)",
        "--position-margin": "0px 0px 0px 0px",
        ...getDesktopAssets()
      },
      mobile: {
        "--overlay-backdrop-blur": "0px",
        "--overlay-color": "rgba(0,0,0,.4)",
        "--position-margin": "0px 0px 0px 0px",
        ...getMobileAssets()
      }
    }
  }
}

export default multiPurposeStyleVariables
