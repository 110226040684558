<template>
  <base-campaign-targeting-rule>
    <div>
      <div>
        Cart value {{ numericCompareOperators[getByCompWith("value").filter].toLowerCase() }}
        {{ getByCompWith("value").value }}
      </div>
      <div>
        Total number of products in the cart
        {{ numericCompareOperators[getByCompWith("numberOfProducts").filter].toLowerCase() }}
        {{ getByCompWith("numberOfProducts").value }}
      </div>
      <div>
        Number of different products in the cart
        {{ numericCompareOperators[getByCompWith("numberOfDifferentProducts").filter].toLowerCase() }}
        {{ getByCompWith("numberOfDifferentProducts").value }}
      </div>
    </div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import numericCompareOperators from "@/components/campaign-targeting-rules/numericCompareOperators"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("who", targetingRuleNames.whoRules.cart)

export default {
  name: "TargetingCart",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin],
  data() {
    return {
      numericCompareOperators: numericCompareOperators
    }
  },
  methods: {
    getByCompWith(compWith) {
      return this.data.find((i) => i["compareWith"] === compWith)
    }
  }
}
</script>
