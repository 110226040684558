<template>
  <base-campaign-template-tab title="Boost Sales">
    <p>This campaign will help you to boost your sales!</p>
  </base-campaign-template-tab>
</template>

<script>
import BaseCampaignTemplateTab from "@/components/campaign-template-tabs/BaseCampaignTemplateTab"
export default {
  name: "BoostSales",
  components: { BaseCampaignTemplateTab }
}
</script>

<style scoped></style>
