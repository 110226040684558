<template>
  <div>
    <settings-accordion :show-device-picker="true">
      <template #title>Input</template>
      <template>
        <div class="pb-5 border-bottom">
          <h4 class="mb-4">Settings</h4>
          <name :form-field="element" class="mb-3" />
          <checkbox v-model="required">Required</checkbox>
        </div>

        <div class="py-5 border-bottom">
          <h4 class="mb-4">Size</h4>
          <SettingsFontSize :show-device-picker="false" layout="horizontal" :variable="manager.getStyleVariableName('font-size')" />
        </div>

        <div class="py-5">
          <h4 class="mb-4">Style</h4>
          <SettingsColor class="mb-2" :show-device-picker="false" layout="horizontal" :variable="manager.getStyleVariableName('color')" />
          <settings-text-style
            class="mb-2"
            layout="horizontal"
            :show-device-picker="false"
            :variable="{
            fontWeight: manager.getStyleVariableName('font-weight'),
            fontStyle: manager.getStyleVariableName('font-style'),
            textDecoration: manager.getStyleVariableName('text-decoration')
          }"
          />
          <settings-text-align
            class="mb-2"
            :show-device-picker="false"
            layout="horizontal"
            :variable="manager.getStyleVariableName('text-align')"
          />
        </div>
      </template>
    </settings-accordion>

    <settings-accordion :show-device-picker="true">
      <template #title>Spacing</template>
      <SettingsMargin layout="vertical" :show-device-picker="false" :variable="manager.getStyleVariableName('margin')" />
      <SettingsPadding layout="vertical" class="mt-4" :show-device-picker="false" :variable="manager.getStyleVariableName('padding')" />
    </settings-accordion>

    <SettingsBackground :variable="manager.getStyleVariableName('background')" />
    <settings-accordion :show-device-picker="true">
      <template #title>Border</template>
      <SettingsBorder
        layout="vertical"
        :show-device-picker="false"
        :color-variable="manager.getStyleVariableName('border-color')"
        :style-variable="manager.getStyleVariableName('border-style')"
        :width-variable="manager.getStyleVariableName('border-width')"
      />
      <SettingsBorderRadius
        class="mt-4"
        layout="vertical"
        :show-device-picker="false"
        :variable="manager.getStyleVariableName('border-radius')"
      />
    </settings-accordion>
  </div>


</template>

<script>
import Checkbox from "@/components/common/Checkbox"
import SettingsAccordion from "@/components/campaign-builder/widget-settings/SettingsAccordion"
import Name from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Name"

export default {
  name: "InputText",
  components: { Name, SettingsAccordion, Checkbox },
  props: ["element", "manager"],
  data() {
    return {
      required: "",
      placeholder: ""
    }
  },
  watch: {
    $data: {
      handler: function () {
        this.element.required = this.required
      },
      deep: true
    }
  },
  mounted() {
    this.required = this.element.required
  }
}
</script>
