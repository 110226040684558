<template>
  <div>
    <dashboard-title title="Campaigns">
      <template #right>
        <router-link :to="{ name: 'Dashboard.Campaigns.Create' }" class="btn btn-primary" href="javascript:;">
          <i class="tio-add mr-1"></i> Create Campaign
        </router-link>
      </template>
    </dashboard-title>
    <campaigns-table />
  </div>
</template>

<script>
import { mapState } from "vuex"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import CampaignsTable from "@/components/dashboard/CampaignsTable"
import ensureHasSite from "@/mixins/ensure-has-site"

export default {
  name: "Campaigns",
  components: { CampaignsTable, DashboardTitle },
  computed: mapState("campaigns", ["campaigns"]),
  mixins: [ensureHasSite]
}
</script>

<style scoped></style>
