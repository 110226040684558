<template>
  <header
    id="header"
    class="navbar navbar-expand-lg navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered"
    style="margin-left: var(--left-width)"
  >
    <div class="d-flex justify-content-between w-100">
      <div class="flex-fill">
        <page-operations />
      </div>
      <div class="column-divider mx-6"></div>
      <div class="">
        <div v-if="isNewCampaign">
          <button class="btn btn-secondary btn-sm mr-2 font-weight-bolder" @click="backPage">Back</button>
          <button class="btn btn-primary btn-sm mr-2 font-weight-bolder" @click="nextPage">
            {{ currentPage === totalPage ? "Next & Save" : "Next" }}
          </button>
        </div>
        <div v-else>
          <button class="btn btn-secondary btn-sm font-weight-bolder mr-2" @click="cancel">Cancel</button>
          <button class="btn btn-primary btn-sm font-weight-bolder mr-2" @click="save" v-if="false">Save</button>
          <button class="btn btn-primary btn-sm font-weight-bolder mr-2" @click="saveAndExit">Save & Exit</button>
        </div>

        <!--        <button class="btn btn-secondary btn-sm mr-2" @click="back">Cancel</button>-->
        <!--        <button class="btn btn-primary btn-sm mr-2" @click="save">Save & Next</button>-->
        <!--      <div class="hs-unfold">-->
        <!--        <a class="js-hs-unfold-invoker btn btn-soft-primary btn-sm dropdown-toggle" href="javascript:;"-->
        <!--           data-hs-unfold-options='{-->
        <!--       "target": "#realisticPreviewDeviceDropdown",-->
        <!--       "type": "css-animation",-->
        <!--       "event": "hover"-->
        <!--     }'>More Realistic Preview</a>-->
        <!--        <div id="realisticPreviewDeviceDropdown" class="hs-unfold-content dropdown-unfold dropdown-menu">-->
        <!--          <a-->
        <!--            v-for="(deviceName, resolution) in devices.mobile"-->
        <!--            :key="resolution"-->
        <!--            href="javascript:;"-->
        <!--            class="dropdown-item"-->
        <!--            @click="moreRealisticPreview(resolution, 'mobile')"-->
        <!--          >-->
        <!--            Mobile: {{ deviceName }}-->
        <!--          </a>-->
        <!--          <div class="dropdown-divider"></div>-->
        <!--          <a-->
        <!--            v-for="(deviceName, resolution) in devices.desktop"-->
        <!--            :key="resolution"-->
        <!--            href="javascript:;"-->
        <!--            class="dropdown-item"-->
        <!--            @click="moreRealisticPreview(resolution, 'desktop')"-->
        <!--          >-->
        <!--            Desktop: {{ deviceName }}-->
        <!--          </a>-->
        <!--        </div>-->
        <!--      </div>-->
      </div>
    </div>
  </header>
</template>
<script>
import { mapState } from "vuex"
import getScreenshot from "@/api/get-screenshot"
import unfoldMixin from "@/mixins/unfold-mixin"
import PageOperations from "@/components/campaign-builder/PageOperations"

export default {
  name: "CampaignBuilderHeader",
  components: { PageOperations },
  props: {
    save: Function,
    exit: Function,
    next: Function,
    cancel: Function
  },
  data() {
    return {
      devices: {
        mobile: {
          "414-736": "Iphone 6/7/8 Plus",
          "320-568": "Iphone 5/S",
          "375-812": "Iphone X",
          "360-740": "Samsung S9"
        },
        desktop: {
          "1024-768": "Mini Desktop",
          "1366-768": "Middle Desktop",
          "1920-1080": "Big Desktop"
        }
      }
    }
  },
  computed: mapState("builder", ["iframes", "isNewCampaign", "currentPage", "totalPage"]),
  mixins: [unfoldMixin],
  methods: {
    async saveAndExit() {
      await this.save()
      await this.exit()
    },
    async backPage() {
      if (this.currentPage > 1) {
        this.$store.dispatch("builder/changePage", this.currentPage - 1)
      } else {
        await this.cancel()
      }
    },
    async nextPage() {
      if (this.currentPage < this.totalPage) {
        await this.$store.dispatch("builder/changePage", this.currentPage + 1)
      } else {
        await this.save()
        await this.next()
      }
    },
    moreRealisticPreview(resolution, iframeDeviceKey) {
      const splitResolution = resolution.split("-")
      const width = splitResolution[0]
      const height = splitResolution[1]
      const size = `width=${width},height=${height},resizable=0`
      const preview = window.open("", "MsgWindow" + resolution, size)
      const iframe = this.iframes[iframeDeviceKey]
      preview.document.write(iframe.contentWindow.document.documentElement.innerHTML)
      getScreenshot({ url: "https://wisepops.com", width, height }).then((screenshot) => {
        preview.document.querySelector("body").style.backgroundImage = `url("${screenshot}")`
      })
    }
  }
}
</script>
