<template>
  <div>
    <dashboard-title title="Plan & Billing" />
    <div class="row justify-content-lg-center">
      <div v-if="loading" class="col-lg-9">
        <loading />
      </div>
      <div v-else class="col-lg-9">
        <account-plan class="mb-3 mb-lg-5" />
        <account-payment-methods class="mb-3 mb-lg-5" />
        <account-billing-address class="mb-3 mb-lg-5" />
        <account-invoices />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import AccountBillingAddress from "@/components/dashboard/AccountBillingAddress"
import AccountPaymentMethods from "@/components/dashboard/AccountPaymentMethods"
import AccountPlan from "@/components/dashboard/AccountPlan"
import Loading from "@/components/common/Loading"
import AccountInvoices from "@/components/dashboard/AccountInvoices"

export default {
  name: "PlanBilling",
  components: { AccountInvoices, Loading, AccountPlan, AccountPaymentMethods, AccountBillingAddress, DashboardTitle },
  data() {
    return {
      loading: true
    }
  },
  async mounted() {
    this.loading = true
    await this.$store.dispatch("user/fetchPaymentMethods")
    this.loading = false
  }
}
</script>

<style scoped></style>
