<template>
  <modal
    :title="`Matching ${campaign.name} Campaign With ${userIntegration.name} (${integration.name}) Integration`"
    size="lg"
    @close="$emit('close')"
  >
    <div ref="PARENTELEMENT">
      <div class="modal-body" v-if="mainLoading">
        <loading />
      </div>
      <base-form @submit="submit" v-else @ready="initForm">
        <div class="modal-body">
          <dashboard-error :message="errorMsg" v-if="errorMsg" />
          <campaign-integration-match-settings
            class="mb-5"
            v-if="Object.values(settingsData).length > 0"
            :settings-data="settingsData"
            :current-settings="formData['settings']"
            :settings-must-have="integration['settings_must_have']"
            @input="handleSettingsInput"
          />

          <campaign-integration-match-form-bindings
            v-if="integrationFormFieldsStatus === 'match' || integrationFormFieldsStatus === 'free'"
            :integration-name="integration.name"
            :type="integrationFormFieldsStatus"
            :current-bindings="formData['form_bindings']"
            :service-form-fields="integrationFormFieldsStatus === 'match' ? fetchedData['form-fields'] : false"
            @input="handleFormBindingsInput"
          />
        </div>

        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" v-if="!submitLoading">Save</button>
          <button class="btn btn-primary" type="submit" disabled v-if="submitLoading">
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
        </div>
      </base-form>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"
import BaseForm from "@/components/BaseForm"
import formMixin from "@/mixins/form-mixin"
import integrationAPI from "@/api/integration"
import DashboardError from "@/components/dashboard/DashboardError"
import Loading from "@/components/common/Loading"
import { mapState } from "vuex"
import CampaignIntegrationMatchFormBindings from "@/components/campaign-integration-match/CampaignIntegrationMatchFormBindings"
import CampaignIntegrationMatchSettings from "@/components/campaign-integration-match/CampaignIntegrationMatchSettings"

export default {
  name: "ModalCampaignIntegrationMatch",
  components: {
    CampaignIntegrationMatchSettings,
    CampaignIntegrationMatchFormBindings,
    Loading,
    DashboardError,
    BaseForm,
    Modal
  },
  props: ["userIntegration", "integration", "initialData"],
  mixins: [formMixin],
  data() {
    return {
      errorMsg: "",
      mainLoading: true,
      submitLoading: false,
      fetchedData: {},
      formData: { form_bindings: {}, settings: {} },
      initFormOnMount: false
    }
  },
  computed: {
    ...mapState("campaignSettings", ["campaign"]),
    integrationFormFieldsStatus() {
      if (Object.keys(this.fetchedData).includes("form-fields")) {
        return "match"
      } else if (Object.keys(this.dependentEndpoints).includes("form-fields")) {
        return "dependent"
      } else {
        return "free"
      }
    },
    settingsData() {
      const data = {}
      Object.keys(this.fetchedData).forEach((key) => {
        if (key !== "form-fields") {
          data[(key + "Id").replace("sId", "Id")] = this.fetchedData[key]
        }
      })
      return data
    },
    knownEndpointParameters() {
      const data = {
        "{integrationId}": this.userIntegration.id
      }
      Object.keys(this.formData.settings).forEach((settingKey) => {
        data[`{${settingKey}}`] = this.formData.settings[settingKey]
      })
      return data
    },
    dependentEndpoints() {
      let endpoints = this.integration.routes
      delete endpoints.store
      let ret = {}
      for (const action of Object.keys(endpoints)) {
        const { uri } = endpoints[action]
        if ((uri.match(/{/g) || []).length > (uri.match(/{integrationId}/g) || []).length) {
          ret[action] = endpoints[action]
        }
      }
      return ret
    }
  },
  methods: {
    generateDataForBackend() {
      let formBindingsFormatted = []
      Object.keys(this.formData["form_bindings"]).forEach((osmKey) => {
        const serviceField = this.formData["form_bindings"][osmKey]
        if (serviceField !== null && serviceField !== "") {
          formBindingsFormatted.push({
            type: "form",
            form_key: osmKey,
            service_field: serviceField
          })
        }
      })
      return { settings: this.formData["settings"], formBindings: formBindingsFormatted }
    },
    convertBackendDataToComponentData(backendData) {
      const ret = { form_bindings: {}, settings: {} }
      ret.settings = backendData.settings
      backendData["form_bindings"].forEach((data) => {
        ret["form_bindings"][data["form_key"]] = data["service_field"]
      })
      return ret
    },
    getEndpoint(endpoint) {
      Object.keys(this.knownEndpointParameters).forEach((par) => {
        endpoint = endpoint.replace(par, this.knownEndpointParameters[par])
      })
      if (endpoint.includes("{") && endpoint.includes("}")) {
        return false
      }
      return endpoint
    },
    async processDependentEndpoints() {
      for (const action of Object.keys(this.dependentEndpoints)) {
        let endpoint = this.getEndpoint(this.dependentEndpoints[action].uri)
        if (!endpoint) {
          continue
        }
        this.mainLoading = true
        let resp = await integrationAPI.get(endpoint)
        this.fetchedData[action] = resp.data
        this.fetchedData = { ...this.fetchedData }
        this.mainLoading = false
      }
    },
    handleSettingsInput(data) {
      this.formData["settings"] = data
      this.processDependentEndpoints()
    },
    handleFormBindingsInput(data) {
      this.formData["form_bindings"] = data
    },
    async submit() {
      this.errorMsg = null

      this.integration["form_must_have"].forEach((i) => {
        if (!Object.values(this.formData["form_bindings"]).includes(i)) {
          const field = this.fetchedData["form-fields"].find((k) => k["field_identifier"] === i)
          return (this.errorMsg = `The "${field.name}" form field of integration is required.`)
        }
      })

      if (this.errorMsg !== null) {
        return
      }

      if (this.initialData) {
        this.$emit("integrationMatched", {
          type: "update",
          id: this.initialData.id,
          thirdPartyIntegration: this.userIntegration,
          ...this.generateDataForBackend()
        })
      } else {
        this.$emit("integrationMatched", {
          type: "create",
          thirdPartyIntegration: this.userIntegration,
          ...this.generateDataForBackend()
        })
      }

      // this.submitLoading = true
      // try {
      //   if (this.initialData) {
      //     // update
      //     await integrationAPI.updateCampaignIntegration(
      //       this.selectedSite.id,
      //       this.campaign.id,
      //       this.initialData.id,
      //       this.generateDataForBackend()
      //     )
      //
      //   } else {
      //     // create
      //
      //     await integrationAPI.createCampaignIntegration(
      //       this.selectedSite.id,
      //       this.campaign.id,
      //       this.userIntegration.id,
      //       this.generateDataForBackend()
      //     )
      //   }
      // } catch (e) {
      //   this.errorMsg = "Please check your form data."
      // }
      // this.submitLoading = false
    }
  },
  async mounted() {
    this.mainLoading = true

    if (this.initialData) {
      this.formData = this.convertBackendDataToComponentData(this.initialData)
    }

    const fetchedData = {}
    let endpoints = this.integration.routes
    delete endpoints.store

    for (const action of Object.keys(endpoints)) {
      const endpoint = this.getEndpoint(endpoints[action].uri)
      if (endpoint) {
        let resp = await integrationAPI.get(endpoint)
        fetchedData[action] = resp.data
      }
    }

    this.fetchedData = fetchedData
    this.mainLoading = false
  }
}
</script>

<style scoped></style>
