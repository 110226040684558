<template>
  <div>
    <dashboard-title title="Profile Settings" />

    <div class="row">
      <div class="col-lg-3">
        <!-- Navbar -->
        <div class="navbar-vertical navbar-expand-lg mb-3 mb-lg-5">
          <!-- Navbar Toggle -->
          <button
            type="button"
            class="navbar-toggler btn btn-block btn-white mb-3"
            aria-label="Toggle navigation"
            aria-expanded="false"
            aria-controls="navbarVerticalNavMenu"
            data-toggle="collapse"
            data-target="#navbarVerticalNavMenu"
          >
            <span class="d-flex justify-content-between align-items-center">
              <span class="h5 mb-0">Nav menu</span>

              <span class="navbar-toggle-default">
                <i class="tio-menu-hamburger"></i>
              </span>

              <span class="navbar-toggle-toggled">
                <i class="tio-clear"></i>
              </span>
            </span>
          </button>
          <!-- End Navbar Toggle -->

          <div id="navbarVerticalNavMenu" class="collapse navbar-collapse">
            <!-- Navbar Nav -->
            <ul
              id="navbarSettings"
              class="js-sticky-block js-scrollspy navbar-nav navbar-nav-lg nav-tabs card card-navbar-nav"
              data-hs-sticky-block-options='{
                       "parentSelector": "#navbarVerticalNavMenu",
                       "breakpoint": "lg",
                       "startPoint": "#navbarVerticalNavMenu",
                       "endPoint": "#stickyBlockEndPoint",
                       "stickyOffsetTop": 20
                     }'
            >
              <li class="nav-item">
                <a class="nav-link active" href="#content"> <i class="tio-user-outlined nav-icon"></i> Basic information </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#passwordSection"> <i class="tio-lock-outlined nav-icon"></i> Password </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#deleteAccountSection"> <i class="tio-delete-outlined nav-icon"></i> Delete account </a>
              </li>
            </ul>
            <!-- End Navbar Nav -->
          </div>
        </div>
        <!-- End Navbar -->
      </div>

      <div class="col-lg-9">
        <!-- Card -->
        <div class="card mb-3 mb-lg-5">
          <div class="card-header">
            <h2 class="card-title h4">Basic information</h2>
          </div>

          <!-- Body -->
          <div class="card-body">
            <dashboard-error v-if="basicInformationError" :message="basicInformationError" />
            <dashboard-success v-if="showBasicInformationSuccess" message="Successfully updated." />

            <!-- Form -->
            <form @submit.prevent="updateUser">
              <!-- Form Group -->
              <div class="row form-group">
                <label for="firstNameLabel" class="col-sm-3 col-form-label input-label">Full name</label>
                <div class="col-sm-9">
                  <div class="input-group input-group-sm-down-break">
                    <input
                      type="text"
                      class="form-control"
                      name="firstName"
                      id="firstNameLabel"
                      required
                      placeholder="Your first name"
                      aria-label="Your first name"
                      v-model="first_name"
                    />
                    <input
                      type="text"
                      class="form-control"
                      name="lastName"
                      id="lastNameLabel"
                      required
                      placeholder="Your last name"
                      aria-label="Your last name"
                      v-model="last_name"
                    />
                  </div>
                </div>
              </div>
              <!-- End Form Group -->
              <div class="d-flex justify-content-end">
                <button type="submit" class="btn btn-primary" :disabled="basicInformationSubmitting">Save changes</button>
              </div>
            </form>
            <!-- End Form -->
          </div>
          <!-- End Body -->
        </div>
        <!-- End Card -->

        <!-- Card -->
        <div id="passwordSection" class="card mb-3 mb-lg-5">
          <div class="card-header">
            <h4 class="card-title">Change your password</h4>
          </div>

          <!-- Body -->
          <div class="card-body">
            <!-- Form -->
            <form id="changePasswordForm" @submit.prevent="updateUserPassword">
              <dashboard-error v-if="passwordError" :message="passwordError" />
              <dashboard-success v-if="showPasswordSuccess" message="Password is changed successfully." />
              <!-- Form Group -->
              <div class="row form-group">
                <label for="currentPasswordLabel" class="col-sm-3 col-form-label input-label">Current password</label>

                <div class="col-sm-9">
                  <input
                    v-model="current_password"
                    type="password"
                    class="form-control"
                    name="currentPassword"
                    id="currentPasswordLabel"
                    required
                    placeholder="Enter current password"
                    aria-label="Enter current password"
                  />
                </div>
              </div>
              <!-- End Form Group -->

              <!-- Form Group -->
              <div class="row form-group">
                <label for="signupSrPassword" class="col-sm-3 col-form-label input-label">New password</label>

                <div class="col-sm-9">
                  <input
                    v-model="password"
                    type="password"
                    class="js-pwstrength form-control"
                    name="signupSrPassword"
                    id="signupSrPassword"
                    required
                    placeholder="Enter new password"
                    aria-label="Enter new password"
                    data-hs-pwstrength-options='{
                               "ui": {
                                 "container": "#changePasswordForm",
                                 "viewports": {
                                   "progress": "#passwordStrengthProgress",
                                   "verdict": "#passwordStrengthVerdict"
                                 }
                               }
                             }'
                  />

                  <p id="passwordStrengthVerdict" class="form-text mb-2"></p>

                  <div id="passwordStrengthProgress"></div>
                </div>
              </div>
              <!-- End Form Group -->

              <!-- Form Group -->
              <div class="row form-group">
                <label for="signupSrConfirmPassword" class="col-sm-3 col-form-label input-label"> Confirm new password </label>

                <div class="col-sm-9">
                  <div class="mb-3">
                    <input
                      v-model="password_confirmation"
                      type="password"
                      class="form-control"
                      name="confirmPassword"
                      id="signupSrConfirmPassword"
                      required
                      placeholder="Confirm your new password"
                      aria-label="Confirm your new password"
                    />
                  </div>
                </div>
              </div>
              <!-- End Form Group -->

              <div class="d-flex justify-content-end">
                <button type="submit" class="btn btn-primary" :disabled="passwordSubmitting">Save Changes</button>
              </div>
            </form>
            <!-- End Form -->
          </div>
          <!-- End Body -->
        </div>
        <!-- End Card -->

        <!-- Card -->
        <div id="deleteAccountSection" class="card mb-3 mb-lg-5">
          <div class="card-header">
            <h4 class="card-title">Delete your account</h4>
          </div>

          <!-- Body -->
          <div class="card-body">
            <p class="card-text">
              When you delete your account, you lose access to On Site Message account services, and we permanently delete your personal
              data.
            </p>

            <div class="form-group">
              <!-- Custom Checkbox -->
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="deleteAccountCheckbox" />
                <label class="custom-control-label" for="deleteAccountCheckbox">Confirm that I want to delete my account.</label>
              </div>
              <!-- End Custom Checkbox -->
            </div>

            <div class="d-flex justify-content-end">
              <a class="btn btn-white mr-2" href="#">Learn more <i class="tio-open-in-new ml-1"></i></a>

              <button type="submit" class="btn btn-danger">Delete</button>
            </div>
          </div>
          <!-- End Body -->
        </div>
        <!-- End Card -->

        <!-- Sticky Block End Point -->
        <div id="stickyBlockEndPoint"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import advancedSelectMixin from "@/mixins/advanced-select-mixin"
import HSScrollspy from "@/theme-files/hs-scrollspy.min"
// import HSStickyBlock from "@/theme-files/hs-sticky-block.min"
import { mapActions, mapState } from "vuex"
import DashboardError from "@/components/dashboard/DashboardError"
import DashboardSuccess from "@/components/dashboard/DashboardSuccess"

export default {
  name: "ProfileSettings",
  components: { DashboardSuccess, DashboardError, DashboardTitle },
  data() {
    return {
      first_name: "",
      last_name: "",
      showBasicInformationSuccess: false,
      basicInformationError: "",
      basicInformationSubmitting: false,

      current_password: "",
      password: "",
      password_confirmation: "",
      showPasswordSuccess: false,
      passwordError: "",
      passwordSubmitting: false
    }
  },
  computed: mapState("user", ["user"]),
  mixins: [advancedSelectMixin],
  methods: {
    ...mapActions("user", ["update", "updatePassword"]),
    async updateUser() {
      this.basicInformationError = ""
      this.showBasicInformationSuccess = false
      this.basicInformationSubmitting = true
      try {
        await this.update({ first_name: this.first_name, last_name: this.last_name })
        this.showBasicInformationSuccess = true
      } catch (e) {
        this.basicInformationError = e
      }
      this.basicInformationSubmitting = false
    },
    async updateUserPassword() {
      this.passwordError = ""
      this.showPasswordSuccess = false
      this.passwordSubmitting = true
      try {
        await this.updatePassword({
          current_password: this.current_password,
          password: this.password,
          password_confirmation: this.password_confirmation
        })
        this.showPasswordSuccess = true
      } catch (e) {
        this.passwordError = e
      }
      this.passwordSubmitting = false
    }
  },
  mounted() {
    this.first_name = this.user.first_name
    this.last_name = this.user.last_name
    this.$nextTick(() => {
      require("@/theme-files/hs.pwstrength")
      this.initAdvancedSelect()
      new HSScrollspy($("body"), {
        // !SETTING "resolve" PARAMETER AND RETURNING "resolve('completed')" IS REQUIRED
        beforeScroll: function (resolve) {
          if (window.innerWidth < 992) {
            $("#navbarVerticalNavMenu")
              .collapse("hide")
              .on("hidden.bs.collapse", function () {
                return resolve("completed")
              })
          } else {
            return resolve("completed")
          }
        }
      }).init()

      // $(".js-sticky-block").each(function() {
      //   new HSStickyBlock($(this), {
      //     targetSelector: $("#header").hasClass("navbar-fixed") ? "#header" : null
      //   }).init()
      // })

      $(".js-pwstrength").each(function () {
        $.HSCore.components.HSPWStrength.init($(this))
      })
    })
  }
}
</script>

<style scoped></style>
