<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div v-for="(andRows, key1) in value" :key="key1">
      <div class="d-flex align-items-center my-2" v-for="(row, key2) in andRows" :key="key2">
        <span class="mr-2 flex-shrink-0">If the visitor has</span>
        <select
          class="mr-2 form-control form-control-sm"
          :value="row.action"
          @change="(e) => setRowData(key1, key2, 'action', e.target.value)"
        >
          <option value="impress">Seen</option>
          <option value="not-impress">Not Seen</option>
          <option value="submit">Submitted</option>
          <option value="not-submit">Not Submitted</option>
        </select>
        <select class="form-control form-control-sm mr-3" @change="(e) => setRowData(key1, key2, 'campaignId', e.target.value)">
          <option v-for="campaign in campaigns" :key="campaign.id" :value="campaign.id" :selected="campaign.id === row.id">
            {{ campaign.name }}
          </option>
        </select>
        <div class="flex-shrink-0 d-flex justify-content-between align-items-center" style="width: 7rem">
          <small class="mr-1 font-weight-bold">AND</small>
          <button
            type="button"
            class="btn btn-icon btn-soft-primary btn-sm mr-1"
            v-if="key2 === andRows.length - 1"
            @click="addAndRow(key1)"
          >
            <i class="tio-add"></i>
          </button>
          <button class="btn btn-icon btn-soft-danger btn-sm" @click="removeRow(key1, key2)" v-if="andRows.length > 1 || value.length > 1">
            <i class="tio-remove-from-trash"></i>
          </button>
        </div>
      </div>
      <div class="my-3 text-center">
        <div class="mb-2">OR</div>
      </div>
    </div>
    <div class="mt-3 text-center">
      <button type="button" class="btn btn-icon btn-soft-primary btn-sm" @click="addOrRow">
        <i class="tio-add"></i>
      </button>
    </div>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"
import { mapState } from "vuex"

const mixin = targetingRuleModalMixin("who", targetingRuleNames.whoRules.linking)

export default {
  name: "LinkingModal",
  components: { ModalCampaignTargetingRule },
  mixins: [mixin],
  computed: {
    ...mapState("campaigns", ["campaigns"])
  },
  methods: {
    addAndRow(key) {
      this.value[key].push({ action: "impress", campaignId: this.campaigns[0].id })
      this.value = [...this.value]
    },
    addOrRow() {
      this.value.push([{ action: "impress", campaignId: this.campaigns[0].id }])
      this.value = [...this.value]
    },
    setRowData(key1, key2, key3, value) {
      this.value[key1][key2][key3] = value
      this.value = [...this.value]
    },
    removeRow(key1, key2) {
      if (this.value[key1].length === 1) {
        this.value.splice(key1, 1)
      } else {
        let tmp = this.value[key1]
        tmp.splice(key2, 1)
        this.value[key1] = tmp
      }
      this.value = [...this.value]
    }
  }
}
</script>
