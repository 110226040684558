import randomId from "@/lib/utils/random-id"
import store from "@/store"

class ElementManager {
  constructor(element, availableStyles, variablePrefix) {
    this.availableStyles = availableStyles
    this.variablePrefix = variablePrefix
    if (element) {
      this.pageNumber = element.closest(".page").dataset.page

      if (!element.id) {
        element.id = randomId()
      }

      this.elementId = element.id
      this.element = element
      this.ensureStyleVariablesDefinedOnElement()
      this.generateStyleVariablesIfNotExist()
    }
  }

  ensureStyleVariablesDefinedOnElement() {
    Object.keys(this.availableStyles).forEach((cssAttr) => {
      if (!this.element.style.getPropertyValue(cssAttr)) {
        this.element.style.setProperty(cssAttr, `var(${this.getStyleVariableName(cssAttr)})`)
      }
    })
  }

  generateStyleVariablesIfNotExist() {
    const shouldHaveList = this.getListOfShouldHaveStyleVariables()
    const styleVariables = store.state.builder.styleVariables
    const { style } = styleVariables[this.pageNumber]

    let toAdd = []
    Object.keys(style).forEach((env) => {
      shouldHaveList.forEach((shouldHaveAttr) => {
        let value = this.getDefaultStyleValue(shouldHaveAttr)
        if (typeof value === "object") {
          value = value[env]
        }

        if (!(shouldHaveAttr in style[env])) {
          toAdd.push({ env: env, variable: shouldHaveAttr, value: value })
        }
      })
    })

    store.commit("builder/setBulkStyleVariableWithoutAddingHistory", toAdd)
  }

  getStyleVariableName(cssProperty) {
    if (!(cssProperty in this.availableStyles)) {
      throw Error("Unknown Button CSS Property: " + cssProperty)
    }
    return "--" + this.variablePrefix + this.elementId + "-" + cssProperty
  }

  getListOfShouldHaveStyleVariables() {
    return Object.keys(this.availableStyles).map((attr) => this.getStyleVariableName(attr))
  }

  getDefaultStyleValue(variable) {
    return this.availableStyles[variable.replace("--" + this.variablePrefix + this.elementId + "-", "")]
  }
}

export default ElementManager
