<template>
  <select class="form-control form-control-sm w-auto" :value="value" @input="(e) => $emit('input', e)" @change="(e) => $emit('change', e)">
    <option :value="numberOperator" :key="numberOperator" v-for="numberOperator in Object.keys(numericCompareOperators)">
      {{ numericCompareOperators[numberOperator] }}
    </option>
  </select>
</template>

<script>
import numericCompareOperators from "@/components/campaign-targeting-rules/numericCompareOperators"

export default {
  name: "TargetingNumericOperatorSelector",
  data() {
    return {
      numericCompareOperators: numericCompareOperators
    }
  },
  props: ["value"]
}
</script>
