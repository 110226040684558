<template>
  <div class="d-flex pb-3 align-items-center border-bottom mb-4">
    <button class="btn btn-ghost-dark btn-sm btn-icon mr-3" @click="closeSetArea">
      <i class="tio-chevron-left tio-lg"></i>
    </button>
    <h4 class="whitespace-nowrap mb-0">
      <slot />
    </h4>
  </div>
</template>

<script>
export default {
  name: "SettingsTitle",
  methods: {
    closeSetArea() {
      this.$store.state.builder.isSetAreaActive = false
      this.$store.state.builder.setAreaTopic = ""
    }
  }
}
</script>
