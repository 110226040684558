<template>
  <img class="img-fluid" style="width: 100%; height: 100%; object-fit: cover" :src="getIcon" />
</template>

<script>
import convertKit from "@/icons/integrations/convertkit.svg"
import drip from "@/icons/integrations/drip.svg"
import getResponse from "@/icons/integrations/getresponse.svg"
import hubspot from "@/icons/integrations/hubspot.svg"
import icontact from "@/icons/integrations/icontact.svg"
import klaviyo from "@/icons/integrations/klaviyo.svg"
import mailchimp from "@/icons/integrations/mailchimp.svg"
import moosend from "@/icons/integrations/moosend.svg"
import omnisend from "@/icons/integrations/omnisend.svg"
import ontraport from "@/icons/integrations/ontraport.svg"
import pipedrive from "@/icons/integrations/pipedrive.svg"
import sendinblue from "@/icons/integrations/sendinblue.svg"
import slack from "@/icons/integrations/slack.svg"
import webhook from "@/icons/integrations/webhook.svg"
import mailjet from "@/icons/integrations/mailjet.svg"

export default {
  name: "IntegrationImg",
  props: {
    integrationType: String
  },
  computed: {
    getIcon() {
      return {
        Webhook: webhook,
        Slack: slack,
        Hubspot: hubspot,
        Klaviyo: klaviyo,
        Omnisend: omnisend,
        Sendinblue: sendinblue,
        Mailchimp: mailchimp,
        Moosend: moosend,
        Pipedrive: pipedrive,
        GetResponse: getResponse,
        IContact: icontact,
        Ontraport: ontraport,
        ConvertKit: convertKit,
        Drip: drip,
        MailJet: mailjet
      }[this.integrationType]
    }
  }
}
</script>
