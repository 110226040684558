<template>
  <a class="btn btn-lg btn-block btn-white mb-4" @click="redirect">
    <span class="d-flex justify-content-center align-items-center">
      <icon-google class="avatar avatar-xss mr-2" />
      {{ message }}
    </span>
  </a>
</template>

<script>
import userAPI from "@/api/user"
import IconGoogle from "@/theme-files/assets/svg/brands/google.svg?inline"

export default {
  name: "GoogleSignInButton",
  components: {
    IconGoogle
  },
  props: {
    redirectRouteName: String,
    message: String
  },
  methods: {
    async redirect() {
      window.localStorage.setItem("oauth-redirect", this.$router.resolve({ name: this.redirectRouteName }).href)
      const response = await userAPI.fetchGoogleOAuthUrl()
      const { redirect_url } = response.data.data
      window.location.href = redirect_url
    }
  }
}
</script>
