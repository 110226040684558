<template>
  <div>
    <settings-background-color variable="--body-background" />
    <settings-template title="Position"
      ><select class="form-control" v-if="device === 'desktop'" v-model="desktopPositionClass" @change="changePositionClass">
        <option v-for="positionClass in Object.keys(desktopAvailablePositionClassList)" :key="positionClass" :value="positionClass">
          {{ desktopAvailablePositionClassList[positionClass] }}
        </option>
      </select>
      <select class="form-control" v-if="device === 'mobile'" v-model="mobilePositionClass" @change="changePositionClass">
        <option v-for="positionClass in Object.keys(mobileAvailablePositionClassList)" :key="positionClass" :value="positionClass">
          {{ mobileAvailablePositionClassList[positionClass] }}
        </option>
      </select>
    </settings-template>
    <settings-font-family variable="--layout-font-family" />
    <settings-padding variable="--body-padding"></settings-padding>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "Layout",
  components: { SettingsTemplate },
  props: { device: String },
  computed: {
    ...mapGetters("builder", ["getPageSelector", "getIframeDocument"])
  },
  data() {
    return {
      desktopAvailablePositionClassList: {
        "d-top-overlap-sticky": "Top Overlap Sticky",
        "d-top-push-down-sticky": "Top Push Down Sticky",
        "d-top-overlap": "Top Overlap",
        "d-top-push-down": "Top Push Down"
      },
      mobileAvailablePositionClassList: {
        "m-top-overlap-sticky": "Top Overlap Sticky",
        "m-top-push-down-sticky": "Top Push Down Sticky",
        "m-top-overlap": "Top Overlap",
        "m-top-push-down": "Top Push Down"
      },
      positionElement: null,
      desktopPositionClass: "",
      mobilePositionClass: ""
    }
  },
  methods: {
    changePositionClass() {
      this.positionElement.className = `position ${this.desktopPositionClass} ${this.mobilePositionClass}`
    }
  },
  mounted() {
    this.positionElement = this.getIframeDocument.querySelector(`${this.getPageSelector} [data-position]`)
    Object.keys(this.desktopAvailablePositionClassList).forEach((cl) => {
      if (this.positionElement.classList.contains(cl)) {
        this.desktopPositionClass = cl
      }
    })
    Object.keys(this.mobileAvailablePositionClassList).forEach((cl) => {
      if (this.positionElement.classList.contains(cl)) {
        this.mobilePositionClass = cl
      }
    })
  }
}
</script>
