<template>
  <settings-content-appearance>
    <template #content>
      <settings-template title="Choose Your Image">
        <float-images :allow-only-change="true" images-class="images"></float-images>
      </settings-template>
      <settings-template title="Button Actions">
        <single-button
          :button-area-selector="`${getPageSelector} .images`"
          :inner-editable="false"
          :show-title="false"
          button-class="button-image"
        />
      </settings-template>
    </template>
    <template #appearance>
      <settings-device-selector></settings-device-selector>
      <settings-template :key="appearanceDevice">
        <appearance-images :show-title="false" images-class="images"></appearance-images>
      </settings-template>
    </template>
  </settings-content-appearance>
</template>

<script>
import FloatImages from "@/components/campaign-builder/widget-settings/FloatImages"
import AppearanceImages from "@/components/campaign-builder/appearance-changers/Images"
import SettingsContentAppearance from "@/components/campaign-builder/SettingsContentAppearance"
import SettingsDeviceSelector from "@/components/campaign-builder/SettingsDeviceSelector"
import SingleButton from "@/components/campaign-builder/content-changers/SingleButton"
import { mapGetters, mapMutations, mapState } from "vuex"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "ImageButtonSettings",
  components: {
    SettingsTemplate,
    SingleButton,
    SettingsDeviceSelector,
    SettingsContentAppearance,
    FloatImages,
    AppearanceImages
  },
  computed: {
    ...mapGetters("builder", ["getPageSelector"]),
    ...mapState("builder", ["appearanceDevice"])
  },
  methods: mapMutations("builder", ["setAppearanceDevice"]),
  created() {
    this.setAppearanceDevice("mobile")
  }
}
</script>

<style scoped></style>
