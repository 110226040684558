<template>
  <div>
    <div class="text-left mb-3">
      <ul class="nav nav-segment d-flex">
        <li class="nav-item flex-grow-1">
          <a
            class="nav-link justify-content-center"
            @click="type = 'transparent'"
            :class="{ active: type === 'transparent' }"
            href="javascript:;"
            data-toggle="bg-color-type"
            role="tab"
            >Transparent</a
          >
        </li>
        <li class="nav-item flex-grow-1">
          <a
            class="nav-link justify-content-center"
            @click="type = 'solid'"
            :class="{ active: type === 'solid' }"
            href="javascript:;"
            data-toggle="bg-color-type"
            role="tab"
            >Solid</a
          >
        </li>
        <li class="nav-item flex-grow-1">
          <a
            class="nav-link justify-content-center"
            @click="type = 'linear-gradient'"
            href="javascript:;"
            :class="{ active: type === 'linear-gradient' }"
            data-toggle="bg-color-type"
            role="tab"
            >Gradient</a
          >
        </li>
      </ul>
    </div>
    <div :key="type">
      <div class="color" v-if="type === 'solid'">
        <settings-template :show-device-picker="false" title="Color" layout="horizontal">
          <Color :env="env" :variable="variable" />
        </settings-template>
      </div>
      <div v-if="type === 'linear-gradient'">
        <color-gradient :env="env" :variable="variable" />
      </div>
    </div>
  </div>
</template>
<script>
import Color from "@/components/campaign-builder/appearance-changers/Color"
import ColorGradient from "@/components/campaign-builder/appearance-changers/ColorGradient"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "BackgroundColor",
  components: { SettingsTemplate, ColorGradient, Color },
  mixins: [appearanceChangerMixin],
  computed: {
    type: {
      get() {
        const data = this.getStyleVariableValue(this.env, this.variable, 0).trim()
        if (data === "transparent") {
          return "transparent"
        } else if (data.includes("linear-gradient")) {
          return "linear-gradient"
        } else {
          return "solid"
        }
      },
      set(type) {
        let currentColor = this.getStyleVariableValue(this.env, this.variable, 0)
        if (currentColor.includes("linear-gradient")) {
          currentColor = currentColor.substring(currentColor.indexOf("(") + 1, currentColor.lastIndexOf(")"))
          currentColor = currentColor.split(/,(?![^(]*\))(?![^"']*["'](?:[^"']*["'][^"']*["'])*[^"']*$)/)[1].trim()
        }
        console.log(currentColor)
        if (type === "transparent") {
          this.setColor("transparent")
        } else if (type === "linear-gradient") {
          this.setColor(`linear-gradient(90deg, ${currentColor}, ${currentColor})`)
        } else if (type === "solid") {
          if (currentColor === "transparent") {
            currentColor = "#fff"
          }
          this.setColor(currentColor)
        }
      }
    }
  },
  methods: {
    setColor(c) {
      this.setStyleVariableDirect({
        env: this.env,
        value: c,
        variable: this.variable
      })
    }
  }
}
</script>
