import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const targetingRuleTexts = { who: {}, when: {} }

const addValue = (type, name, value) => {
  targetingRuleTexts[type][name] = value
}

const addWhoRulesDefaultValue = ({ name, value }) => {
  addValue("who", name, value)
}

const addWhenRulesDefaultValue = ({ name, value }) => {
  addValue("when", name, value)
}

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.returningOrNew,
  value: "returning"
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.linking,
  value: []
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.currentUrl,
  value: [[{ filter: "equals", value: "" }]]
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.ipAllowed,
  value: [{ type: "exact", value: "0.0.0.0" }]
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.ipBlocked,
  value: [{ type: "exact", value: "0.0.0.0" }]
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.adblock,
  value: "detected"
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.visitedUrl,
  value: [[{ filter: "equals", value: "" }]]
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.lastVisitedUrl,
  value: [{ filter: "equals", value: "" }]
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.excludeByVisit,
  value: [{ filter: "equals", value: "", time: 0 }]
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.device,
  value: ["desktop", "mobile", "tablet", "tv"]
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.source,
  value: [{ type: "name", value: "googleAds", filter: "equals" }]
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.operatingSystem,
  value: ["iOS", "MacOS", "Windows", "Android"]
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.numberOfVisitedPages,
  value: { value: 10, filter: "minimum" }
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.timeSpentOnPage,
  value: 60000
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.timeSpentOnSite,
  value: 600000
})

addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.cart,
  value: [
    { compareWith: "value", filter: "greaterThanOrEquals", value: 0 },
    { compareWith: "numberOfProducts", filter: "greaterThanOrEquals", value: 0 },
    { compareWith: "numberOfDifferentProducts", filter: "greaterThanOrEquals", value: 0 }
  ]
})
addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.frequency,
  value: { betweenMilliseconds: 60000, maxCount: 3 }
})
addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.location,
  value: {
    criteria: "is",
    geolocations: [
      {
        country: null,
        sub_div1: null,
        sub_div2: null,
        city: null
      }
    ]
  }
})
addWhoRulesDefaultValue({
  name: targetingRuleNames.whoRules.browserLanguage,
  value: ["en", "tr"]
})
addWhenRulesDefaultValue({
  name: targetingRuleNames.whenRules.afterMilliseconds,
  value: 3000
})

addWhenRulesDefaultValue({
  name: targetingRuleNames.whenRules.exitIntent,
  value: true
})
addWhenRulesDefaultValue({
  name: targetingRuleNames.whenRules.inactivity,
  value: 3000
})

addWhenRulesDefaultValue({
  name: targetingRuleNames.whenRules.scrollPercentage,
  value: 10
})
addWhenRulesDefaultValue({
  name: targetingRuleNames.whenRules.clickTo,
  value: "#add-to-cart-button"
})

export default targetingRuleTexts
