<template>
  <!--  TODO: use base accordion to create this-->
  <div class="accordion my-5">
    <div class="rounded-lg shadow-soft border">
      <a class="btn-block collapsed" href="javascript:;" data-toggle="collapse" :data-target="`#${id}`">
        <div class="d-flex justify-content-between align-items-center w-full h-full px-4" style="height: 3.5rem">
          <div class="d-flex align-items-center flex-fill" v-if="showDevicePicker">
            <slot name="title" />
            <settings-device-picker v-if="showDevicePicker" />
          </div>
          <div class="flex-fill" v-else>
            <slot name="title" />
          </div>
          <span class="card-btn-toggle">
            <span class="card-btn-toggle-default"><i class="tio-add"></i></span>
            <span class="card-btn-toggle-active"><i class="tio-remove"></i></span>
          </span>
        </div>
      </a>

      <div :id="id" class="collapse">
        <div class="py-3 px-4 border-top mt-1">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import randomId from "@/lib/utils/random-id"
import SettingsDevicePicker from "@/components/campaign-builder/SettingsDevicePicker"

export default {
  name: "SettingsAccordion",
  components: { SettingsDevicePicker },
  props: {
    showDevicePicker: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: randomId()
    }
  },
  mounted() {
    if (!window.OSMAccordions) window.OSMAccordions = []
    window.OSMAccordions.push(this.id)

    // TODO: activate this in future
    // $(`#${this.id}`).on("show.bs.collapse", function () {
    //   window.OSMAccordions.forEach((id) => {
    //     $(`#${id}`).collapse("hide")
    //   })
    // })
  },
  beforeDestroy() {
    const i = window.OSMAccordions.findIndex((i) => i.id === this.id)
    window.OSMAccordions.splice(i, 1)
  }
}
</script>

<style scoped>
.card-btn-toggle-default {
  display: none;
}

.card-btn-toggle-active {
  display: block;
}

.collapsed .card-btn-toggle-default {
  display: block;
}

.collapsed .card-btn-toggle-active {
  display: none;
}
</style>
