<template>
  <form method="post" @validSubmit="$emit('submit')" class="js-validate">
    <slot />
  </form>
</template>

<script>
export default {
  name: "BaseForm",
  mounted() {
    this.$nextTick(() => {
      this.$emit("ready")
    })
  }
}
</script>

<style scoped></style>
