<template>
  <div>
    <div class="mb-2 row">
      <div class="col-4">
        <select v-model="horizontalAttribute" class="form-control form-control-sm" @change="setPositionAfterChange">
          <option value="right">Right</option>
          <option value="left">Left</option>
        </select>
      </div>
      <div class="col-8">
        <range-input-with-unit
          v-if="horizontal"
          :min="0"
          :max="100"
          :alternative-units="['px']"
          v-model="horizontal"
          @update="setPositionAfterChange"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <select v-model="verticalAttribute" class="form-control form-control-sm" @change="setPositionAfterChange">
          <option value="top">Top</option>
          <option value="bottom">Bottom</option>
        </select>
      </div>
      <div class="col-8">
        <range-input-with-unit
          v-if="vertical"
          :min="0"
          :max="100"
          :alternative-units="['px']"
          v-model="vertical"
          @update="setPositionAfterChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import RangeInputWithUnit from "@/components/common/RangeInputWithUnit"

export default {
  name: "TwoDirectionPosition",
  components: { RangeInputWithUnit,  },
  mixins: [appearanceChangerMixin],
  data() {
    return {
      horizontalAttribute: null,
      verticalAttribute: null,
      horizontal: null,
      vertical: null,

      topVariable: "",
      bottomVariable: "",
      leftVariable: "",
      rightVariable: ""
    }
  },
  watch: {
    env() {
      this.init()
    }
  },
  methods: {
    setPositionAfterChange() {
      if (this.horizontalAttribute === "right") {
        this.set(this.leftVariable, "unset")
        this.set(this.rightVariable, this.horizontal.value + this.horizontal.unit)
      } else {
        this.set(this.rightVariable, "unset")
        this.set(this.leftVariable, this.horizontal.value + this.horizontal.unit)
      }

      if (this.verticalAttribute === "top") {
        this.set(this.bottomVariable, "unset")
        this.set(this.topVariable, this.vertical.value + this.vertical.unit)
      } else {
        this.set(this.topVariable, "unset")
        this.set(this.bottomVariable, this.vertical.value + this.vertical.unit)
      }
    },
    set(variable, value) {
      this.setStyleVariableDirect({
        env: this.env,
        variable: variable,
        value
      })
    },
    init() {
      this.topVariable = this.variable.top
      this.bottomVariable = this.variable.bottom
      this.leftVariable = this.variable.left
      this.rightVariable = this.variable.right

      const topValue = this.getStyleVariableValue(this.env, this.topVariable, 1)
      const bottomValue = this.getStyleVariableValue(this.env, this.bottomVariable, 1)
      if (topValue.value === "unset") {
        this.verticalAttribute = "bottom"
        this.vertical = bottomValue
      } else {
        this.verticalAttribute = "top"
        this.vertical = topValue
      }

      const leftValue = this.getStyleVariableValue(this.env, this.leftVariable, 1)
      const rightValue = this.getStyleVariableValue(this.env, this.rightVariable, 1)
      if (leftValue.value === "unset") {
        this.horizontalAttribute = "right"
        this.horizontal = rightValue
      } else {
        this.horizontalAttribute = "left"
        this.horizontal = leftValue
      }
    }
  },
  created() {
    this.init()
  }
}
</script>
