<template>
  <settings-template :title="title" :layout="layout" :show-device-picker="showDevicePicker">
    <two-direction-position :variable="variable" />
  </settings-template>
</template>
<script>
import TwoDirectionPosition from "@/components/campaign-builder/appearance-changers/TwoDirectionPosition"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"

export default {
  name: "SettingsTwoDirectionPosition",
  components: { SettingsTemplate, TwoDirectionPosition },
  mixins: [appearanceChangerMixin],
  props: {
    variable: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: "Position"
    }
  }
}
</script>
