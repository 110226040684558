import generateAPIErrorMessage from "@/api/generate-api-error-message"
import siteAPI from "@/api/site"

function initialState() {
  return {
    sites: [],
    selectedSite: {}
  }
}
const sites = {
  state: initialState,
  getters: {
    getSelectedSite: (state) => state.selectedSite,
    getIdOfSelectedSite: (state) => state.selectedSite?.id,
    getInsertCode: (state, getters, rootState) => (site) => {
      return `<script type="text/javascript" src="${process.env.VUE_APP_API_BASE}/front/load/${rootState["user"].user.id}/${site.id}" async></script>`
    }
  },
  mutations: {
    resetState(state) {
      Object.assign(state, initialState())
    },
    setSites(state, payload) {
      state.sites = payload
    },
    setSelectedSite(state, payload) {
      state.selectedSite = payload
      window.localStorage.setItem("activeSiteId", payload.id)
    },
    setSelectedSiteBySiteId(state, payload) {
      state.selectedSite = state.sites.find((site) => site.id === parseInt(payload))
      window.localStorage.setItem("activeSiteId", payload)
    }
  },
  actions: {
    async fetchSites({ commit, getters }) {
      return siteAPI
        .fetchSites()
        .then((resp) => {
          const sites = resp.data.data.sites
          commit("setSites", sites)
          if (sites.length > 0) {
            if (
              !getters.getSelectedSite ||
              !("id" in getters.getSelectedSite) ||
              sites.findIndex((item) => item.id === getters.getSelectedSite.id) === -1
            ) {
              if (window.localStorage.getItem("activeSiteId")) {
                const site = sites.find((site) => parseInt(site.id) === parseInt(window.localStorage.getItem("activeSiteId")))
                if (site) commit("setSelectedSite", site)
                else commit("setSelectedSite", sites[0])
              } else {
                commit("setSelectedSite", sites[0])
              }
            }
          }
        })
        .catch((err) => {
          console.log()
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async addSite({ dispatch }, domain) {
      return siteAPI
        .addSite(domain)
        .then(async (resp) => {
          await dispatch("fetchSites")
          return resp.data.data.site
        })
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async updateSite({ dispatch }, { siteId, domain }) {
      return siteAPI
        .updateSite(siteId, { domain })
        .then(async (resp) => {
          await dispatch("fetchSites")
          return resp.data.data.site
        })
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    }
  },
  namespaced: true
}
export default sites
