<template>
  <div class="card mb-3 mb-lg-5">
    <div class="card-header">
      <h3>Campaign Name</h3>
    </div>
    <div class="card-body">
      <div class="d-flex align-items-center">
        <input class="form-control" v-model="campaignName" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex"

export default {
  name: "CampaignSettingsName",
  computed: {
    ...mapState("campaignSettings", ["name"]),
    campaignName: {
      get() {
        return this.name
      },
      set(v) {
        this.setName(v)
      }
    }
  },
  methods: mapMutations("campaignSettings", ["setName"])
}
</script>
