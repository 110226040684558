<template>
  <base-campaign-targeting-rule>
    <div>After a visitor has scrolled {{ data }}% down on the current page.</div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("when", targetingRuleNames.whenRules.scrollPercentage)

export default {
  name: "TargetingScrollPercentage",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin]
}
</script>
