import randomId from "@/lib/utils/random-id"
import { getImportUrl } from "@/lib/utils/google-font"
import wpHtml from "!raw-loader!./whatsapp/html.html"

class whatsappHtml {
  get() {
    const fontUrl = getImportUrl(["Montserrat"])
    return wpHtml.replaceAll("PARENT_ID", randomId()).replaceAll("FONT_URL", fontUrl)
  }
}

export { whatsappHtml }
