const generateAPIErrorMessage = function (axiosErr) {
  let errorMessage = ""
  const data = axiosErr.response.data
  if ("message" in data) {
    errorMessage += axiosErr.response.data.message + "\n"
  }
  if ("errors" in data) {
    Object.values(axiosErr.response.data.errors).forEach((errInfo) => {
      Object.values(errInfo).forEach((errText) => {
        errorMessage += errText + "\n"
      })
    })
  }
  if ("data" in data && "error" in data.data && "message" in data.data.error) {
    errorMessage += axiosErr.response.data.data.error.message
  }
  if (errorMessage === "") {
    errorMessage = "An error happened."
  }
  return errorMessage.trim()
}
export default generateAPIErrorMessage
