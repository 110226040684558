<template>
  <div class="position-relative">
    <div class="buttons" v-show="!isSetAreaActive">
      <div class="text-center mb-4">
        <ul class="nav nav-segment nav-pills w-100 d-flex" role="tablist">
          <li class="nav-item flex-grow-1">
            <a
              class="nav-link active justify-content-center"
              id="nav-one-eg1-tab"
              data-toggle="pill"
              href="#nav-one-eg1"
              role="tab"
              aria-controls="nav-one-eg1"
              aria-selected="true"
              data-area="content"
              >Content</a
            >
          </li>
          <li class="nav-item flex-grow-1">
            <a
              class="nav-link justify-content-center"
              id="nav-two-eg1-tab"
              data-toggle="pill"
              href="#nav-two-eg1"
              role="tab"
              aria-controls="nav-two-eg1"
              aria-selected="false"
              data-area="appearance"
              >Appearance</a
            >
          </li>
        </ul>
      </div>

      <div class="tab-content">
        <div class="tab-pane fade show active" id="nav-one-eg1" role="tabpanel" aria-labelledby="nav-one-eg1-tab">
          <slot name="content-buttons" />
        </div>

        <div class="tab-pane fade" id="nav-two-eg1" role="tabpanel" aria-labelledby="nav-two-eg1-tab">
          <slot name="appearance-buttons" />
        </div>
      </div>
    </div>
    <div class="content" :class="isSetAreaActive ? 'active' : ''">
      <div class="position-relative">
        <button class="btn position-absolute left-0 px-0 py-0 btn-soft-secondary rounded-circle" style="top: 0.3rem" @click="closeSetArea">
          <icon-chevron-left class="close-button" />
        </button>
        <div v-if="activeSettings === 'content'" :key="contentsKey">
          <slot name="content"></slot>
        </div>
        <div v-if="activeSettings === 'appearance'" :key="appearanceKey">
          <slot name="appearance"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import randomId from "@/lib/utils/random-id"
import IconChevronLeft from "@/icons/icon-chevron-left.svg?inline"
import { mapState } from "vuex"

export default {
  name: "SettingsWrapper",
  components: { IconChevronLeft },
  data() {
    return {
      contentsKey: "c",
      appearanceKey: "a"
    }
  },
  computed: {
    ...mapState("builder", ["isSetAreaActive", "activeSettings"])
  },
  methods: {
    closeSetArea() {
      this.$store.state.builder.isSetAreaActive = false
      this.$store.state.builder.setAreaTopic = ""
    }
  },
  created() {
    this.$store.watch(
      () => this.$store.state.builder.currentPage,
      () => {
        this.$store.state.builder.setAreaTopic = ""
        this.$store.state.builder.isSetAreaActive = false
        this.contentsKey = randomId()
        this.appearanceKey = randomId()
      }
    )
  },
  mounted() {
    this.$store.state.builder.activeSettings = "content"
    $("a[data-toggle='pill']").on("shown.bs.tab", (e) => {
      this.$store.state.builder.activeSettings = e.target.dataset.area
    })
  }
}
</script>
<style scoped>
.close-button {
  width: 1.5rem;
  height: 1.5rem;
}

.buttons {
  z-index: 5;
  height: 100%;
  width: 100%;
}

.content {
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(calc(-100% - 3.5rem));
  transition: 0.5s all;
}

.content.active {
  transform: translateX(0%);
}
</style>
