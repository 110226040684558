<template>
  <div>
    <div v-for="(message, key) in messages" :key="key" class="mb-4">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h5>Message {{ key + 1 }}</h5>
        <button class="btn btn-xs btn-soft-danger btn-icon" @click="removeMessage(key)">
          <i class="tio-clear" />
        </button>
      </div>
      <MiniEditor :align="false" :line-height="false" :value="message.text" @input="(msg) => setMessageText(key, msg)" />
    </div>
    <div class="text-center">
      <button class="btn btn-icon btn-soft-primary rounded-circle" @click="addNewMessage">
        <i class="tio-add" />
      </button>
    </div>
    <h5 class="mt-6">Not Understand Message</h5>
    <MiniEditor
      v-if="notUnderstandMessage !== null"
      :align="false"
      :line-height="false"
      :value="notUnderstandMessage"
      @input="(msg) => setNotUnderstandMessage(msg)"
    />
  </div>
</template>

<script>
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"
import { mapGetters } from "vuex"

export default {
  name: "Messages",
  components: { MiniEditor },
  data() {
    return {
      messages: [],
      notUnderstandMessage: null
    }
  },
  methods: {
    initialize() {
      const messages = this.getIframeDocument.querySelector(`${this.getPageSelector} .whatsapp-popup`).dataset.initialMessages
      this.messages = JSON.parse(messages)
      this.notUnderstandMessage = this.getIframeDocument.querySelector(
        `${this.getPageSelector} .whatsapp-popup`
      ).dataset.notUnderstandMessage
    },
    setMessageText(key, text) {
      this.messages[key].text = text
      this.setMessageDataToHtml()
    },
    setNotUnderstandMessage(text) {
      this.notUnderstandMessage = text
      this.setMessageDataToHtml()
    },
    setMessageDataToHtml() {
      if (this.notUnderstandMessage === "") {
        this.notUnderstandMessage = " "
      }
      if (
        this.messages.length <=
        this.getIframeDocument.querySelectorAll(`${this.getPageSelector} .whatsapp-popup .last-messages .message`).length
      ) {
        const doc = this.getIframeDocument
        doc.querySelector(`${this.getPageSelector} .whatsapp-popup`).setAttribute("data-initial-messages", JSON.stringify(this.messages))
        doc.querySelector(`${this.getPageSelector} .whatsapp-popup`).setAttribute("data-not-understand-message", this.notUnderstandMessage)
        doc.querySelectorAll(`${this.getPageSelector} .whatsapp-popup .last-messages .message`).forEach((e) => e.remove())
        this.messages.forEach((message) => {
          const p = document.createElement("p")
          p.classList.add("message", "from-first")
          p.innerHTML =
            `<div class="message-text">${message.text}</div><span class="message-time">` +
            new Date().getHours() +
            ":" +
            new Date().getHours() +
            "</span>"
          doc
            .querySelector(`${this.getPageSelector} .whatsapp-popup .last-messages .action-buttons`)
            .insertAdjacentElement("beforebegin", p)
        })
      }
    },
    removeMessage(key) {
      this.messages.splice(key, 1)
      this.setMessageDataToHtml()
    },
    addNewMessage() {
      this.messages.push({ text: "Write message here", from: "first" })
      const doc = this.getIframeDocument
      const p = doc.createElement("p")
      p.classList.add("message", "from-first")
      p.innerHTML =
        "<div class='message-text'><p>Write your message here...</p></div><span class='message-time'>" +
        new Date().getHours() +
        ":" +
        new Date().getHours() +
        "</span>"
      doc.querySelector(`${this.getPageSelector} .whatsapp-popup .last-messages .action-buttons`).insertAdjacentElement("beforebegin", p)
      this.setMessageDataToHtml()
      this.initialize()
    }
  },
  computed: {
    ...mapGetters("builder", ["getIframeDocument", "getPageSelector"])
  },
  mounted() {
    this.initialize()
  }
}
</script>
