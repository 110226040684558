<template>
  <div class="card mb-3 mb-lg-5">
    <modal-delete-confirmation
      v-if="showDeleteModal"
      @close="showDeleteModal = false"
      @cancel="showDeleteModal = false"
      @delete="
        deleteLeads()
        showDeleteModal = false
      "
    />
    <div class="card-header">
      <div class="row justify-content-between align-items-center flex-grow-1">
        <div class="col-9">
          <div class="row">
            <div class="col-4">
              <date-range-picker class="mr-5" @change="handleDatePickerChange" />
            </div>
            <div class="col-8">
              <div class="d-flex align-items-center">
                <span class="text-secondary mr-2">Campaigns:</span>
                <select
                  class="js-select2-custom js-datatable-filter custom-select"
                  size="1"
                  style="opacity: 0"
                  multiple
                  data-hs-select2-options='{"minimumResultsForSearch": "Infinity","placeholder": "All"}'
                >
                  <option
                    v-for="campaign in campaigns"
                    :key="campaign.id"
                    :value="campaign.id"
                    :selected="$route.query.campaignId ? parseInt($route.query.campaignId) === parseInt(campaign.id) : false"
                  >
                    {{ campaign.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="col-3">
          <div class="d-flex justify-content-end align-items-center">
            <div id="datatableCounterInfo" style="display: none">
              <div class="d-flex align-items-center">
                <span class="font-size-sm mr-3">
                  <span id="datatableCounter">0</span>
                  Selected
                </span>
                <a class="btn btn-sm btn-outline-danger" href="javascript:;" @click="showDeleteModal = true">
                  <i class="tio-delete-outlined"></i> Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <datatable-table-element :multiselect="true" :heads="['Person', 'Campaign', 'Date', 'Data']" />
  </div>
</template>

<script>
import dateFormatterMixin from "@/mixins/date-formatter-mixin"
import advancedSelectMixin from "@/mixins/advanced-select-mixin"
import leadAPI from "@/api/lead"
import { mapActions, mapState } from "vuex"
import ModalDeleteConfirmation from "@/components/modals/ModalDeleteConfirmation"
import clickSvg from "@/theme-files/assets/svg/illustrations/click.svg"
import DateRangePicker from "@/components/common/DateRangePicker"
import DatatableTableElement from "@/components/common/DatatableTableElement"

export default {
  name: "LeadsTable",
  components: { DatatableTableElement, DateRangePicker, ModalDeleteConfirmation },
  data() {
    return {
      startDate: null,
      endDate: null,
      datatable: null,
      campaignIds: [],
      showDeleteModal: false
    }
  },
  computed: {
    ...mapState("sites", ["selectedSite"]),
    ...mapState("campaigns", ["campaigns"])
  },
  methods: {
    ...mapActions("campaigns", ["fetchCampaignsOfCurrentSite"]),
    excel() {
      this.datatable.button(".buttons-excel").trigger()
    },
    handleDatePickerChange({ startDate, endDate }) {
      this.startDate = startDate.toISOString()
      this.endDate = endDate.toISOString()
      this.datatable.draw()
    },
    async deleteLeads() {
      const ids = []
      this.datatable.rows({ selected: true }).every(function () {
        const id = this.data().id
        ids.push(id)
      })

      await leadAPI.deleteLeads({ siteId: this.selectedSite.id, leadIds: ids })
      this.datatable.ajax.reload()
      $("#datatableCounterInfo").hide()
      $("#datatableCheckAll").prop("checked", false)
    }
  },
  mixins: [dateFormatterMixin, advancedSelectMixin],
  async mounted() {
    const vm = this
    await this.fetchCampaignsOfCurrentSite()
    this.initAdvancedSelect()
    require("@/theme-files/hs.datatables")
    const getFormattedDate = this.getFormattedDate
    const url = leadAPI.getLeadsDatatablesEndpoint({ siteId: this.selectedSite.id })
    this.datatable = $.HSCore.components.HSDatatables.init($("#datatable"), {
      processing: true,
      serverSide: true,
      select: {
        style: "multi",
        selector: "td:first-child input[type='checkbox']",
        classMap: {
          checkAll: "#datatableCheckAll",
          counter: "#datatableCounter",
          counterInfo: "#datatableCounterInfo"
        }
      },
      drawCallback: function () {
        $(".lead-popover").popover({})
        $("body").on("click", function (e) {
          $("[data-toggle=popover]").each(function () {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $(".popover").has(e.target).length === 0) {
              $(this).popover("hide")
            }
          })
        })
      },
      ajax: {
        url: url,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("access_token")
        },
        data: function (params) {
          params.minDate = vm.startDate
          params.maxDate = vm.endDate
          params.campaignIds = vm.campaignIds
          return params
        },
        dataSrc: function (json) {
          var entityMap = {
            "&": "&amp;",
            "<": "&lt;",
            ">": "&gt;",
            '"': "&quot;",
            "'": "&#39;",
            "/": "&#x2F;",
            "`": "&#x60;",
            "=": "&#x3D;"
          }
          function escapeHtml(string) {
            return String(string).replace(/[&<>"'`=/]/g, function (s) {
              return entityMap[s]
            })
          }

          for (var i = 0, ien = json.data.length; i < ien; i++) {
            json.data[i]["campaign_id"] = json.data[i]["campaign_name"]
            json.data[i]["form_data"] = JSON.parse(json.data[i]["form_data"])
            json.data[i]["user_data"] = JSON.parse(json.data[i]["user_data"])
            json.data[i]["created_at"] = getFormattedDate(json.data[i]["created_at"])
            json.data[i]["checkbox"] = `<div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="lead${json.data[i]["id"]}">
                <label class="custom-control-label" for="lead${json.data[i]["id"]}"></label>
              </div>`

            const formData = json.data[i]["form_data"]
            json.data[i]["person"] = `<span class="media align-items-center">
            <div class="media-body">
              <span class="d-block h5 text-hover-primary mb-0">${formData["f_name"] ?? ""} ${formData["l_name"] ?? ""}</span>
              <span class="d-block font-size-sm text-body">${formData["email"] ?? ""}</span>
            </div>
            </span>`

            const popoverUserData = {
              "Operating System": json.data[i]["user_data"]["os"],
              Country: json.data[i]["user_data"]["country"],
              City: json.data[i]["user_data"]["city"],
              Device: json.data[i]["user_data"]["device"],
              Browser: json.data[i]["user_data"]["browser"]
            }
            let popoverText = "<h4>Form Fields</h4>"
            Object.keys(formData).forEach((k) => {
              popoverText += `<div class="mb-2 row lead-form-popover-row">
                <span class="col-5">${k}:</span>
               <span class="col-7">${formData[k]}</span>
               </div>`
            })

            popoverText += "<h4 class='mt-4'>Personal Information</h4>"

            Object.keys(popoverUserData).forEach((k) => {
              popoverText += `<div class="mb-2 row lead-form-popover-row">
                <span class="col-5">${k}:</span>
                <span class="col-7">${popoverUserData[k]}</span>
               </div>`
            })

            popoverText = escapeHtml(popoverText)
            json.data[i][
              "lead_data_popover"
            ] = `<a class="btn btn-white btn-sm lead-popover" href="javascript:;" data-html="true" data-container="body" data-toggle="popover" data-placement="bottom" title="Lead Data" data-content="${popoverText}">Lead Data</a>`
          }

          return json.data
        }
      },
      columns: [{ data: "checkbox" }, { data: "person" }, { data: "campaign_id" }, { data: "created_at" }, { data: "lead_data_popover" }],
      columnDefs: [{ orderable: false, targets: [0, 1, 2, -1] }],
      order: [[3, "desc"]],
      language: {
        zeroRecords: ` <div class="d-flex flex-column align-items-center py-6">
            <img class="img-fluid mb-5" src="${clickSvg}" style="max-width: 16rem;">
            <h1>There are no leads for now.</h1>
            <p>When your visitors fill your campaigns, collected leads will be accessible here.</p>
          </div>`,
        processing: `<div class="d-flex justify-content-center my-4">
  <div class="spinner-border text-info mx-auto" role="status">
    <span class="sr-only">Loading...</span> </div></div>`
      }
    })
    $(".js-datatable-filter").on("change", function () {
      const $this = $(this)
      vm.campaignIds = $this.val()
      vm.datatable.ajax.reload()
    })

    if (this.$route.query.campaignId) {
      vm.campaignIds = [this.$route.query.campaignId]
      vm.datatable.ajax.reload()
    }
  },
  beforeDestroy() {
    $("*").each(function () {
      const popover = $.data(this, "bs.popover")
      if (popover) $(this).popover("hide")
    })
  }
}
</script>
<style>
.leads-table tr th:nth-of-type(1),
.leads-table tr td:nth-of-type(1) {
  padding-right: 0 !important;
}

.leads-table tr th:nth-of-type(2),
.leads-table tr td:nth-of-type(2) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.lead-form-popover-row {
  width: 24rem;
}
</style>
