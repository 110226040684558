<template>
  <modal :title="title" size="lg" @close="$emit('close')">
    <div class="modal-body">
      <div v-if="type === 'when'">
        <targeting-add-button @picked="handlePicked" type-of-rule="when" :name="targetingRuleNames.whenRules.inactivity" />
        <targeting-add-button @picked="handlePicked" type-of-rule="when" :name="targetingRuleNames.whenRules.afterMilliseconds" />
        <targeting-add-button @picked="handlePicked" type-of-rule="when" :name="targetingRuleNames.whenRules.exitIntent" />
        <targeting-add-button @picked="handlePicked" type-of-rule="when" :name="targetingRuleNames.whenRules.scrollPercentage" />
        <targeting-add-button @picked="handlePicked" type-of-rule="when" :name="targetingRuleNames.whenRules.clickTo" />
      </div>
      <div v-if="type === 'who'">
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.returningOrNew" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.linking" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.ipAllowed" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.ipBlocked" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.adblock" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.visitedUrl" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.currentUrl" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.lastVisitedUrl" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.excludeByVisit" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.device" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.source" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.operatingSystem" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.numberOfVisitedPages" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.timeSpentOnPage" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.timeSpentOnSite" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.cart" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.frequency" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.browserLanguage" />
        <targeting-add-button @picked="handlePicked" type-of-rule="who" :name="targetingRuleNames.whoRules.location" />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"
import { whenToShowComponentMap, whoToShowComponentMap } from "@/components/campaign-targeting-rules/targetingRuleComponentMaps"
import TargetingAddButton from "@/components/campaign-targeting-rules/TargetingAddButton"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import { mapState } from "vuex"

export default {
  name: "ModalCampaignTargetingRulePicker",
  props: {
    type: String
  },
  data() {
    return {
      whenToShowComponentMap: whenToShowComponentMap,
      whoToShowComponentMap: whoToShowComponentMap,
      targetingRuleNames: targetingRuleNames
    }
  },
  computed: {
    ...mapState("campaigns", ["campaigns"]),
    title() {
      if (this.type === "who") {
        return "Who To Show?"
      } else if (this.type === "when") {
        return "When To Show?"
      }
      return ""
    }
  },
  methods: {
    handlePicked({ typeOfRule, nameOfRule }) {
      this.$emit("picked", { typeOfRule, nameOfRule })
    }
  },
  components: { TargetingAddButton, Modal }
}
</script>
