<template>
  <div class="card mb-3 mb-lg-5">
    <modal-delete-confirmation
      v-if="showDeleteModal"
      @close="showDeleteModal = false"
      @cancel="showDeleteModal = false"
      @delete="
        deleteLeads()
        showDeleteModal = false
      "
    />
    <div class="card-header">
      <div class="row justify-content-between align-items-center flex-grow-1">
        <div class="col-auto">
          <h4 class="mb-0">Campaign Leads</h4>
        </div>

        <div class="col-md">
          <div class="d-flex justify-content-end align-items-center">
            <div class="col-auto d-flex">
              <date-range-picker @change="handleDatePickerChange" />
            </div>

            <div id="summaryLeadsDatatableCounterInfo" class="ml-4" style="display: none">
              <div class="d-flex align-items-center">
                <span class="font-size-sm mr-3">
                  <span id="summaryLeadsDatatableCounter">0</span>
                  Selected
                </span>
                <a class="btn btn-sm btn-outline-danger" href="javascript:;" @click="showDeleteModal = true">
                  <i class="tio-delete-outlined"></i> Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="table-responsive datatable-custom">
      <table
        id="summaryLeadsDatatable"
        class="leads-table table table-thead-bordered table-nowrap table-align-middle card-table"
        style="width: 100%"
        data-hs-datatables-options='{
             "order": [],
             "pageLength": 10,
             "isResponsive": true,
             "isShowPaging": false,
             "pagination": "summaryLeadsDatatablePagination",
             "entries": "#summaryLeadsDatatableEntries",
             "info" : {
                 "totalQty": "#summaryLeadsDatatableWithPaginationInfoTotalQty"
             }
           }'
      >
        <thead class="thead-light">
          <tr>
            <th scope="col" class="table-column-pr-0">
              <div class="custom-control custom-checkbox">
                <input id="summaryLeadsDatatableCheckAll" type="checkbox" class="custom-control-input" />
                <label class="custom-control-label" for="summaryLeadsDatatableCheckAll"></label>
              </div>
            </th>
            <th class="table-column-pl-0">Person</th>
            <th>Date</th>
            <th>Data</th>
          </tr>
        </thead>
      </table>
    </div>
    <!-- End Table -->

    <!-- Footer -->
    <div class="card-footer">
      <!-- Pagination -->
      <div class="row justify-content-center justify-content-sm-between align-items-sm-center">
        <div class="col-sm mb-2 mb-sm-0">
          <div class="d-flex justify-content-center justify-content-sm-start align-items-center">
            <span class="mr-2">Showing:</span>

            <!-- Select -->
            <select
              id="summaryLeadsDatatableEntries"
              class="js-select2-custom"
              data-hs-select2-options='{
                            "minimumResultsForSearch": "Infinity",
                            "customClass": "custom-select custom-select-sm custom-select-borderless",
                            "dropdownAutoWidth": true,
                            "width": true
                          }'
            >
              <option value="10" selected>10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
            <!-- End Select -->

            <span class="text-secondary mr-2">of</span>

            <!-- Pagination Quantity -->
            <span id="summaryLeadsDatatableWithPaginationInfoTotalQty"></span>
          </div>
        </div>

        <div class="col-sm-auto">
          <div class="d-flex justify-content-center justify-content-sm-end">
            <!-- Pagination -->
            <nav id="summaryLeadsDatatablePagination" aria-label="Activity pagination"></nav>
          </div>
        </div>
      </div>

      <!-- End Pagination -->
    </div>
    <!-- End Footer -->
  </div>
</template>

<script>
import dateFormatterMixin from "@/mixins/date-formatter-mixin"
import advancedSelectMixin from "@/mixins/advanced-select-mixin"
import leadAPI from "@/api/lead"
import sorrySvg from "@/theme-files/assets/svg/illustrations/sorry.svg"
import { mapState } from "vuex"
import ModalDeleteConfirmation from "@/components/modals/ModalDeleteConfirmation"
import DateRangePicker from "@/components/common/DateRangePicker"

export default {
  name: "CampaignSummaryLeadsTable",
  components: { DateRangePicker, ModalDeleteConfirmation },
  props: ["campaignId"],
  data() {
    return {
      startDate: null,
      endDate: null,
      datatable: null,
      showDeleteModal: false
    }
  },
  computed: {
    ...mapState("sites", ["selectedSite"])
  },
  methods: {
    async deleteLeads() {
      const ids = []
      this.datatable.rows({ selected: true }).every(function () {
        const id = this.data().id
        ids.push(id)
      })

      await leadAPI.deleteLeads({ siteId: this.selectedSite.id, leadIds: ids })
      this.datatable.ajax.reload()
      $("#summaryLeadsDatatableCounterInfo").hide()
      $("#summaryLeadsDatatableCheckAll").prop("checked", false)
    },
    handleDatePickerChange({ startDate, endDate }) {
      this.startDate = startDate.toISOString()
      this.endDate = endDate.toISOString()
      this.datatable.draw()
    }
  },
  mixins: [dateFormatterMixin, advancedSelectMixin],
  async mounted() {
    const vm = this
    this.initAdvancedSelect()
    require("@/theme-files/hs.datatables")
    const getFormattedDate = this.getFormattedDate
    const url = leadAPI.getLeadsDatatablesEndpoint({ siteId: this.selectedSite.id })
    this.datatable = $.HSCore.components.HSDatatables.init($("#summaryLeadsDatatable"), {
      processing: true,
      serverSide: true,
      select: {
        style: "multi",
        selector: "td:first-child input[type='checkbox']",
        classMap: {
          checkAll: "#summaryLeadsDatatableCheckAll",
          counter: "#summaryLeadsDatatableCounter",
          counterInfo: "#summaryLeadsDatatableCounterInfo"
        }
      },
      drawCallback: function () {
        $(".lead-popover").popover({})
        $("body").on("click", function (e) {
          $("[data-toggle=popover]").each(function () {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $(".popover").has(e.target).length === 0) {
              $(this).popover("hide")
            }
          })
        })
      },
      ajax: {
        url: url,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("access_token")
        },
        data: function (params) {
          params.minDate = vm.startDate
          params.maxDate = vm.endDate
          params.campaignIds = [vm.campaignId]
          return params
        },
        dataSrc: function (json) {
          var entityMap = {
            "&": "&amp;",
            "<": "&lt;",
            ">": "&gt;",
            '"': "&quot;",
            "'": "&#39;",
            "/": "&#x2F;",
            "`": "&#x60;",
            "=": "&#x3D;"
          }

          function escapeHtml(string) {
            return String(string).replace(/[&<>"'`=/]/g, function (s) {
              return entityMap[s]
            })
          }

          for (var i = 0, ien = json.data.length; i < ien; i++) {
            json.data[i]["form_data"] = JSON.parse(json.data[i]["form_data"])
            json.data[i]["user_data"] = JSON.parse(json.data[i]["user_data"])
            json.data[i]["created_at"] = getFormattedDate(json.data[i]["created_at"])
            json.data[i]["checkbox"] = `<div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="lead${json.data[i]["id"]}">
                <label class="custom-control-label" for="lead${json.data[i]["id"]}"></label>
              </div>`

            const formData = json.data[i]["form_data"]
            json.data[i]["person"] = `<span class="media align-items-center">
            <div class="media-body">
              <span class="d-block h5 text-hover-primary mb-0">${formData["f_name"] ?? ""} ${formData["l_name"] ?? ""}</span>
              <span class="d-block font-size-sm text-body">${formData["email"] ?? ""}</span>
            </div>
            </span>`

            const popoverUserData = {
              "Operating System": json.data[i]["user_data"]["os"],
              Country: json.data[i]["user_data"]["country"],
              City: json.data[i]["user_data"]["city"],
              Device: json.data[i]["user_data"]["device"],
              Browser: json.data[i]["user_data"]["browser"]
            }
            let popoverText = "<h4>Form Fields</h4>"
            Object.keys(formData).forEach((k) => {
              popoverText += `<div class="mb-2 row lead-form-popover-row">
                <span class="col-5">${k}:</span>
               <span class="col-7">${formData[k]}</span>
               </div>`
            })

            popoverText += "<h4 class='mt-4'>Personal Information</h4>"

            Object.keys(popoverUserData).forEach((k) => {
              popoverText += `<div class="mb-2 row lead-form-popover-row">
                <span class="col-5">${k}:</span>
                <span class="col-7">${popoverUserData[k]}</span>
               </div>`
            })

            popoverText = escapeHtml(popoverText)
            json.data[i][
              "lead_data_popover"
            ] = `<a class="btn btn-white btn-sm lead-popover" href="javascript:;" data-html="true" data-container="body" data-toggle="popover" data-placement="bottom" title="Lead Data" data-content="${popoverText}">Lead Data</a>`
          }

          return json.data
        }
      },
      columns: [{ data: "checkbox" }, { data: "person" }, { data: "created_at" }, { data: "lead_data_popover" }],
      columnDefs: [{ orderable: false, targets: [0, 1, -1] }],
      order: [[2, "desc"]],
      language: {
        zeroRecords: `<div class="text-center p-4">
            <img class="mb-3 mx-auto" src="${sorrySvg}" style="width: 10rem;"><p class="mb-0">No data to show</p>
            </div>`,
        processing: `<div class="d-flex justify-content-center my-4">
  <div class="spinner-border text-info mx-auto" role="status">
    <span class="sr-only">Loading...</span> </div></div>`
      }
    })
    $(".js-datatable-filter").on("change", function () {
      var $this = $(this),
        elVal = $this.val(),
        targetColumnIndex = $this.data("target-column-index")
      vm.datatable.column(targetColumnIndex).search(elVal).draw()
    })
  },
  beforeDestroy() {
    $("*").each(function () {
      const popover = $.data(this, "bs.popover")
      if (popover) $(this).popover("hide")
    })
  }
}
</script>
<style>
.leads-table tr th:nth-of-type(1),
.leads-table tr td:nth-of-type(1) {
  padding-right: 0 !important;
}

.leads-table tr th:nth-of-type(2),
.leads-table tr td:nth-of-type(2) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.lead-form-popover-row {
  width: 24rem;
}
</style>
