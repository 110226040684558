<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div ref="PARENTELEMENT">
      <select multiple size="1" style="opacity: 0" class="js-select2-custom custom-select" ref="select-element">
        <option v-for="langCode in Object.keys(langs)" :key="langCode" :selected="value.includes(langCode)" :value="langCode">
          {{ langs[langCode].join(" / ") }}
        </option>
      </select>
    </div>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"
import langs from "@/components/campaign-targeting-rules/langs"
import advancedSelectMixin from "@/mixins/advanced-select-mixin"

const mixin = targetingRuleModalMixin("who", targetingRuleNames.whoRules.browserLanguage)
export default {
  name: "BrowserLanguageModal",
  components: { ModalCampaignTargetingRule },
  mixins: [mixin, advancedSelectMixin],
  data() {
    return {
      langs: langs
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      const item = this.$refs["select-element"]
      this.initAdvancedSelect()

      $(item).on("change.select2", () => {
        this.value = $(item)
          .select2("data")
          .map((obj) => obj.id)
      })
    })
  }
}
</script>
