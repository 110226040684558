<template>
  <div style="height: 100vh; width: 100%">
    <div style="min-width: 100%; height: calc(100vh - 3.75rem); padding-top: 3.75rem">
      <div class="d-block overflow-auto bg-soft-light" style="width: 100%; height: 100%">
        <div style="display: inline-flex; justify-content: center; min-height: 100%; min-width: 100%; align-items: center">
          <div style="margin: 32px; width: min-content; height: min-content">
            <iframe
              ref="iframe"
              :srcdoc="html"
              class="shadow-md d-block border-0"
              style="transform-origin: center center; transform: scale(1)"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <div class="navbar-vertical-footer bg-white" style="height: 3.75rem">
      <div class="d-flex align-items-center justify-content-center">
        <resolution-selector />

        <div class="d-flex align-items-center ml-3">
          <button class="btn btn-sm btn-icon btn-soft-secondary" @click="setScale(scaleRatio - 0.1)">
            <i class="tio-remove" />
          </button>
          <span class="mx-2">
            {{ scaleRatio ? scaleRatioFormatter(scaleRatio) : "" }}
          </span>
          <button class="btn btn-sm btn-icon btn-soft-secondary" @click="setScale(scaleRatio + 0.1)">
            <i class="tio-add" />
          </button>
        </div>

        <button class="btn btn-sm btn-soft-secondary btn-icon ml-3" @click="reloadIframe">
          <i class="tio-refresh" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import ResolutionSelector from "@/components/campaign-builder/ResolutionSelector"

export default {
  name: "Preview",
  components: { ResolutionSelector,  },
  data() {
    return {
      scaleRatio: "1",
      scaleRatios: {},
      eventNotifierOptions: {
        position: "bottom-left",
        duration: 2000
      },
      readyInterval: null
    }
  },
  computed: {
    ...mapGetters("builder", ["getPreviewDevicesLoading", "getStyleVariablesText"]),
    ...mapState("builder", ["previewDevice", "html"]),
    ...mapState("sites", ["sites", "selectedSite"])
  },
  watch: {
    getStyleVariablesText() {
      if (!this.getPreviewDevicesLoading) {
        this.setChangeableStyles()
      }
    },
    previewDevice: {
      handler: function () {
        this.styleIframe()
        this.setBackground()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("builder", ["reloadIframe", "setBackgroundOfIframe", "initializeWidgets"]),
    ...mapActions("sites", ["fetchSites"]),
    ...mapMutations("builder", ["addIframe", "setPreviewDevicesLoading", "setCurrentPage", "setIframe"]),
    ...mapMutations("sites", ["setSelectedSiteBySiteId"]),
    scaleRatioFormatter: (val) => (val * 100).toFixed(2) + "%",
    async setBackground() {
      await this.fetchSites()
      this.setSelectedSiteBySiteId(this.$route.params.site)
      await this.setBackgroundOfIframe(this.selectedSite.domain)
    },
    initEventListeners(iframe) {
      iframe.contentWindow.addEventListener("maSubmit", this.eventListenerSubmit)
      iframe.contentWindow.addEventListener("maWheelOfFortuneSpun", this.eventListenerWheelOfFortuneSpun)
      iframe.contentWindow.addEventListener("maGiftPickerBoxOpen", this.eventListenerGiftPickerOpen)
      iframe.contentWindow.addEventListener("maClose", this.eventListenerClose)
      iframe.contentWindow.addEventListener("maUrl", this.eventListenerUrl)
      iframe.contentWindow.addEventListener("maWhatsapp", this.eventListenerWhatsapp)
      iframe.contentWindow.addEventListener("maGoalReached", this.eventListenerReached)
      iframe.contentWindow.addEventListener("maConversion", this.eventListenerConversion)
      iframe.contentWindow.addEventListener("maRejection", this.eventListenerRejection)
    },
    eventListenerGiftPickerOpen(e) {
      this.$toast.success(
        `Gift picker<br><br>Coupon text: ${e.detail.selectedText}<br>Coupon Code: ${e.detail.selectedCoupon}`,
        this.eventNotifierOptions
      )
    },
    eventListenerWheelOfFortuneSpun(e) {
      this.$toast.success(
        `Wheel of fortune spun<br><br>Coupon text: ${e.detail.selectedText}<br>Coupon Code: ${e.detail.selectedCoupon}`,
        this.eventNotifierOptions
      )
    },
    eventListenerReached() {
      this.$toast.success("Goal Reached", this.eventNotifierOptions)
    },
    eventListenerRejection() {
      this.$toast.success("Rejection", this.eventNotifierOptions)
    },
    eventListenerConversion() {
      this.$toast.success("Conversion", this.eventNotifierOptions)
    },
    eventListenerSubmit() {
      this.$toast.success(`Submit`, this.eventNotifierOptions)
    },
    eventListenerClose() {
      this.$toast.success("Closed", this.eventNotifierOptions)
    },
    eventListenerWhatsapp(data) {
      this.$toast.success(`Whatsapp Redirect | Phone Number: ${data.detail.number}`, this.eventNotifierOptions)
    },
    eventListenerUrl(data) {
      this.$toast.success(`Open ${data.detail.url}<br>New tab: ${data.detail.newTab === "true" ? "Yes" : "No"}`, this.eventNotifierOptions)
    },
    styleIframe() {
      const areaHeight = document.documentElement.clientHeight - 11 * 16 // available area is 9 rem. We are using 11 rem here.

      const deviceWidth = this.previewDevice.width
      const deviceHeight = this.previewDevice.height

      let idealScaleRatio = Math.floor((areaHeight / deviceHeight) * 10) / 10
      if (idealScaleRatio > 1) idealScaleRatio = 1
      this.setScale(idealScaleRatio)

      this.$refs["iframe"].style.width = deviceWidth + "px"
      this.$refs["iframe"].style.minWidth = deviceWidth + "px"
      this.$refs["iframe"].style.height = deviceHeight + "px"
      this.$refs["iframe"].style.minHeight = deviceHeight + "px"
    },
    injectWorkspaceFiles() {
      const iframe = this.$refs["iframe"]
      const doc = iframe.contentWindow.document

      if (!doc.querySelector("script#workspace-js")) {
        const workspaceJS = doc.createElement("script")
        workspaceJS.src = process.env.VUE_APP_WIDGETS_WORKSPACE_INJECTION_JS_PATH
        workspaceJS.id = "workspace-js"
        doc.body.appendChild(workspaceJS)

        const workspaceCSS = doc.createElement("link")
        workspaceCSS.href = process.env.VUE_APP_WIDGETS_WORKSPACE_INJECTION_CSS_PATH
        workspaceCSS.rel = "stylesheet"
        workspaceCSS.id = "workspace-css"
        doc.head.prepend(workspaceCSS)
      }
    },
    handleIframeIsReady() {
      const iframe = this.$refs["iframe"]
      iframe.contentWindow.osmEnvironmentIsPreview = true
      const doc = iframe.contentWindow.document

      doc.addEventListener("osmPageIsChanged", (event) => {
        this.setCurrentPage(event.detail.page)
        this.reloadIframe()
      })

      this.setChangeableStyles()
      this.styleIframe()
      this.setIframe(iframe)
      this.initEventListeners(iframe)

      this.setBackground()
      setTimeout(() => {
        this.setPreviewDevicesLoading(false)
      }, 50)
    },
    checkIframesAreReady() {
      if (this.readyInterval) return
      this.interval = setInterval(() => {
        const doc = this.$refs["iframe"].contentDocument
        const isReady = doc.readyState === "complete" && !!doc.querySelector(".page")
        this.injectWorkspaceFiles()
        if (!isReady || !doc.OSM_WORKSPACE_INJECTED) return
        clearInterval(this.interval)
        this.handleIframeIsReady()
      }, 100)
    },

    setScale(param) {
      this.scaleRatio = parseFloat(param)
      this.$refs["iframe"].style.transform = "scale(" + this.scaleRatio + ")"
      const mx = (this.previewDevice.width * (this.scaleRatio - 1)).toFixed(0) / 2
      const my = (this.previewDevice.height * (this.scaleRatio - 1)).toFixed(0) / 2
      this.$refs["iframe"].style.margin = `${my}px ${mx}px ${my}px ${mx}px`
      // this.$refs["iframe"].style.marginRight = `${mx}px`
      // this.$refs["iframe"].style.marginLeft = `${mx}px`
      // this.$refs["iframe"].style.minHeight = this.previewDevice.height + "px"
    },

    setChangeableStyles() {
      this.$refs["iframe"].contentDocument.querySelector("style#osm-user").innerHTML = this.getStyleVariablesText
    }
  },
  created() {
    this.setPreviewDevicesLoading(true)
    this.checkIframesAreReady()
  }
}
</script>
