<template>
  <modal title="Select Campaign Type" size="xl" @close="$emit('close')">
    <div class="modal-body">
      <dashboard-warning message="Updating the page type resets the content of the page!" v-if="showUpdateWarning" class="mb-4"/>
      <campaign-type-selector @next="next" />
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"
import CampaignTypeSelector from "@/components/campaign-type-select/CampaignTypeSelector"
import DashboardWarning from "@/components/dashboard/DashboardWarning"

export default {
  name: "ModalCampaignTypeSelector",
  props: {
    next: {},
    showUpdateWarning: {
      default: false
    }
  },
  components: { DashboardWarning, CampaignTypeSelector, Modal }
}
</script>

<style scoped></style>
