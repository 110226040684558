<template>
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-primary mx-auto" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading"
}
</script>

<style scoped></style>
