<template>
  <div>
    <modal-insert-code v-if="showInsertCodeModal" :site="insertCodeModalSite" @close="handleInsertCodeClosed" />
    <modal-update-site v-if="showUpdateSite" :site="siteToUpdate" @close="showUpdateSite = false" @siteUpdated="handleSiteUpdated" />
    <modal-add-new-site v-if="showAddNewSite" @close="showAddNewSite = false" @siteCreated="handleSiteCreated" />
    <full-page-loading v-if="fetchSitesLoading" />
    <div v-else>
      <dashboard-title v-if="sites.length > 0" title="Sites">
        <template #right>
          <button class="btn btn-primary" @click="showAddNewSite = true"><i class="tio-add mr-1"></i> Add New Site</button>
        </template>
      </dashboard-title>
      <div v-show="sites.length > 0" class="card">
        <div class="card-header">
          <h4 class="card-header-title">Your Sites</h4>
        </div>

        <loading v-if="loading" />
        <div v-else class="table-responsive">
          <table class="table table-lg table-thead-bordered table-nowrap table-align-middle">
            <thead class="thead-light">
              <tr>
                <th>Domain</th>
                <th>Code Inserted</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="site in sites" :key="site.id">
                <td>
                  {{ site.domain }}
                </td>
                <td>
                  <span v-if="site.is_installed"> <span class="legend-indicator bg-success"></span>Installed</span>
                  <span v-else> <span class="legend-indicator bg-danger"></span>Not Installed</span>
                </td>
                <td>
                  <button
                    class="btn btn-soft-primary btn-xs"
                    @click="
                      insertCodeModalSite = site
                      showInsertCodeModal = true
                    "
                  >
                    Get Install Code
                  </button>
                  <button
                    class="btn btn-soft-primary btn-xs ml-2"
                    @click="
                      siteToUpdate = site
                      showUpdateSite = true
                    "
                  >
                    Change Domain
                  </button>
                  <button class="btn btn-soft-danger btn-xs ml-2" @click="deleteSite(site.id)">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <no-site
        v-show="sites.length < 1"
        @wantsAddNewSite="
          showAddNewSite = true
          redirectToCampaigns = true
        "
      />
    </div>
  </div>
</template>

<script>
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import { mapState } from "vuex"
import ModalInsertCode from "@/components/modals/ModalInsertCode"
import ModalAddNewSite from "@/components/modals/ModalAddNewSite"
import NoSite from "@/components/dashboard/NoSite"
import siteAPI from "@/api/site"
import Loading from "@/components/common/Loading"
import ModalUpdateSite from "@/components/modals/ModalUpdateSite"
import FullPageLoading from "@/components/common/FullPageLoading"

export default {
  name: "Lead",
  components: { FullPageLoading, ModalUpdateSite, Loading, NoSite, ModalAddNewSite, ModalInsertCode, DashboardTitle },
  data() {
    return {
      fetchSitesLoading: true,
      loading: false,
      showInsertCodeModal: false,
      insertCodeModalSite: null,
      showAddNewSite: false,
      showUpdateSite: false,
      siteToUpdate: null,
      redirectToCampaigns: false
    }
  },
  computed: mapState("sites", ["sites"]),
  methods: {
    async deleteSite(siteId) {
      this.loading = true
      await siteAPI.deleteSite(siteId)
      await this.$store.dispatch("sites/fetchSites")
      this.loading = false
    },
    handleSiteCreated(site) {
      this.showAddNewSite = false
      this.insertCodeModalSite = site
      this.showInsertCodeModal = true
    },
    handleSiteUpdated() {
      this.showUpdateSite = false
    },
    handleInsertCodeClosed() {
      this.showInsertCodeModal = false
      if (this.redirectToCampaigns) {
        this.redirectToCampaigns = false
        this.$router.push({ name: "Dashboard.Campaigns" })
      }
    }
  },
  async mounted() {
    await this.$store.dispatch("sites/fetchSites"), (this.fetchSitesLoading = false)
  }
}
</script>

<style scoped></style>
