<template>
  <div>
    <settings-title>Gamification</settings-title>
    <div v-if="gamificationWidgetManager === null">
      <h3 class="mb-3">What kind of gamification you want?</h3>
      <button class="btn btn-soft-primary btn-sm btn-block mb-2" @click="add('lucky-wheel')">Lucky Wheel</button>
      <button class="btn btn-soft-primary btn-sm btn-block" @click="add('gift-picker')">Gift Picker</button>
    </div>

    <div v-else>
      <div class="mb-4 d-flex justify-content-between align-items-start">
        <h3>
          {{ type === 'lucky-wheel' ? 'Lucky Wheel' : null}}
          {{ type === 'gift-picker' ? 'Gift Picker' : null}}
        </h3>
        <button class="btn btn-soft-danger btn-sm mr-2" @click="remove">Remove & Back</button>
      </div>
      <device-picker-tabs />
      <settings-accordion :show-device-picker="true">
        <template #title>Display</template>
        <template>
          <div class="pt-3 pb-5 border-bottom">
            <h4 class="mb-4">Position</h4>
            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; gap: 0.5rem" v-show="env === 'desktop'">
              <div class="picker" @click="positions.desktop = 'left'" :class="{ active: positions.desktop === 'left' }">
                <div class="picker-box left"><span></span></div>
              </div>

              <div class="picker" @click="positions.desktop = 'right'" :class="{ active: positions.desktop === 'right' }">
                <div class="picker-box right"><span></span></div>
              </div>

              <div class="picker" @click="positions.desktop = 'top'" :class="{ active: positions.desktop === 'top' }">
                <div class="picker-box top"><span></span></div>
              </div>
              <div class="picker" @click="positions.desktop = 'bottom'" :class="{ active: positions.desktop === 'bottom' }">
                <div class="picker-box bottom"><span></span></div>
              </div>
            </div>
            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; gap: 0.5rem" v-show="env === 'mobile'">
              <div class="picker" @click="positions.mobile = 'top'" :class="{ active: positions.mobile === 'top' }">
                <div class="picker-box top"><span></span></div>
              </div>
              <div class="picker" @click="positions.mobile = 'bottom'" :class="{ active: positions.mobile === 'bottom' }">
                <div class="picker-box bottom"><span></span></div>
              </div>
            </div>
          </div>

          <div class="pt-3 pb-5">
            <h4 class="mb-4">Size</h4>
            <settings-width :variable="gamificationManager.getStyleVariableName('width')" :show-device-picker="false" layout="horizontal" />
          </div>
        </template>
      </settings-accordion>
      <settings-padding :variable="gamificationManager.getStyleVariableName('padding')" title="Spacing" />
      <settings-accordion v-if="type === 'lucky-wheel'" :show-device-picker="true">
        <template #title>Lucky Wheel Settings</template>
        <template>
          <div v-for="(data, key) in luckyWheelTextAndCoupons" :key="key" class="mb-4">
            <h4>Slice {{ key + 1 }}</h4>
            <div class="row">
              <div class="col-6">
                <label>Text</label>
                <input v-model="luckyWheelTextAndCoupons[key]['text']" class="form-control form-control-sm" type="text" />
              </div>
              <div class="col-3">
                <label>Coupon</label>
                <input v-model="luckyWheelTextAndCoupons[key]['coupon']" class="form-control form-control-sm" type="text" />
              </div>
              <div class="col-3">
                <label>Weight</label>
                <input v-model.number="luckyWheelTextAndCoupons[key]['weight']" class="form-control form-control-sm" type="text" />
              </div>
            </div>
          </div>
        </template>
      </settings-accordion>
      <settings-accordion v-if="type === 'lucky-wheel'" :show-device-picker="true">
        <template #title>Lucky Wheel Colors</template>
        <template>
          <SettingsColor
            layout="horizontal"
            :show-device-picker="false"
            :variable="gamificationWidgetManager.getStyleVariableName('--bg1')"
            class="mb-3"
            title="Slice Group 1"
          />
          <SettingsColor
            layout="horizontal"
            :show-device-picker="false"
            :variable="gamificationWidgetManager.getStyleVariableName('--bg2')"
            class="mb-3"
            title="Slice Group 2"
          />
          <SettingsColor
            layout="horizontal"
            :show-device-picker="false"
            :variable="gamificationWidgetManager.getStyleVariableName('--bg3')"
            class="mb-3"
            title="Slice Group 3"
          />
          <SettingsColor
            layout="horizontal"
            :show-device-picker="false"
            :variable="gamificationWidgetManager.getStyleVariableName('--bg4')"
            class="mb-3"
            title="Slice Group 4"
          />
          <SettingsColor
            layout="horizontal"
            :show-device-picker="false"
            :variable="gamificationWidgetManager.getStyleVariableName('--text')"
            class="mb-3"
            title="Text"
          />
        </template>
      </settings-accordion>

      <template v-if="type === 'gift-picker'">
        <settings-accordion :show-device-picker="true">
          <template #title>Gift Picker Title</template>
          <template>
            <mini-editor :selector="`${getPageSelector} #${gamificationWidgetManager.titleManager.element.id}`" />
            <settings-font-size
              :show-device-picker="false"
              :variable="gamificationWidgetManager.titleManager.getStyleVariableName('font-size')"
              class="mt-4"
              layout="horizontal"
            />
            <settings-margin
              layout="vertical"
              :show-device-picker="false"
              class="mt-4"
              :variable="gamificationWidgetManager.titleManager.getStyleVariableName('margin')"
            />
          </template>
        </settings-accordion>
        <settings-accordion :show-device-picker="true">
          <template #title>Gift Picker Subtitle</template>
          <template>
            <mini-editor :selector="`${getPageSelector} #${gamificationWidgetManager.subtitleManager.element.id}`" />
            <settings-font-size
              :show-device-picker="false"
              :variable="gamificationWidgetManager.subtitleManager.getStyleVariableName('font-size')"
              class="mt-4"
              layout="horizontal"
            />
            <settings-margin
              layout="vertical"
              :show-device-picker="false"
              class="mt-4"
              :variable="gamificationWidgetManager.subtitleManager.getStyleVariableName('margin')"
            />
          </template>
        </settings-accordion>

        <settings-accordion :show-device-picker="true">
          <template #title>Gift Picker Settings</template>
          <template>
            <div class="pb-4 border-bottom">
              <h4 class="mb-3">Size & Count</h4>
              <settings-template class="mb-2" layout="horizontal" title="Box Count" :show-device-picker="false">
                <input type="range" class="form-control-range" v-model="giftPickerBoxCount" min="2" max="7" />
              </settings-template>
              <settings-width
                class="mb-2"
                layout="horizontal"
                :show-device-picker="false"
                :variable="gamificationWidgetManager.getStyleVariableName('--gift-box-width')"
                title="Box Size"
              />
              <settings-width
                :alternative-units="['px']"
                :min="0"
                :max="100"
                layout="horizontal"
                :show-device-picker="false"
                :variable="gamificationWidgetManager.getStyleVariableName('--gift-box-margin-right')"
                title="Box Spacing"
              />
            </div>

            <div class="py-4 border-bottom">
              <h4 class="mb-4">Text & Coupons</h4>
              <div v-for="(data, key) in giftPickerCoupons" :key="key" class="mt-4">
                <h5>Coupon {{ key + 1 }}</h5>
                <div class="row">
                  <div class="col-6">
                    <label>Message</label>
                    <input v-model="giftPickerCoupons[key]['message']" class="form-control form-control-sm" type="text" />
                  </div>
                  <div class="col-3">
                    <label>Coupon</label>
                    <input v-model="giftPickerCoupons[key]['coupon']" class="form-control form-control-sm" type="text" />
                  </div>
                  <div class="col-3">
                    <label>Weight</label>
                    <input v-model.number="giftPickerCoupons[key]['weight']" class="form-control form-control-sm" type="text" />
                  </div>
                </div>
              </div>
            </div>

            <div class="py-4">
              <h4 class="mb-4">Gift Box Colors</h4>
              <SettingsColor
                layout="horizontal"
                :show-device-picker="false"
                :variable="gamificationWidgetManager.getStyleVariableName('--box-top')"
                class="mb-3"
                title="Box Top"
              />
              <SettingsColor
                layout="horizontal"
                :show-device-picker="false"
                :variable="gamificationWidgetManager.getStyleVariableName('--box-bottom')"
                class="mb-3"
                title="Box Bottom"
              />
              <SettingsColor
                layout="horizontal"
                :show-device-picker="false"
                :variable="gamificationWidgetManager.getStyleVariableName('--box-shadow')"
                class="mb-3"
                title="Box Shadow"
              />
              <SettingsColor
                layout="horizontal"
                :show-device-picker="false"
                :variable="gamificationWidgetManager.getStyleVariableName('--box-ribbon-front')"
                class="mb-3"
                title="Ribbon Front"
              />
              <SettingsColor
                layout="horizontal"
                :show-device-picker="false"
                :variable="gamificationWidgetManager.getStyleVariableName('--box-ribbon-bottom')"
                class="mb-3"
                title="Ribbon Bottom"
              />
            </div>
          </template>
        </settings-accordion>
      </template>

      <settings-background :variable="gamificationManager.getStyleVariableName('background')" />
    </div>
  </div>
</template>

<script>
import GamificationManager from "@/lib/marketing-widgets/gamification-manager"
import { mapActions, mapGetters } from "vuex"
import SettingsTitle from "@/components/campaign-builder/widget-settings/SettingsTitle"
import SettingsAccordion from "@/components/campaign-builder/widget-settings/SettingsAccordion"
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"
import DevicePickerTabs from "@/components/campaign-builder/widget-settings/DevicePickerTabs"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "Gamification",
  components: { SettingsTemplate, DevicePickerTabs, MiniEditor, SettingsAccordion, SettingsTitle },
  mixins: [appearanceChangerMixin],
  data: () => ({
    gamificationManager: null,
    gamificationWidgetManager: null,
    type: null,
    luckyWheelTextAndCoupons: [],
    giftPickerCoupons: [],
    giftPickerBoxCount: 0,
    positions: {
      desktop: "",
      mobile: ""
    }
  }),
  watch: {
    luckyWheelTextAndCoupons: {
      deep: true,
      handler(newValue) {
        if (newValue.length > 0) {
          this.gamificationWidgetManager.setTextAndCoupons(newValue)
        }
      }
    },
    giftPickerCoupons: {
      deep: true,
      handler(newValue) {
        if (newValue.length > 0) {
          this.gamificationWidgetManager.setCoupons(newValue)
        }
      }
    },
    giftPickerBoxCount(newValue) {
      if (newValue > 0) this.gamificationWidgetManager.setGiftBoxCount(newValue)
    },
    positions: {
      deep: true,
      handler(newValue) {
        this.getPageElement.querySelector(`.gamification-container`).dataset.desktop = newValue.desktop
        this.getPageElement.querySelector(`.gamification-container`).dataset.mobile = newValue.mobile
      }
    }
  },
  computed: {
    ...mapGetters("builder", ["getPageElement", "getPageSelector"])
  },
  methods: {
    ...mapActions("builder", ["reloadIframe"]),
    init() {
      const element = this.getPageElement.querySelector(`.gamification-container`)
      this.gamificationManager = new GamificationManager(element)
      this.gamificationWidgetManager = this.gamificationManager.getWidgetManager()
      this.type = this.gamificationManager.getGamificationType() ?? null
      if (this.type === "lucky-wheel") {
        this.luckyWheelTextAndCoupons = this.gamificationWidgetManager.getTextAndCoupons()
      } else if (this.type === "gift-picker") {
        this.giftPickerCoupons = this.gamificationWidgetManager.getCoupons()
        this.giftPickerBoxCount = this.gamificationWidgetManager.getGiftBoxCount()
      }

      this.positions.desktop = element.dataset.desktop
      this.positions.mobile = element.dataset.mobile
    },
    add(type) {
      const initialPosition = {
        "gift-picker": {
          desktop: "top",
          mobile: "top"
        },
        "lucky-wheel": {
          desktop: "left",
          mobile: "top"
        }
      }[type]
      this.gamificationManager.add(type, initialPosition)
      this.init()
      this.reloadIframe()
      this.$emit("change")
    },
    remove() {
      this.gamificationManager.remove()
      this.init()
      this.reloadIframe()
      this.$emit("change")
    }
  },
  created() {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.picker {
  display: flex;
  flex-direction: column;

  .picker-box {
    transition: 200ms all;
    opacity: 0.7;
    height: 3rem;
  }

  &.disabled .picker-box {
    opacity: 0.2;
  }

  &:not(.disabled) {
    cursor: pointer;
  }

  &:not(.disabled):hover .picker-box {
    opacity: 1;
  }

  &.active {
    .picker-box {
      opacity: 1;
      border-color: var(--primary);

      span {
        background: var(--primary);
      }

      small {
        color: var(--primary) !important;
      }
    }
  }

  .picker-box {
    position: relative;
    width: 100%;
    border: 1px solid var(--secondary);
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    small {
      text-align: center;
      margin: 0 0.25rem;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: var(--secondary);
    }

    &.left span {
    }

    &.right span {
      right: 0;
      left: auto;
    }

    &.top span {
      width: 100%;
      height: 50%;
    }

    &.bottom span {
      width: 100%;
      height: 50%;
      top: auto;
      bottom: 0;
    }
  }
}
</style>
