<template>
  <div :id="dropdownId" class="dropdown ml-2">
    <button class="btn btn-sm btn-icon opacity-sm btn-light" data-toggle="dropdown">
      <i class="tio-iphone" v-if="previewDevice.type === 'mobile'"></i>
      <i class="tio-imac" v-if="previewDevice.type === 'desktop'"></i>
    </button>
    <div class="dropdown-menu">
      <a @click="updateDevice('mobile')" class="dropdown-item" :class="{ active: previewDevice.type === 'mobile' }" href="javascript:;">
        <i class="tio-iphone dropdown-item-icon"></i> Mobile
      </a>
      <a @click="updateDevice('desktop')" class="dropdown-item" :class="{ active: previewDevice.type === 'desktop' }" href="javascript:;">
        <i class="tio-imac dropdown-item-icon"></i> Desktop
      </a>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import randomId from "@/lib/utils/random-id"

export default {
  name: "SettingsDevicePicker",
  data() {
    return {
      isOpen: false,
      dropdownId: randomId()
    }
  },
  computed: {
    ...mapState("builder", ["previewDevice"])
  },

  methods: {
    ...mapMutations("builder", ["setPreviewDevice"]),
    updateDevice(type) {
      const width = type === "mobile" ? 375 : 1024
      const height = type === "mobile" ? 667 : 640
      this.setPreviewDevice({ type, width, height })
    }
  },
  mounted() {
    const id = this.dropdownId
    this.$nextTick(() => {
      $(`#${id} > button, #${id} .dropdown-menu a`).click(function (e) {
        e.stopPropagation()
        const menu = $(`#${id} .dropdown-menu`)
        menu.toggle()
        // if (menu.is(":visible")) {
        //   $(this).closest(".accordion .card").css("overflow", "visible")
        // } else {
        //   $(this).closest(".accordion .card").css("overflow", "hidden")
        // }
      })
    })
  }
}
</script>

<style scoped></style>
