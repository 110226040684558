<template>
  <header id="header" class="navbar navbar-expand-lg navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered">
    <div class="navbar-nav-wrap">
      <div class="navbar-nav-wrap-content-left">
        <button type="button" class="js-navbar-vertical-aside-toggle-invoker close mr-3">
          <i
            class="tio-first-page navbar-vertical-aside-toggle-short-align"
            data-toggle="tooltip"
            data-placement="right"
            title="Collapse"
          ></i>
          <i
            class="tio-last-page navbar-vertical-aside-toggle-full-align"
            data-template='<div class="tooltip d-none d-sm-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
            data-toggle="tooltip"
            data-placement="right"
            title="Expand"
          ></i>
        </button>
        <site-changer-dropdown />
      </div>
      <div class="navbar-nav-wrap-content-right">
        <ul class="navbar-nav align-items-center flex-row">
          <li class="mr-4 d-flex">
            <account-usage />
          </li>
          <li class="nav-item d-none d-sm-inline-block">
            <header-notification />
          </li>
          <li class="nav-item">
            <header-account />
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<script>
import SiteChangerDropdown from "@/components/dashboard/SiteChangerDropdown"
import HeaderAccount from "@/components/dashboard/HeaderAccount"
import HeaderNotification from "@/components/dashboard/HeaderNotification"
import AccountUsage from "@/components/dashboard/AccountUsage"

export default {
  name: "Header",
  components: {
    AccountUsage,
    HeaderNotification,
    HeaderAccount,
    SiteChangerDropdown
  }
}
</script>
