<template>
  <thead class="thead-light">
    <tr>
      <th scope="col" class="table-column-pr-0" v-if="multiselect">
        <div class="custom-control custom-checkbox">
          <input id="datatableCheckAll" type="checkbox" class="custom-control-input" />
          <label class="custom-control-label" for="datatableCheckAll"></label>
        </div>
      </th>
      <th v-for="(head, key) in heads" :key="key" :class="typeof head === 'object' ? head.class : ''">
        {{ typeof head === "object" ? head.text : head }}
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: "DatatableThead",
  props: {
    heads: Array,
    multiselect: Boolean
  }
}
</script>

<style scoped></style>
