<template>
  <modal title="Exit" @close="$emit('close')" size="md">
    <div class="modal-body">
      <h4>You have unsaved changes. Do you want to save or discard them?</h4>
    </div>
    <div class="modal-footer">
      <button class="btn btn-success" @click="saveAndExit">Save & Exit</button>
      <button class="btn btn-danger" @click="discard">Discard & Exit</button>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"

export default {
  name: "ModalCampaignSaveDiscardCancel",
  components: { Modal },
  props: {
    save: Function,
    exit: Function,
    discard: Function
  },
  methods: {
    async saveAndExit() {
      await this.save()
      await this.exit()
    }
  }
}
</script>

<style scoped></style>
