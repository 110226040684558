<template>
  <div class="container">
    <div class="card mt-4">
      <div class="row justify-content-sm-center text-center py-10">
        <div class="col-10 offset-1">
          <img class="img-fluid mb-5" :src="graphsSvg" style="max-width: 21rem" />
          <h1>Hello, nice to see you!</h1>
          <p class="mb-2">You and your visitors are now minutes away from best marketing experience than ever before.</p>
          <p class="mb-4">To continue, you should add your site.</p>
          <button class="btn btn-primary" @click="$emit('wantsAddNewSite')">Add My First Site</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import graphsSvg from "@/theme-files/assets/svg/illustrations/graphs.svg"

export default {
  name: "NoSite",
  computed: {
    graphsSvg() {
      return graphsSvg
    }
  }
}
</script>
