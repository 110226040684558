// import getAxiosInstance from "@/api/get-axios-instance"

import getAxiosInstance from "@/api/get-axios-instance"

const getLeadsDatatablesEndpoint = ({ siteId }) => process.env.VUE_APP_API_BASE + `/sites/${siteId}/leads-datatables`

const deleteLeads = async ({ siteId, leadIds }) => await getAxiosInstance().delete(`sites/${siteId}/leads?ids=${leadIds.join(",")}`)

const leadAPI = {
  getLeadsDatatablesEndpoint,
  deleteLeads
}
export default leadAPI
