<template>
  <div>
    <div v-if="imgCount < 1">
      <h4 class="mt-3">There is no image.</h4>
    </div>
    <div v-else>
      <h4 class="mt-3" v-if="showTitle">Select Image To Change Appearance</h4>
      <div class="row align-items-center">
        <div class="col-6 mb-3" v-for="(n, index) in imgCount" :key="index">
          <div
            class="card card-hover-shadow overflow-hidden"
            @click="activeImgIndex = index"
            :class="activeImgIndex === index ? 'border-primary' : ''"
            style="border-width: 5px"
          >
            <img :src="getImgSrcByIndex(index)" style="height: 140px; object-fit: cover" />
          </div>
        </div>
      </div>
      <div>
        <settings-template v-if="showPosition" title="Position">
          <div>
            <div class="mb-2 row align-items-center">
              <div class="col-6">
                <select class="form-control" v-model="horizontalAttribute" @change="set">
                  <option value="right">Right</option>
                  <option value="left">Left</option>
                </select>
              </div>
              <div class="col-6">
                <input-with-unit
                  v-if="horizontal"
                  v-model="horizontal"
                  :alternative-units="['px', 'vw', '%']"
                  size="full"
                  @updated="set"
                ></input-with-unit>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-6">
                <select class="form-control" v-model="verticalAttribute" @change="set">
                  <option value="top">Top</option>
                  <option value="bottom">Bottom</option>
                </select>
              </div>
              <div class="col-6">
                <input-with-unit
                  v-if="vertical"
                  v-model="vertical"
                  :alternative-units="['px', 'vh', '%']"
                  size="full"
                  @updated="set"
                ></input-with-unit>
              </div>
            </div>
          </div>
        </settings-template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import InputWithUnit from "@/components/common/InputWithUnit"
import parseUnit from "@/lib/utils/parse-unit"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "Images",
  components: {
    SettingsTemplate,
    InputWithUnit
  },
  props: {
    imagesClass: {
      type: String,
      required: true
    },
    showPosition: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      imgCount: 0,
      activeImgIndex: null,
      img: null,
      opacity: null,
      width: null,
      height: null,
      objectFit: null,
      horizontalAttribute: null,
      verticalAttribute: null,
      horizontal: null,
      vertical: null
    }
  },
  watch: {
    activeImgIndex() {
      this.fillDataFromImage()
    }
  },
  computed: {
    ...mapGetters("builder", ["getIframeDocument", "getCurrentStyleVariables", "getPageSelector"]),
    ...mapState("builder", { env: "appearanceDevice", iframes: "iframes" })
  },
  methods: {
    setImgCount() {
      const images = this.getIframeDocument.querySelectorAll(`${this.getPageSelector} .${this.imagesClass} img`)
      this.imgCount = images.length
    },
    getImgSrcByIndex(index) {
      const images = this.getIframeDocument.querySelectorAll(`${this.getPageSelector} .${this.imagesClass} img`)
      return images[index].src
    },
    set() {
      this.img.style["opacity"] = this.opacity.value / 100
      this.img.style["width"] = this.width.value + this.width.unit
      this.img.style["height"] = this.height.value + this.height.unit
      this.img.style["object-fit"] = this.objectFit

      if (this.horizontalAttribute === "right") this.img.style["left"] = "unset"
      else this.img.style["right"] = "unset"
      this.img.style[this.horizontalAttribute] = this.horizontal.value + this.horizontal.unit
      if (this.verticalAttribute === "top") this.img.style["bottom"] = "unset"
      else this.img.style["top"] = "unset"
      this.img.style[this.verticalAttribute] = this.vertical.value + this.vertical.unit
    },
    fillDataFromImage() {
      const iframe = this.iframes[this.env]
      const images = iframe.contentWindow.document.querySelectorAll(`${this.getPageSelector} .${this.imagesClass} img`)
      this.img = images[this.activeImgIndex]

      const styleVars = this.getCurrentStyleVariables.style[this.env]

      if (!this.img.style["opacity"]) this.img.style["opacity"] = styleVars["--image-opacity"]
      this.opacity = { value: this.img.style["opacity"] * 100, unit: "%" }

      if (!this.img.style.width) this.img.style.width = styleVars["--image-width"]
      this.width = parseUnit(this.img.style.width)

      if (!this.img.style.height) this.img.style.height = styleVars["--image-height"]
      this.height = parseUnit(this.img.style.height)

      if (!this.img.style["object-fit"]) this.img.style["object-fit"] = styleVars["--image-object-fit"]
      this.objectFit = this.img.style["object-fit"]

      const left = styleVars["--image-left"]
      const right = styleVars["--image-right"]
      const top = styleVars["--image-top"]
      const bottom = styleVars["--image-bottom"]

      if (!this.img.style.top && !this.img.style.bottom) {
        if (top <= bottom) {
          this.img.style.top = top
          this.img.style["bottom"] = "unset"
        } else {
          this.img.style.bottom = bottom
          this.img.style["top"] = "unset"
        }
      }

      if (!this.img.style.left && !this.img.style.right) {
        if (left <= right) {
          this.img.style.left = left
          this.img.style["right"] = "unset"
        } else {
          this.img.style.right = right
          this.img.style["left"] = "unset"
        }
      }
      this.horizontalAttribute = this.img.style.left !== "unset" ? "left" : "right"
      this.verticalAttribute = this.img.style.top !== "unset" ? "top" : "bottom"
      this.horizontal = parseUnit(this.img.style[this.horizontalAttribute])
      this.vertical = parseUnit(this.img.style[this.verticalAttribute])
    }
  },
  created() {
    this.setImgCount()
    if (this.imgCount > 0) this.activeImgIndex = 0
    this.fillDataFromImage()
  }
}
</script>
