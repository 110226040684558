<template>
  <input
    :id="pickerId"
    type="text"
    class="js-flatpickr form-control flatpickr-custom"
    placeholder="Select date"
    :value="value"
    :disabled="disabled"
    :data-hs-flatpickr-options='options'
  />
</template>

<script>
import randomId from "@/lib/utils/random-id"
import dayjs from "dayjs"

export default {
  name: "DatePicker",
  props: {
    value: {
      default: dayjs().format("MM/D/YYYY")
    },
    disabled: {
      default: false
    },
    enableTime: {
      default: false,
    }
  },
  data() {
    return {
      pickerId: null
    }
  },
  computed: {
    options(){
      if (this.enableTime){
        return `{"dateFormat": "m/d/Y H:i", "altFormat": "m/d/Y H:i", "enableTime": "true", "time_24hr": "true"}`
      }

      return '{"dateFormat": "m/d/Y"}';
    }
  },
  created() {
    this.pickerId = randomId()
  },
  mounted() {
    console.log('mount')
    const vm = this
    this.$nextTick(() => {
      require("@/theme-files/hs.flatpickr")
      $("#" + this.pickerId).each(function () {
        $.HSCore.components.HSFlatpickr.init($(this))
      })

      $("#" + this.pickerId).on("change", function (e) {
        console.log({
          datePickerNewValue: e.target.value
        })
        vm.$emit("input", e.target.value)
      })
    })
  }
}
</script>

<style scoped>
.form-control:disabled {
  background-color: #f8fafd !important;
}
</style>
