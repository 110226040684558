<template>
  <modal title="Rename The Page" @close="$emit('close')" size="md">
    <div class="modal-body">
      <div class="form-group">
        <label class="input-label">Change the name of the current page from the field below</label>
        <input class="form-control" type="text" v-model="name" />
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" @click="handleUpdate">Update</button>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"

export default {
  name: "ModalUpdateCampaignPageName",
  components: { Modal },
  props: ["currentName"],
  data() {
    return {
      name: this.currentName
    }
  },
  methods: {
    handleUpdate() {
      this.$emit("update", this.name)
    }
  }
}
</script>

<style scoped></style>
