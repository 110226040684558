<template>
  <div class="position-relative">
    <div class="text-center mb-4">
      <ul class="nav nav-segment nav-pills w-100 d-flex" role="tablist">
        <li class="nav-item flex-grow-1">
          <a
            class="nav-link active justify-content-center"
            id="nav-one-eg1-tab"
            data-toggle="pill"
            href="#nav-one-eg1"
            role="tab"
            aria-controls="nav-one-eg1"
            aria-selected="true"
            data-area="content"
            >Content</a
          >
        </li>
        <li class="nav-item flex-grow-1">
          <a
            class="nav-link justify-content-center"
            id="nav-two-eg1-tab"
            data-toggle="pill"
            href="#nav-two-eg1"
            role="tab"
            aria-controls="nav-two-eg1"
            aria-selected="false"
            data-area="appearance"
            >Appearance</a
          >
        </li>
      </ul>
    </div>

    <div class="position-relative">
      <div v-if="activeTab === 'content'" :key="contentKey">
        <slot name="content"></slot>
      </div>
      <div v-if="activeTab === 'appearance'" :key="appearanceKey">
        <slot name="appearance"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import randomId from "@/lib/utils/random-id"

export default {
  name: "SettingsContentAppearance",
  data() {
    return {
      activeTab: "content",
      contentKey: "",
      appearanceKey: ""
    }
  },
  watch: {
    activeTab() {
      this.contentKey = randomId()
      this.appearanceKey = randomId()
    }
  },
  mounted() {
    $("a[data-toggle='pill']").on("shown.bs.tab", (e) => {
      this.activeTab = e.target.dataset.area
    })
  }
}
</script>

<style scoped></style>
