<template>
  <settings-template :layout="layout" :title="title" :show-device-picker="showDevicePicker">
    <range-input-with-unit v-model="value" :alternative-units="['px']" :min="8" :max="72"></range-input-with-unit>
  </settings-template>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import RangeInputWithUnit from "@/components/common/RangeInputWithUnit"

export default {
  name: "SettingsFontSize",
  components: { RangeInputWithUnit, SettingsTemplate },
  props: {
    variable: {
      type: String,
      required: true
    },
    layout: {
      type: String,
      default: "vertical"
    },
    title: {
      type: String,
      default: "Font Size"
    }
  },
  computed: {
    value: {
      get() {
        return this.getStyleVariableValue(this.env, this.variable, 1)
      },
      set(value) {
        this.setStyleVariableDirect({
          env: this.env,
          variable: this.variable,
          value: value.value + value.unit
        })
      }
    }
  },
  mixins: [appearanceChangerMixin]
}
</script>
