<template>
  <base-campaign-template-tab title="Get Feedback From Visitors">
    <p>Getting feedback is the first step of improving customer experience.</p>
    <p>In order to make analyzing responses easier, you can enable third party integrations.</p>
  </base-campaign-template-tab>
</template>

<script>
import BaseCampaignTemplateTab from "@/components/campaign-template-tabs/BaseCampaignTemplateTab"
export default {
  name: "BoostSales",
  components: { BaseCampaignTemplateTab }
}
</script>

<style scoped></style>
