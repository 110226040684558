<template>
  <modal :title="getModalTitle" size="lg" @close="$emit('close')">
    <div ref="PARENTELEMENT">
      <div class="modal-body row" v-if="!addIntegrationData">
        <div class="col-12 col-md-6 col-lg-4 mb-3" v-for="integration in availableIntegrations" :key="integration.key">
          <a
            class="w-100 border p-3 rounded-lg d-block text-primary"
            style="padding: 0.75rem"
            href="javascript:"
            @click="addIntegrationData = integration"
          >
            <div class="row align-items-center">
              <div class="col-4">
                <integration-img :integration-type="integration.type" />
              </div>
              <div class="col-8 font-weight-bolder">{{ integration.name }}</div>
            </div>
          </a>
        </div>
      </div>

      <base-form @submit="submit" v-else @ready="initForm">
        <dashboard-error :message="errorMsg" v-if="errorMsg" />
        <div class="modal-body">
          <div class="form-row align-items-center">
            <div class="col-4">
              <label class="input-label">Integration Name:</label>
            </div>
            <div class="col-8">
              <input
                type="text"
                name="name"
                class="form-control"
                placeholder="Name"
                required
                minlength="1"
                maxlength="255"
                v-model="formData.name"
              />
            </div>
          </div>

          <div class="form-row align-items-center mt-3" v-for="(v, k) in addIntegrationData['auth_must_have']" :key="k">
            <div class="col-4">
              <label class="input-label">{{ startCase(v) }}:</label>
            </div>
            <div class="col-8">
              <input
                type="text"
                :name="v"
                :placeholder="startCase(v)"
                class="form-control"
                required
                minlength="1"
                v-model="formData['auth_data'][v]"
              />
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-white" @click="$emit('close')">Close</button>
          <button type="submit" class="btn btn-primary" v-if="!loading">Add Integration</button>
          <button class="btn btn-primary" type="submit" disabled v-if="loading">
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
        </div>
      </base-form>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"
import BaseForm from "@/components/BaseForm"
import formMixin from "@/mixins/form-mixin"
import integrationAPI from "@/api/integration"
import DashboardError from "@/components/dashboard/DashboardError"
import { startCase } from "lodash"
import IntegrationImg from "@/components/common/IntegrationImg"

export default {
  name: "ModalCreateIntegration",
  components: { IntegrationImg, DashboardError, BaseForm, Modal },
  props: ["availableIntegrations"],
  mixins: [formMixin],
  data() {
    return {
      addIntegrationData: null,
      errorMsg: "",
      loading: false,
      initFormOnMount: false,
      formData: {
        name: "",
        auth_data: {}
      }
    }
  },
  computed: {
    getModalTitle() {
      if (this.addIntegrationData === null) {
        return "Which Service You Want To Integrate?"
      } else {
        return `Integrate With ${this.addIntegrationData.name} Service`
      }
    }
  },
  methods: {
    async submit() {
      this.loading = true
      try {
        const uri = this.addIntegrationData.routes.store.uri
        const resp = await integrationAPI.createIntegration(uri, this.formData)
        this.$emit("integrationCreated", resp.data.data.third_party_integration)
      } catch (e) {
        this.errorMsg = "We can not verify connection, please check your inputs."
      }
      this.loading = false
    },
    startCase(inp) {
      return startCase(inp)
    }
  }
}
</script>

<style scoped></style>
