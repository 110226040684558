<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div class="mr-4 mb-3 flex-shrink-0">Show campaign if visitor uses one of:</div>
    <div class="d-flex flex-wrap">
      <checkbox
        class="mr-4"
        style="margin-top: 0 !important; margin-bottom: 0.5rem !important"
        v-for="(osName, os) in osMap"
        :value="value.includes(os)"
        :key="os"
        @input="(checked) => setData(os, checked)"
      >
        {{ osName }}
      </checkbox>
    </div>
    <div class="d-flex">
      <checkbox style="margin-top: 0 !important; margin-bottom: 0 !important" v-model="allSelected">
        {{ allSelected ? "Deselect All" : "Select All" }}
      </checkbox>
    </div>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"
import Checkbox from "@/components/common/Checkbox"

const mixin = targetingRuleModalMixin("who", targetingRuleNames.whoRules.operatingSystem)

const osMap = {
  iOS: "iOS",
  Android: "Android",
  Windows: "Windows",
  MacOS: "macOS",
  Linux: "Linux",
  WindowsPhone: "Windows Phone",
  WebOS: "WebOS",
  BlackBerry: "BlackBerry",
  Bada: "Bada",
  Tizen: "Tizen",
  ChromeOS: "Chrome OS",
  PlayStation4: "PlayStation 4",
  Roku: "Roku"
}
export default {
  name: "OperatingSystemModal",
  components: { ModalCampaignTargetingRule, Checkbox },
  mixins: [mixin],
  data: () => ({
    osMap: osMap
  }),
  computed: {
    allSelected: {
      get() {
        return this.value.length === Object.values(this.osMap).length
      },
      set(value) {
        if (value) this.value = Object.keys(this.osMap)
        else this.value = []
      }
    }
  },
  methods: {
    setData(os, checked) {
      if (checked && !this.value.includes(os)) this.value.push(os)
      if (!checked && this.value.includes(os)) this.value = this.value.filter((d) => d !== os)
    }
  }
}
</script>
