<template>
  <base-campaign-targeting-rule>
    <div>
      The campaign will appear a maximum number of {{ data.maxCount }} times per visitor{{
        data.maxCount > 1 ? `, with at least ${data.betweenMilliseconds / 1000} second(s) between two appearances` : `.`
      }}
    </div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("who", targetingRuleNames.whoRules.frequency)

export default {
  name: "TargetingFrequency",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin]
}
</script>
