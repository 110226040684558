import ElementManager from "@/lib/marketing-widgets/element-manager"

class SpacerManager extends ElementManager {
  constructor(element) {
    super(
      element,
      {
        height: {
          desktop: "24px",
          mobile: "16px"
        }
      },
      "spacer"
    )
  }
}

export default SpacerManager
