<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div class="d-flex align-items-center"></div>
    <span class="mr-2">The campaign will appear after</span>
    <input style="width: 6rem" type="number" v-model="afterMillisecondsData" class="form-control form-control-sm text-center" />
    <span class="ml-2">second(s)</span>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"

const mixin = targetingRuleModalMixin("when", targetingRuleNames.whenRules.afterMilliseconds)
export default {
  name: "AfterMillisecondsModal",
  components: { ModalCampaignTargetingRule },
  mixins: [mixin],
  computed: {
    afterMillisecondsData: {
      get: function () {
        return this.value / 1000
      },
      set: function (value) {
        this.value = value * 1000
      }
    }
  }
}
</script>
