import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import CreateCampaign from "@/views/CreateCampaign"
import Campaigns from "@/views/Campaigns"
import authRoutes from "@/router/auth"
import Statistics from "@/views/Statistics"
import Leads from "@/views/Leads"
import Dashboard from "@/views/Dashboard"
import auth from "@/router/middleware/auth"
import FetchCurrentUser from "@/views/FetchCurrentUser"
import ProfileSettings from "@/views/ProfileSettings"
import PlanBilling from "@/views/PlanBilling"
import CampaignSummary from "@/views/CampaignSummary"
import CampaignBuilder from "@/views/CampaignBuilder"
import NotFound from "@/views/NotFound"
import CampaignSettings from "@/views/CampaignSettings"
import Sites from "@/views/Sites"
import Admin from "@/views/Admin/Admin"
import CampaignTemplates from "@/views/Admin/CampaignTemplates"
import CreateCampaignTemplate from "@/views/Admin/CreateCampaignTemplate"

Vue.use(VueRouter)
const routes = [
  { path: "/", name: "Home", component: Home },
  ...authRoutes,
  { path: "/fetch-current-user/:redirect", name: "FetchCurrentUser", component: FetchCurrentUser },

  {
    path: "/dashboard",
    name: "Dashboard",
    redirect: "/dashboard/campaigns",
    component: Dashboard,
    children: [
      {
        path: "/dashboard/statistics",
        name: "Dashboard.Statistics",
        component: Statistics,
        meta: { middleware: [auth], siteChanger: "enabled" }
      },
      {
        path: "/dashboard/leads",
        name: "Dashboard.Leads",
        component: Leads,
        meta: { middleware: [auth], siteChanger: "enabled" }
      },
      {
        path: "/dashboard/campaigns",
        name: "Dashboard.Campaigns",
        component: Campaigns,
        meta: { middleware: [auth], siteChanger: "enabled" }
      },
      {
        path: "/dashboard/campaigns/create",
        name: "Dashboard.Campaigns.Create",
        component: CreateCampaign,
        meta: { middleware: [auth], siteChanger: "enabled" }
      },
      {
        path: "/dashboard/campaigns/:id/settings",
        name: "Dashboard.Campaigns.Settings",
        component: CampaignSettings,
        meta: { middleware: [auth], siteChanger: "enabled", redirectOnSiteChange: "Dashboard.Campaigns" }
      },
      {
        path: "/dashboard/campaigns/:id",
        name: "Dashboard.Campaigns.Summary",
        component: CampaignSummary,
        meta: { middleware: [auth], siteChanger: "enabled", redirectOnSiteChange: "Dashboard.Campaigns" }
      },
      {
        path: "/dashboard/account/profile-settings",
        name: "Dashboard.ProfileSettings",
        component: ProfileSettings,
        meta: { middleware: [auth], siteChanger: "hidden" }
      },
      {
        path: "/dashboard/account/plan-and-billing",
        name: "Dashboard.PlanAndBilling",
        component: PlanBilling,
        meta: { middleware: [auth], siteChanger: "hidden" }
      },
      {
        path: "/dashboard/sites",
        name: "Dashboard.Sites",
        component: Sites,
        meta: { middleware: [auth], siteChanger: "hidden" }
      },
      {
        name: "Dashboard.Admin",
        path: "/dashboard/admin",
        component: Admin,
        meta: { middleware: [auth] },
        children: [
          {
            name: "Dashboard.Admin.CampaignTemplates",
            path: "/dashboard/admin/campaign-templates",
            component: CampaignTemplates,
            meta: { middleware: [auth] }
          },
          {
            name: "Dashboard.Admin.CampaignTemplates.Create",
            path: "/dashboard/admin/create-campaign-template",
            component: CreateCampaignTemplate,
            meta: { middleware: [auth] }
          }
        ]
      }
    ],
    meta: { middleware: [auth], siteChanger: "enabled" }
  },

  {
    path: "/builder/:site/:campaign/:variant",
    name: "CampaignBuilder",
    component: CampaignBuilder,
    meta: { middleware: [auth], siteChanger: "hidden" }
  },

  { path: "/404", name: "NotFound", component: NotFound }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.afterEach(() => {
  window.dispatchEvent(new Event("init"))
})

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]

    const context = { from, next, router, to }
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

export default router
