<template>
  <SettingsWrapper>
    <template #content-buttons>
      <settings-selector-button @click="activateSetArea('content-sender')">Sender</settings-selector-button>
      <settings-selector-button @click="activateSetArea('content-messages')">Messages</settings-selector-button>
      <settings-selector-button @click="activateSetArea('content-buttons')">Buttons</settings-selector-button>
    </template>
    <template #appearance-buttons>
      <settings-selector-button @click="activateSetArea('overlay')">Overlay</settings-selector-button>
    </template>
    <template #appearance>
      <WhatsappAppearance />
    </template>
    <template #content>
      <WhatsappContents />
    </template>
  </SettingsWrapper>
</template>

<script>
import SettingsSelectorButton from "@/components/SettingsSelectorButton"
import SettingsWrapper from "@/components/campaign-builder/widget-settings/SettingsWrapper"
import settingsMixin from "@/mixins/settings-mixin"
import WhatsappContents from "@/components/campaign-builder/widget-settings/whatsapp/WhatsappContents"
import WhatsappAppearance from "@/components/campaign-builder/widget-settings/whatsapp/WhatsappAppearance"

export default {
  name: "WhatsappSettings",
  components: {
    WhatsappAppearance,
    WhatsappContents,
    SettingsWrapper,
    SettingsSelectorButton
  },
  mixins: [settingsMixin]
}
</script>
