<template>
  <div>
    <settings-title>{{ getTitle }}</settings-title>
    <template v-if="setAreaTopic === 'content-sender'">
      <settings-template title="Sender Name"
        ><input class="form-control form-control" v-model="senderName" @input="updateName" />
      </settings-template>
      <settings-template title="Sender Picture">
        <FloatImages :allow-only-change="true" images-class="picture"></FloatImages>
      </settings-template>
    </template>
    <messages v-if="setAreaTopic === 'content-messages'"></messages>
    <double-buttons
      v-if="setAreaTopic === 'content-buttons'"
      :button-area-selector="`${getPageSelector} .action-buttons`"
      primary-button-class="primary"
      secondary-button-class="secondary"
    ></double-buttons>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex"
import Messages from "@/components/campaign-builder/widget-settings/whatsapp/Contents/Messages"
import SettingsTitle from "@/components/campaign-builder/widget-settings/SettingsTitle"
import DoubleButtons from "@/components/campaign-builder/content-changers/DoubleButtons"
import FloatImages from "@/components/campaign-builder/widget-settings/FloatImages"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "WhatsappContents",
  data() {
    return {
      senderName: null
    }
  },
  components: {
    SettingsTemplate,
    FloatImages,
    DoubleButtons,
    SettingsTitle,
    Messages
  },
  computed: {
    ...mapGetters("builder", ["getPageSelector", "getIframeDocument"]),
    ...mapState("builder", ["setAreaTopic"]),
    getTitle() {
      return {
        "content-sender": "Sender",
        "content-messages": "Messages",
        "content-buttons": "Buttons"
      }[this.setAreaTopic]
    }
  },
  methods: {
    updateName() {
      this.getIframeDocument.querySelector(`${this.getPageSelector} .top-bar .name`).innerText = this.senderName
    }
  },
  created() {
    this.senderName = this.getIframeDocument.querySelector(`${this.getPageSelector} .top-bar .name`).innerText
  }
}
</script>
