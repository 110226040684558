<template>
  <div>
    <side-preview v-if="showSidePreview" @close="showSidePreview = false" :template="previewTemplate" />
    <div class="row">
      <div class="col-lg-3">
        <!-- Navbar -->
        <div class="mb-3 navbar-vertical navbar-expand-lg mb-lg-5">
          <!-- Navbar Toggle -->
          <button
            type="button"
            class="mb-3 navbar-toggler btn btn-block btn-white"
            aria-label="Toggle navigation"
            aria-expanded="false"
            aria-controls="templateGalleryLeftbar"
            data-toggle="collapse"
            data-target="#templateGalleryLeftbar"
          >
            <span class="d-flex justify-content-between align-items-center">
              <span class="mb-0 h5">Filters</span>

              <span class="navbar-toggle-default">
                <i class="tio-menu-hamburger"></i>
              </span>

              <span class="navbar-toggle-toggled">
                <i class="tio-clear"></i>
              </span>
            </span>
          </button>
          <!-- End Navbar Toggle -->

          <div id="templateGalleryLeftbar" class="collapse navbar-collapse">
            <!-- Navbar Nav -->
            <ul
              id="navbarSettings"
              class="js-sticky-block js-scrollspy navbar-nav navbar-nav-lg nav-tabs card card-navbar-nav"
              data-hs-sticky-block-options='{
                       "parentSelector": "#templateGalleryLeftbar",
                       "breakpoint": "lg",
                       "startPoint": "#templateGalleryLeftbar",
                       "endPoint": "#stickyBlockEndPoint",
                       "stickyOffsetTop": 20
                     }'
            >
              <li class="nav-item">
                <a
                  class="nav-link nav-link-toggle font-weight-bold"
                  href="#sidebarFilterGoal"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarFilterGoal"
                >
                  Goals
                </a>
                <div class="collapse" id="sidebarFilterGoal">
                  <ul class="nav flex-column">
                    <li class="nav-item" v-for="(goal, key) in availableFilters.goal" :key="key">
                      <checkbox
                        class="nav-link"
                        vertical-space="0"
                        :value="selectedGoals.includes(goal)"
                        @input="handleGoalChange(goal, $event)"
                      >
                        <div class="w-full d-flex justify-content-between">
                          <span>{{ goal }}</span>
                          <span class="text-body">{{ getCountOfGoalFilter(goal) }}</span>
                        </div>
                      </checkbox>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link nav-link-toggle font-weight-bold"
                  href="#sidebarFilterTheme"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarFilterTheme"
                >
                  Themes
                </a>
                <div class="collapse" id="sidebarFilterTheme">
                  <ul class="nav flex-column">
                    <li class="nav-item" v-for="(theme, key) in availableFilters.theme" :key="key">
                      <div class="d-flex align-items-center">
                        <checkbox
                          class="flex-1 w-100 nav-link"
                          vertical-space="0"
                          :value="selectedThemes.includes(theme)"
                          @input="handleThemeChange(theme, $event)"
                        >
                          <div class="w-full d-flex justify-content-between">
                            <span>{{ theme }}</span>
                            <span class="text-body">{{ getCountOfThemeFilter(theme) }}</span>
                          </div>
                        </checkbox>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="nav-item">
                <div class="px-5 mt-3 text-right">
                  <button class="btn btn-white btn-sm" @click="clearAllFilters">Reset Filters</button>
                </div>
              </li>
            </ul>
            <!-- End Navbar Nav -->
          </div>
        </div>
        <!-- End Navbar -->
      </div>
      <div class="col-lg-9">
        <div class="row">
          <div class="mb-4 col-12 col-md-6 col-xxl-4 hs-unfold" v-for="template in filteredCampaignTemplates" :key="template.id">
            <a class="card card-hover-shadow" href="javascript:;" @click="handleOpenPreview(template)">
              <!-- <img class="card-img-top" :src="template.screenshot_url" alt="Card image cap" /> -->
              <img
                class="card-img-top"
                src="https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <strong class="card-title">{{ template.name }}</strong>
                  </div>
                  <div class="col-auto">
                    <div class="text-right card-text">Avg. Conversion: 12%</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row" id="stickyBlockEndPoint"></div>
  </div>
</template>

<script>
import campaignTemplatesAPI from "@/api/campaign-templates"
import Checkbox from "@/components/common/Checkbox"
import HSStickyBlock from "@/theme-files/hs-sticky-block"
import unfoldMixin from "@/mixins/unfold-mixin"
import SidePreview from "@/components/dashboard/SidePreview"

export default {
  name: "TemplateGallery",
  components: { SidePreview, Checkbox },
  data() {
    return {
      stickyBlock: null,
      campaignTemplates: [],
      selectedThemes: [],
      selectedGoals: [],
      showSidePreview: false,
      availableFilters: {
        theme: [],
        goal: []
      },
      previewTemplate: null
    }
  },
  mixins: [unfoldMixin],
  computed: {
    filteredCampaignTemplates() {
      return this.campaignTemplates.filter((template) => {
        if (this.selectedThemes.length > 0) {
          const themeTags = template.tags.filter((tag) => tag.type === "theme").map((tag) => tag.value)

          if (themeTags.length < 1) return false

          let flag = false
          this.selectedThemes.forEach((themeFilter) => {
            if (themeTags.includes(themeFilter)) {
              flag = true
            }
          })

          if (!flag) return false
        }

        if (this.selectedGoals.length > 0) {
          const goalTags = template.tags.filter((tag) => tag.type === "goal").map((tag) => tag.value)

          if (goalTags.length < 1) return false

          let flag = false
          this.selectedGoals.forEach((themeFilter) => {
            if (goalTags.includes(themeFilter)) {
              flag = true
            }
          })

          if (!flag) return false
        }

        return true
      })
    }
  },
  methods: {
    handleOpenPreview(template) {
      this.showSidePreview = true
      this.previewTemplate = template
    },
    clearAllFilters() {
      this.selectedThemes = []
      this.selectedGoals = []
    },
    getCountOfThemeFilter(theme) {
      return this.campaignTemplates
        .filter((template) => {
          return template.tags.some((tag) => tag.type === "theme" && tag.value === theme)
        })
        .filter((template) => {
          if (this.selectedGoals.length < 1) return true
          for (const goal of this.selectedGoals) {
            if (template.tags.some((tag) => tag.type === "goal" && tag.value === goal)) {
              return true
            }
          }
          return false
        }).length
    },
    getCountOfGoalFilter(goal) {
      return this.campaignTemplates
        .filter((template) => {
          return template.tags.some((tag) => tag.type === "goal" && tag.value === goal)
        })
        .filter((template) => {
          if (this.selectedThemes.length < 1) return true
          for (const theme of this.selectedThemes) {
            if (template.tags.some((tag) => tag.type === "theme" && tag.value === theme)) {
              return true
            }
          }
          return false
        }).length
    },
    handleThemeChange(value, checked) {
      if (this.selectedThemes.includes(value) && !checked) {
        this.selectedThemes = this.selectedThemes.filter((v) => v !== value)
      } else if (!this.selectedThemes.includes(value) && checked) {
        this.selectedThemes.push(value)
      }
    },
    handleGoalChange(value, checked) {
      if (this.selectedGoals.includes(value) && !checked) {
        this.selectedGoals = this.selectedGoals.filter((v) => v !== value)
      } else if (!this.selectedGoals.includes(value) && checked) {
        this.selectedGoals.push(value)
      }
    }
  },
  async mounted() {
    const resp = await campaignTemplatesAPI.fetchCampaignTemplates()
    const data = resp.data.data.campaign_templates
    this.campaignTemplates = data

    data.forEach((campaignTemplate) => {
      campaignTemplate.tags.forEach((tag) => {
        if (tag.type !== "theme" && tag.type !== "goal") return

        if (!this.availableFilters[tag.type].includes(tag.value)) {
          this.availableFilters[tag.type].push(tag.value)
        }
      })
    })
    document.body.classList.add("navbar-vertical-aside-mini-mode")

    this.$nextTick(() => {
      const vm = this
      // =======================================================
      $(".js-sticky-block").each(function () {
        vm.stickyBlock = new HSStickyBlock($(this), {
          targetSelector: $("#header").hasClass("navbar-fixed") ? "#header" : null
        })
      })
    })
  },
  beforeDestroy() {
    this.stickyBlock.remove()
  }
}
</script>

<style scoped></style>
