<template>
  <div>
    <Label
      class="mb-2"
      v-if="false && formInputData['type'] !== 'checkbox'"
      :form-input-data="formInputData"
      :key="updateKey"
      @updateFormInputData="mountField"
    />

    <Component :is="formInputData.editComponent" :manager="manager" :element="formInputData.field" />

    <!--    <settings-accordion>-->
    <!--      <template #title>Input Settings</template>-->
    <!--      <template #default>-->

    <!--        <Name-->
    <!--          class="mb-2"-->
    <!--          v-if="formInputData['type'] !== 'checkbox' && formInputData['type'] !== 'rating' && formInputData['type'] !== 'radios'"-->
    <!--          :form-input-data="formInputData"-->
    <!--        />-->

    <!--        <h4>Appearance</h4>-->
    <!--        <div v-if="formInputData.type === 'rating'">-->
    <!--          <div class="row my-4">-->
    <!--            <div class="col-6">-->
    <!--              <settings-template title="Stroke Color" :show-device-picker="false">-->
    <!--                <color-picker :show-opacity="false" :value="manager.getStrokeColor()" @input="(color) => manager.setStrokeColor(color)" />-->
    <!--              </settings-template>-->
    <!--            </div>-->
    <!--            <div class="col-6">-->
    <!--              <settings-template title="Fill Color" :show-device-picker="false">-->
    <!--                <color-picker :show-opacity="false" :value="manager.getFillColor()" @input="(color) => manager.setFillColor(color)" />-->
    <!--              </settings-template>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <SettingsWidth :variable="manager.getStyleVariableName('width')" />-->
    <!--          <SettingsMargin :variable="manager.getStyleVariableName('margin')" />-->
    <!--          <SettingsPadding :variable="manager.getStyleVariableName('padding')" />-->
    <!--          <SettingsBorderRadius :variable="manager.getStyleVariableName('border-radius')" />-->
    <!--          <SettingsBorder-->
    <!--            :color-variable="manager.getStyleVariableName('border-color')"-->
    <!--            :style-variable="manager.getStyleVariableName('border-style')"-->
    <!--            :width-variable="manager.getStyleVariableName('border-width')"-->
    <!--          />-->
    <!--        </div>-->

    <!--        <div v-else>-->
    <!--          <SettingsFontSize layout="horizontal" class="mt-4" :variable="manager.getStyleVariableName('font-size')" />-->
    <!--          <SettingsTextColor class="mt-2" :variable="manager.getStyleVariableName('color')" />-->
    <!--          <SettingsBackgroundColor :variable="manager.getStyleVariableName('background')" />-->
    <!--          <SettingsMargin :variable="manager.getStyleVariableName('margin')" />-->
    <!--          <SettingsPadding :variable="manager.getStyleVariableName('padding')" />-->
    <!--          <SettingsBorderRadius :variable="manager.getStyleVariableName('border-radius')" />-->
    <!--          <SettingsBorder-->
    <!--            :color-variable="manager.getStyleVariableName('border-color')"-->
    <!--            :style-variable="manager.getStyleVariableName('border-style')"-->
    <!--            :width-variable="manager.getStyleVariableName('border-width')"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </settings-accordion>-->
  </div>
</template>

<script>
import Label from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Label"
import InputText from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/InputText"
import Select from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Select"
import Name from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Name"
import Checkbox from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Checkbox"
import Checkboxes from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Checkboxes"
import Rating from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Rating"
import Radio from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Radio"
import Dropdown from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/Dropdown"
import InputTel from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/InputTel"
import draggable from "vuedraggable"
import { mapGetters } from "vuex"
import formFields from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/FormFields"
import IconBars from "@/icons/icon-bars.svg"
import IconX from "@/icons/icon-x.svg"
import SettingsAccordion from "@/components/campaign-builder/widget-settings/SettingsAccordion"
import formFieldInputManagerFactory from "@/lib/marketing-widgets/form-field-input-manager-factory"
import ColorPicker from "@/components/campaign-builder/appearance-changers/base/ColorPicker"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "FormFieldContentEditor",
  components: {
    SettingsTemplate,
    ColorPicker,
    SettingsAccordion,
    Dropdown,
    InputTel,
    Radio,
    Rating,
    Checkbox,
    Checkboxes,
    Name,
    Select,
    InputText,
    Label,
    draggable,
    IconBars,
    IconX
  },
  computed: {
    ...mapGetters("builder", ["getIframeDocument", "getPageSelector"])
  },
  props: ["element"],
  data() {
    return {
      formInputData: {},
      manager: null,
      updateKey: 1
    }
  },
  methods: {
    mountField() {
      const element = this.element
      const label = element.querySelector("label:not(.not-parent-label)") ?? false
      const obj = { label }
      formFields.forEach((formField) => {
        if (element.querySelector(formField.selector)) {
          obj["field"] = element.querySelector(formField.selector)
          obj["type"] = formField.type
          obj["field_name"] = element.querySelector(formField.nameSelector).name
          obj["name"] = formField.name
          obj["editComponent"] = formField.component
        }
      })
      this.formInputData = obj
      this.updateKey += 1
    }
  },
  created() {
    this.mountField()
    this.manager = formFieldInputManagerFactory(this.formInputData.field)
  }
}
</script>
