<template>
  <component :is="componentName" v-bind="$attrs" />
</template>
<script>
import TargetingInactivity from "@/components/campaign-targeting-rules/TargetingInactivity"
import TargetingScrollPercentage from "@/components/campaign-targeting-rules/TargetingScrollPercentage"
import TargetingAfterMilliseconds from "@/components/campaign-targeting-rules/TargetingAfterMilliseconds"
import TargetingExitIntent from "@/components/campaign-targeting-rules/TargetingExitIntent"
import TargetingClickTo from "@/components/campaign-targeting-rules/TargetingClickTo"
import TargetingReturningOrNew from "@/components/campaign-targeting-rules/TargetingReturningOrNew"
import TargetingLinking from "@/components/campaign-targeting-rules/TargetingLinking"
import TargetingIpAllowed from "@/components/campaign-targeting-rules/TargetingIpAllowed"
import TargetingIpBlocked from "@/components/campaign-targeting-rules/TargetingIpBlocked"
import TargetingAdblock from "@/components/campaign-targeting-rules/TargetingAdblock"
import TargetingVisitedUrl from "@/components/campaign-targeting-rules/TargetingVisitedUrl"
import TargetingExcludeByVisit from "@/components/campaign-targeting-rules/TargetingExcludeByVisit"
import TargetingDevice from "@/components/campaign-targeting-rules/TargetingDevice"
import TargetingSource from "@/components/campaign-targeting-rules/TargetingSource"
import TargetingOperatingSystem from "@/components/campaign-targeting-rules/TargetingOperatingSystem"
import TargetingNumberOfVisitedPages from "@/components/campaign-targeting-rules/TargetingNumberOfVisitedPages"
import TargetingTimeSpentOnPage from "@/components/campaign-targeting-rules/TargetingTimeSpentOnPage"
import TargetingTimeSpentOnSite from "@/components/campaign-targeting-rules/TargetingTimeSpentOnSite"
import TargetingCart from "@/components/campaign-targeting-rules/TargetingCart"
import TargetingFrequency from "@/components/campaign-targeting-rules/TargetingFrequency"
import TargetingCurrentUrl from "@/components/campaign-targeting-rules/TargetingCurrentUrl"
import TargetingLastVisitedUrl from "@/components/campaign-targeting-rules/TargetingLastVisitedUrl"
import TargetingBrowserLanguage from "@/components/campaign-targeting-rules/TargetingBrowserLanguage"
import TargetingLocation from "@/components/campaign-targeting-rules/TargetingLocation"

export default {
  name: "TargetingWidget",
  props: {
    componentName: String
  },
  components: {
    TargetingExitIntent,
    TargetingAfterMilliseconds,
    TargetingInactivity,
    TargetingScrollPercentage,
    TargetingClickTo,
    TargetingReturningOrNew,
    TargetingLinking,
    TargetingIpAllowed,
    TargetingIpBlocked,
    TargetingVisitedUrl,
    TargetingAdblock,
    TargetingDevice,
    TargetingSource,
    TargetingNumberOfVisitedPages,
    TargetingExcludeByVisit,
    TargetingTimeSpentOnPage,
    TargetingTimeSpentOnSite,
    TargetingFrequency,
    TargetingCart,
    TargetingCurrentUrl,
    TargetingLastVisitedUrl,
    TargetingBrowserLanguage,
    TargetingOperatingSystem,
    TargetingLocation
  }
}
</script>

<style scoped></style>
