import randomId from "@/lib/utils/random-id"
import { getImportUrl } from "@/lib/utils/google-font"
import multiPurposeTemplates from "@/lib/marketing-widgets/multi-purpose-templates"

const closeButton = `<button class="close-button font-family-Poppins" data-close="true" data-mobile-visibility-delay="0" data-desktop-visibility-delay="0"><span>X</span></button>`

class multiPurposeHtml {
  constructor(type) {
    this.randId = randomId()
    this.type = type
    this.template = multiPurposeTemplates()[type]
    if (this.type === "popup") {
      this.positionClass = "desktop-center-center mobile-center-center"
      this.titleButton = "close"
    } else if (this.type === "panel") {
      this.positionClass = "panel panel-right collapsed"
      this.titleButton = "expand-toggle"
    }
  }

  get() {
    const fontUrl = getImportUrl(["Poppins"])
    let html = `
            <link href="${fontUrl}" rel="stylesheet">
              <div id="${this.randId}" data-page-parent>
              <div class="position ${this.positionClass}" data-position id="${randomId()}">
              <div class="inner" >
              <div class="layout font-family-Poppins">
              ${this.titleButton === "close" ? closeButton : ""}
              <div class="images"></div> `

    if (this.type === "panel") {
      html += `<div class="osm-element">
                  <div class="panel-title">
                    <div class="panel-title-text" data-osm-text-field>Write Your Panel Title Here</div>
                    <button type="button" class="panel-toggle-button"></button>
                  </div>
                </div>`
    }
    html += `
<div class="content-wrapper">
<div class="gamification-wrapper">
            <div class="gamification-container" data-desktop="left" data-mobile="top"></div>
<div class="osm-element body-wrapper">
            <form class="form">
              <div class="body">
              </div>
            </form>
          </div></div>
        </div>
        </div>
        </div>
        </div>
        <div class="overlay" data-close="true" data-overlay></div>
        </div>`
    return html
  }
}

export default multiPurposeHtml
