<template>
  <div class="hs-unfold">
    <a
      class="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle"
      href="javascript:;"
      data-hs-unfold-options='{
                       "target": "#notificationDropdown",
                       "type": "css-animation"
                     }'
    >
      <i class="tio-notifications-on-outlined"></i>
      <span class="btn-status btn-sm-status btn-status-danger"></span>
    </a>

    <div
      id="notificationDropdown"
      class="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu"
      style="width: 25rem"
    >
      <!-- Header -->
      <div class="card-header">
        <span class="card-title h4">Notifications</span>

        <!-- Unfold -->
        <div class="hs-unfold">
          <a
            class="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
            href="javascript:;"
            data-hs-unfold-options='{
                             "target": "#notificationSettingsOneDropdown",
                             "type": "css-animation"
                           }'
          >
            <i class="tio-more-vertical"></i>
          </a>
          <div id="notificationSettingsOneDropdown" class="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right">
            <span class="dropdown-header">Settings</span>
            <a class="dropdown-item" href="#"> <i class="tio-archive dropdown-item-icon"></i> Archive all </a>
            <a class="dropdown-item" href="#"> <i class="tio-all-done dropdown-item-icon"></i> Mark all as read </a>
            <a class="dropdown-item" href="#"> <i class="tio-toggle-off dropdown-item-icon"></i> Disable notifications </a>
            <a class="dropdown-item" href="#"> <i class="tio-gift dropdown-item-icon"></i> What's new? </a>
            <div class="dropdown-divider"></div>
            <span class="dropdown-header">Feedback</span>
            <a class="dropdown-item" href="#"> <i class="tio-chat-outlined dropdown-item-icon"></i> Report </a>
          </div>
        </div>
        <!-- End Unfold -->
      </div>
      <!-- End Header -->

      <!-- Nav -->
      <ul class="nav nav-tabs nav-justified" id="notificationTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="notificationNavOne-tab"
            data-toggle="tab"
            href="#notificationNavOne"
            role="tab"
            aria-controls="notificationNavOne"
            aria-selected="true"
            >Messages (3)</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="notificationNavTwo-tab"
            data-toggle="tab"
            href="#notificationNavTwo"
            role="tab"
            aria-controls="notificationNavTwo"
            aria-selected="false"
            >Archived</a
          >
        </li>
      </ul>
      <!-- End Nav -->

      <!-- Body -->
      <div class="card-body-height">
        <!-- Tab Content -->
        <div class="tab-content" id="notificationTabContent">
          <div class="tab-pane fade show active" id="notificationNavOne" role="tabpanel" aria-labelledby="notificationNavOne-tab">
            <ul class="list-group list-group-flush navbar-card-list-group">
              <!-- Item -->
              <li class="list-group-item custom-checkbox-list-wrapper">
                <div class="row">
                  <div class="col-auto position-static">
                    <div class="d-flex align-items-center">
                      <div class="custom-control custom-checkbox custom-checkbox-list">
                        <input type="checkbox" class="custom-control-input" id="notificationCheck1" checked />
                        <label class="custom-control-label" for="notificationCheck1"></label>
                        <span class="custom-checkbox-list-stretched-bg"></span>
                      </div>
                      <div class="avatar avatar-sm avatar-circle">
                        <!--                      <img class="avatar-img" src="./assets/img/160x160/img3.jpg" alt="Image Description">-->
                      </div>
                    </div>
                  </div>
                  <div class="col ml-n3">
                    <span class="card-title h5">Brian Warner</span>
                    <p class="card-text font-size-sm">
                      changed an issue from "In Progress" to <span class="badge badge-success">Review</span>
                    </p>
                  </div>
                  <small class="col-auto text-muted text-cap">2hr</small>
                </div>
                <a class="stretched-link" href="#"></a>
              </li>
              <!-- End Item -->
            </ul>
          </div>

          <div class="tab-pane fade" id="notificationNavTwo" role="tabpanel" aria-labelledby="notificationNavTwo-tab">
            <ul class="list-group list-group-flush navbar-card-list-group">
              <!-- Item -->
              <li class="list-group-item custom-checkbox-list-wrapper">
                <div class="row">
                  <div class="col-auto position-static">
                    <div class="d-flex align-items-center">
                      <div class="custom-control custom-checkbox custom-checkbox-list">
                        <input type="checkbox" class="custom-control-input" id="notificationCheck7" />
                        <label class="custom-control-label" for="notificationCheck7"></label>
                        <span class="custom-checkbox-list-stretched-bg"></span>
                      </div>
                      <div class="avatar avatar-sm avatar-soft-dark avatar-circle">
                        <span class="avatar-initials">A</span>
                      </div>
                    </div>
                  </div>
                  <div class="col ml-n3">
                    <span class="card-title h5">Anne Richard</span>
                    <p class="card-text font-size-sm">accepted your invitation to join Notion</p>
                  </div>
                  <small class="col-auto text-muted text-cap">1dy</small>
                </div>
                <a class="stretched-link" href="#"></a>
              </li>
              <!-- End Item -->
            </ul>
          </div>
        </div>
        <!-- End Tab Content -->
      </div>
      <!-- End Body -->

      <!-- Card Footer -->
      <a class="card-footer text-center" href="#">
        View all notifications
        <i class="tio-chevron-right"></i>
      </a>
      <!-- End Card Footer -->
    </div>
  </div>
</template>

<script>
import unfoldMixin from "@/mixins/unfold-mixin"

export default {
  name: "HeaderNotification",
  mixins: [unfoldMixin]
}
</script>

<style scoped></style>
