<template>
  <component :is="componentName" v-bind="$attrs" v-on="$listeners" />
</template>
<script>
import InactivityModal from "@/components/campaign-targeting-rules/modals/InactivityModal"
import ScrollPercentageModal from "@/components/campaign-targeting-rules/modals/ScrollPercentageModal"
import AfterMillisecondsModal from "@/components/campaign-targeting-rules/modals/AfterMillisecondsModal"
import ExitIntentModal from "@/components/campaign-targeting-rules/modals/ExitIntentModal"
import ClickToModal from "@/components/campaign-targeting-rules/modals/ClickToModal"
import ReturningOrNewModal from "@/components/campaign-targeting-rules/modals/ReturningOrNewModal"
import LinkingModal from "@/components/campaign-targeting-rules/modals/LinkingModal"
import IpAllowedModal from "@/components/campaign-targeting-rules/modals/IpAllowedModal"
import IpBlockedModal from "@/components/campaign-targeting-rules/modals/IpBlockedModal"
import AdblockModal from "@/components/campaign-targeting-rules/modals/AdblockModal"
import VisitedUrlModal from "@/components/campaign-targeting-rules/modals/VisitedUrlModal"
import ExcludeByVisitModal from "@/components/campaign-targeting-rules/modals/ExcludeByVisitModal"
import DeviceModal from "@/components/campaign-targeting-rules/modals/DeviceModal"
import SourceModal from "@/components/campaign-targeting-rules/modals/SourceModal"
import OperatingSystemModal from "@/components/campaign-targeting-rules/modals/OperatingSystemModal"
import NumberOfVisitedPagesModal from "@/components/campaign-targeting-rules/modals/NumberOfVisitedPagesModal"
import TimeSpentOnPageModal from "@/components/campaign-targeting-rules/modals/TimeSpentOnPageModal"
import TimeSpentOnSiteModal from "@/components/campaign-targeting-rules/modals/TimeSpentOnSiteModal"
import CartModal from "@/components/campaign-targeting-rules/modals/CartModal"
import FrequencyModal from "@/components/campaign-targeting-rules/modals/FrequencyModal"
import CurrentUrlModal from "@/components/campaign-targeting-rules/modals/CurrentUrlModal"
import LastVisitedUrlModal from "@/components/campaign-targeting-rules/modals/LastVisitedUrlModal"
import BrowserLanguageModal from "@/components/campaign-targeting-rules/modals/BrowserLanguageModal"
import LocationModal from "@/components/campaign-targeting-rules/modals/LocationModal"

export default {
  name: "TargetingModal",
  props: {
    componentName: String
  },
  components: {
    ExitIntentModal,
    AfterMillisecondsModal,
    InactivityModal,
    ScrollPercentageModal,
    ClickToModal,
    ReturningOrNewModal,
    LinkingModal,
    IpAllowedModal,
    IpBlockedModal,
    VisitedUrlModal,
    AdblockModal,
    DeviceModal,
    SourceModal,
    NumberOfVisitedPagesModal,
    ExcludeByVisitModal,
    TimeSpentOnPageModal,
    TimeSpentOnSiteModal,
    FrequencyModal,
    CartModal,
    CurrentUrlModal,
    LastVisitedUrlModal,
    BrowserLanguageModal,
    OperatingSystemModal,
    LocationModal
  }
}
</script>

<style scoped></style>
