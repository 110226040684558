import getAxiosInstance from "@/api/get-axios-instance"

const createCampaignTemplate = async ({ name, is_active, tags, variantId }) => {
  return await getAxiosInstance().post(`admin/campaign-templates/create-by-campaign-variant/${variantId}`, { name, is_active, tags })
}

const updateCampaignTemplate = async (campaignTemplateId, { name, is_active, tags }) => {
  return await getAxiosInstance().put(`admin/campaign-templates/${campaignTemplateId}`, { name, is_active, tags })
}
const getCampaignTemplatesDatatablesEndpoint = () => process.env.VUE_APP_API_BASE + `/admin/campaign-templates-datatables`
const fetchCampaignTemplate = async (campaignTemplateId) => await getAxiosInstance().get(`admin/campaign-templates/${campaignTemplateId}`)

const adminAPI = {
  createCampaignTemplate,
  getCampaignTemplatesDatatablesEndpoint,
  updateCampaignTemplate,
  fetchCampaignTemplate
}
export default adminAPI
