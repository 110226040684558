<template>
  <div class="card">
    <modal-plan v-if="showPlanModal" @close="showPlanModal = false" />
    <!-- Header -->
    <div class="card-header">
      <h4 class="card-header-title">Overview</h4>
    </div>
    <!-- End Header -->

    <!-- Body -->
    <div class="card-body">
      <div class="row">
        <div class="col-md mb-3 mb-md-0">
          <div class="mb-4">
            <span class="card-subtitle mb-0">Your plan is:</span>
            <h3>{{ user.plan.name }} {{ user.plan.billing_period ? " - " + user.plan.billing_period : "" }}</h3>
          </div>
        </div>

        <ul class="list-inline list-unstyled col-md-auto">
          <li class="list-inline-item mb-2 mr-2" v-if="user.plan.price && user.plan.price > 0">
            <a class="btn btn-white" href="#">Cancel subscription</a>
          </li>
          <li class="list-inline-item mb-2">
            <a class="btn btn-primary" href="javascript:;" @click="handleChangePlanClick"> Change Plan </a>
          </li>
        </ul>
      </div>
      <!-- End Row -->
    </div>
    <!-- End Body -->
  </div>
</template>

<script>
import { mapState } from "vuex"
import ModalPlan from "@/components/modals/ModalPlan"

export default {
  name: "AccountPlan",
  components: { ModalPlan },
  computed: mapState("user", ["user", "paymentMethods"]),
  data() {
    return {
      showPlanModal: false
    }
  },
  methods: {
    handleChangePlanClick() {
      if (this.paymentMethods.length > 0) {
        this.showPlanModal = true
      } else {
        document.querySelector("#account-payment-methods").scrollIntoView({ behavior: "smooth" })
      }
    }
  }
}
</script>

<style scoped></style>
