import getAxiosInstance from "@/api/get-axios-instance"

const fetchAvailableIntegrations = async () => await getAxiosInstance().get(`third-party-integrations/available-integrations`)
const fetchUserIntegrations = async () => await getAxiosInstance().get(`third-party-integrations`)
const fetchCampaignIntegrations = async (siteId, campaignId) =>
  await getAxiosInstance().get(`sites/${siteId}/campaigns/${campaignId}/third-party-integrations`)
const deleteCampaignIntegration = async (siteId, campaignId, campaignThirdPartyIntegrationId) =>
  await getAxiosInstance().delete(`sites/${siteId}/campaigns/${campaignId}/third-party-integrations/${campaignThirdPartyIntegrationId}`)
const createIntegration = async function (endpoint, { name, auth_data }) {
  return await getAxiosInstance().post(endpoint, { name, auth_data })
}

const createCampaignIntegration = async (siteId, campaignId, integrationId, { settings, form_bindings }) =>
  await getAxiosInstance().post(`sites/${siteId}/campaigns/${campaignId}/third-party-integrations/match/${integrationId}`, {
    settings,
    form_bindings
  })
const updateCampaignIntegration = async (siteId, campaignId, campaignIntegrationId, { settings, form_bindings }) =>
  await getAxiosInstance().put(`sites/${siteId}/campaigns/${campaignId}/third-party-integrations/${campaignIntegrationId}`, {
    settings,
    form_bindings
  })

const get = async (endpoint) => await getAxiosInstance().get(endpoint)

const integrationAPI = {
  fetchCampaignIntegrations,
  deleteCampaignIntegration,
  updateCampaignIntegration,
  fetchAvailableIntegrations,
  createIntegration,
  fetchUserIntegrations,
  createCampaignIntegration,
  get
}
export default integrationAPI
