<template>
  <div class="card card-hover-shadow card-body">
    <div class="mb-4 row align-items-sm-center">
      <div class="mb-3 col-sm mb-sm-0">
        <h4>Statistics</h4>
      </div>
      <div class="col-sm-auto">
        <div class="form-control">
          <div class="custom-control custom-checkbox custom-radio-reverse">
            <input type="checkbox" class="custom-control-input" name="showImpression" id="showImpression" v-model="showImpressions" />
            <label class="custom-control-label media align-items-center" for="showImpression">
              <span class="mr-2 legend-indicator" style="background: #377dff"></span>
              <span class="media-body"> Impression </span>
            </label>
          </div>
        </div>
      </div>

      <div class="col-sm-auto">
        <div class="form-control">
          <div class="custom-control custom-checkbox custom-radio-reverse">
            <input type="checkbox" class="custom-control-input" name="showConversion" id="showConversion" v-model="showSubmits" />
            <label class="custom-control-label media align-items-center" for="showConversion">
              <span class="mr-2 legend-indicator" style="background: #00c9db"></span>
              <span class="media-body"> Lead (Submit) </span>
            </label>
          </div>
        </div>
      </div>

      <div class="col-sm-auto">
        <div class="form-control">
          <div class="custom-control custom-checkbox custom-radio-reverse">
            <input type="checkbox" class="custom-control-input" name="showConversionRate" id="showConversionRate" v-model="showOpenUrls" />
            <label class="custom-control-label media align-items-center" for="showConversionRate">
              <span class="mr-2 legend-indicator" style="background: #DE5E09"></span>
              <span class="media-body"> Open URL </span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-sm-auto">
        <div class="form-control">
          <div class="custom-control custom-checkbox custom-radio-reverse">
            <input
              type="checkbox"
              class="custom-control-input"
              name="showConversionRate"
              id="showWhatsappRedirect"
              v-model="showWhatsappRedirects"
            />
            <label class="custom-control-label media align-items-center" for="showWhatsappRedirect">
              <span class="mr-2 legend-indicator" style="background: #6dce4c"></span>
              <span class="media-body"> Whatsapp Redirect </span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-sm-auto">
        <div class="form-control">
          <div class="custom-control custom-checkbox custom-radio-reverse">
            <input
              type="checkbox"
              class="custom-control-input"
              name="showConversionRate"
              id="showPhoneRedirect"
              v-model="showPhoneRedirects"
            />
            <label class="custom-control-label media align-items-center" for="showPhoneRedirect">
              <span class="mr-2 legend-indicator" style="background: #7C1ECB"></span>
              <span class="media-body"> Phone Redirect </span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- End Row -->

    <!-- Bar Chart -->
    <div class="chartjs-custom" style="height: 24rem; width: 100%">
      <canvas class="js-chart"> </canvas>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatisticsLineChart",
  props: {
    dailyMetrics: Object,
    conversionCountByDay: Object,
    conversionRateByDay: Object
  },
  data() {
    return {
      showMetrics: {},
      orderedDailyMetrics: {},
      showImpressions: true,
      showSubmits: false,
      showOpenUrls: false,
      showWhatsappRedirects: false,
      showPhoneRedirects: false,
      orderedViewCountByDay: {},
      orderedConversionCountByDay: {},
      orderedConversionRateByDay: {},
      chart: null
    }
  },
  watch: {
    getData(newVal) {
      if (this.chart) {
        this.chart.data.datasets = newVal
        this.chart.update()
      }
    }
  },
  computed: {
    getData() {
      const data = []
      if (this.showImpressions) {
        data.push({
          data: Object.values(this.dailyMetrics["impression"]),
          backgroundColor: "transparent",
          borderColor: "#377dff",
          borderWidth: 2,
          pointRadius: 0,
          hoverBorderColor: "#377dff",
          pointBackgroundColor: "#377dff",
          pointBorderColor: "#fff",
          pointHoverRadius: 0
        })
      }

      if (this.showSubmits) {
        data.push({
          data: Object.values(this.dailyMetrics["submit"]),
          backgroundColor: "transparent",
          borderColor: "#00c9db",
          borderWidth: 2,
          pointRadius: 0,
          hoverBorderColor: "#00c9db",
          pointBackgroundColor: "#00c9db",
          pointBorderColor: "#fff",
          pointHoverRadius: 0
        })
      }

      if (this.showOpenUrls) {
        data.push({
          data: Object.values(this.dailyMetrics["open_url"]),
          backgroundColor: "transparent",
          borderColor: "#ff6907",
          borderWidth: 2,
          pointRadius: 0,
          hoverBorderColor: "#DE5E09",
          pointBackgroundColor: "#DE5E09",
          pointBorderColor: "#fff",
          pointHoverRadius: 0
        })
      }
      if (this.showWhatsappRedirects) {
        data.push({
          data: Object.values(this.dailyMetrics["whatsapp_redirect"]),
          backgroundColor: "transparent",
          borderColor: "#6dce4c",
          borderWidth: 2,
          pointRadius: 0,
          hoverBorderColor: "#28a745",
          pointBackgroundColor: "#28a745",
          pointBorderColor: "#fff",
          pointHoverRadius: 0
        })
      }
      if (this.showPhoneRedirects) {
        data.push({
          data: Object.values(this.dailyMetrics["phone_redirect"]),
          backgroundColor: "transparent",
          borderColor: "#a740f6",
          borderWidth: 2,
          pointRadius: 0,
          hoverBorderColor: "#7C1ECB",
          pointBackgroundColor: "#7C1ECB",
          pointBorderColor: "#fff",
          pointHoverRadius: 0
        })
      }

      return data
    }
  },

  mounted() {
    const vm = this
    const dates = Object.keys(this.dailyMetrics["impression"])

    this.$nextTick(function () {
      require("@/theme-files/hs.chartjs")
      vm.chart = $.HSCore.components.HSChartJS.init($(this.$el.querySelector(".js-chart")), {
        type: "line",
        data: {
          labels: dates,
          datasets: vm.getData
        },
        options: {
          scales: {
            yAxes: [
              {
                gridLines: {
                  color: "#e7eaf3",
                  drawBorder: false,
                  zeroLineColor: "#e7eaf3"
                },
                ticks: {
                  fontColor: "#97a4af",
                  fontFamily: "Open Sans, sans-serif",
                  padding: 10,
                  postfix: "k"
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                  drawBorder: false
                },
                ticks: {
                  fontSize: 12,
                  fontColor: "#97a4af",
                  fontFamily: "Open Sans, sans-serif",
                  padding: 5
                }
              }
            ]
          },
          tooltips: {
            hasIndicator: true,
            mode: "index",
            intersect: false,
            lineMode: true,
            lineWithLineColor: "rgba(19, 33, 68, 0.075)"
          },
          hover: {
            mode: "nearest",
            intersect: true
          }
        }
      })
    })
  }
}
</script>

<style scoped></style>
