<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div v-for="(row, key1) in value" :key="key1">
      <div class="d-flex align-items-center my-2">
        <span class="mr-2 flex-shrink-0">Page url(s)</span>
        <targeting-string-compare-operator-selector
          class="mr-2"
          :value="row.filter"
          @change="(e) => setRowData(key1, 'filter', e.target.value)"
        ></targeting-string-compare-operator-selector>
        <targeting-url-input
          class="mr-4"
          :show-domain="showDomain(row.filter)"
          :value="row.value"
          @input="(e) => setRowData(key1, 'value', e.target.value)"
        ></targeting-url-input>
        <button type="button" class="btn btn-icon btn-soft-danger btn-sm flex-shrink-0" @click="removeRow(key1)" v-if="value.length > 1">
          <i class="tio-remove-from-trash"></i>
        </button>
      </div>
      <div class="my-3 text-center">
        <div class="mb-2">OR</div>
      </div>
    </div>
    <div class="mt-3 text-center">
      <button type="button" class="btn btn-icon btn-soft-primary btn-sm" @click="addOrRow">
        <i class="tio-add"></i>
      </button>
    </div>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"
import TargetingStringCompareOperatorSelector from "@/components/campaign-targeting-rules/TargetingStringCompareOperatorSelector"
import TargetingUrlInput from "@/components/campaign-targeting-rules/TargetingUrlInput"

const mixin = targetingRuleModalMixin("who", targetingRuleNames.whoRules.lastVisitedUrl)

export default {
  name: "LastVisitedUrlModal",
  components: { TargetingUrlInput, TargetingStringCompareOperatorSelector, ModalCampaignTargetingRule },
  mixins: [mixin],
  methods: {
    showDomain(filter) {
      return filter === "equals" || filter === "notEquals" || filter === "startsWith" || filter === "notStartsWith"
    },
    addOrRow() {
      this.value.push({ filter: "equals", value: "", time: "0" })
      this.value = [...this.value]
    },
    setRowData(key1, key2, value) {
      this.value[key1][key2] = value
      this.value = [...this.value]
    },
    removeRow(key) {
      let tmp = this.value
      tmp.splice(key, 1)
      this.value = tmp
      this.value = [...this.value]
    }
  }
}
</script>
