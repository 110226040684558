<template>
  <div>
    <div v-if="showTitle" class="d-flex justify-content-between align-items-center mb-2">
      <h5 class="mb-0">{{ title }}</h5>
      <button v-if="button === false" class="btn btn-soft-primary btn-icon btn-xs" @click="add">
        <i class="tio-add tio-lg"></i>
      </button>
      <button v-else class="btn btn-soft-danger btn-icon btn-xs" @click="remove">
        <i class="tio-clear tio-lg"></i>
      </button>
    </div>
    <div v-if="button !== false">
      <div v-if="innerEditable">
        <mini-editor :link="false" :selector="`${buttonAreaSelector} .${buttonClass}`" />
      </div>
      <div class="row align-items-center mt-3">
        <div class="col-4">
          <span>Count As:</span>
        </div>
        <div class="col-8">
          <select class="form-control" v-model="buttonActions.countAs">
            <option value="none">None</option>
            <option value="conversion">Conversion</option>
            <option value="rejection">Rejection</option>
          </select>
        </div>
      </div>
      <div class="button-actions">
        <Checkbox v-model="buttonActions.goalReached.status">Campaign Goal Reached</Checkbox>
        <Checkbox v-model="buttonActions.close.status">Close Campaign</Checkbox>
        <Checkbox v-model="buttonActions.openUrl.status" :disabled="buttonActions.whatsapp.status">Open URL</Checkbox>
        <div v-if="buttonActions.openUrl.status" class="ml-4 mb-6">
          <div class="row align-items-center">
            <div class="col-3">
              <span>URL:</span>
            </div>
            <div class="col-9">
              <input type="text" v-model="buttonActions.openUrl.url" class="form-control form-control-sm" />
            </div>
          </div>
          <Checkbox v-model="buttonActions.openUrl.newTab">Open the page in a new tab</Checkbox>
        </div>
        <Checkbox v-model="buttonActions.whatsapp.status" :disabled="buttonActions.openUrl.status">Redirect To WhatsApp </Checkbox>

        <div v-if="buttonActions.whatsapp.status" class="ml-4 mb-6">
          <div class="row align-items-center">
            <div class="col-3">
              <span>Number:</span>
            </div>
            <div class="col-9">
              <input type="text" v-model="buttonActions.whatsapp.number" class="form-control form-control-sm" />
            </div>
          </div>
        </div>
        <Checkbox v-model="buttonActions.submit.status">Open Next Page / Submit Form</Checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Checkbox from "@/components/common/Checkbox"
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"

export default {
  name: "SingleButton",
  components: { MiniEditor, Checkbox },
  props: {
    buttonAreaSelector: String,
    buttonClass: String,
    title: String,
    showTitle: {
      type: Boolean,
      default: true
    },
    innerEditable: {
      type: Boolean,
      default: true
    },
    order: {
      default: "1"
    }
  },
  data() {
    return {
      button: false,
      buttonActions: {
        openUrl: {
          status: false,
          url: "https://",
          newTab: false
        },
        whatsapp: {
          status: false,
          number: ""
        },
        submit: {
          status: false
        },
        goalReached: {
          status: false
        },
        close: {
          status: false
        },
        countAs: "none"
      }
    }
  },
  computed: mapGetters("builder", ["getIframeDocument", "getPageSelector"]),
  watch: {
    buttonActions: {
      handler: "buttonWatchHandler",
      deep: true
    }
  },
  methods: {
    buttonWatchHandler() {
      this.setButtonData()
    },
    analyzeButtons() {
      const button = this.getIframeDocument.querySelector(`${this.buttonAreaSelector} .${this.buttonClass}`)
      if (!button) {
        this.button = false
        return
      }
      this.button = button
      if (button.dataset["close"]) {
        this.buttonActions.close.status = true
      }
      if (button.dataset["whatsapp"]) {
        this.buttonActions.whatsapp.status = true
        this.buttonActions.whatsapp.number = button.dataset["whatsapp"]
      }
      if (button.dataset["url"]) {
        this.buttonActions.openUrl.status = true
        this.buttonActions.openUrl.url = button.dataset["url"]
        if (button.dataset["newTab"]) {
          this.buttonActions.openUrl.newTab = true
        }
      }
      if (button.dataset["submit"]) {
        this.buttonActions.submit.status = true
      }
      if (button.dataset["goalReached"]) {
        this.buttonActions.goalReached.status = true
      }
      if (button.dataset["countAs"]) {
        this.buttonActions.countAs = button.dataset["countAs"]
      }
    },
    remove() {
      const button = this.getIframeDocument.querySelector(`${this.buttonAreaSelector} .${this.buttonClass}`)
      const owner = button.parentElement
      owner.removeChild(button)
      this.analyzeButtons()
    },
    add() {
      const button = this.getIframeDocument.createElement("button")
      button.classList.add(this.buttonClass)
      button.dataset.countAs = "none"
      if (this.order === "1") {
        this.getIframeDocument.querySelector(`${this.buttonAreaSelector}`).prepend(button)
      } else {
        this.getIframeDocument.querySelector(`${this.buttonAreaSelector}`).appendChild(button)
      }

      this.analyzeButtons()
    },
    setButtonData() {
      const attrs = ["data-url", "data-new-tab", "data-submit", "data-goal-reached", "data-count-as", "data-whatsapp", "data-close"]

      const button = this.getIframeDocument.querySelector(`${this.buttonAreaSelector} .${this.buttonClass}`)
      if (!button) {
        return
      }
      attrs.forEach((attr) => button.removeAttribute(attr))
      if (this.buttonActions.openUrl.status === true) {
        button.setAttribute("data-url", this.buttonActions.openUrl.url)
        button.setAttribute("data-new-tab", this.buttonActions.openUrl.newTab)
      }
      if (this.buttonActions.whatsapp.status === true) {
        button.setAttribute("data-whatsapp", this.buttonActions.whatsapp.number)
      }
      if (this.buttonActions.submit.status === true) {
        button.setAttribute("data-submit", this.buttonActions.submit.status)
      }
      if (this.buttonActions.close.status === true) {
        button.setAttribute("data-close", this.buttonActions.close.status)
      }
      if (this.buttonActions.goalReached.status === true) {
        button.setAttribute("data-goal-reached", this.buttonActions.goalReached.status)
      }
      button.setAttribute("data-count-as", this.buttonActions.countAs)
    }
  },
  created() {
    this.analyzeButtons()
    this.setButtonData()
  }
}
</script>

<style scoped></style>
