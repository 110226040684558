import ElementManager from "@/lib/marketing-widgets/element-manager"

class FormButtonManager extends ElementManager {
  constructor(button) {
    super(
      button,
      {
        display: "block",
        width: "100%",
        background: "#333",
        "--hover-background": "#222",
        "border-radius": "8px 8px 8px 8px",
        padding: "8px 8px 8px 8px",
        margin: "16px 0px 16px 0px",
        "font-size": "15px",
        color: "#fff",
        "border-style": "none",
        "border-width": "0px 0px 0px 0px",
        "border-color": "#f00"
      },
      "form-btn-"
    )
  }
}

export default FormButtonManager
