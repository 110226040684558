<template>
  <base-campaign-targeting-rule>
    <div>
      <div v-for="(andSentences, key1) in getDescription" :key="key1">
        <div class="my-2" v-for="(sentence, key2) in andSentences" :key="key2">
          <div style="line-height: 1.2">{{ sentence }}</div>
        </div>
        <strong v-if="data.length - 1 > key1">OR</strong>
      </div>
    </div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import { mapState } from "vuex"
import stringCompareOperators from "@/components/campaign-targeting-rules/stringCompareOperators"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("who", targetingRuleNames.whoRules.visitedUrl)

export default {
  name: "TargetingVisitedUrl",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin],
  computed: {
    ...mapState("sites", ["selectedSite"]),
    getDescription() {
      let sentences = []
      this.data.forEach((andRows) => {
        sentences.push(
          andRows.map((row) => {
            return `URL of one of the previously visited pages ${stringCompareOperators[row.filter].toLowerCase()}: ${
              this.showDomain(row.filter) ? this.selectedSite.domain + "/" : ""
            }${row.value}`
          })
        )
      })
      return sentences
    }
  },
  methods: {
    showDomain(filter) {
      return filter === "equals" || filter === "notEquals" || filter === "startsWith" || filter === "notStartsWith"
    }
  }
}
</script>
