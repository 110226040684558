<template>
  <div class="d-flex align-items-center">
    <input v-model.number="val" :min="min" :max="max" class="form-control-range mr-3 w-100 flex-fill" type="range" />
    <input
      v-model.number="val"
      class="form-control form-control-sm"
      style="border-top-right-radius: 0; border-bottom-right-radius: 0; width: 3.75rem; flex-shrink: 0; padding-left: 0.5rem; padding-right: 0.35rem"
      type="number"
    />
    <select
      v-model="unit"
      class="form-control form-control-sm"
      style="border-top-left-radius: 0; border-bottom-left-radius: 0; width: 3.75rem; flex-shrink:0; padding-left: 0.25rem"
    >
      <option v-for="u in alternativeUnits" :key="u" :value="u">
        {{ u.toUpperCase() }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "RangeInputWithUnit",
  data() {
    return { unit: this.value.unit, val: this.value.value }
  },
  props: {
    value: {
      required: true,
      type: Object
    },
    alternativeUnits: {
      required: true,
      type: Array
    },
    min: {
      required: true,
      type: Number
    },
    max: {
      required: true,
      type: Number
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function (newValue) {
        if (this.val !== newValue.value || this.unit !== newValue.unit) {
          this.val = newValue.value
          this.unit = newValue.unit
        }
      }
    },
    $data: {
      deep: true,
      handler: function (v) {
        this.$emit("input", {
          value: v.val,
          unit: v.unit
        })
        this.$emit("update")
      }
    }
  }
}
</script>
