<template>
  <div>
    <modal-global-integrations
      v-if="showIntegrationsModal"
      @close="showIntegrationsModal = false"
      :user-integrations="userIntegrations"
      @integrationPicked="handleIntegrationPicked"
      @openCreateIntegration="handleOpenCreateIntegration"
    />
    <modal-create-integration
      v-if="showCreateIntegrationModal"
      @close="showCreateIntegrationModal = false"
      :available-integrations="availableIntegrations"
      @integrationCreated="handleIntegrationCreated"
    />
    <modal-campaign-integration-match
      v-if="showMatchModal"
      @close="showMatchModal = false"
      :campaign="campaign"
      :integration="availableIntegrations[matchIntegration.type]"
      :user-integration="matchIntegration"
      :initial-data="matchInitialData"
      @integrationMatched="handleIntegrationMatched"
    />
    <div class="card mb-3 mb-lg-5">
      <div class="card-header">
        <h3 class="mr-4 mb-0">Integrations</h3>
        <div class="btn-group">
          <button class="btn btn-sm btn-soft-primary" type="button" @click="showIntegrationsModal = true">Add Integration</button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" v-for="(integration, k) in campaignIntegrations" :key="integration.id">
            <campaign-settings-integrations-list-item
              :campaign-integration="integration"
              @deleted="removeCampaignIntegration(k)"
              @openMatchModalToUpdate="handleOpenMatchModalToUpdate(integration)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalGlobalIntegrations from "@/components/modals/ModalGlobalIntegrations"
import ModalCreateIntegration from "@/components/modals/ModalCreateIntegration"
import ModalCampaignIntegrationMatch from "@/components/modals/ModalCampaignIntegrationMatch"
import { mapActions, mapMutations, mapState } from "vuex"
import CampaignSettingsIntegrationsListItem from "@/components/dashboard/CampaignSettingsIntegrationsListItem"

export default {
  name: "CampaignSettingsIntegrations",
  components: {
    CampaignSettingsIntegrationsListItem,
    ModalCampaignIntegrationMatch,
    ModalCreateIntegration,
    ModalGlobalIntegrations
  },
  data() {
    return {
      showIntegrationsModal: false,
      showMatchModal: false,
      showCreateIntegrationModal: false,
      matchIntegration: null,
      matchInitialData: null
    }
  },
  computed: {
    ...mapState("sites", ["selectedSite"]),
    ...mapState("campaignSettings", ["campaignIntegrations", "campaign", "availableIntegrations", "userIntegrations"])
  },
  methods: {
    ...mapMutations("campaignSettings", ["removeCampaignIntegration", "updateCampaignIntegration", "addCampaignIntegration"]),
    ...mapActions("campaignSettings", ["fetchUserIntegrations"]),
    reset() {
      this.showIntegrationsModal = false
      this.showMatchModal = false
      this.showCreateIntegrationModal = false
      this.matchInitialData = null
    },
    handleIntegrationCreated(createdIntegration) {
      this.reset()
      this.matchIntegration = createdIntegration
      this.showMatchModal = true
      this.fetchUserIntegrations()
    },
    handleIntegrationPicked(integration) {
      this.reset()
      this.matchIntegration = integration
      this.showMatchModal = true
    },
    handleOpenMatchModalToUpdate(campaignIntegration) {
      this.reset()
      this.matchIntegration = campaignIntegration["third_party_integration"]
      this.matchInitialData = campaignIntegration
      this.showMatchModal = true
    },
    async handleIntegrationMatched(data) {
      this.reset()
      if (data.type === "update") {
        this.updateCampaignIntegration(data)
      } else if (data.type === "create") {
        this.addCampaignIntegration(data)
      }
    },
    handleOpenCreateIntegration() {
      this.reset()
      this.showCreateIntegrationModal = true
    }
  },
  async mounted() {
    this.loading = true
    this.$emit("input", this.campaignIntegrations)
    this.loading = false
  }
}
</script>
