<template>
  <modal title="Integrations" @close="$emit('close')" size="lg">
    <div class="modal-body">
      <a v-for="integration in userIntegrations" :key="integration.id" href="javascript:" @click="handleIntegrationPicked(integration)">
        <div class="card mb-4 py-3">
          <div class="row align-items-center">
            <div class="mr-2 ml-5">
              <integration-img style="width: 3rem; height: 3rem" :integration-type="integration.type" />
            </div>
            <div class="col">
              <h4 class="card-title">{{ integration.name }}</h4>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" @click="handleCreateNewIntegration">Create New Integration</button>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"
import { startCase } from "lodash"
import IntegrationImg from "@/components/common/IntegrationImg"

export default {
  name: "ModalGlobalIntegrations",
  components: { IntegrationImg, Modal },
  props: ["userIntegrations"],
  methods: {
    startCase(inp) {
      return startCase(inp)
    },
    handleIntegrationPicked(integration) {
      this.$emit("integrationPicked", integration)
    },
    handleCreateNewIntegration() {
      this.$emit("openCreateIntegration")
    }
  }
}
</script>

<style scoped></style>
