import ElementManager from "@/lib/marketing-widgets/element-manager"

class LayoutManager extends ElementManager {
  constructor(layoutElement) {
    super(
      layoutElement,
      {
        overflow: "auto",
        padding: "0px 0px 0px 0px",
        "border-radius": "0px 0px 0px 0px",
        background: "#ffffff",
        "border-style": "none",
        "border-width": "0px 0px 0px 0px",
        "border-color": "#f00",

        filter: "drop-shadow(0 10px 15px rgba(40, 50, 60, 0.25))"
      },
      "layout-"
    )
  }
}

export default LayoutManager
