import getAxiosInstance from "@/api/get-axios-instance"

const fetchSites = async () => await getAxiosInstance().get("sites")
const addSite = async (domain) => await getAxiosInstance().post("sites", { domain })
const updateSite = async (siteId, { domain }) => await getAxiosInstance().put(`sites/${siteId}`, { domain })
const deleteSite = async (siteId) => await getAxiosInstance().delete(`sites/${siteId}`)

const siteAPI = {
  fetchSites,
  addSite,
  deleteSite,
  updateSite
}
export default siteAPI
