<template>
  <div class="text-center">
    <div class="nav nav-segment nav-pills w-100 d-flex">
      <div class="nav-item flex-grow-1">
        <a
          class="nav-link justify-content-center"
          data-toggle="device"
          role="tab"
          href="javascript:;"
          @click="setAppearanceDevice('mobile')"
          :class="appearanceDevice === 'mobile' ? 'active' : ''"
        >
          Mobile
        </a>
      </div>
      <div class="nav-item flex-grow-1">
        <a
          class="nav-link justify-content-center"
          data-toggle="device"
          role="tab"
          href="javascript:;"
          @click="setAppearanceDevice('desktop')"
          :class="appearanceDevice === 'desktop' ? 'active' : ''"
        >
          Desktop
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex"

export default {
  name: "SettingsDeviceSelector",
  computed: mapState("builder", ["appearanceDevice"]),
  methods: mapMutations("builder", ["setAppearanceDevice"])
}
</script>
