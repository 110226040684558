import store from "@/store/index"

const ensureHasSite = {
  async beforeRouteEnter(to, from, next) {
    if (store.state.sites.sites.length < 1) {
      await store.dispatch("sites/fetchSites")
    }

    if (store.state.sites.sites.length < 1) {
      return next({ name: "Dashboard.Sites" })
    }

    return next()
  }
}
export default ensureHasSite
