<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div class="d-flex align-items-center mb-2">
      <span class="mr-2" style="width: 14rem">Cart value:</span>
      <targeting-numeric-operator-selector
        class="mr-2"
        :value="getByCompWith('value').filter"
        @change="(e) => setValue('value', 'filter', e.target.value)"
      />
      <input
        class="form-control form-control-sm text-center"
        style="width: 6rem"
        type="number"
        :value="getByCompWith('value').value"
        @input="(e) => setValue('value', 'value', parseInt(e.target.value))"
      />
    </div>
    <div class="d-flex align-items-center mb-2">
      <span class="mr-2" style="width: 14rem">Number Of Products:</span>
      <targeting-numeric-operator-selector
        class="mr-2"
        :value="getByCompWith('numberOfProducts').filter"
        @change="(e) => setValue('numberOfProducts', 'filter', e.target.value)"
      />

      <input
        class="form-control form-control-sm text-center"
        style="width: 6rem"
        type="number"
        :value="getByCompWith('numberOfProducts').value"
        @input="(e) => setValue('numberOfProducts', 'value', parseInt(e.target.value))"
      />
    </div>
    <div class="d-flex align-items-center">
      <span class="mr-2" style="width: 14rem">Number Of Different Products:</span>
      <targeting-numeric-operator-selector
        class="mr-2"
        :value="getByCompWith('numberOfDifferentProducts').filter"
        @change="(e) => setValue('numberOfDifferentProducts', 'filter', e.target.value)"
      />
      <input
        class="form-control form-control-sm text-center"
        style="width: 6rem"
        type="number"
        :value="getByCompWith('numberOfDifferentProducts').value"
        @input="(e) => setValue('numberOfDifferentProducts', 'value', parseInt(e.target.value))"
      />
    </div>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"
import TargetingNumericOperatorSelector from "@/components/campaign-targeting-rules/TargetingNumericCompareOperatorSelector"

const mixin = targetingRuleModalMixin("who", targetingRuleNames.whoRules.cart)

export default {
  name: "CartModal",
  components: { TargetingNumericOperatorSelector, ModalCampaignTargetingRule },
  mixins: [mixin],
  methods: {
    getByCompWith(compWith) {
      return this.value.find((i) => i["compareWith"] === compWith)
    },
    setValue(compareWith, key, value) {
      const i = this.value.findIndex((i) => i["compareWith"] === compareWith)
      this.value[i][key] = value
    }
  }
}
</script>
