<template>
  <SettingsWrapper>
    <template #content-buttons>
      <settings-selector-button @click="activateSetArea('content-message')">Message</settings-selector-button>
      <settings-selector-button @click="activateSetArea('content-button')">Button</settings-selector-button>
      <settings-selector-button @click="activateSetArea('content-images')">Images</settings-selector-button>
    </template>
    <template #content>
      <Contents />
    </template>
    <template #appearance-buttons>
      <settings-selector-button @click="activateSetArea('layout')">Layout</settings-selector-button>
      <div class="ml-4">
        <settings-selector-button @click="activateSetArea('close-button')">Close Button</settings-selector-button>
        <settings-selector-button @click="activateSetArea('message')">Message</settings-selector-button>
        <settings-selector-button @click="activateSetArea('images')">Images</settings-selector-button>
        <settings-selector-button :disabled="buttonActive" @click="activateSetArea('bar-button')">Button</settings-selector-button>
      </div>
    </template>
    <template #appearance>
      <Appearance />
    </template>
  </SettingsWrapper>
</template>

<script>
import SettingsWrapper from "@/components/campaign-builder/widget-settings/SettingsWrapper"
import SettingsSelectorButton from "@/components/SettingsSelectorButton"
import settingsMixin from "@/mixins/settings-mixin"
import Contents from "@/components/campaign-builder/widget-settings/flat-bar/Contents"
import Appearance from "@/components/campaign-builder/widget-settings/flat-bar/Appearance"
import { mapGetters, mapState } from "vuex"

export default {
  name: "FlatBarSettings",
  mixins: [settingsMixin],
  components: { Appearance, Contents, SettingsWrapper, SettingsSelectorButton },
  data() {
    return {
      buttonActive: false
    }
  },
  computed: {
    ...mapGetters("builder", ["getIframeDocument", "getPageSelector"]),
    ...mapState("builder", ["activeSettings"])
  },
  watch: {
    activeSettings() {
      this.setDisabledButtons()
    }
  },
  methods: {
    setDisabledButtons() {
      this.buttonActive = !this.getIframeDocument.querySelector(`${this.getPageSelector} .form-and-buttons button.primary`)
    }
  },
  mounted() {
    this.setDisabledButtons()
  }
}
</script>
