<template>
  <modal title="Billing Address" @close="$emit('close')" size="lg">
    <div ref="PARENTELEMENT">
      <base-form @submit="saveAddress">
        <div class="modal-body">
          <div class="row form-group">
            <label for="addressLine1Label" class="col-sm-3 col-form-label input-label">Line 1</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                name="addressLine1"
                id="addressLine1Label"
                placeholder="Address line 1"
                v-model="formAddressData.line1"
                required
              />
            </div>
          </div>
          <div class="row form-group">
            <label for="addressLine2Label" class="col-sm-3 col-form-label input-label">Line 2</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                name="addressLine2"
                id="addressLine2Label"
                placeholder="Address line 2"
                v-model="formAddressData.line2"
              />
            </div>
          </div>
          <div class="row form-group">
            <label for="addressCountryLabel" class="col-sm-3 col-form-label input-label">Country</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                name="addressCountry"
                id="addressCountryLabel"
                placeholder="Country"
                v-model="formAddressData.country"
              />
            </div>
          </div>
          <div class="row form-group">
            <label for="addressCityLabel" class="col-sm-3 col-form-label input-label">City</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                name="addressCity"
                id="addressCityLabel"
                placeholder="City"
                v-model="formAddressData.city"
              />
            </div>
          </div>
          <div class="row form-group">
            <label for="addressPostalCodeLabel" class="col-sm-3 col-form-label input-label">Postal Code:</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                name="addressPostalCode"
                id="addressPostalCodeLabel"
                placeholder="Postal Code"
                v-model="formAddressData.postal_code"
              />
            </div>
          </div>
          <div class="row form-group">
            <label for="addressStateLabel" class="col-sm-3 col-form-label input-label">State:</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                name="addressState"
                id="addressStateLabel"
                placeholder="State"
                v-model="formAddressData.state"
              />
            </div>
          </div>

          <div class="row form-group">
            <label for="addressTaxIdLabel" class="col-sm-3 col-form-label input-label">Tax ID:</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" name="addressTaxId" id="addressTaxIdLabel" placeholder="Tax ID" v-model="formTaxId" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-white mr-2" @click="$emit('close')">Close</button>
            <button type="submit" class="btn btn-primary" v-if="!loading">Save changes</button>
            <button class="btn btn-primary" type="submit" disabled v-if="loading">
              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </div>
      </base-form>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"
import { mapActions, mapState } from "vuex"
import BaseForm from "@/components/BaseForm"
import formMixin from "@/mixins/form-mixin"

export default {
  name: "ModalBillingAddress",
  components: { BaseForm, Modal },
  mixins: [formMixin],
  data() {
    return {
      loading: false,
      formAddressData: {},
      formTaxId: null
    }
  },
  computed: {
    ...mapState("user", ["address", "user"])
  },
  methods: {
    ...mapActions("user", ["updateAddress", "fetchAddress"]),
    async saveAddress() {
      this.loading = true
      await this.updateAddress({ address: this.formAddressData, taxId: this.formTaxId })
      await this.fetchAddress()
      this.loading = false
      this.$emit("close")
    }
  },
  mounted() {
    this.formAddressData = { ...this.address }
    this.formTaxId = this.user.taxId
  }
}
</script>
