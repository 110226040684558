<template>
  <div>
    <h4 class="mb-3">Form Field Bindings</h4>
    <div class="row">
      <div class="col-6">
        <h5>Input From OSM</h5>
      </div>
      <div class="col-6">
        <h5>Field in {{ integrationName }}</h5>
      </div>
    </div>
    <div class="row mt-2" v-for="campaignField in campaignFormFields" :key="campaignField">
      <div class="col-6">
        <label class="input-label">{{ campaignField }}:</label>
      </div>
      <div class="col-6">
        <input
          class="form-control form-control-sm"
          v-if="type === 'free'"
          :value="matches[campaignField]"
          @input="handleInput(campaignField, $event.target.value)"
          type="text"
        />
        <select
          class="form-control form-control-sm"
          v-else-if="type === 'match'"
          :value="matches[campaignField]"
          @input="handleInput(campaignField, $event.target.value)"
        >
          <option selected="" :value="null">-----</option>
          <option v-for="(field, k) in serviceFormFields" :key="k" :value="field['field_identifier']">{{ field.name }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "CampaignIntegrationMatchFormBindings",
  props: {
    integrationName: String,
    currentBindings: {},
    type: String,
    serviceFormFields: {}
  },
  data() {
    return {
      campaignFormFields: [],
      matches: []
    }
  },
  computed: {
    ...mapState("campaignSettings", ["campaign"]),
    serviceFieldList() {
      return this.serviceFormFields ? this.serviceFormFields.map((f) => f["field_identifier"]) : []
    }
  },
  methods: {
    fillCampaignFormFields() {
      Object.values(this.campaign.form_fields).map((variantFields) => {
        Object.keys(variantFields).map((fieldName) => {
          if (!this.campaignFormFields.includes(fieldName)) {
            this.campaignFormFields.push(fieldName)
          }
        })
      })
    },
    handleInput(key, value) {
      this.matches[key] = value
      this.$emit("input", this.matches)
    }
  },
  created() {
    this.fillCampaignFormFields()
    this.campaignFormFields.forEach((field) => {
      this.matches[field] = null
      if (field in this.currentBindings) {
        if (this.type === "select" && !this.serviceFieldList.includes(this.currentBindings[field])) {
          return
        }

        this.matches[field] = this.currentBindings[field]
      }
    })
    console.log({ matches: this.matches })

    this.$emit("input", this.matches)
  }
}
</script>

<style scoped></style>
