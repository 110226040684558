import userAPI from "@/api/user"
import generateAPIErrorMessage from "@/api/generate-api-error-message"

function initialState() {
  return {
    accessToken: localStorage.getItem("access_token") || "",
    user: null,
    address: null,
    taxId: null,
    authError: null,
    paymentMethods: []
  }
}
const user = {
  state: initialState,
  mutations: {
    resetState(state) {
      Object.assign(state, initialState())
    },
    userLogin(state, payload) {
      localStorage.setItem("access_token", payload["access_token"])
      state.accessToken = payload["access_token"]
      state.user = payload.user
    },
    setAuthError(state, payload) {
      state.authError = payload
    },
    setUser(state, payload) {
      state.user = payload
    },
    setAddressAndTaxId(state, payload) {
      if (payload.address === null) {
        state.address = { line1: "", line2: "", country: "", city: "", postal_code: "", state: "" }
      } else {
        state.address = payload.address
      }
      state.taxId = payload["tax_id"]
    },
    userLogout() {
      window.localStorage.removeItem("access_token")
    },
    setPaymentMethods(state, payload) {
      state.paymentMethods = payload
    },
    clear(state) {
      state.accessToken = null
      state.user = null
      window.localStorage.removeItem("access_token")
    }
  },
  actions: {
    async register({ commit }, { first_name, last_name, email, password, password_confirmation }) {
      return userAPI
        .register({ first_name, last_name, email, password, password_confirmation })
        .then((resp) => commit("userLogin", resp.data.data))
        .catch((err) => {
          throw generateAPIErrorMessage(err)
        })
    },
    async login({ commit }, { email, password }) {
      return userAPI
        .login({ email, password })
        .then((resp) => commit("userLogin", resp.data.data))
        .catch((err) => {
          throw generateAPIErrorMessage(err)
        })
    },
    async update({ commit }, { first_name, last_name }) {
      return userAPI
        .update({ first_name, last_name })
        .then((resp) => commit("setUser", resp.data.data.user))
        .catch((err) => {
          throw generateAPIErrorMessage(err)
        })
    },
    async updatePassword(ctx, { current_password, password, password_confirmation }) {
      return userAPI
        .updatePassword({ current_password, password, password_confirmation })
        .then(() => true)
        .catch((err) => {
          throw generateAPIErrorMessage(err)
        })
    },
    async logout({ commit }) {
      await userAPI.logout().finally(() => {
        commit("userLogout")
        commit("resetState")
        commit("sites/resetState", null, { root: true })
        commit("campaigns/resetState", null, { root: true })
        commit("campaignSettings/resetState", null, { root: true })
      })
    },
    async fetchAddress({ commit }) {
      return userAPI
        .fetchAddressAndTaxId()
        .then((resp) => commit("setAddressAndTaxId", resp.data.data))
        .catch((err) => {
          throw generateAPIErrorMessage(err)
        })
    },
    async fetchCurrentUser({ commit }) {
      return userAPI
        .fetchCurrentUser()
        .then((resp) => commit("setUser", resp.data.data.user))
        .catch(() => commit("clear"))
    },
    async updatePlan(c, planId) {
      return userAPI
        .updateUserPlan({ planId })
        .then((resp) => resp)
        .catch((err) => {
          throw generateAPIErrorMessage(err)
        })
    },
    async fetchPlans() {
      return userAPI
        .fetchAvailablePlans()
        .then((resp) => resp.data.data.plans)
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async fetchInvoices() {
      return userAPI
        .fetchInvoices()
        .then((resp) => resp.data.data.invoices)
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async fetchStripeKey() {
      return userAPI
        .fetchStripePublicApiKey()
        .then((resp) => {
          return resp.data.data["stripe_public_key"]
        })
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async fetchStripeSetupIntentToken() {
      return userAPI
        .createAndFetchStripeSetupIntent()
        .then((resp) => resp.data.data["intent_data"]["client_secret"])
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async addPaymentMethod(_, paymentMethod) {
      return userAPI
        .addPaymentMethod(paymentMethod)
        .then(() => true)
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async fetchPaymentMethods({ commit }) {
      return userAPI
        .fetchPaymentMethods()
        .then((res) => commit("setPaymentMethods", res.data.data["payment_methods"]))
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async deletePaymentMethod(_, paymentMethod) {
      return userAPI
        .deletePaymentMethod(paymentMethod)
        .then(() => true)
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async updateAddress(_, { taxId, address }) {
      return userAPI
        .saveAddress({ taxId, address })
        .then(() => true)
        .catch((err) => {
          throw generateAPIErrorMessage(err)
        })
    },
    async updateDefaultPaymentMethod(_, paymentMethod) {
      return userAPI
        .updateDefaultPaymentMethod(paymentMethod)
        .then(() => true)
        .catch((err) => {
          throw generateAPIErrorMessage(err)
        })
    }
  },
  namespaced: true
}

export default user
