<template>
  <auth-layout>
    <base-form @submit="submit">
      <div class="text-center">
        <div class="mb-5">
          <h1 class="display-4">Create your account</h1>
          <p>
            Already have an account?
            <router-link :to="{ name: 'Login' }">Sign in here</router-link>
          </p>
        </div>

        <google-sign-in-button redirect-route-name="Register" message="Sign up with Google" />

        <span class="divider text-muted mb-4">OR</span>
      </div>
      <label class="input-label" for="fullNameSrEmail">Full name</label>

      <!-- Form Group -->
      <div class="form-row">
        <div class="col-sm-6">
          <div class="js-form-message form-group">
            <input
              type="text"
              class="form-control form-control-lg"
              name="fullName"
              id="fullNameSrEmail"
              placeholder="Mark"
              aria-label="Mark"
              v-model="first_name"
              required
              data-msg="Please enter your first name."
            />
          </div>
        </div>

        <div class="col-sm-6">
          <div class="js-form-message form-group">
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="last_name"
              placeholder="Williams"
              aria-label="Williams"
              required
              data-msg="Please enter your last name."
            />
          </div>
        </div>
      </div>
      <!-- End Form Group -->

      <!-- Form Group -->
      <div class="js-form-message form-group">
        <label class="input-label" for="signupSrEmail">Your email</label>

        <input
          type="email"
          class="form-control form-control-lg"
          name="email"
          id="signupSrEmail"
          v-model="email"
          placeholder="Markwilliams@example.com"
          aria-label="Markwilliams@example.com"
          required
          data-msg="Please enter a valid email address."
        />
      </div>
      <!-- End Form Group -->

      <!-- Form Group -->
      <div class="js-form-message form-group">
        <label class="input-label" for="signupSrPassword">Password</label>

        <div class="input-group input-group-merge">
          <input
            type="password"
            class="js-toggle-password form-control form-control-lg"
            name="password"
            minlength="8"
            id="signupSrPassword"
            placeholder="8+ characters required"
            aria-label="8+ characters required"
            required
            v-model="password"
            data-msg="Your password is invalid. Please try again."
            data-hs-toggle-password-options='{
                               "target": [".js-toggle-password-target-1", ".js-toggle-password-target-2"],
                               "defaultClass": "tio-hidden-outlined",
                               "showClass": "tio-visible-outlined",
                               "classChangeTarget": ".js-toggle-passowrd-show-icon-1"
                             }'
          />
          <div class="js-toggle-password-target-1 input-group-append">
            <a class="input-group-text" href="javascript:;">
              <i class="js-toggle-passowrd-show-icon-1 tio-visible-outlined"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- End Form Group -->

      <!-- Form Group -->
      <div class="js-form-message form-group">
        <label class="input-label" for="signupSrConfirmPassword">Confirm password</label>

        <div class="input-group input-group-merge">
          <input
            type="password"
            class="js-toggle-password form-control form-control-lg"
            name="confirmPassword"
            id="signupSrConfirmPassword"
            placeholder="8+ characters required"
            aria-label="8+ characters required"
            required
            v-model="password_confirmation"
            data-msg="Password does not match the confirm password."
            data-hs-toggle-password-options='{
                               "target": [".js-toggle-password-target-1", ".js-toggle-password-target-2"],
                               "defaultClass": "tio-hidden-outlined",
                               "showClass": "tio-visible-outlined",
                               "classChangeTarget": ".js-toggle-passowrd-show-icon-2"
                             }'
          />
          <div class="js-toggle-password-target-2 input-group-append">
            <a class="input-group-text" href="javascript:;">
              <i class="js-toggle-passowrd-show-icon-2 tio-visible-outlined"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- End Form Group -->

      <!-- Checkbox -->
      <div class="js-form-message form-group">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            id="termsCheckbox"
            name="termsCheckbox"
            required
            v-model="terms_of_service_agreed"
            data-msg="Please accept our Terms and Conditions."
          />
          <label class="custom-control-label text-muted" for="termsCheckbox"> I accept the <a href="#">Terms and Conditions</a></label>
        </div>
      </div>
      <!-- End Checkbox -->

      <button type="submit" class="btn btn-lg btn-block btn-primary mb-2">Create an account</button>
    </base-form>
  </auth-layout>
</template>

<script>
import { mapActions, mapMutations } from "vuex"
import AuthLayout from "@/layout/AuthLayout"
import GoogleSignInButton from "@/components/auth/GoogleSignInButton"
import BaseForm from "@/components/BaseForm"
import formMixin from "@/mixins/form-mixin"

export default {
  name: "Register",
  components: { BaseForm, GoogleSignInButton, AuthLayout },
  mixins: [formMixin],
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      terms_of_service_agreed: false,
      password: "",
      password_confirmation: ""
    }
  },
  methods: {
    ...mapMutations({ setError: "user/setAuthError" }),
    ...mapActions({ register: "user/register" }),
    submit() {
      this.register(this.$data)
        .then(() => {
          this.setError(null)
          this.$router.push({ name: "Dashboard.Campaigns" })
        })
        .catch((err) => this.setError(err))
    }
  },
  created() {
    let oauth = window.localStorage.getItem("oauth-data")
    if (oauth) {
      oauth = JSON.parse(oauth)
      window.localStorage.removeItem("oauth-data")
      window.localStorage.removeItem("oauth-redirect")
      this.email = oauth["google_data"]["email"]
      this.first_name = oauth["google_data"]["first_name"]
      this.last_name = oauth["google_data"]["last_name"]
    }
  }
}
</script>
