<template>
  <div class="row">
    <div class="col-6">
      <label class="input-label">{{ label }}:</label>
    </div>
    <div class="col-6">
      <select class="form-control form-control-sm" :value="value" @input="$emit('input', $event.target.value)" :required="required">
        <option :value="null" selected="selected">-----</option>
        <option v-for="{ name, id } in availableValues" :key="id" :value="id">{{ name }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "CampaignIntegrationMatchSetting",
  props: {
    label: String,
    availableValues: [],
    required: Boolean,
    value: {
      default: null
    }
  }
}
</script>
