<template>
  <div>
    <single-button
      :button-area-selector="buttonAreaSelector"
      :button-class="primaryButtonClass"
      class="mb-8"
      order="1"
      title="Primary Button"
    />
    <single-button :button-area-selector="buttonAreaSelector" :button-class="secondaryButtonClass" order="2" title="Secondary Button" />
  </div>
</template>

<script>
import SingleButton from "@/components/campaign-builder/content-changers/SingleButton"

export default {
  name: "DoubleButtons",
  components: { SingleButton },
  props: {
    buttonAreaSelector: String,
    primaryButtonClass: String,
    secondaryButtonClass: String
  }
}
</script>
