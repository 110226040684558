<template>
  <div class="border rounded-lg mb-3">
    <div class="d-flex align-items-center">
      <button class="flex-grow-1 btn text-left px-4 py-3" @click="editable ? (modalOpen = true) : ''">
        <h5>{{ title }}</h5>
        <div style="font-size: 0.925rem">
          <slot />
        </div>
      </button>
      <a
        class="btn btn-icon btn-sm btn-no-focus btn-ghost-secondary rounded-circle mr-3"
        href="javascript:;"
        @click="handleRemoveRule"
        v-if="editable"
      >
        <i class="tio-clear tio-lg"></i>
      </a>
    </div>
    <div v-if="modalOpen">
      <targeting-modal :component-name="modalName" @close="modalOpen = false"></targeting-modal>
    </div>
  </div>
</template>

<script>
import { whenToShowModalMap, whoToShowModalMap } from "@/components/campaign-targeting-rules/targetingRuleComponentMaps"
import targetingRuleTexts from "@/components/campaign-targeting-rules/targetingRuleTexts"
import TargetingModal from "@/components/campaign-targeting-rules/TargetingModal"
import { mapMutations } from "vuex"

export default {
  name: "BaseCampaignTargetingRule",
  components: { TargetingModal },
  inject: ["nameOfRule", "typeOfRule", "editable"],
  data() {
    return {
      modalOpen: false,
      modalName: "",
      targetingRuleTexts: targetingRuleTexts,
      title: ""
    }
  },
  mounted() {
    if (this.typeOfRule === "who") {
      this.modalName = whoToShowModalMap[this.nameOfRule]
    } else if (this.typeOfRule === "when") {
      this.modalName = whenToShowModalMap[this.nameOfRule]
    }

    this.title = this.targetingRuleTexts[this.typeOfRule][this.nameOfRule].title
  },
  methods: {
    ...mapMutations("campaignSettings", ["removeRule"]),
    handleRemoveRule() {
      this.removeRule({ typeOfRule: this.typeOfRule, name: this.nameOfRule })
    }
  }
}
</script>

<style scoped></style>
