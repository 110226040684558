<template>
  <modal title="Subscriptions" @close="$emit('close')" size="xl">
    <div class="modal-body" ref="PARENTELEMENT">
      <dashboard-error :message="error" v-if="error" />
      <div class="alert alert-warning text-center mb-5" role="alert" v-if="hasTemporaryPlan">
        <div class="font-weight-bold">
          You requested to downgrade your plan from {{ user.temporary_plan.name }} to {{ user.current_plan.name }}.
        </div>
        <div>To make a different request, you must cancel this request first.</div>

        <button type="submit" class="btn btn-primary btn-sm mt-3" v-if="!cancelRequestLoading" @click="cancelSubscriptionChangeRequest">
          Cancel Request
        </button>
        <button type="submit" class="btn btn-primary btn-sm mt-3" disabled v-if="cancelRequestLoading">
          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
      </div>
      <!-- Toggle Switch -->
      <div class="d-flex justify-content-center align-items-center mb-5">
        <span>Monthly</span>
        <label class="toggle-switch mx-2" for="customSwitch">
          <input
            type="checkbox"
            class="js-toggle-switch toggle-switch-input"
            id="customSwitch"
            checked
            v-model="periodCheck"
            data-hs-toggle-switch-options='{
                         "targetSelector": "#pricingCount1, #pricingCount2, #pricingCount3"
                       }'
          />
          <span class="toggle-switch-label">
            <span class="toggle-switch-indicator"></span>
          </span>
        </label>
        <span>Annually</span>
      </div>
      <!-- End Toggle Switch -->

      <!-- Pricing -->
      <div class="row">
        <div class="col-sm-6 col-lg-4 mb-3 mb-sm-5" v-for="(plan, key) in planData" :key="key">
          <div class="card custom-checkbox-card custom-checkbox-card-lg checked">
            <!-- Header -->
            <div class="card-header d-block text-center">
              <!-- Checkbox -->
              <input
                type="radio"
                :id="'billingPricingRadio2' + (key + 1)"
                name="billingPricingRadio"
                :disabled="
                  hasTemporaryPlan ||
                  (userCurrentPlan.name === plan.name && userCurrentPlan.period === (periodCheck ? 'annually' : 'monthly'))
                "
                v-model="selectedPlan"
                :value="periodCheck ? plan.stripePlan_annually : plan.stripePlan_monthly"
                class="custom-checkbox-card-input"
              />
              <label
                class="custom-checkbox-card-label custom-checkbox-card-label-stretched"
                :for="'billingPricingRadio2' + (key + 1)"
              ></label>
              <!-- End Checkbox -->

              <small class="card-subtitle">{{ plan.name }}</small>
              <div class="mb-3">
                <span class="card-title display-3">
                  $<span
                    :id="'pricingCount' + (key + 1)"
                    :data-hs-toggle-switch-item-options="
                      JSON.stringify({
                        min: plan.price_monthly,
                        max: plan.price_annually
                      })
                    "
                    >{{ plan.price_annually }}</span
                  >
                  <small class="font-size-sm text-muted">/ {{ periodCheck ? "year" : "month" }}</small>
                </span>
              </div>
            </div>
            <!-- End Header -->

            <!-- Body -->
            <div class="card-body">
              <ul class="list-checked list-checked-primary list-unstyled-py-2">
                <li class="list-checked-item">Quota: {{ plan.quota }}/month</li>
              </ul>
            </div>
            <!-- End Body -->

            <!-- Footer -->
            <div class="card-body-stretched">
              <button
                type="button"
                class="btn btn-block btn-white custom-checkbox-card-btn"
                :disabled="userCurrentPlan.name === plan.name && userCurrentPlan.period === (periodCheck ? 'annually' : 'monthly')"
              >
                {{
                  userCurrentPlan.name === plan.name && userCurrentPlan.period === (periodCheck ? "annually" : "monthly")
                    ? "Current Plan"
                    : "Select Plan"
                }}
              </button>
            </div>

            <div class="card-footer">
              <p class="card-text small"><i class="tio-help-outlined mr-1"></i> Terms &amp; conditions apply</p>
            </div>
          </div>
        </div>
      </div>
      <!-- End Pricing -->
    </div>
    <div class="modal-footer">
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-white mr-2" @click="$emit('close')">Close</button>
        <button type="submit" class="btn btn-primary" v-if="!loading" @click="updateSubscription" :disabled="!selectedPlan">
          Save changes
        </button>
        <button class="btn btn-primary" type="submit" disabled v-if="loading">
          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"
import toggleSwitchMixin from "@/mixins/toggle-switch-mixin"
import { mapActions, mapState } from "vuex"
import DashboardError from "@/components/dashboard/DashboardError"
import userAPI from "@/api/user"

export default {
  name: "ModalPlan",
  data() {
    return {
      loading: false,
      plans: [],
      periodCheck: true,
      planData: [],
      userCurrentPlan: null,
      selectedPlan: null,
      cancelRequestLoading: false,
      error: null
    }
  },
  watch: {
    periodCheck() {
      this.selectedPlan = null
    }
  },
  computed: {
    ...mapState("user", ["user"]),
    hasTemporaryPlan() {
      return this.user["temporary_plan"] !== null
    }
  },
  components: { DashboardError, Modal },
  mixins: [toggleSwitchMixin],
  methods: {
    ...mapActions("user", ["fetchPlans", "updatePlan", "fetchCurrentUser"]),
    async cancelSubscriptionChangeRequest() {
      this.error = null
      this.cancelRequestLoading = true
      try {
        await userAPI.cancelSubscriptionChangeRequests()
        window.location.reload()
      } catch (e) {
        this.error = e
        this.cancelRequestLoading = false
      }
    },
    async updateSubscription() {
      this.error = null
      this.loading = true
      try {
        await this.updatePlan(this.selectedPlan)
      } catch (e) {
        this.error = e
      }
      window.location.reload()
    }
  },
  async mounted() {
    this.loading = true
    this.plans = await this.fetchPlans()
    this.loading = false

    const planData = {}
    this.plans.forEach((i) => {
      if (!planData[i.name]) planData[i.name] = {}
      planData[i.name]["quota"] = i.quota
      planData[i.name]["name"] = i.name
      const periodPrice = "price_" + i.billing_period
      const periodStripePlan = "stripePlan_" + i.billing_period
      planData[i.name][periodPrice] = i.price
      planData[i.name][periodStripePlan] = i.stripe_price
    })
    this.planData = Object.values(planData)

    if (this.user.plan.name === "Trial") {
      this.userCurrentPlan = {}
    } else {
      this.userCurrentPlan = {
        name: this.user.plan.name,
        period: this.user.plan.billing_period
      }
    }

    this.initToggleSwitch()
  }
}
</script>

<style scoped></style>
