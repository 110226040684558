<template>
  <full-page-loading />
</template>

<script>
import FullPageLoading from "@/components/common/FullPageLoading"

export default {
  name: "Logout",
  components: { FullPageLoading },
  async created() {
    await this.$store.dispatch("user/logout")
    await this.$router.push({ name: "Login" })
  }
}
</script>

<style scoped></style>
