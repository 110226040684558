<template>
  <div class="card">
    <div class="row no-gutters">
      <div class="col-md-4 d-flex align-items-center">
        <img v-if="thumbnailSrc" :src="thumbnailSrc" class="img-fluid" style="max-height: 5rem; width: 100%; object-fit: cover" alt="image" />
        <i :class="`tio-${icon}`" style="font-size: 4rem; color: var(--dark); margin-left: 1.5rem; opacity: .7"></i>
      </div>
      <div class="col-md-8">
        <div class="card-body d-flex align-items-center justify-content-end h-100">
          <button class="btn btn-sm btn-ghost-primary mr-1" data-toggle="collapse" :data-target="`#editImage${id}`">
            Edit
            <i class="tio-expand-all"></i>
          </button>
          <button class="btn btn-sm btn-ghost-primary" v-if="changeable" @click="$emit('change')">Change</button>
          <button class="btn btn-sm btn-ghost-danger" v-if="removable" @click="$emit('remove')">Remove</button>
        </div>
      </div>
    </div>
    <div class="collapse" :id="`editImage${id}`">
      <div class="card-body" :key="historyKey">
        <slot name="edit-body" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import randomId from "@/lib/utils/random-id"

export default {
  name: "VisualAssetCard",
  props: { thumbnailSrc: String, icon: String, id: String, removable: Boolean, changeable: Boolean },
  computed: {
    ...mapState("builderHistory", ["history"])
  },
  data() {
    return {
      historyKey: randomId()
    }
  },
  watch: {
    history() {
      this.historyKey = randomId()
    }
  }
}
</script>

<style scoped></style>
