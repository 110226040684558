<template>
  <div>
    <div class="mb-3">
      <h3>Action</h3>
      <select v-model="action" class="form-control form-control-sm">
        <option value="share">Share</option>
        <option value="follow">Follow</option>
      </select>
    </div>

    <div v-if="action === 'share'" class="mb-3">
      <div>URL: <input type="text" class="form-control form-control-sm" v-model="shareData.url" /></div>
      <div class="d-block">Text: <input type="text" class="form-control form-control-sm" v-model="shareData.label" /></div>

      <h3 class="mt-3">Share Services</h3>
      <div v-for="(currService, key) in shareData.services" :key="key" class="mb-2 d-flex align-items-center">
        <select class="form-control form-control-sm" :value="currService" @change="updateShareData(key, $event.target.value)">
          <option :value="service" :key="service" v-for="service in ['facebook', 'twitter', 'linkedin']">
            {{ service }}
          </option>
        </select>
        <button @click="removeShareService(key)" v-if="shareData.services.length > 1" class="btn btn-soft-danger btn-icon btn-xs ml-4">
          <i class="tio-remove tio-lg"></i>
        </button>
      </div>
      <div class="text-center">
        <button v-if="shareData.services.length < 3" @click="addShareService" class="btn btn-soft-primary btn-icon btn-xs">
          <i class="tio-add tio-lg"></i>
        </button>
      </div>
    </div>

    <div v-if="action === 'follow'" class="mb-3">
      <h3 class="mt-3">Follow Buttons</h3>
      <div v-for="(followButton, key) in followData" :key="key" class="mb-3 border-bottom pb-3">
        <div class="d-flex align-items-center">
          <span> URL: </span>
          <select
            class="form-control form-control-sm mr-2"
            :value="followButton.service"
            @change="updateFollowData(key, 'service', $event.target.value)"
          >
            <option
              :value="service"
              :key="service"
              v-for="service in ['facebook', 'twitter', 'linkedin', 'pinterest', 'instagram', 'youtube']"
            >
              {{ service }}.com/
            </option>
          </select>
          <div>
            <input
              type="text"
              class="form-control form-control-sm"
              :value="followButton.urlSuffix"
              @input="updateFollowData(key, 'urlSuffix', $event.target.value)"
            />
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <span>Label:</span>
          <input
            type="text"
            class="form-control form-control-sm"
            :value="followButton.label"
            @input="updateFollowData(key, 'label', $event.target.value)"
          />
        </div>
        <div class="text-center mt-2">
          <button @click="removeFollowService(key)" v-if="followData.length > 1" class="btn btn-soft-danger btn-icon btn-xs">
            <i class="tio-remove tio-lg"></i>
          </button>
        </div>
      </div>
      <div class="text-center">
        <button v-if="followData.length < 5" @click="addFollowService" class="btn btn-soft-primary btn-icon btn-xs">
          <i class="tio-add tio-lg"></i>
        </button>
      </div>
    </div>
    <div class="mb-3">
      <h3>Icon Style</h3>
      <select v-model="style" class="form-control form-control-sm">
        <option value="base">Default</option>
        <option value="filled">Filled</option>
        <option value="filled-rounded">Filled Rounded</option>
      </select>
    </div>
    <div class="mb-3">
      <settings-justify-content :variable="this.manager.getStyleVariableName('justify-content')" title="Justify Content" />
    </div>
    <div class="d-flex mb-3 align-items-center">
      <label class="flex-shrink-0 mr-3 my-0">Label - Icon Position</label>
      <select class="form-control form-control-sm" v-model="direction">
        <option value="column">Column</option>
        <option value="column-reverse">Column Reverse</option>
      </select>
    </div>

    <div>
      <span>Icon Size</span>
      <settings-range-with-unit :variable="manager.getStyleVariableName('--icon-size')" :min="0" :max="100" :alternative-units="['px']" />
    </div>

    <settings-font-size :variable="manager.getStyleVariableName('--label-font-size')" title="Label Font Size" />
    <settings-color :variable="manager.getStyleVariableName('--label-color')" title="Label Color" />

    <div>
      <span>Icon Label GAP</span>
      <settings-range-with-unit
        :variable="manager.getStyleVariableName('--icon-label-gap')"
        :min="0"
        :max="100"
        :alternative-units="['px']"
      />
    </div>

    <div>
      <span>Space Between Buttons</span>
      <settings-range-with-unit
        :variable="manager.getStyleVariableName('--button-spacing')"
        :min="0"
        :max="100"
        :alternative-units="['px']"
      />
    </div>
  </div>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"

export default {
  name: "SocialButtonGroupSettings",
  props: ["manager"],
  data() {
    return {
      style: this.manager.element.dataset.style,
      action: this.manager.element.dataset.action,
      shareData: [],
      followData: [],
      iconElements: []
    }
  },
  mixins: [appearanceChangerMixin],
  watch: {
    action(newValue) {
      this.manager.element.dataset.action = newValue
      this.manager.draw()
    },
    style(newValue) {
      this.manager.element.dataset.style = newValue
    },
    shareData: {
      handler: function (newValue) {
        this.manager.element.dataset.share = JSON.stringify(newValue)
        this.manager.draw()
      },
      deep: true
    },
    followData: {
      handler: function (newValue) {
        this.manager.element.dataset.follow = JSON.stringify(newValue)
        this.manager.draw()
      },
      deep: true
    }
  },
  computed: {
    direction: {
      get() {
        return this.getStyleVariableValueDirectCurrentEnv(this.manager.getStyleVariableName("--direction"))
      },
      set(value) {
        this.setStyleVariableDirectCurrentEnv({
          variable: this.manager.getStyleVariableName("--direction"),
          value: value
        })
      }
    }
  },
  methods: {
    updateShareData(key, value) {
      this.shareData.services[key] = value
      this.shareData = { ...this.shareData }
    },
    addShareService() {
      this.shareData.services.push("facebook")
      this.shareData = { ...this.shareData }
    },
    removeShareService(key) {
      this.shareData.services.splice(key, 1)
      this.shareData = { ...this.shareData }
    },

    updateFollowData(index, key, value) {
      this.followData[index][key] = value
      this.shareData = { ...this.shareData }
    },
    addFollowService() {
      this.followData.push({
        service: "youtube",
        label: "Follow",
        urlSuffix: "c/JonGjengset"
      })
      this.shareData = { ...this.shareData }
    },
    removeFollowService(key) {
      this.followData.splice(key, 1)
      this.shareData = { ...this.shareData }
    }
  },
  created() {
    this.shareData = JSON.parse(this.manager.element.dataset.share)
    this.followData = JSON.parse(this.manager.element.dataset.follow)
  }
}
</script>

<style scoped></style>
