import FormFieldInputManager from "@/lib/marketing-widgets/form-field-input-manager"

class FormFieldCheckboxesManager extends FormFieldInputManager {
  constructor(radiosParent) {
    super(radiosParent)
  }

  ensureStyleVariablesDefinedOnElement() {
    const checkboxLabels = this.element.querySelectorAll("label.checkboxes-option")
    Object.keys(this.availableStyles).forEach((cssAttr) => {
      checkboxLabels.forEach((checkbox) => {
        if (!checkbox.style[cssAttr]) {
          checkbox.style[cssAttr] = `var(${this.getStyleVariableName(cssAttr)})`
        }
      })
    })
  }
}

export default FormFieldCheckboxesManager
