import generateAPIErrorMessage from "@/api/generate-api-error-message"
import campaignAPI from "@/api/campaign"

function initialState() {
  return {
    campaigns: [],
    campaignStatistics: []
  }
}
const campaigns = {
  state: initialState,
  mutations: {
    resetState(state) {
      Object.assign(state, initialState())
    },
    setCampaigns(state, data) {
      state.campaigns = data
    },
    setCampaignStatistics(state, data) {
      state.campaignStatistics = data
    }
  },
  actions: {
    async fetchCampaignsOfCurrentSite({ rootGetters, commit }) {
      const selectedSite = rootGetters["sites/getSelectedSite"]
      return campaignAPI
        .fetchCampaigns({ siteId: selectedSite.id })
        .then((resp) => {
          const campaigns = resp.data.data.campaigns
          commit("setCampaigns", campaigns)
        })
        .catch((err) => console.log(generateAPIErrorMessage(err)))
    },
    async updateCampaignStatus({ rootGetters }, { campaignId, status }) {
      const selectedSite = rootGetters["sites/getSelectedSite"]
      return campaignAPI
        .updateCampaign({ siteId: selectedSite.id, campaignId, formData: { is_active: status } })
        .then(() => {
          return true
        })
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async deleteCampaignVariant({ rootGetters }, { campaignId, campaignVariantId }) {
      const selectedSite = rootGetters["sites/getSelectedSite"]
      return campaignAPI
        .deleteCampaignVariant({ siteId: selectedSite.id, campaignId, campaignVariantId })
        .then(() => {})
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    },
    async updateCampaignVariantStatus({ rootGetters }, { campaignId, campaignVariantId, status }) {
      const selectedSite = rootGetters["sites/getSelectedSite"]
      return campaignAPI
        .updateCampaignVariant({
          siteId: selectedSite.id,
          campaignId,
          campaignVariantId,
          data: { is_active: status }
        })
        .then(() => {})
        .catch((err) => {
          throw Error(generateAPIErrorMessage(err))
        })
    }
  },
  namespaced: true
}
export default campaigns
