<template>
  <form @submit.prevent="submit">
    <div class="form-group">
      <label class="input-label" for="campaignName">Template Name</label>
      <input type="text" id="campaignName" class="form-control" v-model="formName" />
    </div>

    <div class="form-group">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" id="isActive" class="custom-control-input" v-model="formIsActive" />
        <label class="custom-control-label" for="isActive">
          <span>Active</span>
        </label>
      </div>
    </div>

    <div class="table-responsive card">
      <div class="card-header">
        <h5>Tags</h5>
      </div>
      <table class="table table-thead-bordered table-align-middle">
        <thead>
          <tr>
            <th>Type</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tag, key) in formTags" :key="key">
            <td><input class="form-control form-control-sm" type="text" v-model="formTags[key]['type']" /></td>
            <td><input class="form-control form-control-sm" type="text" v-model="formTags[key]['value']" /></td>
          </tr>
        </tbody>
      </table>

      <div class="card-footer text-right">
        <button class="btn btn-white btn-sm" type="button" @click="handleAddTag">Add Tag</button>
      </div>
    </div>

    <button class="btn btn-primary mt-4">Save</button>
  </form>
</template>

<script>
export default {
  name: "CampaignTemplateForm",
  data() {
    return {
      formName: this.name,
      formIsActive: this.is_active,
      formTags: this.tags
    }
  },
  props: {
    name: String,
    is_active: Boolean,
    tags: Array
  },
  methods: {
    handleAddTag() {
      this.formTags.push({ type: "", value: "" })
    },
    submit() {
      this.formTags = this.formTags.filter((tag) => {
        return tag["type"].length > 0 && tag["value"].length > 0
      })
      this.$emit("submit", { name: this.formName, is_active: this.formIsActive, tags: this.formTags })
    }
  }
}
</script>
