import { cloneDeep } from "lodash"

export const HISTORY_TYPES = {
  ADD_PAGE: "ADD_PAGE",
  REMOVE_PAGE: "REMOVE_PAGE",
  REORDER_PAGE: "REORDER_PAGE",
  UPDATE_STYLE_VARIABLE: "UPDATE_STYLE_VARIABLE",
  UPDATE_ELEMENT_CLASS: "UPDATE_ELEMENT_CLASS",
  CONTENT_UPDATE: "CONTENT_UPDATE",
  REMOVE_BODY_ELEMENT: "REMOVE_BODY_ELEMENT",
  ADD_BODY_ELEMENT: "ADD_BODY_ELEMENT",
  REMOVE_GAMIFICATION_WIDGET: "REMOVE_GAMIFICATION_WIDGET",
  ADD_GAMIFICATION_WIDGET: "ADD_GAMIFICATION_WIDGET",
  ADD_VISUAL_ASSET: "ADD_VISUAL_ASSET",
  REMOVE_VISUAL_ASSET: "REMOVE_VISUAL_ASSET",
  ADD_FLOAT_IMAGE: "ADD_FLOAT_IMAGE",
  REMOVE_FLOAT_IMAGE: "REMOVE_FLOAT_IMAGE",
  UPDATE_ELEMENT_DATA: "UPDATE_ELEMENT_DATA"
}

function initialState() {
  return {
    history: [],
    future: [],
    groupingEnabled: false,
    groupingHistory: []
  }
}

const builderHistory = {
  state: initialState,
  mutations: {
    resetState(state) {
      Object.assign(state, initialState())
    },
    setGroupingEnabled(state, payload) {
      state.groupingEnabled = payload
    },
    addToHistory(state, payload) {
      state.history.push(payload)
    },
    addToGroupingHistory(state, payload) {
      state.groupingHistory.push(payload)
    },
    setGroupingHistory(state, payload) {
      state.groupingHistory = payload
    },
    setHistory(state, payload) {
      state.history = payload
    },
    setFuture(state, payload) {
      state.future = payload
    }
  },
  actions: {
    startGrouping({ commit }) {
      commit("setGroupingEnabled", true)
    },
    stopGrouping({ state, commit }) {
      const data = state.groupingHistory.flat()
      if (data.length > 0) {
        commit("addToHistory", data)
      }
      commit("setGroupingEnabled", false)
      commit("setGroupingHistory", [])
    },
    addToHistory({ commit, state }, payload) {
      if (!Array.isArray(payload)) {
        payload = [payload]
      }

      let subHistory = []
      for (const { type, data } of payload) {
        if (type === HISTORY_TYPES.UPDATE_STYLE_VARIABLE) {
          const { oldVal, newVal, variable, env, pageNumber } = data
          if (oldVal === newVal) continue

          const l = state.history.length
          if (l === 0) {
            subHistory.push({ type, data })
            continue
          }

          let lastItem = state.history[l - 1][0]

          if (
            lastItem &&
            lastItem.data &&
            lastItem.data.variable === variable &&
            lastItem.data.env === env &&
            lastItem.data.pageNumber === pageNumber
          ) {
            state.history[l - 1][0].data.newVal = newVal
            continue
          }
        } else if (type === HISTORY_TYPES.CONTENT_UPDATE) {
          const { oldVal, newVal } = data
          if (oldVal === newVal) return
        } else if (type === HISTORY_TYPES.UPDATE_ELEMENT_CLASS) {
          let { oldVal, newVal } = data
          oldVal = oldVal.split(" ").sort().join(" ")
          newVal = newVal.split(" ").sort().join(" ")
          if (oldVal === newVal) continue
        } else if (type === HISTORY_TYPES.UPDATE_ELEMENT_DATA) {
          let { oldVal, newVal } = data
          if (oldVal === newVal) continue
        }
        subHistory.push({ type, data })
      }

      if (subHistory.length === 0) {
        return
      }
      if (state.groupingEnabled) {
        commit("addToGroupingHistory", subHistory)
      } else {
        commit("addToHistory", subHistory)
      }
      commit("setFuture", [])
    },
    undoUpdateStyleVariable({ commit }, { oldVal, env, variable, pageNumber }) {
      commit(
        "builder/setStyleVariableDirectWithoutAddingHistory",
        {
          value: oldVal,
          variable,
          env,
          pageNumber
        },
        { root: true }
      )
    },
    redoUpdateStyleVariable({ commit }, { newVal, env, variable, pageNumber }) {
      commit(
        "builder/setStyleVariableDirectWithoutAddingHistory",
        {
          value: newVal,
          variable,
          env,
          pageNumber
        },
        { root: true }
      )
    },
    undoAddPage({ dispatch }, data) {
      dispatch("builder/removePage", data.pageNumber, { root: true })
    },
    redoAddPage({ dispatch }, data) {
      dispatch("undoRemovePage", data)
    },
    undoAddBodyElement({ rootGetters }, data) {
      rootGetters["builder/getIframeDocument"].querySelector(data.selector).remove()
    },
    redoAddBodyElement({ rootGetters }, data) {
      const { index, html, pageNumber } = data
      const body = rootGetters["builder/getIframeDocument"].querySelector(`.page${pageNumber} .body-wrapper .body`)
      const element = body.querySelector(`div:nth-child(${index})`)

      if (element) {
        element.insertAdjacentHTML("beforebegin", html)
      } else {
        body.insertAdjacentHTML("beforeend", html)
      }
    },
    undoRemoveBodyElement({ dispatch }, data) {
      dispatch("redoAddBodyElement", data)
    },
    redoRemoveBodyElement({ dispatch }, data) {
      dispatch("undoAddBodyElement", data)
    },
    undoUpdateElementData({ rootGetters }, { selector, dataAttribute, oldVal }) {
      rootGetters["builder/getIframeDocument"].querySelector(selector).dataset[dataAttribute] = oldVal
    },
    redoUpdateElementData({ rootGetters }, { selector, dataAttribute, newVal }) {
      rootGetters["builder/getIframeDocument"].querySelector(selector).dataset[dataAttribute] = newVal
    },
    undoRemovePage({ dispatch, commit, rootState, rootGetters }, { pageNumber, pageContent, pageStyleVariables }) {
      const doc = rootGetters["builder/getIframeDocument"]
      const tempPageNumber = rootState.builder.totalPage + 1
      const parentId = rootState.builder.parentId
      commit("builder/addPage", null, { root: true })

      doc.querySelector(`#${parentId} .page${tempPageNumber - 1}`).insertAdjacentHTML("afterend", pageContent)
      doc.querySelectorAll(`#${parentId} .page`).forEach((page) => {
        page.setAttribute("data-total-page", tempPageNumber.toString())
      })

      commit("builder/addStyleVariables", { [tempPageNumber]: pageStyleVariables }, { root: true })
      dispatch("builder/reloadIframe", null, { root: true })
      dispatch(
        "builder/changeOrderOfPage",
        {
          oldPageNumber: tempPageNumber,
          newPageNumber: pageNumber
        },
        { root: true }
      )
    },
    redoRemovePage({ dispatch }, data) {
      dispatch("undoAddPage", data)
    },
    undoReorderPage({ dispatch }, { oldPageNumber, newPageNumber }) {
      dispatch(
        "builder/changeOrderOfPage",
        {
          oldPageNumber: oldPageNumber,
          newPageNumber: newPageNumber
        },
        { root: true }
      )
    },
    redoReorderPage({ dispatch }, { oldPageNumber, newPageNumber }) {
      dispatch("undoReorderPage", { oldPageNumber: newPageNumber, newPageNumber: oldPageNumber })
    },
    undoContentUpdate({ rootGetters }, { oldVal, selector }) {
      const doc = rootGetters["builder/getIframeDocument"]
      doc.querySelector(selector).innerHTML = oldVal
    },
    redoContentUpdate({ dispatch }, { newVal, selector }) {
      dispatch("undoContentUpdate", { oldVal: newVal, selector })
    },
    undoClassUpdate({ rootGetters }, { oldVal, newVal, selector }) {
      const doc = rootGetters["builder/getIframeDocument"]
      doc.querySelector(selector).classList.remove(...newVal.split(" "))
      doc.querySelector(selector).classList.add(...oldVal.split(" "))
    },
    redoClassUpdate({ dispatch }, { oldVal, newVal, selector }) {
      dispatch("undoClassUpdate", { oldVal: newVal, newVal: oldVal, selector })
    },
    undoAddGamificationWidget({ rootGetters }, { pageNumber }) {
      rootGetters["builder/getIframeDocument"].querySelector(`.page${pageNumber} .gamification-container .gamification-widget`).remove()
    },
    undoRemoveGamificationWidget({ rootGetters }, { html, pageNumber }) {
      rootGetters["builder/getIframeDocument"].querySelector(`.page${pageNumber} .gamification-container`).innerHTML = html
    },
    redoAddGamificationWidget({ dispatch }, data) {
      dispatch("undoRemoveGamificationWidget", data)
    },
    redoRemoveGamificationWidget({ dispatch }, data) {
      dispatch("undoAddGamificationWidget", data)
    },

    undoAddFloatImage({ rootGetters }, { selector }) {
      rootGetters["builder/getIframeDocument"].querySelector(selector).remove()
    },
    redoAddFloatImage({ rootGetters }, { html, pageNumber }) {
      rootGetters["builder/getIframeDocument"].querySelector(`.page${pageNumber} .images`).insertAdjacentHTML("beforeend", html)
    },
    undoRemoveFloatImage({ dispatch }, data) {
      dispatch("redoAddFloatImage", data)
    },
    redoRemoveFloatImage({ dispatch }, data) {
      dispatch("undoAddFloatImage", data)
    },

    undoAddBlockImage({ rootGetters }, { selector }) {
      rootGetters["builder/getIframeDocument"].querySelector(selector).remove()
    },
    redoAddBlockImage({ rootGetters }, { html, pageNumber }) {
      rootGetters["builder/getIframeDocument"].querySelector(`.page${pageNumber} .content-wrapper`).insertAdjacentHTML("beforeend", html)
    },
    undoRemoveBlockImage({ dispatch }, data) {
      dispatch("redoAddBlockImage", data)
    },
    redoRemoveBlockImage({ dispatch }, data) {
      dispatch("undoAddBlockImage", data)
    },
    undo({ state, commit, dispatch }) {
      const history = cloneDeep(state.history)
      const future = cloneDeep(state.future)
      if (history.length < 1) return
      const actions = history.pop()
      future.push(actions.reverse())
      for (const action of actions) {
        if (action.type === HISTORY_TYPES.UPDATE_STYLE_VARIABLE) {
          dispatch("undoUpdateStyleVariable", action.data)
        } else if (action.type === HISTORY_TYPES.ADD_PAGE) {
          dispatch("undoAddPage", action.data)
        } else if (action.type === HISTORY_TYPES.REMOVE_PAGE) {
          dispatch("undoRemovePage", action.data)
        } else if (action.type === HISTORY_TYPES.REORDER_PAGE) {
          dispatch("undoReorderPage", action.data)
        } else if (action.type === HISTORY_TYPES.CONTENT_UPDATE) {
          dispatch("undoContentUpdate", action.data)
        } else if (action.type === HISTORY_TYPES.UPDATE_ELEMENT_CLASS) {
          dispatch("undoClassUpdate", action.data)
        } else if (action.type === HISTORY_TYPES.ADD_BODY_ELEMENT) {
          dispatch("undoAddBodyElement", action.data)
        } else if (action.type === HISTORY_TYPES.REMOVE_BODY_ELEMENT) {
          dispatch("undoRemoveBodyElement", action.data)
        } else if (action.type === HISTORY_TYPES.ADD_GAMIFICATION_WIDGET) {
          dispatch("undoAddGamificationWidget", action.data)
        } else if (action.type === HISTORY_TYPES.REMOVE_GAMIFICATION_WIDGET) {
          dispatch("undoRemoveGamificationWidget", action.data)
        } else if (action.type === HISTORY_TYPES.UPDATE_ELEMENT_DATA) {
          dispatch("undoUpdateElementData", action.data)
        } else if (action.type === HISTORY_TYPES.ADD_VISUAL_ASSET) {
          dispatch("undoAddBlockImage", action.data)
        } else if (action.type === HISTORY_TYPES.REMOVE_BLOCK_IMAGE) {
          dispatch("undoRemoveBlockImage", action.data)
        } else if (action.type === HISTORY_TYPES.ADD_FLOAT_IMAGE) {
          dispatch("undoAddFloatImage", action.data)
        } else if (action.type === HISTORY_TYPES.REMOVE_FLOAT_IMAGE) {
          dispatch("undoRemoveFloatImage", action.data)
        }
      }
      commit("setFuture", future)
      commit("setHistory", history)
      dispatch("builder/reloadIframe", null, { root: true })
    },
    redo({ dispatch, commit, state }) {
      const future = cloneDeep(state.future)
      const history = cloneDeep(state.history)

      if (future.length < 1) return
      const actions = future.pop()
      history.push(actions.reverse())

      for (const action of actions) {
        if (action.type === HISTORY_TYPES.UPDATE_STYLE_VARIABLE) {
          dispatch("redoUpdateStyleVariable", action.data)
        } else if (action.type === HISTORY_TYPES.ADD_PAGE) {
          dispatch("redoAddPage", action.data)
        } else if (action.type === HISTORY_TYPES.REMOVE_PAGE) {
          dispatch("redoRemovePage", action.data)
        } else if (action.type === HISTORY_TYPES.REORDER_PAGE) {
          dispatch("redoReorderPage", action.data)
        } else if (action.type === HISTORY_TYPES.CONTENT_UPDATE) {
          dispatch("redoContentUpdate", action.data)
        } else if (action.type === HISTORY_TYPES.UPDATE_ELEMENT_CLASS) {
          dispatch("redoClassUpdate", action.data)
        } else if (action.type === HISTORY_TYPES.ADD_BODY_ELEMENT) {
          dispatch("redoAddBodyElement", action.data)
        } else if (action.type === HISTORY_TYPES.REMOVE_BODY_ELEMENT) {
          dispatch("redoRemoveBodyElement", action.data)
        } else if (action.type === HISTORY_TYPES.ADD_GAMIFICATION_WIDGET) {
          dispatch("redoAddGamificationWidget", action.data)
        } else if (action.type === HISTORY_TYPES.REMOVE_GAMIFICATION_WIDGET) {
          dispatch("redoRemoveGamificationWidget", action.data)
        } else if (action.type === HISTORY_TYPES.UPDATE_ELEMENT_DATA) {
          dispatch("redoUpdateElementData", action.data)
        } else if (action.type === HISTORY_TYPES.ADD_VISUAL_ASSET) {
          dispatch("redoAddBlockImage", action.data)
        } else if (action.type === HISTORY_TYPES.REMOVE_BLOCK_IMAGE) {
          dispatch("redoRemoveBlockImage", action.data)
        } else if (action.type === HISTORY_TYPES.ADD_FLOAT_IMAGE) {
          dispatch("redoAddFloatImage", action.data)
        } else if (action.type === HISTORY_TYPES.REMOVE_FLOAT_IMAGE) {
          dispatch("redoRemoveFloatImage", action.data)
        }
      }
      commit("setFuture", future)
      commit("setHistory", history)
      dispatch("builder/reloadIframe", null, { root: true })
    }
  },
  namespaced: true
}

export default builderHistory
