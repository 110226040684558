<template>
  <div>
    <div class="dropdown" v-if="status === 'enabled'">
      <button
        class="btn btn-white dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ selectedDomain }}
      </button>
      <modal-add-new-site v-if="showAddNewSite" @close="showAddNewSite = false" @siteCreated="handleSiteCreated" />
      <modal-insert-code v-if="showInsertCode" @close="showInsertCode = false" :site="insertCodeSite" />
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <button class="dropdown-item" @click="changeDomain(site.id)" v-for="site in sites" :key="site.id">
          {{ site.domain }}
        </button>
        <div class="dropdown-divider" v-if="sites.length > 0"></div>
        <button class="dropdown-item" @click="showAddNewSite = true">Add New Site</button>
        <button class="dropdown-item" @click="$router.push({ name: 'Dashboard.Sites' })">Manage Sites</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import ModalAddNewSite from "@/components/modals/ModalAddNewSite"
import ModalInsertCode from "@/components/modals/ModalInsertCode"

export default {
  name: "SiteChangerDropdown",
  components: { ModalInsertCode, ModalAddNewSite },
  data() {
    return {
      showAddNewSite: false,
      showInsertCode: false,
      insertCodeSite: null
    }
  },
  computed: {
    ...mapState("sites", ["sites", "selectedSite"]),
    selectedDomain() {
      return this.selectedSite.domain
    },
    status() {
      return this.$route.meta.siteChanger
    }
  },
  methods: {
    ...mapMutations("sites", ["setSelectedSiteBySiteId"]),
    changeDomain(data) {
      this.setSelectedSiteBySiteId(data)
      if (this.$route.meta.redirectOnSiteChange) {
        this.$router.push({ name: this.$route.meta.redirectOnSiteChange })
      }
    },
    handleSiteCreated(site) {
      this.showAddNewSite = false
      this.insertCodeSite = site
      this.showInsertCode = true
    }
  }
}
</script>

<style scoped></style>
