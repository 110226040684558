<template>
  <div class="mb-3 card mb-lg-5">
    <div class="card-header">
      <div class="row justify-content-between align-items-center flex-grow-1">
        <div class="col-6">
          <h3 class="mb-0">Campaign Variants</h3>
        </div>
      </div>
    </div>

    <datatable-table-element
      :multiselect="false"
      :paginated="false"
      :heads="[
        { class: '', text: '' },
        { class: '', text: 'Name' },
        { class: 'table-column-right-aligned', text: 'Impressions' },
        { class: 'table-column-right-aligned', text: 'Submits' },
        { class: 'table-column-right-aligned', text: 'Date Created' },
        { class: '', text: '' }
      ]"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import advancedSelectMixin from "@/mixins/advanced-select-mixin"
import dateFormatterMixin from "@/mixins/date-formatter-mixin"
import sorrySvg from "@/theme-files/assets/svg/illustrations/sorry.svg"
import campaignAPI from "@/api/campaign"
import { debounce } from "lodash"
import DatatableTableElement from "@/components/common/DatatableTableElement"

export default {
  name: "CampaignVariantsTable",
  components: { DatatableTableElement },
  computed: {
    ...mapState("sites", ["selectedSite"]),
    ...mapState("user", ["user"])
  },
  data() {
    return {
      datatable: null
    }
  },
  props: ["campaignId"],
  mixins: [advancedSelectMixin, dateFormatterMixin],
  methods: {
    ...mapActions("campaigns", ["updateCampaignVariantStatus", "deleteCampaignVariant"]),
    async changeCampaignVariantStatus(inp) {
      inp.disabled = true
      const campaignVariantId = inp.dataset["campaignVariantId"]
      const checked = inp.checked
      try {
        await this.updateCampaignVariantStatus({ campaignId: this.campaignId, campaignVariantId, status: checked })
      } catch (e) {
        inp.checked = !inp.checked
      } finally {
        inp.disabled = false
      }
    },
    goToBuilder(btn) {
      const campaignVariantId = btn.dataset["campaignVariantId"]
      return this.$router.push({
        name: "CampaignBuilder",
        params: { site: this.selectedSite.id, campaign: this.campaignId, variant: campaignVariantId }
      })
    },
    createCampaignTemplate(btn) {
      const variantId = btn.dataset["createCampaignTemplate"]
      return this.$router.push({
        name: "Dashboard.Admin.CampaignTemplates.Create",
        query: { campaignVariantId: variantId }
      })
    },
    async duplicateVariant(btn) {
      const campaignVariantId = btn.dataset["duplicateVariant"]
      await campaignAPI.duplicateCampaignVariant({
        siteId: this.selectedSite.id,
        campaignId: this.campaignId,
        campaignVariantId
      })
      this.datatable.ajax.reload()
    },
    async deleteVariant(btn) {
      btn.disabled = true
      const campaignVariantId = btn.dataset["deleteVariant"]
      await this.deleteCampaignVariant({ campaignId: this.campaignId, campaignVariantId })
      this.datatable.ajax.reload()
    },
    search: debounce(function (e) {
      this.datatable.column(2).search(e.target.value).draw()
    }, 200)
  },
  async mounted() {
    const vm = this
    this.initAdvancedSelect()
    const url = campaignAPI.getCampaignVariantsDatatablesEndpoint({
      siteId: this.selectedSite.id,
      campaignId: this.campaignId
    })
    require("@/theme-files/hs.datatables")
    this.datatable = $.HSCore.components.HSDatatables.init($("#datatable"), {
      processing: true,
      serverSide: true,
      drawCallback: function () {
        vm.$el.querySelectorAll(".toggle-switch-input").forEach((inp) => {
          inp.addEventListener("change", () => vm.changeCampaignVariantStatus(inp))
        })
        vm.$el.querySelectorAll(".go-to-builder-btn").forEach((btn) => {
          btn.addEventListener("click", () => vm.goToBuilder(btn))
        })
        vm.$el.querySelectorAll(".variant-delete").forEach((btn) => {
          btn.addEventListener("click", () => vm.deleteVariant(btn))
        })
        vm.$el.querySelectorAll(".duplicate-variant").forEach((btn) => {
          btn.addEventListener("click", () => vm.duplicateVariant(btn))
        })
        vm.$el.querySelectorAll("[data-create-campaign-template]").forEach((btn) => {
          btn.addEventListener("click", () => vm.createCampaignTemplate(btn))
        })

        vm.$el.querySelectorAll(".screenshot-loading").forEach((loadingElement) => {
          const campaignVariantId = loadingElement.dataset.campaignVariantId
          const parent = loadingElement.closest(".go-to-builder-btn")
          const checkerFunc = async () => {
            const response = await campaignAPI.fetchCampaign({ siteId: vm.selectedSite.id, campaignId: vm.campaignId })
            const variant = response.data.data.campaign.variants.find((variant) => variant.id === parseInt(campaignVariantId))
            const ssUrl = variant["ss_url"]
            if (ssUrl) {
              loadingElement.remove()
              parent.insertAdjacentHTML(
                "afterbegin",
                // `<div class="mr-3 avatar avatar-xl" style="width: auto;aspect-ratio: 16 / 9;"><img class="border avatar-img" src="${ssUrl}"></div>`
                `<div class="mr-3 avatar avatar-xl" style="width: auto;aspect-ratio: 16 / 9;"><img class="border avatar-img" src="https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"></div>`
              )
              clearInterval(checkingInterval)
            }
          }
          const checkingInterval = setInterval(checkerFunc, 3000)
        })
      },
      ajax: {
        url: url,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("access_token")
        },
        data: function (params) {
          return params
        },
        dataSrc: function (json) {
          for (var i = 0, ien = json.data.length; i < ien; i++) {
            json.data[i][
              "is_active"
            ] = `<label class="toggle-switch toggle-switch-sm d-flex align-items-center" for="campaignVariantStatusToggle${
              json.data[i].id
            }">
              <input type="checkbox" id="campaignVariantStatusToggle${json.data[i].id}" data-campaign-variant-id="${
              json.data[i].id
            }" class="toggle-switch-input campaign-variant-status-changer" ${json.data[i].is_active ? "checked" : ""}>
              <span class="toggle-switch-label">
                <span class="toggle-switch-indicator"></span>
              </span>
            </label>`

            json.data[i][
              "name"
            ] = `<a href="javascript:;" class="media align-items-center go-to-builder-btn" data-campaign-variant-id="${json.data[i].id}">`
            if (json.data[i].screenshot_url) {
              json.data[i][
                "name"
              ] += `<div class="mr-3 avatar avatar-xl" style="width:auto; aspect-ratio: 16 / 9;"><img class="border avatar-img" src="https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"/></div>`
            } else {
              json.data[i][
                "name"
              ] += `<div class="mr-3 d-flex justify-content-center align-items-center avatar-xl screenshot-loading" data-campaign-variant-id="${json.data[i].id}" style="width:auto; aspect-ratio: 16 / 9;"><div class="mx-auto spinner-border text-primary" role="status"><span class="sr-only">Loading...</span></div></div>`
            }
            json.data[i]["name"] += `<div class="media-body"><span class="mb-0 h5 text-hover-primary">Variant ${i + 1}</span></div>
            </a>`

            json.data[i]["impression"] = `<div class="text-right">${json.data[i]["impression"]}</div>`
            json.data[i]["submit"] = `<div class="text-right">${json.data[i]["submit"]}</div>`
            json.data[i]["created_at"] = `<div class="text-right">${vm.getFormattedDate(json.data[i]["created_at"])}</div>`

            json.data[i]["actions"] = `<div class="text-right">
              <button class="btn btn-sm btn-outline-primary duplicate-variant" data-duplicate-variant="${json.data[i].id}">
                <i class="tio-copy"></i> Duplicate
              </button>`

            if (json.data.length > 1) {
              json.data[i][
                "actions"
              ] += `<button class="ml-2 btn btn-sm btn-outline-danger variant-delete" data-delete-variant="${json.data[i].id}">
                <i class="tio-delete-outlined"></i> Delete
              </button>
              </div>`
            }

            if (vm.user["is_admin"]) {
              json.data[i][
                "actions"
              ] += `<button class="ml-2 btn btn-sm btn-outline-danger" data-create-campaign-template="${json.data[i].id}">
Create Campaign Template
</button></div>`
            }
          }
          return json.data
        }
      },
      columns: [
        { data: "is_active" },
        { data: "name" },
        { data: "impression" },
        { data: "submit" },
        { data: "created_at" },
        { data: "actions" }
      ],
      columnDefs: [{ orderable: false, targets: [0, 1, -1] }],
      order: [[4, "asc"]],
      language: {
        zeroRecords: `<div class="p-4 text-center">
            <img class="mx-auto mb-3" src="${sorrySvg}" style="width: 10rem;"><p class="mb-0">No data to show</p>
            </div>`,
        processing: `<div class="my-4 d-flex justify-content-center">
  <div class="mx-auto spinner-border text-info" role="status">
    <span class="sr-only">Loading...</span> </div></div>`
      }
    })
  }
}
</script>
