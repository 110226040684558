<template>
  <main id="content" role="main" class="main">
    <!-- Content -->
    <div class="content container-fluid">
      <div class="row align-items-sm-center py-sm-10">
        <div class="col-sm-6">
          <div class="text-center text-sm-right mr-sm-4 mb-5 mb-sm-0">
            <img class="w-60 w-sm-100 mx-auto" :src="thinkSvg" alt="Image Description" style="max-width: 15rem" />
          </div>
        </div>

        <div class="col-sm-6 col-md-4 text-center text-sm-left">
          <h1 class="display-1 mb-0">404</h1>
          <p class="lead">Sorry, the page you're looking for cannot be found.</p>
          <router-link class="btn btn-primary" :to="{ name: 'Dashboard' }">Go back to the App</router-link>
        </div>
      </div>
      <!-- End Row -->
    </div>
  </main>
</template>

<script>
import thinkSvg from "@/theme-files/assets/svg/illustrations/think.svg"

export default {
  name: "NotFound",
  computed: {
    thinkSvg() {
      return thinkSvg
    }
  }
}
</script>

<style scoped></style>
