<template>
  <settings-template :title="title" :layout="layout" :show-device-picker="showDevicePicker">
    <range-input-with-unit v-model="value" :alternative-units="['px', 'vh', '%']" :min="min" :max="max" />
  </settings-template>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import RangeInputWithUnit from "@/components/common/RangeInputWithUnit"

export default {
  name: "SettingsHeight",
  components: { RangeInputWithUnit, SettingsTemplate },
  props: {
    variable: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "Height"
    }
  },
  mixins: [appearanceChangerMixin],
  computed: {
    value: {
      get() {
        return this.getStyleVariableValue(this.env, this.variable, 1)
      },
      set(height) {
        this.setStyleVariableDirect({
          env: this.env,
          variable: this.variable,
          value: height.value + height.unit
        })
      }
    },
    min() {
      return 0
    },
    max() {
      if (this.value.unit === "px") {
        return 400
      }
      return 100
    }
  }
}
</script>
