<template>
  <div>
    <dashboard-title title="Leads" />
    <leads-table />
  </div>
</template>

<script>
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import LeadsTable from "@/components/dashboard/LeadsTable"
import { mapState } from "vuex"
import ensureHasSite from "@/mixins/ensure-has-site"

export default {
  name: "Lead",
  components: { LeadsTable, DashboardTitle },
  mixins: [ensureHasSite],
  computed: mapState("sites", ["selectedSite"])
}
</script>

<style scoped></style>
