<template>
  <base-campaign-targeting-rule>
    <span>Campaign will be appear if browser language is {{ data.map((d) => langs[d].join(" / ")).join(" or ") }}</span>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import langs from "@/components/campaign-targeting-rules/langs"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("who", targetingRuleNames.whoRules.browserLanguage)
export default {
  name: "TargetingDevice",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin],
  data() {
    return {
      langs: langs
    }
  }
}
</script>
