<template>
  <div class="card" id="account-payment-methods">
    <div class="card-header">
      <h4 class="card-header-title">Payment Methods</h4>
    </div>
    <div class="card-body">
      <div>
        <!--            <h4 class="mb-4">Payment Methods</h4>-->
        <loading v-if="paymentMethodsLoadStatus === 'loading'" />
        <dashboard-error
          v-else-if="paymentMethodsLoadStatus === 'success' && paymentMethods.length === 0"
          message="There is no available payment method, add at least one to subscribe."
        />
        <ul class="list-group mb-3" v-else>
          <!-- List Item -->
          <li class="list-group-item" v-for="method in paymentMethods" :key="method.id">
            <h4>
              {{ method.owner_name }}
              <span class="badge badge-primary badge-pill text-uppercase ml-1" v-if="method.is_default">Default</span>
            </h4>
            <div class="media">
              <img class="avatar avatar-sm avatar-4by3 mr-3" v-if="getBrandLogo(method.brand)" :src="getBrandLogo(method.brand)" />
              <span class="avatar avatar-sm avatar-4by3 mr-3" v-else>{{ method.brand.charAt(0).toUpperCase() }}</span>
              <div class="media-body">
                <div class="row">
                  <div class="col-sm mb-2 mb-sm-0">
                    <span class="d-block text-dark">{{ method.brand.charAt(0).toUpperCase() }}{{ method.brand.slice(1) }} •••• 4242</span>
                    <small class="d-block text-muted">Expires 12/22</small>
                  </div>

                  <div class="col-sm-auto">
                    <div class="d-flex justify-content-sm-end">
                      <button
                        v-if="!method.is_default"
                        @click="makeDefaultPaymentMethod(method.id)"
                        type="button"
                        class="btn btn-xs btn-white mr-2"
                      >
                        Make Default
                      </button>

                      <!-- #TODO: Disable it if its primary and user has paid plan -->
                      <button @click="removePaymentMethod(method.id)" type="button" class="btn btn-xs btn-white">
                        <i class="tio-delete-outlined mr-1"></i> Delete
                      </button>
                    </div>
                  </div>
                </div>
                <!-- End Row -->
              </div>
            </div>
          </li>
          <!-- End List Item -->
        </ul>
      </div>
      <div>
        <h4 class="mb-4">Add New Payment Method</h4>
        <dashboard-error v-if="addPaymentStatus === 'error'" :message="addPaymentStatusError" />
        <dashboard-success v-if="addPaymentStatus === 'success'" message="Payment method is added" />
        <div class="mt-4">
          <div class="row form-group mb-4">
            <label for="addPaymentMethodName" class="col-sm-3 col-form-label input-label">Card Holder Name</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" name="addPaymentMethodName" id="addPaymentMethodName" v-model="name" />
            </div>
          </div>
          <div class="row form-group">
            <label for="addPaymentMethodName" class="col-sm-3 col-form-label input-label">Card</label>
            <div class="col-sm-9">
              <div id="card-element" class="form-control"></div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-primary" @click="submitPaymentMethod" v-if="addPaymentStatus !== 'loading'">Add Card</button>
          <button class="btn btn-primary" disabled v-else>
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import DashboardError from "@/components/dashboard/DashboardError"
import DashboardSuccess from "@/components/dashboard/DashboardSuccess"
import masterCardLogo from "@/theme-files/assets/svg/brands/mastercard.svg"
import visaLogo from "@/theme-files/assets/svg/brands/visa.svg"
import Loading from "@/components/common/Loading"

export default {
  name: "AccountPaymentMethods",
  components: { Loading, DashboardSuccess, DashboardError },
  computed: {
    masterCardLogo() {
      return masterCardLogo
    },
    visaLogo() {
      return visaLogo
    },
    ...mapState("user", ["paymentMethods"])
  },
  mounted() {
    this.addStripeToPage()
  },
  data() {
    return {
      stripeAPIToken: "",
      stripe: "",
      card: "",
      paymentMethodSelected: {},
      cardStyle: {
        base: {
          color: "#2b2b2b",
          fontSize: "16px"
        }
      },
      name: "",
      addPaymentStatus: "",
      addPaymentStatusError: "",
      paymentMethodsLoadStatus: "success"
    }
  },
  methods: {
    ...mapActions("user", [
      "fetchStripeKey",
      "addPaymentMethod",
      "fetchPaymentMethods",
      "fetchStripeSetupIntentToken",
      "deletePaymentMethod",
      "updateDefaultPaymentMethod"
    ]),
    getBrandLogo(brand) {
      if (brand === "mastercard") return this.masterCardLogo
      if (brand === "visa") return this.visaLogo
      return null
    },
    async submitPaymentMethod() {
      const intentToken = await this.fetchStripeSetupIntentToken()
      this.addPaymentStatus = "loading"
      const result = await this.stripe.confirmCardSetup(intentToken, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.name
          }
        }
      })
      if (result.error) {
        this.addPaymentStatus = "error"
        this.addPaymentStatusError = result.error.message
      } else {
        await this.addPaymentMethod(result.setupIntent.payment_method)
        this.addPaymentStatus = "success"
        this.card.clear()
        this.name = ""
        await this.loadPaymentMethods()
      }
    },

    async addStripeToPage() {
      this.stripeAPIToken = await this.fetchStripeKey()
      const documentTag = document
      const tag = "script"
      const object = documentTag.createElement(tag)
      const scriptTag = documentTag.getElementsByTagName(tag)[0]
      object.src = "//js.stripe.com/v3/"
      object.addEventListener(
        "load",
        function () {
          // eslint-disable-next-line no-undef
          this.stripe = Stripe(this.stripeAPIToken)
          const elements = this.stripe.elements()
          this.card = elements.create("card", { hidePostalCode: true, style: this.cardStyle })
          this.card.mount("#card-element")
        }.bind(this),
        false
      )
      scriptTag.parentNode.insertBefore(object, scriptTag)
    },

    async loadPaymentMethods() {
      this.paymentMethodsLoadStatus = "loading"
      await this.fetchPaymentMethods()
      this.paymentMethodsLoadStatus = "success"
    },

    async removePaymentMethod(paymentID) {
      this.paymentMethodsLoadStatus = "loading"
      await this.deletePaymentMethod(paymentID)
      await this.loadPaymentMethods()
    },

    async makeDefaultPaymentMethod(paymentID) {
      this.paymentMethodsLoadStatus = "loading"
      await this.updateDefaultPaymentMethod(paymentID)
      await this.loadPaymentMethods()
    }
  }
}
</script>
