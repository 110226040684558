<template>
  <div>
    <select v-model="buttonType" class="mb-3 form-control">
      <option :selected="buttonType === 'bar-button'" value="bar-button">Make Button Entire Bar</option>
      <option :selected="buttonType === 'normal-button'" value="normal-button">Use Button</option>
    </select>
    <single-button
      v-if="buttonType === 'normal-button'"
      :button-area-selector="`${getPageSelector} .form-and-buttons`"
      :show-title="false"
      button-class="primary"
    />
    <single-button
      v-if="buttonType === 'bar-button'"
      :button-area-selector="`${getPageSelector}`"
      :inner-editable="false"
      :show-title="false"
      button-class="bar-button"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import SingleButton from "@/components/campaign-builder/content-changers/SingleButton"

export default {
  name: "Buttons",
  components: { SingleButton },
  data() {
    return {
      buttonType: "none"
    }
  },
  computed: mapGetters("builder", ["getPageSelector", "getIframeDocument"]),
  watch: {
    buttonType(newValue) {
      console.log(newValue)
      if (newValue === "bar-button") {
        const primaryButton = this.getIframeDocument.querySelector(`${this.getPageSelector} .form-and-buttons button.primary`)
        if (primaryButton) {
          primaryButton.remove()
        }
        const element = this.getIframeDocument.querySelector(`${this.getPageSelector} .flat-bar.layout`)
        element.parentElement.innerHTML = `<div class="bar-button">${element.outerHTML}</div>`
      } else if (newValue === "normal-button") {
        const element = this.getIframeDocument.querySelector(`${this.getPageSelector} .bar-button`)
        if (element) {
          element.parentElement.innerHTML = `${element.querySelector(".flat-bar.layout").outerHTML}`
        }
        this.getIframeDocument.querySelector(
          `${this.getPageSelector} .form-and-buttons`
        ).innerHTML = `<button class="primary">Button</button>`
      }
    }
  },
  created() {
    if (this.getIframeDocument.querySelector(`${this.getPageSelector} .form-and-buttons button.primary`)) {
      this.buttonType = "normal-button"
    } else {
      this.buttonType = "bar-button"
    }
  }
}
</script>
