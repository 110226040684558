import Vue from "vue"

const clipboardMixin = {
  methods: {
    initClipboard() {
      window.ClipboardJS = require("@/theme-files/clipboard.min")
      require("@/theme-files/hs.clipboard")
      const parent = this.$refs.PARENTELEMENT ? this.$refs.PARENTELEMENT : this.$el
      parent.querySelectorAll(".js-clipboard").forEach((el) => {
        $.HSCore.components.HSClipboard.init(el)
      })
    }
  },
  mounted() {
    Vue.nextTick().then(() => this.initClipboard())
  }
}
export default clipboardMixin
