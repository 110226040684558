<template>
  <div>
    <div>
      <campaign-type-select-button
        v-for="(template, key) in multiPurposeTemplates"
        :key="key"
        :active="typeName === 'multi-purpose' && typeData['type'] === key"
        @click="
          setTypeName('multi-purpose')
          setTypeData('type', key)
        "
      >
        {{ template.name }}
      </campaign-type-select-button>
      <campaign-type-select-button :active="typeName === 'satis-meter'" @click="setTypeName('satis-meter')">
        SatisMeter
      </campaign-type-select-button>
      <campaign-type-select-button :active="typeName === 'flat-bar'" @click="setTypeName('flat-bar')" >Flat Bar
      </campaign-type-select-button>
      <campaign-type-select-button :active="typeName === 'whatsapp'" @click="setTypeName('whatsapp')"
        >Whatsapp
      </campaign-type-select-button>
      <campaign-type-select-button :active="typeName === 'image-button'" @click="setTypeName('image-button')"
        >Fixed Image Button
      </campaign-type-select-button>
      <campaign-type-select-button :active="typeName === 'just-image'" @click="setTypeName('just-image')"
        >Just Image
      </campaign-type-select-button>
    </div>
    <div class="text-right">
      <button v-if="readyToCreate" class="mt-4 btn btn-lg btn-soft-primary" @click="next">Next</button>
    </div>
  </div>
</template>

<script>
import CampaignTypeSelectButton from "@/components/campaign-type-select/CampaignTypeSelectButton"
import multiPurposeTemplates from "@/lib/marketing-widgets/multi-purpose-templates"

export default {
  name: "CampaignTypeSelector",
  components: { CampaignTypeSelectButton },
  data() {
    return {
      typeName: "",
      typeData: {}
    }
  },
  computed: {
    multiPurposeTemplates() {
      return multiPurposeTemplates()
    },
    readyToCreate() {
      return (
        this.typeName === "multi-purpose" ||
        this.typeName === "satis-meter" ||
        this.typeName === "just-image" ||
        this.typeName === "image-button" ||
        this.typeName === "flat-bar" ||
        this.typeName === "whatsapp"
      )
    }
  },
  methods: {
    setTypeName(value) {
      this.typeName = value
      this.typeData = {}
    },
    setTypeData(key, value) {
      this.typeData[key] = value
      this.typeData = { ...this.typeData }
    },
    next() {
      this.$emit("next", { type: this.typeName, typeData: this.typeData })
    }
  }
}
</script>
<style lang="scss" scoped>
button {
  &:not(:last-of-type) {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
</style>
