<template>
  <base-campaign-targeting-rule>
    <div>
      <div v-for="(sentence, key) in getDescription" :key="key">
        <div style="line-height: 1.2">{{ sentence }}</div>
        <strong v-if="data.length - 1 > key">OR</strong>
      </div>
    </div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("who", targetingRuleNames.whoRules.ipBlocked)

export default {
  name: "TargetingIpBlocked",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin],
  computed: {
    getDescription() {
      return this.data.map((ipData) => {
        if (ipData.type === "exact") {
          return `The ip address of visitor must not be ${ipData.value}`
        } else if (ipData.type === "range") {
          return `The ip address of visitor must not be between ${ipData.from} - ${ipData.to}`
        }
      })
    }
  }
}
</script>
