import LuckyWheelManager from "@/lib/marketing-widgets/lucky-wheel-manager"
import ElementManager from "@/lib/marketing-widgets/element-manager"
import GiftPickerManager from "@/lib/marketing-widgets/gift-picker-manager"
import store from "@/store/index"
import { HISTORY_TYPES } from "@/store/builder-history"

class GamificationManager extends ElementManager {
  constructor(gamificationContainer) {
    super(
      gamificationContainer,
      {
        width: {
          desktop: "420px",
          mobile: "100%"
        },
        background: "transparent",
        padding: "40px 40px 40px 40px"
      },
      "gamification-"
    )
  }

  getGamificationWidget() {
    return this.element.querySelector(".gamification-widget")
  }

  add(type, { desktop, mobile }) {
    const el = this.element.ownerDocument.createElement("div")
    el.classList.add("osm-element", "gamification-widget")
    el.dataset.type = type
    this.element.dataset.desktop = desktop
    this.element.dataset.mobile = mobile

    if (type === "lucky-wheel") {
      el.innerHTML = LuckyWheelManager.getInitialHtml()
    } else if (type === "gift-picker") {
      el.innerHTML = GiftPickerManager.getInitialHtml()
    }

    this.element.appendChild(el)
    this.getWidgetManager()

    store.dispatch("builderHistory/addToHistory", {
      type: HISTORY_TYPES.ADD_GAMIFICATION_WIDGET,
      data: {
        pageNumber: store.state.builder.currentPage,
        html: el.outerHTML
      }
    })
  }

  remove() {
    store.dispatch("builderHistory/addToHistory", {
      type: HISTORY_TYPES.REMOVE_GAMIFICATION_WIDGET,
      data: {
        pageNumber: store.state.builder.currentPage,
        html: this.getGamificationWidget().outerHTML
      }
    })
    this.getGamificationWidget().remove()
  }

  getGamificationType() {
    if (this.getGamificationWidget()?.dataset.type === "lucky-wheel") {
      return "lucky-wheel"
    }
    if (this.getGamificationWidget()?.dataset.type === "gift-picker") {
      return "gift-picker"
    }
    return null
  }

  getWidgetManager() {
    if (this.getGamificationType() === "lucky-wheel") {
      return new LuckyWheelManager(this.getGamificationWidget())
    } else if (this.getGamificationType() === "gift-picker") {
      return new GiftPickerManager(this.getGamificationWidget())
    }
    return null
  }
}

export default GamificationManager
