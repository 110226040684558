import getAxiosInstance from "@/api/get-axios-instance"

const fetchCampaigns = async ({ siteId }) => await getAxiosInstance().get(`sites/${siteId}/campaigns`)
const fetchCampaign = async ({ siteId, campaignId }) => await getAxiosInstance().get(`sites/${siteId}/campaigns/${campaignId}`)
const fetchCampaignStatistics = async ({ siteId, startDate, endDate }) =>
  await getAxiosInstance().get(`sites/${siteId}/campaigns`, { start_date: startDate, end_date: endDate })
const getCampaignsDatatablesEndpoint = ({ siteId }) => process.env.VUE_APP_API_BASE + `/sites/${siteId}/campaigns-datatables`
const getCampaignVariantsDatatablesEndpoint = ({ siteId, campaignId }) =>
  process.env.VUE_APP_API_BASE + `/sites/${siteId}/campaigns/${campaignId}/variants-datatables`

const updateCampaign = async function ({ siteId, campaignId, formData }) {
  return await getAxiosInstance().put(`sites/${siteId}/campaigns/${campaignId}`, formData)
}

const updateCampaignVariant = async function ({ siteId, campaignId, campaignVariantId, data }) {
  let sendData = {}
  if ("is_active" in data) sendData["is_active"] = data["is_active"]
  if ("body" in data) sendData["body"] = data["body"]
  if ("form_fields" in data) sendData["form_fields"] = data["form_fields"]
  return await getAxiosInstance().put(`sites/${siteId}/campaigns/${campaignId}/variants/${campaignVariantId}`, sendData)
}

const fetchCampaignVariant = async ({ siteId, campaignId, campaignVariantId }) =>
  await getAxiosInstance().get(`sites/${siteId}/campaigns/${campaignId}/variants/${campaignVariantId}`)

const deleteCampaigns = async ({ siteId, campaignIds }) =>
  await getAxiosInstance().delete(`sites/${siteId}/campaigns?ids=${campaignIds.join(",")}`)

const deleteCampaignVariant = async ({ siteId, campaignId, campaignVariantId }) =>
  await getAxiosInstance().delete(`sites/${siteId}/campaigns/${campaignId}/variants/${campaignVariantId}`)

const createCampaign = async ({ siteId, name, settings, body }) =>
  await getAxiosInstance().post(`sites/${siteId}/campaigns`, { name, settings, body })

const createCampaignByCampaignTemplate = async ({ siteId, campaignTemplateId, name }) =>
  await getAxiosInstance().post(`sites/${siteId}/campaigns/create-by-template/${campaignTemplateId}`, { name })

const duplicateCampaignVariant = async ({ siteId, campaignId, campaignVariantId }) =>
  await getAxiosInstance().post(`sites/${siteId}/campaigns/${campaignId}/variants/${campaignVariantId}/duplicate`)

const fetchGeotargetingLocations = async () => await getAxiosInstance().get(`geotargeting-locations`)

const campaignAPI = {
  fetchCampaigns,
  fetchCampaign,
  duplicateCampaignVariant,
  fetchCampaignStatistics,
  updateCampaign,
  fetchCampaignVariant,
  createCampaign,
  updateCampaignVariant,
  getCampaignsDatatablesEndpoint,
  getCampaignVariantsDatatablesEndpoint,
  deleteCampaigns,
  fetchGeotargetingLocations,
  deleteCampaignVariant,
  createCampaignByCampaignTemplate
}
export default campaignAPI
