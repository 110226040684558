import ElementManager from "@/lib/marketing-widgets/element-manager"
import FormButtonManager from "@/lib/marketing-widgets/form-button-manager"
import TextFieldManager from "@/lib/marketing-widgets/text-field-manager"
import SpacerManager from "@/lib/marketing-widgets/spacer-manager"
import DividerManager from "@/lib/marketing-widgets/divider-manager"
import FormFieldLabelManager from "@/lib/marketing-widgets/form-field-label-manager"
import formFieldInputManagerFactory from "@/lib/marketing-widgets/form-field-input-manager-factory"
import formFields from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/FormFields"
import CountDownManager from "@/lib/marketing-widgets/count-down-manager"
import SocialButtonGroupManager from "@/lib/marketing-widgets/social-button-group-manager"

class BodyManager extends ElementManager {
  constructor(bodyElement) {
    super(
      bodyElement,
      {
        width: {
          desktop: "420px",
          mobile: "320px"
        },
        padding: {
          desktop: "50px 50px 50px 50px",
          mobile: "40px 40px 40px 40px"
        },
        background: "transparent",
        "justify-self": "center",
        "align-self": "center",
        "border-radius": "0px 0px 0px 0px",
        "border-color": "rgb(0,0,0)",
        "border-style": "solid",
        "border-width": "0px 0px 0px 0px"
      },
      "body-"
    )

    // to initialize...
    this.getChildrenData()
  }

  getChildrenData() {
    function truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "&hellip;" : str
    }

    return Array.from(this.element.querySelectorAll(`.osm-element`)).map((field) => {
      let element = field.querySelector(":scope > [data-osm-button]")
      if (element)
        return {
          type: "button",
          icon: "mouse-outlined",
          element,
          manager: new FormButtonManager(element),
          buttonText: `Button (${truncate(element.innerText, 20)})`
        }

      element = field.querySelector(":scope > [data-osm-text-field]")
      if (element)
        return {
          type: "text",
          icon: "text",
          element,
          manager: new TextFieldManager(element),
          buttonText: `Text (${truncate(element.innerText, 20)})`
        }

      element = field.querySelector(":scope > [data-osm-spacer]")
      if (element)
        return {
          type: "spacer",
          icon: "enter",
          element,
          manager: new SpacerManager(element),
          buttonText: "Spacer"
        }

      element = field.querySelector(":scope > [data-osm-divider]")
      if (element)
        return {
          type: "divider",
          icon: "reflect-vertical",
          element,
          manager: new DividerManager(element),
          buttonText: "Divider"
        }

      element = field.querySelector(":scope > [data-osm-form-field]")
      if (element) {
        element.querySelector(".form-field-label") && new FormFieldLabelManager(element.querySelector(".form-field-label"))
        formFieldInputManagerFactory(element.querySelector(".form-field-input"))
        let buttonSuffix = "",
          icon = null
        formFields.forEach((formField) => {
          if (element.querySelector(formField.selector)) {
            buttonSuffix = `(${formField.name})`
            icon = formField.icon
          }
        })
        return { type: "form-input", element, icon, buttonText: "Input " + buttonSuffix }
      }
      element = field.querySelector(":scope > [data-osm-count-down]")
      if (element)
        return {
          type: "count-down",
          icon: "timer",
          element,
          manager: new CountDownManager(element),
          buttonText: "Count Down"
        }

      element = field.querySelector(":scope > [data-osm-social-button-group]")
      if (element)
        return {
          type: "social-button-group",
          icon: "share",
          element,
          manager: new SocialButtonGroupManager(element),
          buttonText: "Social Button Group"
        }
    })
  }
}

export default BodyManager
