import { render, staticRenderFns } from "./DashboardError.vue?vue&type=template&id=f28b76f8&scoped=true&"
import script from "./DashboardError.vue?vue&type=script&lang=js&"
export * from "./DashboardError.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f28b76f8",
  null
  
)

export default component.exports