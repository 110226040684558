import randomId from "@/lib/utils/random-id"

import html from "!raw-loader!./flat-bar/html.html"
import { getImportUrl } from "@/lib/utils/google-font"

class flatBarHtml {
  get() {
    const fontUrl = getImportUrl(["Poppins"])
    return html.replace("FONT_URL", fontUrl).replaceAll("PARENT_ID", randomId())
  }
}

export { flatBarHtml }
