<template>
  <settings-content-appearance>
    <template #content>
      <settings-template title="Currently Editing">
        <div class="row d-flex align-items-center justify-content-between">
          <div class="col">
            <select class="form-control px-1" v-model.number="currentTab">
              <option v-for="i in tabCount" :key="i" :selected="currentTab === i - 1" :value="i - 1">Question {{ i }}</option>
            </select>
          </div>
          <div class="col d-flex">
            <button class="btn w-100 btn-soft-primary" @click="addTab">Add</button>
            <button class="btn w-100 btn-soft-danger" v-if="tabCount > 1" @click="removeTab(currentTab)">Remove</button>
          </div>
        </div>
      </settings-template>
      <settings-template title="Question">
        <mini-editor
          :key="`${getPageSelector}+${currentTab}+1`"
          :align="false"
          :line-height="false"
          :link="false"
          :selector="`${getPageSelector} [data-tab='${currentTab}'] .question`"
        ></mini-editor>
      </settings-template>
      <settings-template title="Answer Type">
        <div class="row align-items-center mb-3">
          <div class="col-4 pr-0"><span>Select Type:</span></div>
          <div class="col-8">
            <select class="form-control" v-model="type" @change="changeType">
              <option value="number">Number</option>
              <option value="text">Text</option>
              <option value="free-text">Free Text</option>
            </select>
          </div>
        </div>
        <div v-if="type === 'number'">
          <div class="row align-items-center mb-3">
            <div class="col-4 pr-0"><span>Last Number</span></div>
            <div class="col-8">
              <select class="form-control" v-model="numberLastNumber" @change="changeNumber">
                <option v-for="i in 8" :key="i" :value="i + 2">{{ i + 2 }}</option>
              </select>
            </div>
          </div>
          <Checkbox v-model="numberStartFromZero" @change="changeNumber">Start From Zero</Checkbox>
          <div class="mt-4 mb-1">Bottom Left Message</div>
          <mini-editor
            :key="`${getPageSelector}+${currentTab}+2`"
            :align="false"
            :line-height="false"
            :link="false"
            :selector="`${getPageSelector} [data-tab='${currentTab}'] .bottom-messages .left`"
          ></mini-editor>
          <div class="mt-4 mb-1">Bottom Right Message</div>
          <mini-editor
            :key="`${getPageSelector}+${currentTab}+3`"
            :align="false"
            :line-height="false"
            :link="false"
            :selector="`${getPageSelector} [data-tab='${currentTab}'] .bottom-messages .right`"
          ></mini-editor>
        </div>
        <div v-if="type === 'text'">
          <div class="card mb-4" v-for="(_, k) in textAnswers" :key="k">
            <div class="card-body">
              <div class="mb-2">
                <div class="form-group">
                  <label class="mb-1 input-label" :for="`answer-${k}`">Answer:</label
                  ><input class="form-control" :id="`answer-${k}`" v-model="textAnswers[k].text" @input="changeAnswerData(k)" />
                </div>
                <div class="form-group">
                  <label class="mb-1 input-label" :for="`answer-value-${k}`">Value:</label
                  ><input class="form-control" :id="`answer-value-${k}`" v-model="textAnswers[k].value" @input="changeAnswerData(k)" />
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <button class="btn btn-soft-primary w-100" @click="addAnswer">Add New</button>
                </div>
                <div class="col-6">
                  <button class="btn btn-soft-danger w-100" v-if="textAnswers.length > 1" @click="deleteAnswer(k)">Remove</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </settings-template>
    </template>
    <template #appearance>
      <settings-template title="Position">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1 input-label" for="desktop-position-satismeter">Desktop:</label
              ><select class="px-1 form-control" id="desktop-position-satismeter" v-model="desktopPosition" @change="changePosition">
                <option value="top-left">Top Left</option>
                <option value="top-center">Top Center</option>
                <option value="top-right">Top Right</option>
                <option value="center-left">Center Left</option>
                <option value="center-center">Center Center</option>
                <option value="center-right">Center Right</option>
                <option value="bottom-left">Bottom Left</option>
                <option value="bottom-center">Bottom Center</option>
                <option value="bottom-right">Bottom Right</option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="mb-1 input-label" for="mobile-position-satismeter">Mobile:</label
              ><select class="px-1 form-control" id="mobile-position-satismeter" v-model="mobilePosition" @change="changePosition">
                <option value="top">Top</option>
                <option value="center">Center</option>
                <option value="bottom">Bottom</option>
              </select>
            </div>
          </div>
        </div>
      </settings-template>
      <settings-color title="Layout Background Color" variable="--layout-background-color"></settings-color>
      <settings-template title="Button Background Color">
        <div class="row align-items-center mb-2">
          <div class="col-3"><span>Normal</span></div>
          <div class="col-9">
            <color variable="--button-background-color"></color>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-3"><span>Hover</span></div>
          <div class="col-9">
            <color variable="--button-hover-background-color"></color>
          </div>
        </div>
      </settings-template>
      <settings-template title="Button Color">
        <div class="row align-items-center mb-2">
          <div class="col-3"><span>Normal</span></div>
          <div class="col-9">
            <color variable="--button-color"></color>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-3"><span>Hover</span></div>
          <div class="col-9">
            <color variable="--button-hover-color"></color>
          </div>
        </div>
      </settings-template>
      <settings-color title="Close Button Foreground" variable="--close-button-color"></settings-color>
      <settings-background-color title="Close Button Background" variable="--close-button-background"></settings-background-color>
      <SettingsVisibility
        :can-be-delayed="true"
        selector=".close-button"
        title="Close Button Visibility"
        variable="--close-button-display"
      />
    </template>
  </settings-content-appearance>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import Color from "@/components/campaign-builder/appearance-changers/Color"
import typeText from "!raw-loader!./raw-html/type-text.html"
import typeNumber from "!raw-loader!./raw-html/type-number.html"
import tab from "!raw-loader!./raw-html/tab.html"
import typeFreeText from "!raw-loader!./raw-html/type-free-text.html"
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"
import Checkbox from "@/components/common/Checkbox"
import randomId from "@/lib/utils/random-id"
import SettingsContentAppearance from "@/components/campaign-builder/SettingsContentAppearance"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "SatisMeterSettings",
  components: {
    SettingsTemplate,
    SettingsContentAppearance,
    Checkbox,
    MiniEditor,
    Color
  },
  data() {
    return {
      currentTab: 0,
      tabCount: 0,
      eventCache: [],
      desktopPosition: "",
      mobilePosition: "",
      numberLastNumber: 5,
      numberStartFromZero: false,
      textAnswers: [],
      type: ""
    }
  },
  computed: mapGetters("builder", ["getIframeDocument", "getPageSelector"]),
  watch: {
    currentTab() {
      this.initTab()
    }
  },
  methods: {
    ...mapMutations("builder", ["setAppearanceDevice"]),
    addTab() {
      let html = ""
      const tabsParent = this.getIframeDocument.querySelector(`${this.getPageSelector} .satismeter-tabs`)
      tabsParent.querySelectorAll(`.satismeter-tab`).forEach((tabInFrame, index) => {
        html += tabInFrame.outerHTML
        if (index === this.currentTab) html += tab
        tabInFrame.remove()
      })
      tabsParent.innerHTML += html
      this.currentTab += 1
      this.initTab()
    },
    removeTab(tabNumber) {
      const tab = this.getIframeDocument.querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)[tabNumber]
      tab.remove()
      const isLast = this.tabCount - 1 === this.currentTab
      if (isLast) {
        this.currentTab -= 1
        this.initTab()
      }
      this.initTab()
    },
    changePosition() {
      const positionElement = this.getIframeDocument.querySelector(`${this.getPageSelector} .position`)
      positionElement.dataset.desktop = this.desktopPosition
      positionElement.dataset.mobile = this.mobilePosition
    },
    changeType() {
      const tab = this.getIframeDocument.querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)[this.currentTab]
      const answerElement = tab.querySelector(".answer")
      if (this.type === "text") answerElement.innerHTML = typeText
      else if (this.type === "free-text") answerElement.innerHTML = typeFreeText
      else if (this.type === "number") answerElement.innerHTML = typeNumber
      this.fillDataFromWidget()
      this.initTab()
    },
    changeNumber() {
      let html = ""
      let max = 1
      if (this.numberStartFromZero) max = 0
      for (let i = this.numberLastNumber; i >= max; i--) {
        html += `<button class="answer-button" data-value="${i}">${i}</button>`
      }
      const tab = this.getIframeDocument.querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)[this.currentTab]
      tab.querySelector(`.answer-options`).innerHTML = html
      this.initTab()
    },
    addAnswer() {
      this.textAnswers.push({
        text: "New Answer",
        value: "New Value"
      })
      this.updateTextAnswer()
    },
    deleteAnswer(index) {
      this.textAnswers = this.textAnswers.filter((value, key) => key !== index)
      this.updateTextAnswer()
    },
    changeAnswerData(key) {
      const tab = this.getIframeDocument.querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)[this.currentTab]
      const button = tab.querySelectorAll(`.answer-options .answer-button`)[key]
      button.dataset.value = this.textAnswers[key].value
      button.innerText = this.textAnswers[key].text
    },
    updateTextAnswer() {
      let html = ""
      this.textAnswers.forEach((answer) => {
        html += `<button class="answer-button" data-value="${answer.value}">${answer.text}</button>`
      })
      const tab = this.getIframeDocument.querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)[this.currentTab]
      tab.querySelector(`.answer-options`).innerHTML = html
      this.fillDataFromWidget()
      this.initTab()
    },
    fillDataFromWidget() {
      const tab = this.getIframeDocument.querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)[this.currentTab]
      const buttons = tab.querySelectorAll(".answer-options .answer-button")
      if (this.type === "number") {
        this.numberStartFromZero = buttons[buttons.length - 1].dataset.value === "0"
        this.numberLastNumber = buttons[0].dataset.value
      } else if (this.type === "text") {
        this.textAnswers = []
        buttons.forEach((button) => {
          this.textAnswers.push({
            value: button.dataset.value,
            text: button.innerText
          })
        })
      }
    },
    initTab(event) {
      const doc = this.getIframeDocument
      let k = 0
      doc.querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`).forEach((tab) => {
        tab.dataset.tab = `${k}`
        k++
      })

      if (event) {
        const { eventId, tabIndex } = event.detail
        doc.dispatchEvent(
          new CustomEvent("satisMeterDashboardCommunication", {
            detail: { eventId, tabIndex, source: "dashboard" }
          })
        )
      } else {
        const eventId = randomId()
        doc.dispatchEvent(
          new CustomEvent("satisMeterDashboardCommunication", {
            detail: {
              eventId,
              tabIndex: this.currentTab,
              source: "dashboard"
            }
          })
        )
      }

      const tabs = this.getIframeDocument.querySelectorAll(`${this.getPageSelector} .satismeter-tabs .satismeter-tab`)
      this.tabCount = tabs.length
      this.type = tabs[this.currentTab].querySelector(".answer-options").dataset.type
      this.fillDataFromWidget()
    }
  },
  created() {
    this.setAppearanceDevice("all")
    const position = this.getIframeDocument.querySelector(`${this.getPageSelector} .position`)
    this.desktopPosition = position.dataset.desktop
    this.mobilePosition = position.dataset.mobile
    this.currentTab = 0
    this.initTab()

    this.getIframeDocument.addEventListener("satisMeterDashboardCommunication", (event) => {
      const { eventId, tabIndex, source } = event.detail
      if (!this.eventCache.includes(eventId) && source !== "dashboard") {
        this.eventCache.push(eventId)
        this.currentTab = tabIndex
        this.initTab(event)
      }
    })
  }
}
</script>
