import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const targetingRuleTexts = { who: {}, when: {} }

const addText = (type, { name, title, description, howItWorks }) => {
  targetingRuleTexts[type][name] = {
    title,
    description,
    howItWorks
  }
}

const addWhoRulesText = ({ name, title, description, howItWorks }) => {
  addText("who", { name, title, description, howItWorks })
}

const addWhenRulesText = ({ name, title, description, howItWorks }) => {
  addText("when", { name, title, description, howItWorks })
}

addWhoRulesText({
  name: targetingRuleNames.whoRules.returningOrNew,
  title: "Returning / New",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.linking,
  title: "Engaged with OSM campaigns",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.currentUrl,
  title: "Current URL",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.ipAllowed,
  title: "Ip Allowed",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.ipBlocked,
  title: "Ip Blocked",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.adblock,
  title: "AdBlock Detection",
  description: "Adblock description",
  howItWorks: "How it works adblock test."
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.visitedUrl,
  title: "Visited Url",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.lastVisitedUrl,
  title: "Last Visited URL",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.excludeByVisit,
  title: "Exclude By Visit",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.device,
  title: "Device",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.source,
  title: "Source",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.operatingSystem,
  title: "Operating System",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.numberOfVisitedPages,
  title: "Number Of Visited Pages",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.timeSpentOnPage,
  title: "Time Spent On Page",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.timeSpentOnSite,
  title: "Time Spent On Page",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.cart,
  title: "Cart Rules",
  description: "",
  howItWorks: ""
})
addWhoRulesText({
  name: targetingRuleNames.whoRules.frequency,
  title: "Frequency",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.browserLanguage,
  title: "Browser Language",
  description: "",
  howItWorks: ""
})

addWhoRulesText({
  name: targetingRuleNames.whoRules.location,
  title: "Location",
  description: "",
  howItWorks: ""
})

addWhenRulesText({
  name: targetingRuleNames.whenRules.afterMilliseconds,
  title: "After X seconds",
  description: "",
  howItWorks: ""
})

addWhenRulesText({
  name: targetingRuleNames.whenRules.exitIntent,
  title: "On Exit Intent",
  description: "",
  howItWorks: ""
})
addWhenRulesText({
  name: targetingRuleNames.whenRules.inactivity,
  title: "After X seconds of inactivity",
  description: "",
  howItWorks: ""
})

addWhenRulesText({
  name: targetingRuleNames.whenRules.scrollPercentage,
  title: "After X percent scrolling",
  description: "",
  howItWorks: ""
})
addWhenRulesText({
  name: targetingRuleNames.whenRules.clickTo,
  title: "On click to a specific area",
  description: "",
  howItWorks: ""
})

export default targetingRuleTexts
