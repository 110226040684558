<template>
  <base-campaign-targeting-rule>
    <div>The campaign will be appear if {{ data.filter }} {{ data.value }} pages are visited.</div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("who", targetingRuleNames.whoRules.numberOfVisitedPages)

export default {
  name: "TargetingNumberOfVisitedPages",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin]
}
</script>
