<template>
  <div>
    <div class="row align-items-center">
      <div class="col-4"><span>Name</span></div>
      <div class="col-8"><input class="form-control form-control-sm" v-model="name" /></div>
    </div>
    <div class="row align-items-center">
      <div class="col">
        <CheckboxInput v-model="isRequired">Required</CheckboxInput>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxInput from "@/components/common/Checkbox"

export default {
  name: "Checkbox",
  components: { CheckboxInput },
  props: ["element"],
  data() {
    return {
      isRequired: "",
      name: "",
      checkboxElement: null
    }
  },
  watch: {
    $data: {
      handler: function () {
        this.checkboxElement.name = this.name
        this.element.querySelector("span.checkbox-text").innerText = this.name
        this.checkboxElement.required = this.isRequired
      },
      deep: true
    }
  },
  mounted() {
    this.checkboxElement = this.element.querySelector("input[type='checkbox']")
    this.isRequired = this.checkboxElement.required
    this.name = this.checkboxElement.name
  }
}
</script>
