<template>
  <base-campaign-targeting-rule>
    <div>
      <span>Show campaign if source of visitor is one of these:</span>
      <div v-for="(source, k) in nameSources" :key="k">{{ sourceNames[source.value] }}</div>
      <div v-for="(source, k) in fullPathSources" :key="k">
        Source URL is {{ stringCompareOperators[source.filter] }} {{ source.value }}
      </div>
    </div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import stringCompareOperators from "@/components/campaign-targeting-rules/stringCompareOperators"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const sourceNames = {
  direct: "Direct",
  googleAds: "Google Ads",
  googleOrganic: "Google Organic",
  facebookAds: "Facebook Ads",
  facebookOrganic: "Facebook Organic"
}
const mixin = targetingRuleMixin("who", targetingRuleNames.whoRules.source)

export default {
  name: "TargetingSource",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin],
  data() {
    return {
      sourceNames: sourceNames,
      stringCompareOperators: stringCompareOperators
    }
  },
  computed: {
    nameSources() {
      return this.data.filter((d) => d.type === "name")
    },
    fullPathSources() {
      return this.data.filter((d) => d.type === "fullPath")
    }
  }
}
</script>
