<template>
  <div class="hs-unfold">
    <a
      ref="js-hs-unfold-invoker"
      class="js-hs-unfold-invoker navbar-dropdown-account-wrapper"
      href="javascript:;"
      data-hs-unfold-options='{ "target": "#accountNavbarDropdown", "type": "css-animation" }'
    >
      <div class="avatar avatar-sm avatar-circle">
        <img class="avatar-img" :src="userAvatar" alt="Image Description" />
        <span class="avatar-status avatar-sm-status avatar-status-success"></span>
      </div>
    </a>

    <div
      id="accountNavbarDropdown"
      class="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account"
      style="width: 16rem"
    >
      <div class="dropdown-item-text">
        <div class="media align-items-center">
          <div class="avatar avatar-sm avatar-circle mr-2">
            <img class="avatar-img" :src="userAvatar" alt="Image Description" />
          </div>
          <div class="media-body">
            <span class="card-title h5">{{ user.first_name }} {{ user.last_name }}</span>
            <span class="card-text">{{ user.email }}</span>
          </div>
        </div>
      </div>

      <a class="dropdown-item btn" @click="$router.push({ name: 'Dashboard.ProfileSettings' })">
        <span class="text-truncate pr-2" title="Profile Settings">Profile Settings</span>
      </a>
      <a class="dropdown-item btn" @click="$router.push({ name: 'Dashboard.PlanAndBilling' })">
        <span class="text-truncate pr-2" title="Profile Settings">Plan & Billing Settings</span>
      </a>

      <div class="dropdown-divider"></div>

      <a class="dropdown-item btn" @click="$router.push({ name: 'Logout' })">
        <span class="text-truncate pr-2" title="Sign out">Sign out</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import unfoldMixin from "@/mixins/unfold-mixin"

export default {
  name: "HeaderAccount",
  computed: {
    ...mapState("user", ["user"]),
    userAvatar() {
      return `https://ui-avatars.com/api/?name=${this.user.first_name + this.user.last_name}`
    }
  },
  mixins: [unfoldMixin]
}
</script>

<style scoped></style>
