<template>
  <div class="row">
    <targeting-modal
      v-if="targetingRuleModal"
      :component-name="targetingRuleModal"
      @close="targetingRuleModal = null"
      @save="
        targetingRuleModal = null
        showWhenModal = false
        showWhoModal = false
      "
    />
    <modal-campaign-targeting-rule-picker v-else-if="showWhenModal" type="when" @close="showWhenModal = false" @picked="handlePicked" />
    <modal-campaign-targeting-rule-picker v-else-if="showWhoModal" type="who" @close="showWhoModal = false" @picked="handlePicked" />

    <div class="col-12">
      <div class="card mb-3 mb-lg-5">
        <div class="card-header">
          <h3 class="mr-4 mb-0">When To Show?</h3>
          <button class="btn btn-sm btn-soft-primary" type="button" @click="showWhenModal = true">Add Rule</button>
        </div>
        <div class="card-body">
          <targeting-widget v-for="key in Object.keys(whenRules)" :key="key" :component-name="whenToShowComponentMap[key]" />
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="card mb-3 mb-lg-5">
        <div class="card-header">
          <h3 class="mr-4 mb-0">Who To Show?</h3>
          <button class="btn btn-sm btn-soft-primary" type="button" @click="showWhoModal = true">Add Rule</button>
        </div>
        <div class="card-body">
          <targeting-widget v-for="key in Object.keys(whoRules)" :key="key" :component-name="whoToShowComponentMap[key]"></targeting-widget>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import {
  whenToShowComponentMap,
  whenToShowModalMap,
  whoToShowComponentMap,
  whoToShowModalMap
} from "@/components/campaign-targeting-rules/targetingRuleComponentMaps"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import TargetingWidget from "@/components/campaign-targeting-rules/TargetingWidget"
import ModalCampaignTargetingRulePicker from "@/components/modals/ModalCampaignTargetingRulePicker"
import TargetingModal from "@/components/campaign-targeting-rules/TargetingModal"

export default {
  name: "CampaignSettingsTargeting",
  components: { TargetingModal, ModalCampaignTargetingRulePicker, TargetingWidget },
  data() {
    return {
      whenToShowComponentMap: whenToShowComponentMap,
      whoToShowComponentMap: whoToShowComponentMap,
      whoToShowModalMap: whoToShowModalMap,
      whenToShowModalMap: whenToShowModalMap,
      targetingRuleNames: targetingRuleNames,
      showWhenModal: false,
      showWhoModal: false,
      targetingRuleModal: null
    }
  },
  methods: {
    handlePicked({ typeOfRule, nameOfRule }) {
      if (typeOfRule === "who") {
        this.targetingRuleModal = this.whoToShowModalMap[nameOfRule]
      } else if (typeOfRule === "when") {
        this.targetingRuleModal = this.whenToShowModalMap[nameOfRule]
      }
    }
  },
  computed: {
    ...mapState("campaigns", ["campaigns"]),
    ...mapState("campaignSettings", ["whenRules", "whoRules"]),
    ...mapGetters("campaignSettings", ["ruleExists"])
  }
}
</script>

<style scoped></style>
