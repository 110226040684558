<template>
  <settings-template :title="title" :show-device-picker="showDevicePicker" :layout="layout">
    <Color :un-formatted="unFormatted" :variable="variable" />
  </settings-template>
</template>

<script>
import Color from "@/components/campaign-builder/appearance-changers/Color"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"

export default {
  name: "SettingsColor",
  props: {
    title: {
      type: String,
      default: "Color"
    },
    variable: {
      type: String,
      required: true
    },
    unFormatted: {
      type: Boolean,
      default: false
    }
  },
  mixins: [appearanceChangerMixin],
  components: { SettingsTemplate, Color }
}
</script>
