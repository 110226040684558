import randomId from "@/lib/utils/random-id"
import { getImportUrl } from "@/lib/utils/google-font"
import smHtml from "!raw-loader!./satis-meter/html.html"

class satisMeterHtml {
  get() {
    const fontUrl = getImportUrl(["Poppins"])
    return smHtml.replaceAll("PARENT_ID", randomId()).replaceAll("FONT_URL", fontUrl)
  }
}

export { satisMeterHtml }
