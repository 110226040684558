<template>
  <div>
    <dashboard-title title="Campaign Settings">
      <template #right>
        <button class="btn btn-primary btn-sm" @click="submit" v-if="!saveLoading">Save Campaign Settings</button>
        <button class="btn btn-primary btn-sm" disabled="disabled" v-else>
          <span class="spinner-grow spinner-grow-sm mr-2"></span><span>Save Campaign Settings</span>
        </button>
      </template>
    </dashboard-title>
    <loading v-if="loading"></loading>
    <div class="row" v-else>
      <div class="col-lg-6">
        <campaign-settings-name />
      </div>
      <div class="col-lg-6">
        <campaign-settings-schedule />
      </div>
      <div class="col-lg-12">
        <campaign-settings-targeting />
      </div>
      <div class="col-lg-12">
        <campaign-settings-integrations />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import { mapActions } from "vuex"
import Loading from "@/components/common/Loading"
import CampaignSettingsName from "@/components/dashboard/CampaignSettingsName"
import CampaignSettingsTargeting from "@/components/dashboard/CampaignSettingsTargeting"
import CampaignSettingsIntegrations from "@/components/dashboard/CampaignSettingsIntegrations"
import CampaignSettingsSchedule from "@/components/dashboard/CampaignSettingsSchedule"
import ensureHasSite from "@/mixins/ensure-has-site"

export default {
  name: "CampaignSettings",
  components: {
    CampaignSettingsSchedule,
    CampaignSettingsIntegrations,
    CampaignSettingsTargeting,
    CampaignSettingsName,
    Loading,
    DashboardTitle
  },
  mixins: [ensureHasSite],
  data() {
    return {
      loading: true,
      saveLoading: false
    }
  },
  methods: {
    ...mapActions("campaignSettings", ["init", "updateSettings"]),
    async submit() {
      this.saveLoading = true
      await this.updateSettings()
      this.saveLoading = false
      await this.$router.push({ name: "Dashboard.Campaigns.Summary", params: { id: this.$route.params.id } })
    }
  },
  async mounted() {
    this.loading = true
    await this.init(this.$route.params.id)
    this.loading = false
  }
}
</script>
