<template>
  <settings-template :title="title" :show-device-picker="showDevicePicker">
    <div class="d-flex">
      <button class="btn btn-ghost-secondary btn-sm" @click="value = 'center'" :class="{ active: value === 'center' }">
        <i class="tio-vertical-align-middle"></i>
        <span>Middle</span>
      </button>

      <button class="btn btn-ghost-secondary btn-sm" @click="value = 'start'" :class="{ active: value === 'start' }">
        <i class="tio-vertical-align-top"></i>
        <span>Top</span>
      </button>

      <button class="btn btn-ghost-secondary btn-sm" @click="value = 'end'" :class="{ active: value === 'end' }">
        <i class="tio-vertical-align-bottom"></i>
        <span>Bottom</span>
      </button>

      <button class="btn btn-ghost-secondary btn-sm" @click="value = 'stretch'" :class="{ active: value === 'stretch' }">
        <i class="tio-square-outlined"></i>
        <span>Stretch</span>
      </button>
    </div>
  </settings-template>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "SettingsAlign",
  props: {
    title: {
      type: String,
      default: "Align"
    }
  },
  mixins: [appearanceChangerMixin],
  components: { SettingsTemplate },
  computed: {
    value: {
      get() {
        return this.getStyleVariableValue(this.env, this.variable, 0)
      },
      set(value) {
        this.setStyleVariableValue({
          env: this.env,
          variable: this.variable,
          value: value
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

button i {
  font-size: 2rem;
}

button span {
  margin-top: 0.25rem;
}
</style>
