<template>
  <div>
    <h4 class="mb-4">Border Radius</h4>
    <settings-four-direction-base
      label1="Top Left"
      label2="Top Right"
      label3="Bottom Right"
      label4="Bottom Left"
      :variable="variable"
      :odd-units="['px', '%']"
      :even-units="['px', '%']"
    />
  </div>
</template>
<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsFourDirectionBase from "@/components/campaign-builder/settings-elements/SettingsFourDirectionBase"

export default {
  name: "SettingsBorderRadius",
  props: {
    variable: {
      type: String,
      required: true
    },
    layout: {
      type: String,
      default: "accordion"
    },
    title: {
      type: String,
      default: "Radius"
    }
  },
  components: { SettingsFourDirectionBase },
  mixins: [appearanceChangerMixin]
}
</script>
