<template>
  <base-campaign-targeting-rule>
    <div>Visitors from: {{ data }}</div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("who", targetingRuleNames.whoRules.location)

export default {
  name: "TargetingLocation",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin],
  computed: {}
}
</script>
