<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div class="d-flex mb-2" v-for="(ipData, key) in value" :key="key">
      <div class="row flex-grow-1">
        <div class="col">
          <select class="form-control form-control-sm" :value="ipData.type" @change="(e) => setType(key, e.target.value)">
            <option value="exact">Specific IP address</option>
            <option value="range">Within IP range</option>
          </select>
        </div>
        <div class="col">
          <div v-if="ipData.type === 'exact'">
            <input class="form-control form-control-sm" :value="ipData.value" @input="(e) => setValue(key, 'value', e.target.value)" />
          </div>
          <div class="d-flex" v-if="ipData.type === 'range'">
            <input class="form-control form-control-sm mr-4" :value="ipData.from" @input="(e) => setValue(key, 'from', e.target.value)" />
            <input class="form-control form-control-sm" :value="ipData.to" @input="(e) => setValue(key, 'to', e.target.value)" />
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 d-flex justify-content-between align-items-center ml-6" style="width: 7rem">
        <small class="mr-1 font-weight-bold">OR</small>
        <button type="button" class="btn btn-icon btn-soft-primary btn-sm mr-1" v-if="key === value.length - 1" @click="addRow">
          <i class="tio-add"></i>
        </button>
        <button type="button" class="btn btn-icon btn-soft-danger btn-sm" @click="removeRow(key)" v-if="value.length > 1">
          <i class="tio-remove-from-trash"></i>
        </button>
      </div>
    </div>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"

const mixin = targetingRuleModalMixin("who", targetingRuleNames.whoRules.ipAllowed)

export default {
  name: "IpAllowedModal",
  components: { ModalCampaignTargetingRule },
  mixins: [mixin],
  methods: {
    setType(key, value) {
      let obj = {}
      if (value === "exact") {
        obj.value = "0.0.0.0"
      } else if (value === "range") {
        obj.from = "0.0.0.0"
        obj.to = "8.8.8.8"
      }
      this.value[key] = { type: value, ...obj }
      this.value = [...this.value]
    },
    setValue(key, type, value) {
      this.value[key][type] = value
      this.value = [...this.value]
    },
    addRow() {
      this.value.push({ type: "exact", value: "0.0.0.0" })
      this.value = [...this.value]
    },
    removeRow(key) {
      let tmp = this.value
      tmp.splice(key, 1)
      this.value = [...tmp]
    }
  }
}
</script>
