<template>
  <div class="">
    <div class="d-inline-flex align-items-center">
      <div class="dropdown resolution-device-selector">
        <button
          class="btn btn-sm btn-white dropdown-toggle btn-resolution-device-selector"
          type="button"
          id="device-selector"
          data-toggle="dropdown"
        >
          <span style="width: 8rem" class="text-left font-weight-bolder" v-show="device === 'desktop'">
            <i class="tio-monitor dropdown-item-icon"></i> Desktop
          </span>
          <span style="width: 8rem" class="text-left font-weight-bolder" v-show="device === 'mobile'">
            <i class="tio-android-phone dropdown-item-icon"></i> Mobile
          </span>
        </button>
        <div class="dropdown-menu" aria-labelledby="device-selector">
          <a class="dropdown-item" href="javascript:;" data-type="desktop"> <i class="tio-monitor dropdown-item-icon"></i> Desktop </a>
          <a class="dropdown-item" href="javascript:;" data-type="mobile"> <i class="tio-android-phone dropdown-item-icon"></i> Mobile </a>
        </div>
      </div>

      <div class="ml-2">
        <div class="dropdown desktop-selector" v-show="device === 'desktop'">
          <button
            class="btn btn-sm btn-white dropdown-toggle btn-desktop-selector"
            type="button"
            id="desktop-selector"
            data-toggle="dropdown"
          >
            <span style="width: 9rem" class="text-left font-weight-bolder">Laptop <span class="ml-2 font-weight-normal">(1366x768)</span> </span>
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="javascript:;" data-resolution="1024-640">Min Desktop <span class="ml-2 font-weight-normal">(1024x640)</span></a>
            <a class="dropdown-item" href="javascript:;" data-resolution="1366-768">Laptop <span class="ml-2 font-weight-normal">(1366x768)</span> </a>
            <a class="dropdown-item" href="javascript:;" data-resolution="1440-900">Macbook <span class="ml-2 font-weight-normal">(1440x900)</span></a>
            <a class="dropdown-item" href="javascript:;" data-resolution="1920-1080">Full HD <span class="ml-2 font-weight-normal">(1920x1080)</span> </a>
          </div>
        </div>

        <div class="dropdown mobile-selector" v-show="device === 'mobile'">
          <button
            class="btn btn-sm btn-white dropdown-toggle btn-mobile-selector"
            type="button"
            id="mobile-selector"
            data-toggle="dropdown"
          >
            <span style="width: 9rem" class="text-left font-weight-bolder">iPhone 6/7/8 <span class="ml-2 font-weight-normal">(375x667)</span></span>
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="javascript:;" data-resolution="375-667">iPhone 6/7/8 <span class="ml-2 font-weight-normal">(375x667)</span></a>
            <a class="dropdown-item" href="javascript:;" data-resolution="414-896">iPhone Max <span class="ml-2 font-weight-normal">(414x896)</span></a>
            <a class="dropdown-item" href="javascript:;" data-resolution="360-760">Galaxy S10 <span class="ml-2 font-weight-normal">(360x760)</span></a>
            <a class="dropdown-item" href="javascript:;" data-resolution="412-892">Galaxy A50 <span class="ml-2 font-weight-normal">(412x892)</span></a>
            <a class="dropdown-item" href="javascript:;" data-resolution="768-1024">iPad <span class="ml-2 font-weight-normal">(768x1024)</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex"

export default {
  name: "ResolutionSelector",
  data() {
    return {
      device: "desktop",
      selectedMobileDevice: "375-667",
      selectedDesktopDevice: "1366-768"
    }
  },
  computed: mapState("builder", ["previewDevice"]),
  watch: {
    previewDevice(newVal) {
      const str = `${newVal.width}-${newVal.height}`
      if (
        (newVal.type === "desktop" && this.device === "desktop" && this.selectedDesktopDevice === str) ||
        (newVal.type === "mobile" && this.device === "mobile" && this.selectedMobileDevice === str)
      ) {
        return
      }

      this.device = newVal.type
      this.handleDeviceChange()
    }
  },
  methods: {
    ...mapMutations("builder", ["setPreviewDevice"]),
    handleDeviceChange() {
      const resolution = this.device === "desktop" ? this.selectedDesktopDevice.split("-") : this.selectedMobileDevice.split("-")

      const [width, height] = resolution

      this.setPreviewDevice({
        type: this.device,
        width: width,
        height: height
      })
    }
  },
  mounted() {
    const self = this
    $(".dropdown.resolution-device-selector a").click(function () {
      self.device = $(this)[0].dataset.type
      self.handleDeviceChange()
    })

    $(".dropdown.desktop-selector a").click(function () {
      $(".btn-desktop-selector span").html($(this).html())
      self.selectedDesktopDevice = $(this)[0].dataset.resolution
      self.handleDeviceChange()
    })

    $(".dropdown.mobile-selector a").click(function () {
      $(".btn-mobile-selector span").html($(this).html())
      self.selectedMobileDevice = $(this)[0].dataset.resolution
      self.handleDeviceChange()
    })
  }
}
</script>
