<template>
  <settings-template :title="title" :layout="layout" :show-device-picker="showDevicePicker">
    <select class="form-control form-control-sm" v-model="value">
      <option value="auto">Auto</option>
      <option value="visible">Visible</option>
      <option value="hidden">Hidden</option>
      <option value="scroll">Scroll</option>
    </select>
  </settings-template>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "SettingsOverflow",
  props: {
    variable: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "Overflow"
    }
  },
  mixins: [appearanceChangerMixin],
  components: { SettingsTemplate },
  computed: {
    value: {
      get() {
        return this.getStyleVariableValue(this.env, this.variable, 0)
      },
      set(value) {
        this.setStyleVariableValue({
          env: this.env,
          variable: this.variable,
          value: value
        })
      }
    }
  }
}
</script>
