<template>
  <base-campaign-targeting-rule>
    <div>
      <div v-for="(sentence, key) in getDescription" :key="key">
        <div style="line-height: 1.2">{{ sentence }}</div>
        <strong v-if="data.length - 1 > key">OR</strong>
      </div>
    </div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import { mapState } from "vuex"
import stringCompareOperators from "@/components/campaign-targeting-rules/stringCompareOperators"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("who", targetingRuleNames.whoRules.excludeByVisit)

export default {
  name: "TargetingExcludeByVisited",
  components: {
    BaseCampaignTargetingRule
  },
  mixins: [mixin],
  computed: {
    ...mapState("sites", ["selectedSite"]),
    getDescription() {
      return this.data.map((row) => {
        return `Excluded if one of the visited url is
          ${stringCompareOperators[row.filter].toLowerCase()}: ${this.showDomain(row.filter) ? this.selectedSite.domain + "/" : ""}${
          row.value
        }
          Blocking time: ${parseInt(row.time) === 0 ? "forever" : row.time / 1000 + " seconds"}
          `
      })
    }
  },
  methods: {
    showDomain(filter) {
      return filter === "equals" || filter === "notEquals" || filter === "startsWith" || filter === "notStartsWith"
    }
  }
}
</script>
