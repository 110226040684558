import Vue from "vue"

const formMixin = {
  data() {
    return {
      initFormOnMount: true
    }
  },
  methods: {
    initForm() {
      require("@/theme-files/hs.validation")
      const HSTogglePassword = require("@/theme-files/hs-toggle-password")
      const el = this.$refs.PARENTELEMENT ? this.$refs.PARENTELEMENT : this.$el

      el.querySelectorAll(".js-toggle-password").forEach((item) => {
        new HSTogglePassword(item).init()
      })

      el.querySelectorAll(".js-validate").forEach((item) => {
        $.HSCore.components.HSValidation.init($(item), {
          rules: {
            confirmPassword: {
              equalTo: "#signupSrPassword"
            }
          }
        })
      })
    }
  },
  mounted() {
    if (this.initFormOnMount) {
      Vue.nextTick().then(() => {
        this.initForm()
      })
    }
  }
}
export default formMixin
