<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div class="mr-4 flex-shrink-0"><strong>Show campaign if sources:</strong></div>
    <div class="d-flex mt-3">
      <checkbox
        class="mr-3"
        style="margin-top: 0 !important; margin-bottom: 0 !important"
        v-for="(sourceName, source) in sourceNames"
        :value="nameSources.filter((s) => s.value === source).length > 0"
        :key="source"
        @input="(checked) => setByName(source, checked)"
      >
        {{ sourceName }}
      </checkbox>
    </div>
    <div class="mt-2">
      <span>
        Custom sources
        <div :key="key" v-for="(source, key) in fullPathSources">
          <div class="d-flex align-items-center mt-2">
            <span class="flex-shrink-0 mr-3">Page url(s)</span>
            <targeting-string-compare-operator-selector
              class="mr-3"
              :value="source.filter"
              @change="(e) => updateCustomSource(key, 'filter', e.target.value)"
            ></targeting-string-compare-operator-selector>
            <input
              class="form-control form-control-sm mr-3"
              :value="source.value"
              @input="(e) => updateCustomSource(key, 'value', e.target.value)"
            />
            <button type="button" class="btn btn-icon btn-soft-danger btn-sm" @click="removeCustomSource(key)">
              <i class="tio-remove-from-trash"></i>
            </button>
          </div>
        </div>
      </span>
      <div class="mt-3 text-center">
        <button type="button" class="btn btn-icon btn-soft-primary btn-sm" @click="addCustomSource"><i class="tio-add"></i></button>
      </div>
    </div>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"
import stringCompareOperators from "@/components/campaign-targeting-rules/stringCompareOperators"
import TargetingStringCompareOperatorSelector from "@/components/campaign-targeting-rules/TargetingStringCompareOperatorSelector"
import Checkbox from "@/components/common/Checkbox"

const mixin = targetingRuleModalMixin("who", targetingRuleNames.whoRules.source)
const sourceNames = {
  direct: "Direct",
  googleAds: "Google Ads",
  googleOrganic: "Google Organic",
  facebookAds: "Facebook Ads",
  facebookOrganic: "Facebook Organic"
}
export default {
  name: "SourceModal",
  components: { Checkbox, TargetingStringCompareOperatorSelector, ModalCampaignTargetingRule },
  mixins: [mixin],
  data: () => ({
    sourceNames: sourceNames,
    stringCompareOperators: stringCompareOperators
  }),
  computed: {
    nameSources() {
      return this.value.filter((d) => d.type === "name")
    },
    fullPathSources() {
      return this.value.filter((d) => d.type === "fullPath")
    }
  },
  methods: {
    setByName(value, checked) {
      const filtered = this.value.filter((d) => d.type === "name" && d.value === value)
      if (checked && filtered.length === 0) {
        this.value.push({
          type: "name",
          filter: "equals",
          value: value
        })
      } else if (!checked && filtered.length > 0) {
        this.value = this.value.filter((source) => {
          return !(source.type === "name" && source.value === value)
        })
      }
      this.value = [...this.value]
    },
    addCustomSource() {
      this.value.push({ type: "fullPath", value: "", filter: "contains" })
      this.value = [...this.value]
    },
    getFullListIndexOfCustomSource(index) {
      let i = -1
      let fullListIndex = null
      for (let fi = 0; fi < this.value.length; fi++) {
        const source = this.value[fi]
        if (source.type === "fullPath") i++
        if (i === index) {
          fullListIndex = fi
          break
        }
      }
      return fullListIndex
    },
    removeCustomSource(index) {
      this.value.splice(this.getFullListIndexOfCustomSource(index), 1)
      this.value = [...this.value]
    },
    updateCustomSource(index, key, value) {
      this.value[this.getFullListIndexOfCustomSource(index)][key] = value
      this.value = [...this.value]
    }
  }
}
</script>
