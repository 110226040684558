const fontList = [
  "Nunito",
  "Roboto",
  "Poppins",
  "Raleway",
  "Open Sans",
  "Lato",
  "Montserrat",
  "Source Sans Pro",
  "Noto Sans",
  "PT Sans",
  "Ubuntu",
  "Fira Sans",
  "Titillium Web",
  "Ephesis",
  "Oxygen"
]
const getImportUrl = (fonts) => {
  let text = "https://fonts.googleapis.com/css2?"
  fonts.forEach((font) => {
    text += `family=${font}:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&`
  })
  text += "display=swap"
  return encodeURI(text)
}
module.exports = {
  fontList,
  getImportUrl
}
