<template>
  <main id="content" role="main" class="main">
    <div class="position-fixed top-0 right-0 left-0 bg-img-hero" style="height: 32rem" :style="`background-image: url(${abstractBG4})`">
      <figure class="position-absolute right-0 bottom-0 left-0">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
          <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
        </svg>
      </figure>
    </div>
    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-5" href="javascript:;">
        <h1 class="text-primary display-3 z-index-2">OSM</h1>
        <!--        <icon-osm class="z-index-2" alt="Image Description" style="width: 4rem" />-->
      </a>
      <div class="row justify-content-center">
        <div class="col-md-7 col-lg-5">
          <div class="card card-lg mb-5">
            <div class="card-body">
              <slot />
              <h5 v-if="authError" class="text-danger mt-3 text-center">{{ authError }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// import IconOsm from "@/icons/icon-osm.svg?inline"
import abstractBG4 from "@/theme-files/assets/svg/components/abstract-bg-4.svg"
import { mapState } from "vuex"

export default {
  name: "AuthLayout",
  // components: { IconOsm },
  computed: {
    ...mapState("user", ["authError"]),
    abstractBG4() {
      return abstractBG4
    }
  }
}
</script>
<style>
body {
}
</style>
