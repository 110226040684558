<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div class="d-flex align-items-center">
      <span class="mr-2">Number of visited pages</span>
      <select class="form-control form-control-sm w-auto mr-2" :value="value.filter" @change="(e) => setData('filter', e.target.value)">
        <option value="maximum">Maximum</option>
        <option value="minimum">Minimum</option>
      </select>
      <input
        class="form-control form-control-sm text-center"
        style="width: 6rem"
        type="number"
        :value="value.value"
        @input="(e) => setData('value', e.target.value)"
      />
    </div>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"

const mixin = targetingRuleModalMixin("who", targetingRuleNames.whoRules.numberOfVisitedPages)

export default {
  name: "NumberOfVisitedPagesModal",
  components: { ModalCampaignTargetingRule },
  mixins: [mixin],
  methods: {
    setData(key, value) {
      this.value[key] = value
      this.value = { ...this.value }
    }
  }
}
</script>
