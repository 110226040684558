<template>
  <div class="rounded text-left d-flex align-items-center">
    <integration-img class="my-0 mr-3" style="width: 2.5rem; height: 2.5rem" :integration-type="integrationType"></integration-img>
    <h5 class="my-0">{{ integrationName }}</h5>
  </div>
</template>

<script>
import IntegrationImg from "@/components/common/IntegrationImg"
export default {
  name: "CampaignSummarySettingsIntegrationItem",
  components: { IntegrationImg },
  props: ["integrationType", "integrationName"]
}
</script>

<style scoped></style>
