<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div class="d-flex align-items-center">
      <span class="mr-4 flex-shrink-0">Show campaign if visitor uses one of</span>
      <checkbox
        class="ml-3"
        style="margin-top: 0 !important; margin-bottom: 0 !important"
        v-for="(deviceName, device) in deviceMap"
        :value="value.includes(device)"
        :key="device"
        @input="(checked) => setValue(device, checked)"
      >
        {{ deviceName }}
      </checkbox>
    </div>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"
import Checkbox from "@/components/common/Checkbox"

const mixin = targetingRuleModalMixin("who", targetingRuleNames.whoRules.device)
const deviceMap = {
  desktop: "Desktop",
  mobile: "Mobile",
  tablet: "Tablet",
  tv: "TV"
}
export default {
  name: "TargetingDeviceModal",
  components: { ModalCampaignTargetingRule, Checkbox },
  mixins: [mixin],
  data() {
    return {
      deviceMap: deviceMap
    }
  },
  methods: {
    setValue(device, checked) {
      if (checked && !this.value.includes(device)) this.value.push(device)
      if (!checked && this.value.includes(device)) this.value = this.value.filter((d) => d !== device)
    }
  }
}
</script>
