<template>
  <div class="card mb-3 mb-lg-5">
    <modal-campaign-schedule v-if="showScheduleModal" :schedule="schedule" @close="showScheduleModal = false" @submit="handleSubmit" />
    <div class="card-header">
      <h3 class="mr-4 mb-0">Schedule</h3>
      <div>
        <button class="btn btn-soft-primary btn-sm" @click="showScheduleModal = true">Set Schedule</button>
        <button class="btn btn-soft-danger ml-2 btn-sm" v-if="!isScheduleEmpty" @click="removeSchedule">Remove</button>
      </div>
    </div>
    <div class="card-body">
      <div v-if="isScheduleEmpty"><span class="form-control border-0 pl-0">You have not set a schedule.</span></div>
      <div v-else>
        <div class="mb-2 row" v-if="schedule.from">
          <div class="col-auto" style="width: 10rem">
            <h5 class="mb-0">Start date:</h5>
          </div>
          <div class="col-auto">
            <span>{{ formatDateToShow(schedule.from) }}</span>
          </div>
        </div>
        <div class="mb-2 row" v-if="schedule.to">
          <div class="col-auto" style="width: 10rem">
            <h5 class="mb-0">End date:</h5>
          </div>
          <div class="col-auto">
            <span>{{ formatDateToShow(schedule.to) }}</span>
          </div>
        </div>
        <div class="row" v-if="schedule.dailySchedule">
          <div class="col-auto" style="width: 10rem">
            <h5 class="mb-0">Daily Schedule:</h5>
          </div>
          <div class="col-auto">
            <div class="mb-1" v-for="(item, key) in schedule.dailySchedule" :key="key">
              {{ dayList[item.dayOfWeek] }}@{{ item.startHours }}:{{ item.startMinutes }} - {{ item.endHours }}:{{ item.endMinutes }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCampaignSchedule from "@/components/modals/ModalCampaignSchedule"
import { mapMutations, mapState } from "vuex"

export default {
  name: "CampaignSettingsSchedule",
  components: { ModalCampaignSchedule },
  data() {
    return {
      showScheduleModal: false,
      dayList: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    }
  },
  computed: {
    ...mapState("campaignSettings", ["schedule"]),
    isScheduleEmpty() {
      return this.schedule.from === null && this.schedule.to === null && this.schedule.dailySchedule === null
    }
  },
  methods: {
    ...mapMutations("campaignSettings", ["setSchedule"]),
    formatDateToShow(date) {
      return date.replace("T", " ")
    },
    handleSubmit(schedule) {
      this.setSchedule(schedule)
      this.showScheduleModal = false
    },
    removeSchedule() {
      this.setSchedule({ from: null, to: null, dailySchedule: null })
    }
  },
  created() {
    if (this.schedule === null) {
      this.setSchedule({ from: null, to: null, dailySchedule: null })
    }
  }
}
</script>
