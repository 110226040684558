<template>
  <button :disabled="disabled" class="my-2 text-left btn btn-soft-primary d-flex align-items-center justify-content-between w-100 font-weight-bold" @click="$emit('click', $event)">
    <div>
      <i :class="`tio-${icon} mr-2`"></i>
      <slot></slot>
    </div>
    <i class="tio-chevron-right"></i>
  </button>
</template>

<script>
export default {
  name: "SettingsSelectorButton",
  props: {
    disabled: {
      default: false
    },
    icon: {
      default: "alarm"
    }
  }
}
</script>
<style lang="scss" scoped>
.border-button:disabled {
  @apply bg-red-300 cursor-not-allowed;
}
</style>
