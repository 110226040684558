<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h4 class="mb-0">Float Images</h4>
      <button v-if="!allowOnlyChange" @click="handleAddImageClick" class="btn btn-ghost-primary">
        <i class="tio-add mr-1"></i>
        Add Float Image
      </button>
    </div>

    <modal-gallery v-if="showModalGallery" @imageSelected="handleImageSelected" @close="showModalGallery = false" />
    <div v-for="({ image, manager }, index) in images" :key="index" class="mb-4">
      <visual-asset-card
        :thumbnail-src="image.src"
        :id="image.id"
        :removable="!allowOnlyChange"
        :changeable="true"
        @change="handleChangeImageClick(index)"
        @remove="removeImage(index)"
      >
        <template #edit-body>
          <float-image-appearance :manager="manager" />
        </template>
      </visual-asset-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import ModalGallery from "@/components/modals/ModalGallery"
import FloatImageAppearance from "@/components/campaign-builder/widget-settings/FloatImageAppearance"
import VisualAssetCard from "@/components/campaign-builder/widget-settings/VisualAssetCard"
import FloatImageManager from "@/lib/marketing-widgets/float-image-manager"
import randomId from "@/lib/utils/random-id"
import { HISTORY_TYPES } from "@/store/builder-history"

export default {
  name: "FloatImages",
  components: { VisualAssetCard, FloatImageAppearance, ModalGallery },
  props: {
    imagesClass: {
      required: true,
      type: String
    },
    allowOnlyChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      images: [],
      showModalGallery: false,
      galleryAction: null,
      changeImageIndex: null
    }
  },
  computed: {
    ...mapGetters("builder", ["getIframeDocument", "getPageElement"]),
    ...mapState("builder", ["currentPage"]),
    ...mapState("builderHistory", ["history"])
  },
  watch: {
    history() {
      this.fillDataImages()
    }
  },
  methods: {
    fillDataImages() {
      this.images = []
      Array.from(this.getPageElement.querySelectorAll(".images picture")).forEach((picture) => {
        this.images.push({
          image: picture.querySelector("img"),
          manager: new FloatImageManager(picture.querySelector("img"))
        })
      })
    },
    handleChangeImageClick(index) {
      this.galleryAction = "change"
      this.changeImageIndex = index
      this.showModalGallery = true
    },
    handleAddImageClick() {
      this.galleryAction = "addNew"
      this.showModalGallery = true
    },
    handleImageSelected(imageUrl) {
      this.showModalGallery = false
      if (this.galleryAction === "addNew") {
        const picture = this.getIframeDocument.createElement("picture")
        picture.id = randomId()
        picture.classList.add("osm-picture")
        this.getPageElement.querySelector(".images").appendChild(picture)

        const source = this.getIframeDocument.createElement("source")
        source.setAttribute("srcset", imageUrl)
        picture.appendChild(source)

        const img = this.getIframeDocument.createElement("img")
        img.setAttribute("src", imageUrl)
        img.setAttribute("id", randomId())
        picture.appendChild(img)
        this.fillDataImages()

        this.$store.dispatch("builderHistory/addToHistory", {
          type: HISTORY_TYPES.ADD_FLOAT_IMAGE,
          data: {
            selector: "#" + picture.id,
            html: picture.outerHTML,
            pageNumber: this.$store.state.builder.currentPage
          }
        })
      } else if (this.galleryAction === "change") {
        // const image = this.images[this.changeImageIndex]
        console.log("change is not implemented")
        // Object.values(this.getIframeDocuments).forEach((doc) => {
        //   const owner = doc.querySelector(`${this.getPageSelector} .${this.imagesClass}`)
        //   const img = owner.querySelectorAll("img")[this.changeImageId]
        //   img.src = imageUrl
        // })
      }
      this.fillDataImages()
    },
    removeImage(index) {
      const picture = this.images[index].image.closest("picture")

      this.$store.dispatch("builderHistory/addToHistory", {
        type: HISTORY_TYPES.REMOVE_FLOAT_IMAGE,
        data: {
          selector: "#" + picture.id,
          html: picture.outerHTML,
          pageNumber: this.$store.state.builder.currentPage
        }
      })

      picture.remove()
      this.fillDataImages()
    }
  },

  mounted() {
    this.fillDataImages()
  }
}
</script>
