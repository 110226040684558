<template>
  <portal to="sidepreview">
    <div
      style="position: fixed; top: 0; left: 0; z-index: 102; width: 100vw; height: 100vh; background-color: rgba(19, 33, 68, 0.25)"
    ></div>
    <div class="modal show" tabindex="-1" @click.self="handleClose" style="display: block">
      <div class="modal-dialog" id="sidepreview" style="max-height: 100%">
        <div
          class="modal-content overflow-auto"
          style="border-radius: 0; min-height: 100vh !important; max-height: 100vh !important; border: 0; width: 100%"
        >
          <div class="modal-header border-bottom py-4 align-items-start">
            <h2 class="modal-title">{{ template.name }}</h2>
            <button type="button" class="btn btn-icon btn-xs btn-ghost-secondary" @click="handleClose">
              <i class="tio-clear tio-lg"></i>
            </button>
          </div>
          <div class="modal-body d-flex flex-column">
            <div class="row flex-fill">
              <div class="d-flex flex-column col-9">
                <div class="d-flex justify-content-between mb-3">
                  <div>
                    <div class="nav nav-segment nav-pills">
                      <a
                        v-for="(pageName, pageNumber) in pageMap"
                        :key="pageNumber"
                        class="nav-link"
                        :class="{ active: pageNumber === currentPage }"
                        style="cursor: pointer"
                        @click="changePage(pageNumber)"
                      >
                        {{ pageName }}
                      </a>
                    </div>
                  </div>
                  <div class="nav nav-segment nav-pills">
                    <a
                      class="nav-link mr-2"
                      style="cursor: pointer"
                      :class="{ active: showingDevice === 'desktop' }"
                      @click="showingDevice = 'desktop'"
                    >
                      <i class="tio-monitor mr-2"></i>
                      Desktop
                    </a>
                    <a
                      class="nav-link"
                      style="cursor: pointer"
                      :class="{ active: showingDevice === 'mobile' }"
                      @click="showingDevice = 'mobile'"
                    >
                      <i class="tio-android-phone mr-2"></i> Mobile
                    </a>
                  </div>
                </div>
                <div class="w-100 d-flex align-items-center justify-center-center" style="height: 100%">
                  <div :class="{ 'mobile-device': showingDevice === 'mobile', 'desktop-device': showingDevice === 'desktop' }">
                    <div class="url-area" v-if="showingDevice === 'desktop'">{{ selectedSite.domain }}</div>

                    <div class="content" style="width: 100%; height: 100%">
                      <iframe
                        ref="iframe"
                        @load="handleIframeLoad"
                        :srcdoc="iframeContent"
                        style="background: transparent; border: 0; width: 100%; height: 100%"
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <button class="btn btn-block btn-primary font-weight-bold" @click="handleUseTemplate">USE TEMPLATE</button>

                <div class="mt-8">
                  <h3 class="mb-3" v-if="tabs.length > 0">This template can help you...</h3>
                  <component v-for="tab in tabs" :is="tab" :key="tab" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import { mapState } from "vuex"
import campaignAPI from "@/api/campaign"
import IncreaseConversion from "@/components/campaign-template-tabs/IncreaseConversion"
import BoostSales from "@/components/campaign-template-tabs/BoostSales"
import GetFeedback from "@/components/campaign-template-tabs/GetFeedback"
export default {
  name: "SidePreview",
  props: {
    template: Object
  },
  components: {
    IncreaseConversion,
    BoostSales,
    GetFeedback
  },
  data() {
    return {
      showingDevice: "desktop",
      iframeContent: "",
      pageMap: {},
      currentPage: "1"
    }
  },
  computed: {
    ...mapState("sites", ["selectedSite"]),
    tabs() {
      return this.template.tags.filter((tag) => tag.type === "description_tab").map((tag) => tag.value)
    }
  },
  methods: {
    async handleUseTemplate() {
      const newCampaignRequest = await campaignAPI.createCampaignByCampaignTemplate({
        siteId: this.selectedSite.id,
        name: this.template.name,
        campaignTemplateId: this.template.id
      })
      const newCampaign = newCampaignRequest.data.data.campaign
      return this.$router.push({
        name: "CampaignBuilder",
        query: { new: true },
        params: { site: newCampaign.site_id, campaign: newCampaign.id, variant: newCampaign.variants[0].id }
      })
    },
    handleIframeLoad() {
      const pages = this.$refs.iframe.contentWindow.document.querySelectorAll(".page")
      pages.forEach((page) => {
        this.pageMap[page.dataset.page] = page.dataset.pageName ?? "Page " + page.dataset.page
      })
      this.pageMap = { ...this.pageMap }
    },
    changePage(pageNumber) {
      this.currentPage = pageNumber
      this.$refs.iframe.contentWindow.dispatchEvent(
        new CustomEvent("dashboardReload", {
          detail: {
            page: this.currentPage
          }
        })
      )
    },
    handleClose() {
      document.querySelector("#sidepreview").style.right = "-90vw"
      setTimeout(() => {
        this.$emit("close")
      }, 200)
    }
  },
  async mounted() {
    console.log(this.template)
    // const docWidth = document.body.offsetWidth
    // const docHeight = document.body.offsetHeight
    // let scale = 1
    // if (docHeight < 750) {
    //   scale = docHeight / 750
    // }

    document.body.classList.add("multi-purpose-open")
    this.iframeContent = await (await fetch(this.template.access_url)).text()
    setTimeout(() => {
      document.querySelector("#sidepreview").style.right = 0
    }, 0)
  },
  beforeDestroy() {
    document.body.classList.remove("multi-purpose-open")
  }
}
</script>

<style lang="scss">
#sidepreview {
  transition: all 200ms;
  min-width: 90vw;
  max-width: 90vw;
  margin-right: 0;
  right: -90vw;
  margin-top: 0;
  margin-bottom: 0;

  .desktop-device {
    min-height: 600px;
    height: 100%;
    width: 100%;
    border-top: 3em solid rgba(230, 230, 230, 0.7);
    box-shadow: 0 0.1em 1em 0 rgba(0, 0, 0, 0.4);
    position: relative;
    border-radius: 3px 3px 0 0;
  }

  .desktop-device:before {
    display: block;
    position: absolute;
    content: "";
    top: -1.7em;
    left: 1em;
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background-color: #f44;
    box-shadow: 0 0 0 2.5px #f44, 1.5em 0 0 2.5px #9b3, 3em 0 0 2.5px #fb5;
  }

  .desktop-device .url-area {
    display: block;
    position: absolute;
    content: "";
    top: -2.5em;
    left: 6.5em;
    width: calc(100% - 7.5em);
    line-height: 2em;
    padding-left: 1rem;
    height: 2em;
    border-radius: 2px;
    background-color: white;
  }

  .desktop-device > * {
    display: block;
  }
}

/* The device with borders */
.mobile-device {
  position: relative;
  width: 414px;
  height: 736px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.mobile-device:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.mobile-device:after {
  content: "";
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}
</style>
