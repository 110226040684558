<template>
  <div>
    <dashboard-title title="Statistics">
      <template #right>
        <a
          class="mr-3 btn btn-white dropdown-toggle collapsed"
          data-toggle="collapse"
          href="#filterSearchCollapse"
          role="button"
          aria-expanded="false"
          aria-controls="filterSearchCollapse"
        >
          <i class="mr-1 tio-filter-outlined"></i> Filters
        </a>
        <button class="btn btn-white" @click="exportCsv"><i class="mr-1 tio-download-to"></i> Export</button>
      </template>
      <template #bottom>
        <div class="mt-2 collapse card show" id="filterSearchCollapse">
          <div class="card-body">
            <form>
              <div class="d-flex align-items-center">
                <label for="filterCampaigns" class="mb-0 input-label" style="width: 7rem">Campaigns:</label>
                <div class="row flex-fill align-items-center">
                  <div class="col-3">
                    <select
                      id="filterCampaignStatus"
                      class="flex-shrink-0 js-select2-custom custom-select"
                      size="1"
                      style="opacity: 0"
                      data-hs-select2-options='{  "minimumResultsForSearch": "Infinity", "dropdownAutoWidth": true}'
                    >
                      <option value="all">Active & Passives</option>
                      <option value="active">Actives</option>
                      <option value="passive">Passives</option>
                    </select>
                  </div>
                  <div class="col-9">
                    <select
                      id="filterCampaigns"
                      class="js-select2-custom js-datatable-filter custom-select"
                      size="1"
                      style="opacity: 0"
                      multiple
                      data-hs-select2-options='{"minimumResultsForSearch": "Infinity","placeholder": "All"}'
                    >
                      <option
                        v-for="campaign in campaignList"
                        :key="campaign.id"
                        :value="campaign.id"
                        :selected="filterCampaignIds.includes(campaign.id)"
                      >
                        {{ campaign.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="my-3 d-flex align-items-center">
                <label for="filterDevice" class="flex-shrink-0 mb-0 input-label" style="width: 7rem">Device:</label>
                <select
                  id="filterDevice"
                  class="flex-shrink-0 js-select2-custom custom-select device-selector"
                  size="1"
                  style="opacity: 0"
                  data-hs-select2-options='{  "minimumResultsForSearch": "Infinity", "dropdownAutoWidth": true}'
                >
                  <option value="all">All Devices</option>
                  <option value="desktop">Desktop</option>
                  <option value="mobile">Mobile</option>
                  <option value="tablet">Tablet</option>
                </select>
              </div>
              <div class="d-flex align-items-center">
                <label class="flex-shrink-0 mb-0 input-label" style="width: 7rem">Date:</label>
                <date-range-picker @change="handleDatePickerChange" />
              </div>
            </form>
          </div>
        </div>
      </template>
    </dashboard-title>
    <loading v-if="loading" />
    <div v-else>
      <div class="row gx-2 gx-lg-3">
        <div class="mb-3 col-sm-12 col-lg-2 mb-lg-5">
          <statistics-mini-card title="Impressions" :key="contentKey" :dataset="dailyMetrics['impression']" />
        </div>
        <div class="mb-3 col-sm-12 col-lg-2 mb-lg-5">
          <statistics-mini-card title="Lead (Submit)" :key="contentKey" :dataset="dailyMetrics['submit']" />
        </div>
        <div class="mb-3 col-sm-12 col-lg-2 mb-lg-5">
          <statistics-mini-card title="Lead Rate" :percentage="true" :key="contentKey" :dataset="dailyMetrics['lead_rate']" />
        </div>
        <div class="mb-3 col-sm-12 col-lg-2 mb-lg-5">
          <statistics-mini-card title="Open URL" :key="contentKey" :dataset="dailyMetrics['open_url']" />
        </div>
        <div class="mb-3 col-sm-12 col-lg-2 mb-lg-5">
          <statistics-mini-card title="Whatsapp Redirection" :key="contentKey"
                                :dataset="dailyMetrics['whatsapp_redirect']" />
        </div>
        <div class="mb-3 col-sm-12 col-lg-2 mb-lg-5">
          <statistics-mini-card title="Phone Redirection" :key="contentKey" :dataset="dailyMetrics['phone_redirect']" />
        </div>
      </div>
      <statistics-line-chart :key="contentKey" :daily-metrics="dailyMetrics" />
    </div>
  </div>
</template>

<script>
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import { mapActions, mapState } from "vuex"
import advancedSelectMixin from "@/mixins/advanced-select-mixin"
import statisticsAPI from "@/api/statistics"
import StatisticsMiniCard from "@/components/dashboard/StatisticsMiniCard"
import Loading from "@/components/common/Loading"
import { saveAs } from "file-saver"
import StatisticsLineChart from "@/components/dashboard/StatisticsLineChart"
import ensureHasSite from "@/mixins/ensure-has-site"
import moment from "moment"
import DateRangePicker from "@/components/common/DateRangePicker"
import randomId from "@/lib/utils/random-id"

const Excel = require("exceljs")

export default {
  name: "Statistics",
  components: { DateRangePicker, StatisticsLineChart, Loading, StatisticsMiniCard, DashboardTitle },
  data() {
    return {
      startDate: moment().subtract(29, "days"),
      endDate: moment(),
      loading: true,
      fetchedData: null,
      device: "all",
      campaignStatus: "all",
      contentKey: 0,
      filterCampaignIds: [],
      dailyMetrics: {},
      totalMetrics: {}
    }
  },
  computed: {
    ...mapState("sites", ["selectedSite"]),
    ...mapState("campaigns", ["campaigns"]),
    campaignList() {
      if (this.campaignStatus === "active") return this.campaigns.filter((campaign) => campaign.is_active)
      if (this.campaignStatus === "passive") return this.campaigns.filter((campaign) => !campaign.is_active)
      return this.campaigns
    }
  },
  mixins: [advancedSelectMixin, ensureHasSite],
  methods: {
    ...mapActions("campaigns", ["fetchCampaignsOfCurrentSite"]),
    async fetch() {
      const resp = await statisticsAPI.fetchStatistics({
        siteId: this.selectedSite.id,
        startDate: this.startDate.format("YYYY-MM-DD"),
        endDate: this.endDate.format("YYYY-MM-DD")
      })
      this.fetchedData = resp.data.data["campaign_statistics"]
    },
    generateData() {
      this.dailyMetrics = {
        impression: {},
        whatsapp_redirect: {},
        open_url: {},
        submit: {},
        phone_redirect: {},
        lead_rate: {}
      }

      let now = this.startDate.clone()
      while (now.isSameOrBefore(this.endDate)) {
        Object.keys(this.dailyMetrics).forEach((key) => {
          this.dailyMetrics[key][now.format("YYYY-MM-DD")] = 0
        })
        now.add(1, "days")
      }

      this.fetchedData.forEach((item) => {
        if (this.device !== "all" && item["device"] !== this.device) {
          return
        }

        if (this.campaignStatus !== "all" && !this.campaignList.map((c) => c.id).includes(item["campaign_id"])) {
          return
        }

        if (this.filterCampaignIds.length > 0 && !this.filterCampaignIds.includes(item["campaign_id"])) {
          return
        }

        this.dailyMetrics[item["action_type"]][item["date"]] += item["count"]
      })

      Object.keys(this.dailyMetrics["submit"]).forEach(day => {
        console.log(this.dailyMetrics["submit"][day]);
        console.log(this.dailyMetrics["impression"][day]);
        this.dailyMetrics["lead_rate"][day] = parseFloat( (100 * this.dailyMetrics["submit"][day] / (this.dailyMetrics["impression"][day] || 1)).toFixed(2))
      })

      //
      // Object.keys(this.dailyMetrics).forEach(metric => {
      //   this.dailyMetrics[metric] = Object.keys(this.dailyMetrics[metric]).sort().reduce(
      //     (obj, key) => {
      //       obj[key] = this.dailyMetrics[metric][key]
      //       return obj
      //     },
      //     {}
      //   )
      // })
      this.contentKey += 1
    },

    async handleDatePickerChange({ startDate, endDate }) {
      this.startDate = startDate
      this.endDate = endDate
      this.loading = true
      await this.fetch()
      this.generateData()
      this.loading = false
    },
    exportCsv() {
      const workbook = new Excel.Workbook()
      const dates = Object.keys(this.dailyMetrics["impression"]).sort()

      // all campaigns worksheet
      const worksheet = workbook.addWorksheet("All", { state: "visible" })
      worksheet.mergeCells("A1:J2")
      worksheet.getCell("A1").value = `Daily Report Of All (For ${this.device} devices)`
      worksheet.getCell("A1").font = { size: 14, bold: true }
      worksheet.addRow(["Date", "Impression Count", "Submit Count", "Whatsapp Redirect Count", "Open URL Count"])

      dates.forEach((date) => {
        worksheet.addRow([
          date,
          this.dailyMetrics["impression"][date],
          this.dailyMetrics["submit"][date],
          this.dailyMetrics["whatsapp_redirect"][date],
          this.dailyMetrics["phone_redirect"][date],
          this.dailyMetrics["open_url"][date]
        ])
      })

      let campaignIds = []
      if (this.filterCampaignIds.length > 0) campaignIds = this.filterCampaignIds
      else campaignIds = this.campaignList.map((c) => c.id)
      console.log(campaignIds)

      campaignIds.forEach((campaignId) => {
        const nameExcludedChars = ["*", "?", ":", "\\", "/", "[", "]"]
        let campaignName = this.campaignList.find((c) => c.id === campaignId).name
        nameExcludedChars.forEach((char) => (campaignName = campaignName.replaceAll(char, "")))

        let worksheet = null
        try {
          worksheet = workbook.addWorksheet(`${campaignName}`, { state: "visible" })
        } catch (e) {
          worksheet = workbook.addWorksheet(`${campaignName + randomId()}`, { state: "visible" })
        }

        worksheet.mergeCells("A1:J2")
        worksheet.getCell("A1").value = `Daily Report Of ${campaignName} (For ${this.device} devices)`
        worksheet.getCell("A1").font = { size: 14, bold: true }
        worksheet.addRow(["Date", "Impression Count", "Submit Count", "Whatsapp Redirect Count", "Open Url Count"])

        const campaignStatisticData = this.fetchedData.filter(
          (item) => item.campaign_id === campaignId && !(this.device !== "all" && item["device"] !== this.device)
        )

        dates.forEach((date) => {
          const d = campaignStatisticData
            .filter((cs) => cs.date === date)
            .reduce(
              (a, cv) => {
                a[cv["action_type"]] += cv["count"]
                return a
              },
              {
                impression: 0,
                submit: 0,
                whatsapp_redirect: 0,
                phone_redirect: 0,
                open_url: 0,
                date: date
              }
            )

          worksheet.addRow([d["date"], d["impression"], d["submit"], d["whatsapp_redirect"], d["phone_redirect"], d["open_url"]])
        })
      })

      workbook.worksheets.forEach((worksheet) => (worksheet.getRow(3).font = { bold: true }))

      workbook.xlsx.writeBuffer({}).then(function(data) {
        const blob = new Blob([data], { type: "application/octet-stream" })
        saveAs(blob, "osmreport.xlsx")
      })
    }
  },
  async mounted() {
    this.initAdvancedSelect()
    require("@/theme-files/hs.daterangepicker")
    const vm = this

    $(".device-selector").on("change", function() {
      vm.device = $(this).val()
      vm.generateData()
    })
    $("#filterCampaignStatus").on("change", function() {
      vm.filterCampaignIds = []
      vm.campaignStatus = $(this).val()
      vm.generateData()
    })
    $("#filterCampaigns").on("change", function() {
      vm.filterCampaignIds = $(this)
        .val()
        .map((i) => parseInt(i))
      vm.generateData()
    })

    this.loading = true
    await this.fetchCampaignsOfCurrentSite()
    await this.fetch()
    this.generateData()
    this.loading = false
  }
}
</script>

<style scoped></style>
