<template>
<div id="app">
  <portal-target name="modal" multiple/>
  <portal-target name="sidepreview" />
  <router-view />
</div>
</template>
<script>

export default {
  created() {
    $.extend({
      HSCore: {
        init: function() {
          $(document).ready(function() {
            $("[data-toggle='tooltip']").tooltip()
            $("[data-toggle='popover']").popover()
          })
        },
        components: {}
      }
    })
    $.HSCore.init()
  }
}
</script>
<style lang="scss">
body{
  font-family: "Inter", sans-serif!important;
}
</style>
