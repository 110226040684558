<template>
  <div>
    <modal-gallery v-if="showModalGallery" @imageSelected="handleImageSelected" @close="showModalGallery = false" />

    <button class="btn btn-block border w-100 position-relative bg-soft-secondary" @click="showModalGallery = true">
      <img v-if="styles.url" style="max-width: 100%; max-height: 8rem" :src="styles.url" />
      <div v-else class="w-full" style="height: 6rem"></div>
      <div class="position-absolute d-flex align-items-center justify-content-center top-0 left-0 w-100 h-100">
        <span class="text-cap text-dark">
          <span v-if="styles.url">
            <i class="tio-edit mr-1"></i>
            Replace Image
          </span>
          <span v-else>
            <i class="tio-add mr-1"></i>
            Select Image
          </span>
        </span>
      </div>

      <button
        class="btn btn-outline-danger btn-sm btn-icon position-absolute right-0 top-0 mr-2 mt-2"
        v-if="styles.url"
        @click.stop="handleImageSelected('')"
      >
        <i class="tio-remove-from-trash"></i>
      </button>
    </button>

    <gradient-color-picker
      class="py-5 border-bottom"
      color1-title="FallbackColor 1"
      color2-title="FallbackColor 2"
      v-model="styles.fallbackColor"
    />
    <div class="pt-5">
      <h4 class="mb-4">Image Settings</h4>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label class="input-label mb-0">Repeat</label>
            <select class="form-control form-control-sm" v-model="styles.repeat">
              <option value="no-repeat">No Repeat</option>
              <option value="repeat-x">Repeat Horizontally</option>
              <option value="repeat-y">Repeat Vertically</option>
              <option value="repeat">Repeat Both</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="input-label mb-0">Position</label>
            <select class="form-control form-control-sm" v-model="position">
              <option value="stretch">Stretch</option>
              <option value="cover">Cover</option>
              <option value="contain">Contain</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label class="input-label mb-0">Horizontal Alignment</label>

            <select v-model="styles.horizontalPosition" class="form-control form-control-sm">
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="right">Right</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="input-label mb-0">Vertical Alignment</label>
            <select v-model="styles.verticalPosition" class="form-control form-control-sm">
              <option value="top">Top</option>
              <option value="center">Center</option>
              <option value="bottom">Bottom</option>
            </select>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import ModalGallery from "@/components/modals/ModalGallery"
import GradientColorPicker from "@/components/campaign-builder/appearance-changers/base/GradientColorPicker"

export default {
  name: "BackgroundImage",
  components: { GradientColorPicker, ModalGallery },
  mixins: [appearanceChangerMixin],
  data: () => ({
    showModalGallery: false,
    position: "cover",
    styles: {
      url: "",
      repeat: "",
      verticalPosition: "",
      horizontalPosition: "",
      size: "",
      fallbackColor: ""
    }
  }),
  methods: {
    handleImageSelected(url) {
      this.styles.url = url
      this.showModalGallery = false
    },
    fromBackgroundImageString(str) {
      const styles = {}
      const data = str.match(/url\(["|'](.*)["|']\) (\S*) ([^,]*), (.*)/)
      styles.url = data[1].trim()
      styles.repeat = data[2].trim()
      styles.fallbackColor = data[4]
      const [position, size] = data[3].split("/")
      const [horizontalPosition, verticalPosition] = position.split(" ")

      styles.size = size.trim()
      styles.verticalPosition = verticalPosition.trim()
      styles.horizontalPosition = horizontalPosition.trim()
      this.styles = styles
      if (styles.size === "100% 100%") {
        this.position = "stretch"
      } else {
        this.position = this.styles.size
      }
    },
    getBackgroundImageString() {
      return `url("${this.styles.url}") ${this.styles.repeat} ${this.styles.horizontalPosition} ${this.styles.verticalPosition} / ${this.styles.size}, ${this.styles.fallbackColor}`
    }
  },
  watch: {
    styles: {
      handler: function () {
        this.setStyleVariableDirect({
          env: this.env,
          variable: this.variable,
          value: this.getBackgroundImageString()
        })
      },
      deep: true
    },
    position(value) {
      if (value === "stretch") {
        this.styles.verticalPosition = "center"
        this.styles.horizontalPosition = "center"
        this.styles.size = "100% 100%"
      } else if (value === "cover") {
        this.styles.size = "cover"
      } else if (value === "contain") {
        this.styles.size = "contain"
      }
    }
  },
  created() {
    this.fromBackgroundImageString(this.getStyleVariableValueDirect(this.env, this.variable))
  }
}
</script>
