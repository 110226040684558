import Vue from "vue"
import SettingsBackgroundColor from "@/components/campaign-builder/settings-elements/SettingsBackgroundColor"
import SettingsBorder from "@/components/campaign-builder/settings-elements/SettingsBorder"
import SettingsFontSize from "@/components/campaign-builder/settings-elements/SettingsFontSize"
import SettingsPadding from "@/components/campaign-builder/settings-elements/SettingsPadding"
import SettingsBorderRadius from "@/components/campaign-builder/settings-elements/SettingsBorderRadius"
import SettingsMargin from "@/components/campaign-builder/settings-elements/SettingsMargin"
import SettingsTextColor from "@/components/campaign-builder/settings-elements/SettingsTextColor"
import SettingsHidden from "@/components/campaign-builder/settings-elements/SettingsHidden"
import SettingsFontFamily from "@/components/campaign-builder/settings-elements/SettingsFontFamily"
import SettingsVisibility from "@/components/campaign-builder/settings-elements/SettingsVisibility"
import SettingsBlur from "@/components/campaign-builder/settings-elements/SettingsBlur"
import SettingsSize from "@/components/campaign-builder/settings-elements/SettingsSize"
import SettingsColor from "@/components/campaign-builder/settings-elements/SettingsColor"
import SettingsWidth from "@/components/campaign-builder/settings-elements/SettingsWidth"
import SettingsOverflow from "@/components/campaign-builder/settings-elements/SettingsOverflow"
import SettingsFormLooking from "@/components/campaign-builder/settings-elements/SettingsFormLooking"
import SettingsTwoDirectionPosition from "@/components/campaign-builder/settings-elements/SettingsTwoDirectionPosition"
import SettingsHorizontalPosition from "@/components/campaign-builder/settings-elements/SettingsHorizontalPosition"
import SettingsBackground from "@/components/campaign-builder/settings-elements/SettingsBackground"
import SettingsHeight from "@/components/campaign-builder/settings-elements/SettingsHeight"
import SettingsFlexColumnAlignSelf from "@/components/campaign-builder/settings-elements/SettingsFlexColumnAlignSelf"
import SettingsRangeWithUnit from "@/components/campaign-builder/settings-elements/SettingsRangeWithUnit"
import SettingsJustifyContent from "@/components/campaign-builder/settings-elements/SettingsJustifyContent"
import SettingsAlign from "@/components/campaign-builder/settings-elements/SettingsAlign"
import SettingsTextStyle from "@/components/campaign-builder/settings-elements/SettingsTextStyle"
import SettingsTextAlign from "@/components/campaign-builder/settings-elements/SettingsTextAlign"

Vue.component("SettingsBackgroundColor", SettingsBackgroundColor)
Vue.component("SettingsBackground", SettingsBackground)
Vue.component("SettingsBorder", SettingsBorder)
Vue.component("SettingsBorderRadius", SettingsBorderRadius)
Vue.component("SettingsFontSize", SettingsFontSize)
Vue.component("SettingsFontFamily", SettingsFontFamily)
Vue.component("SettingsMargin", SettingsMargin)
Vue.component("SettingsPadding", SettingsPadding)
Vue.component("SettingsHidden", SettingsHidden)
Vue.component("SettingsVisibility", SettingsVisibility)
Vue.component("SettingsTextColor", SettingsTextColor)
Vue.component("SettingsBlur", SettingsBlur)
Vue.component("SettingsSize", SettingsSize)
Vue.component("SettingsColor", SettingsColor)
Vue.component("SettingsWidth", SettingsWidth)
Vue.component("SettingsHeight", SettingsHeight)
Vue.component("SettingsFlexColumnAlignSelf", SettingsFlexColumnAlignSelf)
Vue.component("SettingsAlign", SettingsAlign)
Vue.component("SettingsOverflow", SettingsOverflow)
Vue.component("SettingsFormLooking", SettingsFormLooking)
Vue.component("SettingsTwoDirectionPosition", SettingsTwoDirectionPosition)
Vue.component("SettingsHorizontalPosition", SettingsHorizontalPosition)
Vue.component("SettingsRangeWithUnit", SettingsRangeWithUnit)
Vue.component("SettingsJustifyContent", SettingsJustifyContent)
Vue.component("SettingsTextStyle", SettingsTextStyle)
Vue.component("SettingsTextAlign", SettingsTextAlign)
