<template>
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col-sm mb-2 mb-sm-0">
        <h1 class="page-header-title">{{ title }}</h1>
      </div>

      <div class="col-sm-auto">
        <slot name="right" />
      </div>
    </div>
    <div class="row" v-if="!!$slots.bottom">
      <div class="col-12">
        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardTitle",
  props: { title: String }
}
</script>

<style scoped></style>
