<template>
  <div id="campaign-builder">
    <modal-campaign-save-discard-cancel v-if="showModal" @close="showModal = false" :save="save" :exit="exit" :discard="exit" />
    <full-page-loading v-show="loading || getPreviewDevicesLoading" />
    <div v-show="!loading && !getPreviewDevicesLoading">
      <sidebar style="width: var(--left-width)" v-if="!loading && !getPreviewDevicesLoading" />
      <main style="padding-left: var(--left-width)" id="content" role="main" class="main pointer-event">
        <CampaignBuilderHeader :exit="exit" :next="next" :cancel="cancel" :save="save" v-if="!loading && !getPreviewDevicesLoading" />
        <Preview />
      </main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import Preview from "@/components/campaign-builder/Preview"
import CampaignBuilderHeader from "@/components/campaign-builder/CampaignBuilderHeader"
import htmlExporter from "@/lib/utils/html-exporter"
import Sidebar from "@/components/campaign-builder/Sidebar"
import campaignAPI from "@/api/campaign"
import FullPageLoading from "@/components/common/FullPageLoading"
import ModalCampaignSaveDiscardCancel from "@/components/modals/ModalCampaignSaveDiscardCancel"

export default {
  name: "CampaignBuilder",
  components: {
    ModalCampaignSaveDiscardCancel,
    FullPageLoading,
    Sidebar,
    CampaignBuilderHeader,
    Preview
  },
  computed: {
    ...mapGetters("builder", ["getIframeDocument", "getPreviewDevicesLoading"]),
    ...mapState("sites", ["selectedSite"]),
    ...mapState("builder", ["initialHtml"]),
    ...mapState("builderHistory", ["history"])
  },
  data() {
    return {
      showEditor: false,
      loading: true,
      showModal: false
    }
  },
  methods: {
    ...mapActions("builder", ["initBuilder"]),
    ...mapMutations("builder", ["initStyleVariables", "setHtml", "setTotalPage", "setParentId", "setHtml", "setIsNewCampaign"]),
    async cancel() {
      if (this.history.length > 0) {
        this.openDiscardPopUp()
      } else {
        await this.exit()
      }
    },
    async exit() {
      this.showModal = false
      await this.$router.push({ name: "Dashboard.Campaigns.Summary", params: { id: this.$route.params.campaign } })
    },
    async next() {
      await this.$router.push({ name: "Dashboard.Campaigns.Settings", params: { id: this.$route.params.campaign } })
    },
    async back() {
      await this.$router.push({ name: "Dashboard.Campaigns.Summary", params: { id: this.$route.params.campaign } })
    },
    openDiscardPopUp() {
      this.showModal = true
    },
    async save() {
      this.showModal = false
      this.loading = true
      this.getIframeDocument.dispatchEvent(new Event('osmBeforeSave'))

      const cloneNode = this.getIframeDocument.cloneNode(true)
      const html = htmlExporter.getHtml(cloneNode)
      const formFields = htmlExporter.getFormFields(cloneNode)
      const { site, campaign, variant } = this.$route.params
      await campaignAPI.updateCampaignVariant({
        siteId: site,
        campaignId: campaign,
        campaignVariantId: variant,
        data: {
          body: html,
          form_fields: formFields
        }
      })
      this.loading = false

      this.getIframeDocument.dispatchEvent(new Event('osmAfterSave'))

      this.$store.commit('builderHistory/resetState')
    },
  },
  beforeDestroy() {
    document.body.classList.remove("has-navbar-vertical-aside", "navbar-vertical-aside-show")
    document.body.style.removeProperty("background-color")
  },
  async mounted() {
    document.body.classList.remove("navbar-vertical-aside-mini-mode")
    document.body.classList.add("has-navbar-vertical-aside", "navbar-vertical-aside-show")
    document.body.style.backgroundColor = "#f9fafc"

    this.loading = true

    const { site, campaign, variant } = this.$route.params
    const isNew = !!this.$route.query.new
    this.setIsNewCampaign(isNew)
    await this.initBuilder({ siteId: site, campaignId: campaign, campaignVariantId: variant })

    this.loading = false
  },
  beforeCreate() {
    this.$store.commit("builder/resetState")
    this.$store.commit("builderHistory/resetState")
  }
}
</script>
<style>
:root {
  --left-width: 30rem;
}

@media screen and (max-width: 1360px) {
  :root {
    --left-width: 26rem;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  margin: 0 !important;
}

input[type="number"] {
  appearance: auto !important;
  -moz-appearance: auto !important;
}
</style>
