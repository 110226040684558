<template>
  <color-picker :key="env" v-model="value" :show-opacity="!this.unFormatted" />
</template>

<script>
import ColorPicker from "@/components/campaign-builder/appearance-changers/base/ColorPicker"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"

export default {
  name: "Color",
  mixins: [appearanceChangerMixin],
  components: { ColorPicker },
  props: {
    unFormatted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    value: {
      get() {
        let color = this.getStyleVariableValue(this.env, this.variable, 0)
        if (this.unFormatted) {
          color = `rgba(${color}, 1)`
        }
        return color
      },
      set(c) {
        if (this.unFormatted) {
          c = c.replace("rgba(", "").replace(", 1)", "")
        }
        this.setStyleVariableValue({
          env: this.env,
          variable: this.variable,
          value: c
        })
      }
    }
  }
}
</script>
