<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div class="d-flex align-items-center flex-wrap">
      <span>The popup will appear a maximum number of</span>
      <input
        class="form-control mx-2 form-control-sm text-center"
        style="width: 6rem"
        type="number"
        :value="value.maxCount"
        @input="(e) => setValue('maxCount', e.target.value)"
      /><span>times per visitor</span>
    </div>
    <div class="d-flex mt-1 align-items-center" v-if="value.maxCount > 1">
      <span class="mr-2">With at least</span>
      <input
        class="form-control form-control-sm text-center"
        style="width: 6rem"
        type="number"
        :value="value.betweenMilliseconds / 1000"
        @input="(e) => setValue('betweenMilliseconds', e.target.value * 1000)"
      /><span class="ml-2">seconds between two appearances.</span>
    </div>
    <span v-else>.</span>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"

const mixin = targetingRuleModalMixin("who", targetingRuleNames.whoRules.frequency)

export default {
  name: "FrequencyModal",
  components: { ModalCampaignTargetingRule },
  mixins: [mixin],
  methods: {
    setValue(key, value) {
      this.value[key] = value
      this.value = { ...this.value }
    }
  }
}
</script>
