function whatsappStyleVariables() {
  return {
    style: {
      desktop: {
        "--overlay-backdrop-blur": "4px",
        "--overlay-color": "rgba(0,0,0,.2)",
        "--overlay-display": "block"
      },
      mobile: {
        "--overlay-backdrop-blur": "4px",
        "--overlay-color": "rgba(0,0,0,.2)",
        "--overlay-display": "block"
      }
    }
  }
}

export default whatsappStyleVariables
