import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

export const whenToShowComponentMap = {
  [targetingRuleNames.whenRules.inactivity]: "targeting-inactivity",
  [targetingRuleNames.whenRules.afterMilliseconds]: "targeting-after-milliseconds",
  [targetingRuleNames.whenRules.exitIntent]: "targeting-exit-intent",
  [targetingRuleNames.whenRules.scrollPercentage]: "targeting-scroll-percentage",
  [targetingRuleNames.whenRules.clickTo]: "targeting-click-to"
}

export const whenToShowModalMap = {
  [targetingRuleNames.whenRules.inactivity]: "inactivity-modal",
  [targetingRuleNames.whenRules.afterMilliseconds]: "after-milliseconds-modal",
  [targetingRuleNames.whenRules.exitIntent]: "exit-intent-modal",
  [targetingRuleNames.whenRules.scrollPercentage]: "scroll-percentage-modal",
  [targetingRuleNames.whenRules.clickTo]: "click-to-modal"
}

export const whoToShowComponentMap = {
  [targetingRuleNames.whoRules.returningOrNew]: "targeting-returning-or-new",
  [targetingRuleNames.whoRules.linking]: "targeting-linking",
  [targetingRuleNames.whoRules.ipAllowed]: "targeting-ip-allowed",
  [targetingRuleNames.whoRules.ipBlocked]: "targeting-ip-blocked",
  [targetingRuleNames.whoRules.adblock]: "targeting-adblock",
  [targetingRuleNames.whoRules.visitedUrl]: "targeting-visited-url",
  [targetingRuleNames.whoRules.excludeByVisit]: "targeting-exclude-by-visit",
  [targetingRuleNames.whoRules.device]: "targeting-device",
  [targetingRuleNames.whoRules.source]: "targeting-source",
  [targetingRuleNames.whoRules.numberOfVisitedPages]: "targeting-number-of-visited-pages",
  [targetingRuleNames.whoRules.operatingSystem]: "targeting-operating-system",
  [targetingRuleNames.whoRules.timeSpentOnPage]: "targeting-time-spent-on-page",
  [targetingRuleNames.whoRules.timeSpentOnSite]: "targeting-time-spent-on-site",
  [targetingRuleNames.whoRules.cart]: "targeting-cart",
  [targetingRuleNames.whoRules.frequency]: "targeting-frequency",
  [targetingRuleNames.whoRules.currentUrl]: "targeting-current-url",
  [targetingRuleNames.whoRules.lastVisitedUrl]: "targeting-last-visited-url",
  [targetingRuleNames.whoRules.browserLanguage]: "targeting-browser-language",
  [targetingRuleNames.whoRules.location]: "targeting-location"
}

export const whoToShowModalMap = {
  [targetingRuleNames.whoRules.returningOrNew]: "returning-or-new-modal",
  [targetingRuleNames.whoRules.linking]: "linking-modal",
  [targetingRuleNames.whoRules.ipAllowed]: "ip-allowed-modal",
  [targetingRuleNames.whoRules.ipBlocked]: "ip-blocked-modal",
  [targetingRuleNames.whoRules.adblock]: "adblock-modal",
  [targetingRuleNames.whoRules.visitedUrl]: "visited-url-modal",
  [targetingRuleNames.whoRules.excludeByVisit]: "exclude-by-visit-modal",
  [targetingRuleNames.whoRules.device]: "device-modal",
  [targetingRuleNames.whoRules.source]: "source-modal",
  [targetingRuleNames.whoRules.numberOfVisitedPages]: "number-of-visited-pages-modal",
  [targetingRuleNames.whoRules.operatingSystem]: "operating-system-modal",
  [targetingRuleNames.whoRules.timeSpentOnPage]: "time-spent-on-page-modal",
  [targetingRuleNames.whoRules.timeSpentOnSite]: "time-spent-on-site-modal",
  [targetingRuleNames.whoRules.cart]: "cart-modal",
  [targetingRuleNames.whoRules.frequency]: "frequency-modal",
  [targetingRuleNames.whoRules.currentUrl]: "current-url-modal",
  [targetingRuleNames.whoRules.lastVisitedUrl]: "last-visited-url-modal",
  [targetingRuleNames.whoRules.location]: "location-modal",
  [targetingRuleNames.whoRules.browserLanguage]: "browser-language-modal"
}
