<template>
  <div :key="env">
    <settings-template :title="label1" layout="horizontal" class="mb-2" :show-device-picker="false">
      <range-input-with-unit v-model="value[0]" @update="refresh" :alternative-units="oddUnits" :max="100" :min="0" />
    </settings-template>

    <settings-template :title="label2" layout="horizontal" class="mb-2" :show-device-picker="false">
      <range-input-with-unit v-model="value[1]" @update="refresh" :alternative-units="evenUnits" :max="100" :min="0" />
    </settings-template>

    <settings-template :title="label3" layout="horizontal" class="mb-2" :show-device-picker="false">
      <range-input-with-unit v-model="value[2]" @update="refresh" :alternative-units="oddUnits" :max="100" :min="0" />
    </settings-template>

    <settings-template :title="label4" layout="horizontal" :show-device-picker="false">
      <range-input-with-unit v-model="value[3]" @update="refresh" :alternative-units="evenUnits" :max="100" :min="0" />
    </settings-template>
  </div>
</template>
<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import RangeInputWithUnit from "@/components/common/RangeInputWithUnit"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "SettingsFourDirectionBase",
  props: {
    label1: String,
    label2: String,
    label3: String,
    label4: String,
    variable: {
      type: String,
      required: true
    },
    oddUnits: Array,
    evenUnits: Array
  },
  computed: {
    value: {
      get() {
        return this.getStyleVariableValue(this.env, this.variable, 2)
      },
      set(value) {
        value = value
          .map((item) => {
            if (item.value === "" || item.unit === "") {
              item = { value: "0", unit: "px" }
            }
            return item.value + item.unit
          })
          .join(" ")

        this.setStyleVariableDirectCurrentEnv({
          value: value,
          variable: this.variable
        })
      }
    }
  },
  methods: {
    refresh() {
      this.value = [...this.value]
    }
  },
  components: { SettingsTemplate, RangeInputWithUnit },
  mixins: [appearanceChangerMixin]
}
</script>
