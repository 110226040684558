<template>
  <settings-template :layout="layout" :title="title" :show-device-picker="showDevicePicker">
    <background :variable="variable" />
  </settings-template>
</template>
<script>
import Background from "@/components/campaign-builder/appearance-changers/Background"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"

export default {
  name: "SettingsBackgroundColor",
  props: {
    variable: {
      type: String,
      required: true
    },
    layout: {
      type: String,
      default: "accordion"
    },
    title: {
      type: String,
      default: "Background"
    }
  },
  mixins: [appearanceChangerMixin],
  components: { SettingsTemplate, Background }
}
</script>
