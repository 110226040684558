export default function satisMeterStyleVariables() {
  return {
    style: {
      all: {
        "--layout-background-color": "rgb(255,255,255)",

        "--button-background-color": "rgba(255, 73, 129, 0.2)",
        "--button-color": "rgb(51, 51, 51)",

        "--button-hover-background-color": "rgb(255, 73, 129)",
        "--button-hover-color": "rgb(250, 250, 250)",

        "--close-button-color": "rgb(160,160,160)",
        "--close-button-background": "rgb(255,255,255)",
        "--close-button-display": "block"
      }
    }
  }
}
