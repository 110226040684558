import randomId from "@/lib/utils/random-id"
import { getImportUrl } from "@/lib/utils/google-font"
import html from "!raw-loader!./wheel-of-fortune/html.html"

class wheelOfFortuneHtml {
  get() {
    const fontUrl = getImportUrl(["Montserrat"])
    return html.replaceAll("PARENT_ID", randomId()).replace("IMG_ID", randomId()).replace("FONT_URL", fontUrl)
  }
}

export { wheelOfFortuneHtml }
