import Vue from "vue"

const unfoldMixin = {
  methods: {
    initUnfold(els) {
      const HSUnfold = require("@/theme-files/hs-unfold.min")
      els.forEach((el) => {
        new HSUnfold(el).init()
      })
    }
  },
  mounted() {
    Vue.nextTick().then(() => {
      this.initUnfold(this.$el.querySelectorAll(".js-hs-unfold-invoker"))
    })
  }
}
export default unfoldMixin
