import ElementManager from "@/lib/marketing-widgets/element-manager"

class FormFieldLabelManager extends ElementManager {
  constructor(textField) {
    super(
      textField,
      {
        background: "transparent",
        "border-radius": "0px 0px 0px 0px",
        padding: "0px 0px 0px 0px",
        margin: "0px 0px 4px 0px",
        "font-size": "14px",
        color: "#484848",
        "border-style": "none",
        "border-width": "0px",
        "border-color": "#f00"
      },
      "label-"
    )
  }
}

export default FormFieldLabelManager
