<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <p class="text-sm m-0">Quota: {{ user.used_quota }} / {{ user.plan.quota }}</p>
      <span>
        <i
          class="tio-help-outlined text-body m-0"
          id="quotaTooltip"
          data-toggle="tooltip"
          data-placement="bottom"
          data-container="body"
          data-html="true"
          :title="tooltipTitle"
        ></i>
      </span>
    </div>
    <div class="progress" style="width: 180px; height: 8px">
      <div
        class="progress-bar"
        role="progressbar"
        :style="`width: ${usagePercentage}%;`"
        :aria-valuenow="usagePercentage"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "AccountUsage",
  data() {
    return {
      usagePercentage: 0
    }
  },
  computed: {
    ...mapState("user", ["user"]),
    tooltipTitle() {
      if (this.user.temporary_plan === null) {
        return `<div class="width:20rem">You're using ${this.user.plan.name} plan.</div><div>Monthly quota of plan is ${this.user.plan.quota}</div>`
      } else {
        return `<div class="width:20rem">You have downgraded your plan from ${this.user["temporary_plan"].name} to ${this.user["current_plan"].name}.</div>
            <div>The quota you see is from your old plan, (${this.user["temporary_plan"].name}), its usable until the next billing cycle.</div>
            <div>After that, your quota will be ${this.user["current_plan"].quota}.</div>`
      }
    }
  },
  created() {
    this.usagePercentage = (100 * this.user.used_quota) / this.user.plan.quota
  },
  mounted() {
    $("#quotaTooltip").tooltip({ boundary: "window" })
  }
}
</script>
<style>
.tooltip-inner {
  max-width: 30rem !important;
}
</style>
