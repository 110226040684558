<template>
  <div>
    <settings-accordion :show-device-picker="true">
      <template #title>Input</template>
      <template>
        <div class="pb-5 border-bottom">
          <h4 class="mb-4">Settings</h4>
          <settings-template class="mb-3" layout="horizontal" title="Name" :show-device-picker="false">
            <input type="text" class="form-control form-control-sm" :value="name" @input="changeName" />
          </settings-template>
          <checkbox v-model="isRequired">Required</checkbox>
        </div>

        <div class="py-5 border-bottom">
          <h4 class="mb-4">Size</h4>
          <SettingsFontSize :show-device-picker="false" layout="horizontal" :variable="manager.getStyleVariableName('font-size')" />
        </div>

        <div class="py-5 border-bottom">
          <h4 class="mb-4">Style</h4>
          <SettingsColor class="mb-2" :show-device-picker="false" layout="horizontal" :variable="manager.getStyleVariableName('color')" />
          <settings-text-style
            class="mb-2"
            layout="horizontal"
            :show-device-picker="false"
            :variable="{
              fontWeight: manager.getStyleVariableName('font-weight'),
              fontStyle: manager.getStyleVariableName('font-style'),
              textDecoration: manager.getStyleVariableName('text-decoration')
            }"
          />
          <settings-text-align
            class="mb-2"
            :show-device-picker="false"
            layout="horizontal"
            :variable="manager.getStyleVariableName('text-align')"
          />
        </div>
        <div class="py-5">
          <h4 class="mb-2">Options</h4>
          <draggable handle=".handle" @end="dragEnd">
            <div class="position-relative handle border rounded-md px-4 py-2 mb-2" v-for="(option, key) in options" :key="key">
              <button
                class="btn btn-icon btn-soft-danger btn-xs position-absolute"
                v-if="options.length > 1"
                style="top: 50%; transform: translateY(-50%); right: -1rem"
                @click="removeOption(key)"
              >
                <i class="tio-clear"></i>
              </button>
              <span class="position-absolute" style="left: -0.75rem; top: 50%; transform: translateY(-50%)">
                <i class="tio-drag tio-lg"></i>
              </span>

              <label class="w-100">
                Value
                <input
                  class="form-control form-control-sm"
                  :value="option.value"
                  @input="(event) => setOption(key, 'value', event.target.value)"
                />
              </label>
              <label class="w-100">
                Text
                <input
                  class="form-control form-control-sm"
                  :value="option.text"
                  @input="(event) => setOption(key, 'text', event.target.value)"
                />
              </label>
            </div>
          </draggable>
          <div class="d-flex justify-content-center">
            <button class="btn btn-soft-primary btn-icon rounded-circle" @click="addOption">
              <i class="tio-add"></i>
            </button>
          </div>
        </div>
      </template>
    </settings-accordion>

    <settings-accordion :show-device-picker="true">
      <template #title>Spacing</template>
      <SettingsMargin
        title="Block Outer Space"
        layout="vertical"
        :show-device-picker="false"
        :variable="manager.getStyleVariableName('margin')"
      />
      <SettingsPadding
        title="Field Inner Space"
        layout="vertical"
        class="mt-4"
        :show-device-picker="false"
        :variable="manager.getStyleVariableName('padding')"
      />
    </settings-accordion>

    <SettingsBackground :variable="manager.getStyleVariableName('background')" />
    <settings-accordion :show-device-picker="true">
      <template #title>Border</template>
      <SettingsBorder
        layout="vertical"
        :show-device-picker="false"
        :color-variable="manager.getStyleVariableName('border-color')"
        :style-variable="manager.getStyleVariableName('border-style')"
        :width-variable="manager.getStyleVariableName('border-width')"
      />
      <SettingsBorderRadius
        class="mt-4"
        layout="vertical"
        :show-device-picker="false"
        :variable="manager.getStyleVariableName('border-radius')"
      />
    </settings-accordion>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import Checkbox from "@/components/common/Checkbox"
import FormElementHelper from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/FormElementHelper"
import SettingsAccordion from "@/components/campaign-builder/widget-settings/SettingsAccordion"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import formFieldInputManagerFactory from "@/lib/marketing-widgets/form-field-input-manager-factory"

export default {
  name: "Checkboxes",
  props: ["element", "manager"],
  components: {
    SettingsTemplate,
    SettingsAccordion,
    Checkbox,
    draggable
  },
  data() {
    return {
      name: "",
      options: []
    }
  },
  computed: {
    isRequired: {
      get() {
        return this.element.querySelector("input").required
      },
      set(value) {
        this.element.querySelectorAll("input").forEach((inp) => (inp.required = value))
      }
    }
  },
  methods: {
    changeName(e) {
      this.element.querySelectorAll("input").forEach((input) => (input.name = e.target.value))
    },
    setOption(key, type, value) {
      const input = this.element.querySelectorAll("input")[key]
      const span = input.parentElement.querySelector("span.checkbox-text")
      if (type === "value") {
        input.value = value
      } else if (type === "text") {
        span.innerText = value
      }
    },
    fillOptions() {
      this.options = Array.from(this.element.querySelectorAll("input")).map((input) => {
        console.log("xx")
        return { value: input.value, text: input.parentElement.querySelector(".checkbox-text").innerHTML }
      })
    },
    addOption() {
      const html = FormElementHelper.getCheckboxesOption(this.name, `Checkbox ${this.options.length + 1}`, this.isRequired)
      this.element.innerHTML = this.element.innerHTML + html
      formFieldInputManagerFactory(this.element)
      this.fillOptions()
    },
    removeOption(key) {
      this.element.querySelectorAll("label")[key].remove()
      this.fillOptions()
    },
    dragEnd(event) {
      const { newIndex, oldIndex } = event
      const parent = this.element
      const fields = parent.querySelectorAll("label")
      const newFields = [...fields]

      const tmp = newFields[oldIndex]
      newFields.splice(oldIndex, 1)
      newFields.splice(newIndex, 0, tmp)

      parent.innerHTML = null
      newFields.forEach((field) => parent.appendChild(field))
    }
  },
  mounted() {
    this.name = this.element.querySelector("input").name
    this.fillOptions()
  }
}
</script>
