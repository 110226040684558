<template>
  <div class="card mb-3 mb-lg-5">
    <div class="card-header">
      <div class="row justify-content-between align-items-center flex-grow-1">
        <div class="col-9">
          <h3 class="mb-0">Campaign Settings</h3>
        </div>
        <div class="col-3">
          <div class="text-right">
            <button
              class="btn btn-primary btn-sm"
              @click="$router.push({ name: 'Dashboard.Campaigns.Settings', params: { id: $route.params.id } })"
            >
              Edit settings
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 mb-3 mb-lg-0 col-lg-6">
          <div class="border rounded-lg">
            <h4 class="border-bottom px-4 py-3">Who To Show Campaign</h4>
            <div class="px-4 py-3">
              <targeting-widget
                v-for="key in Object.keys(whoRules)"
                :key="key"
                :editable="false"
                :component-name="whoToShowComponentMap[key]"
              />
            </div>
          </div>
        </div>
        <div class="col-12 mb-3 mb-lg-0 col-lg-6">
          <div class="border rounded-lg">
            <h4 class="border-bottom px-4 py-3">When To Show Campaign</h4>
            <div class="px-4 py-3">
              <targeting-widget
                v-for="key in Object.keys(whenRules)"
                :key="key"
                :editable="false"
                :component-name="whenToShowComponentMap[key]"
              />
            </div>
          </div>
        </div>
        <div class="col-12 mb-3 mt-3">
          <div class="border rounded-lg">
            <h4 class="border-bottom px-4 py-3">Integrations</h4>
            <div class="card-body">
              <div class="d-inline-block px-4 py-3 rounded border" v-for="integration in campaignIntegrations" :key="integration.id">
                <campaign-summary-settings-integration-item
                  :integration-type="integration.third_party_integration.type"
                  :integration-name="integration.third_party_integration.name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { whenToShowComponentMap, whoToShowComponentMap } from "@/components/campaign-targeting-rules/targetingRuleComponentMaps"
import TargetingWidget from "@/components/campaign-targeting-rules/TargetingWidget"
import CampaignSummarySettingsIntegrationItem from "@/components/dashboard/CampaignSummarySettingsIntegrationItem"

export default {
  name: "CampaignSummarySettings",
  components: { CampaignSummarySettingsIntegrationItem, TargetingWidget },
  data() {
    return {
      whenToShowComponentMap: whenToShowComponentMap,
      whoToShowComponentMap: whoToShowComponentMap
    }
  },
  computed: {
    ...mapState("campaignSettings", ["whenRules", "whoRules", "campaignIntegrations"])
  },
  methods: {
    ...mapActions("campaignSettings", ["init"])
  },
  mounted() {
    this.init(this.$route.params.id)
  }
}
</script>

<style scoped></style>
