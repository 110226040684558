import ElementManager from "@/lib/marketing-widgets/element-manager"

class LuckyWheelManager extends ElementManager {
  constructor(element) {
    super(
      element,
      {
        "--bg1": "#BB1BC7",
        "--bg2": "#FF5200",
        "--bg3": "#BB1BC7",
        "--bg4": "#FF5200",
        "--text": "white"
      },
      "lckyw"
    )
    this.element = element
  }

  setTextAndCoupons(data) {
    data.forEach(({ coupon, weight, text }, index) => {
      const el = this.element.querySelector(`.labels text:nth-of-type(${index + 1})`)
      el.dataset.coupon = coupon
      el.dataset.weight = weight
      el.textContent = text
    })
  }

  getTextAndCoupons() {
    return [...this.element.querySelectorAll(`.labels text`)].map((el) => {
      return {
        coupon: el.dataset.coupon,
        weight: el.dataset.weight,
        text: el.textContent
      }
    })
  }

  static getInitialHtml() {
    return `<div>
          <svg viewBox="0 0 730 730" xmlns="http://www.w3.org/2000/svg">
            <g class="wheel">
              <circle class="frame" cx="365" cy="365" r="347.6" />
              <g class="sticks">
                <rect height="24.33" rx="4" ry="4" width="9.3" x="360.4" />
                <rect height="9.27" rx="4" ry="4" transform="translate(1082.8 352.8) rotate(90)" width="24.3" x="352.8" y="713.2" />
                <rect height="9.27" rx="4" ry="4" transform="translate(145.8 -133.6) rotate(60)" width="24.3" x="176.4" y="54.8" />
                <rect height="9.27" rx="4" ry="4" transform="translate(851.4 -133.6) rotate(60)" width="24.3" x="529.2" y="665.9" />
                <rect height="9.27" rx="4" ry="4" transform="translate(102.3 -4.5) rotate(30)" width="24.3" x="47.3" y="183.9" />
                <rect height="9.27" rx="4" ry="4" transform="translate(360.5 -262.7) rotate(30)" width="24.3" x="658.4" y="536.8" />
                <rect height="9.27" rx="4" ry="4" width="24.3" y="360.4" />
                <rect height="9.27" rx="4" ry="4" width="24.3" x="705.7" y="360.4" />
                <rect height="9.27" rx="4" ry="4" transform="translate(-262.7 102.3) rotate(-30)" width="24.3" x="47.3" y="536.8" />
                <rect height="9.27" rx="4" ry="4" transform="translate(-4.5 360.5) rotate(-30)" width="24.3" x="658.4" y="183.9" />
                <rect height="9.27" rx="4" ry="4" transform="translate(-486.4 498.6) rotate(-60)" width="24.3" x="176.4" y="665.9" />
                <rect height="9.27" rx="4" ry="4" transform="translate(219.2 498.6) rotate(-60)" width="24.3" x="529.2" y="54.8" />
              </g>
              <g class="sectors">
                <path d="M365,365,529.5,80A328.1,328.1,0,0,0,365,35.9Z" transform="translate(0)" />
                <path d="M365,365,650,200.5A328.5,328.5,0,0,0,529.5,80Z" transform="translate(0)" />
                <path d="M365,365H694.1A328.1,328.1,0,0,0,650,200.5Z" transform="translate(0)" />
                <path d="M365,365,650,529.5A328.1,328.1,0,0,0,694.1,365Z" transform="translate(0)" />
                <path d="M365,365,529.5,650A328.5,328.5,0,0,0,650,529.5Z" transform="translate(0)" />
                <path d="M365,365V694.1A328.1,328.1,0,0,0,529.5,650Z" transform="translate(0)" />
                <path d="M365,365,200.5,650A328.1,328.1,0,0,0,365,694.1Z" transform="translate(0)" />
                <path d="M365,365,80,529.5A328.5,328.5,0,0,0,200.5,650Z" transform="translate(0)" />
                <path d="M365,365H35.9A328.1,328.1,0,0,0,80,529.5Z" transform="translate(0)" />
                <path d="M365,365,80,200.5A328.1,328.1,0,0,0,35.9,365Z" transform="translate(0)" />
                <path d="M365,365,200.5,80A328.5,328.5,0,0,0,80,200.5Z" transform="translate(0)" />
                <path d="M365,365V35.9A328.1,328.1,0,0,0,200.5,80Z" transform="translate(0)" />
              </g>
              <g class="labels">
                <text text-anchor="end" data-coupon="" data-weight="5" transform="rotate(286,365,368.5)" x="655" y="370">Almost</text>
                <text text-anchor="end" data-coupon="25off" data-weight="5" transform="rotate(316,365,368.5)" x="655" y="370">25% off</text>
                <text text-anchor="end" data-coupon="" data-weight="5" transform="rotate(346,365,368.5)" x="655" y="370">Not quite</text>
                <text text-anchor="end" data-coupon="free-shipping" data-weight="5" transform="rotate(16,365,368.5)" x="655" y="370">Free shipping</text>
                <text text-anchor="end" data-coupon="" data-weight="5" transform="rotate(46,365,368.5)" x="655" y="370">So close</text>
                <text text-anchor="end" data-coupon="5off" data-weight="5" transform="rotate(76,365,368.5)" x="655" y="370">5% off</text>
                <text text-anchor="end" data-coupon="" data-weight="10" transform="rotate(106,365,368.5)" x="655" y="370">No luck today</text>
                <text text-anchor="end" data-coupon="10-off" data-weight="15" transform="rotate(136,365,368.5)" x="655" y="370">10% off</text>
                <text text-anchor="end" data-coupon="50-off" data-weight="0" transform="rotate(166,365,368.5)" x="655" y="370">50% off</text>
                <text text-anchor="end" data-coupon="15-off" data-weight="5" transform="rotate(196,365,368.5)" x="655" y="370">15% off</text>
                <text text-anchor="end" data-coupon="" data-weight="20" transform="rotate(226,365,368.5)" x="655" y="370">Next time</text>
                <text text-anchor="end" data-coupon="20-off" data-weight="2" transform="rotate(256,365,368.5)" x="655" y="370">20% off</text>
              </g>
              <g class="middle">
                <g opacity="0.2">
                  <circle cx="368.5" cy="368.5" r="54.5" />
                </g>
                <g class="wheelMiddle">
                  <circle cx="365" cy="365" fill="#fff" r="54.5" />
                </g>
                <circle cx="365" cy="365" fill="#ccc" r="11.6" />
              </g>
            </g>
            <g opacity="0.15">
              <path
                d="M46.9,372.5c0-181.7,147.4-329,329.1-329A327.3,327.3,0,0,1,556.3,97.2,327.3,327.3,0,0,0,365,35.9C183.3,35.9,35.9,183.3,35.9,365c0,115.2,59.2,216.5,148.8,275.3C101.3,580.6,46.9,482.9,46.9,372.5Z"
                transform="translate(0)"
              />
            </g>
            <g class="active">
              <g>
                <path
                  d="M707,160.5c-11.4-17.9-35.8-22.8-54.5-11a41.7,41.7,0,0,0-13.6,14.1l-33.6,58.9a2.3,2.3,0,0,0,0,2.4,2.4,2.4,0,0,0,2.3,1.1l67.5-5.1a43.8,43.8,0,0,0,18.6-6.3C712.4,202.7,718.3,178.5,707,160.5Z"
                  fill-opacity="0.22"
                  transform="translate(0)"
                />
                <path
                  class="winIndicator"
                  d="M711.9,157.4a38.4,38.4,0,0,0-66,1.8l-31.5,57.5a2.1,2.1,0,0,0,0,2.4,2.6,2.6,0,0,0,2.2,1.2l65.6-3.9a39.6,39.6,0,0,0,17.9-5.9A38.5,38.5,0,0,0,711.9,157.4Z"
                  transform="translate(0)"
                />
                <path
                  d="M681.7,166.9a9.3,9.3,0,0,0-6.6,4.8l-.8,2.1a14.9,14.9,0,0,0-.2,2.1,8.8,8.8,0,0,0,1.1,4.2,9.2,9.2,0,0,0,2.9,3,7.6,7.6,0,0,0,2.9,1.3l1.1.2a8.6,8.6,0,0,0,4.2-.6,8.4,8.4,0,0,0,3.4-2.5,7.4,7.4,0,0,0,2-3.8,8.5,8.5,0,0,0-.1-4.2,8.4,8.4,0,0,0-2.1-3.8,7.4,7.4,0,0,0-3.5-2.3l-1-.3A12.2,12.2,0,0,0,681.7,166.9Z"
                  fill="#ccc"
                  transform="translate(0)"
                />
              </g>
            </g>
          </svg>
          </div>`
  }
}

export default LuckyWheelManager
