<template>
  <SettingsColor  :layout="layout" :show-device-picker="showDevicePicker" :variable="variable" title="Text Color" />
</template>
<script>
export default {
  name: "SettingsTextColor",
  props: {
    variable: {
      type: String,
      required: true
    },
    showDevicePicker: {
      type: Boolean,
      default: true
    }
  }
}
</script>
