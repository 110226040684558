<template>
  <div class="accordion my-5" :data-accordion-group="group">
    <div class="rounded-lg shadow-soft border">
      <a class="btn-block collapsed" href="javascript:;" data-toggle="collapse" :data-target="`#${id}`">
        <div class="d-flex justify-content-between align-items-center w-full h-full px-4" style="height: 3.5rem">
          <div class="flex-fill">
            <slot name="title" />
          </div>
          <span class="card-btn-toggle">
            <span class="card-btn-toggle-default"><i class="tio-add"></i></span>
            <span class="card-btn-toggle-active"><i class="tio-remove"></i></span>
          </span>
        </div>
      </a>
      <div :id="id" class="collapse">
        <div class="py-3 px-4 border-top mt-1">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import randomId from "@/lib/utils/random-id"

export default {
  name: "BaseAccordion",
  props: {
    group: String,
    closeGroupOnOpen: Boolean,
    openAtStart: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      id: randomId()
    }
  },
  mounted() {
    if (!window.OSMOpenedAccordions) window.OSMOpenedAccordions = {}
    if (!window.OSMOpenedAccordions[this.group]) window.OSMOpenedAccordions[this.group] = []

    $(`#${this.id}`).on("show.bs.collapse", () => {
      if (this.closeGroupOnOpen) {
        window.OSMOpenedAccordions[this.group].forEach((id) => {
          $(`#${id}`).collapse("hide")
        })
        window.OSMOpenedAccordions[this.group] = []
      }

      window.OSMOpenedAccordions[this.group].push(this.id)
    })

    if (this.openAtStart && window.OSMOpenedAccordions[this.group].length === 0) {
      $(`#${this.id}`).collapse("show")
      window.OSMOpenedAccordions[this.group].push(this.id)
    }
  },
  beforeDestroy() {
    const i = window.OSMOpenedAccordions[this.group].findIndex((i) => i === this.id)
    if (i > -1) {
      window.OSMOpenedAccordions[this.group].splice(i, 1)
    }
  }
}
</script>

<style scoped>
.card-btn-toggle-default {
  display: none;
}

.card-btn-toggle-active {
  display: block;
}

.collapsed .card-btn-toggle-default {
  display: block;
}

.collapsed .card-btn-toggle-active {
  display: none;
}
</style>
