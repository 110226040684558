<template>
  <modal title="Set Schedule" @close="$emit('close')" size="lg">
    <div class="modal-body">
      <div class="row align-items-center mb-4">
        <div class="col-3">
          <h5 class="mb-0">Start date</h5>
        </div>
        <div class="col-6">
          <date-picker class="form-control-sm" @input="fillScheduleFormStartEndFields" v-model="startDate" />
        </div>
        <div class="col-3">
          <div class="d-flex align-items-center">
            <hour-picker class="form-control-sm" v-model="startHours" @input="fillScheduleFormStartEndFields" />
            <strong class="mx-2" style="font-size: 1.25rem; line-height: 1; margin-top: -0.25rem">:</strong>
            <minute-picker class="form-control-sm" v-model="startMinutes" @input="fillScheduleFormStartEndFields" />
          </div>
        </div>
      </div>
      <div class="mb-4">
        <h5>End date</h5>
        <div class="row align-items-center">
          <div class="col-3">
            <div class="custom-control custom-radio">
              <input type="radio" id="neverEnd" class="custom-control-input" name="end" value="never" v-model="endDateType" checked />
              <label class="custom-control-label" for="neverEnd">Never</label>
            </div>
          </div>
          <div class="col-9"></div>
          <div class="col-3">
            <div class="custom-control custom-radio">
              <input
                type="radio"
                id="endOn"
                class="custom-control-input indeterminate-checkbox"
                value="on"
                v-model="endDateType"
                name="end"
              />
              <label class="custom-control-label" for="endOn">On</label>
            </div>
          </div>
          <div class="col-6">
            <date-picker
              class="form-control-sm"
              @input="fillScheduleFormStartEndFields"
              v-model="endDate"
              :disabled="endDateType === 'never'"
            />
          </div>
          <div class="col-3">
            <div class="d-flex align-items-center">
              <hour-picker
                class="form-control-sm"
                v-model="endHours"
                :disabled="endDateType === 'never'"
                @input="fillScheduleFormStartEndFields"
              />
              <strong class="mx-2" style="font-size: 1.25rem; line-height: 1; margin-top: -0.25rem">:</strong>
              <minute-picker
                class="form-control-sm"
                v-model="endMinutes"
                :disabled="endDateType === 'never'"
                @input="fillScheduleFormStartEndFields"
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <h5>Daily Schedule</h5>
        <div class="row align-items-center">
          <div class="col-3">
            <div class="custom-control custom-radio">
              <input
                type="radio"
                id="everyDay"
                class="custom-control-input"
                value="everyDay"
                name="dailyScheduleType"
                v-model="dailyScheduleType"
                @input="scheduleFormData.dailySchedule = null"
              />
              <label class="custom-control-label" for="everyDay">Every Day</label>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3">
            <div class="custom-control custom-radio">
              <input
                type="radio"
                id="specificDay"
                class="custom-control-input indeterminate-checkbox"
                name="dailyScheduleType"
                value="specificDay"
                @input="
                  scheduleFormData.dailySchedule = []
                  handleDailyScheduleAdd()
                "
                v-model="dailyScheduleType"
              />
              <label class="custom-control-label" for="specificDay">Specific Day</label>
            </div>
          </div>
          <div v-if="scheduleFormData.dailySchedule !== null" class="col-9">
            <div class="row mb-2">
              <div class="col-4"><strong>Schedule Day</strong></div>
              <div class="col-4"><strong>From</strong></div>
              <div class="col-4"><strong>To</strong></div>
            </div>
          </div>
          <div v-if="scheduleFormData.dailySchedule !== null" class="col-9 offset-3">
            <div class="row mb-2 relative" v-for="(item, key) in scheduleFormData.dailySchedule" :key="key">
              <div class="col-4">
                <div class="d-flex">
                  <button class="btn btn-white btn-icon btn-sm mr-2" @click="handleDailyScheduleDelete(key)">
                    <i class="tio-delete"></i>
                  </button>
                  <select class="form-control form-control-sm" v-model="scheduleFormData.dailySchedule[key].dayOfWeek">
                    <option value="0">Sunday</option>
                    <option value="1">Monday</option>
                    <option value="2">Tuesday</option>
                    <option value="3">Wednesday</option>
                    <option value="4">Thursday</option>
                    <option value="5">Friday</option>
                    <option value="6">Saturday</option>
                  </select>
                </div>
              </div>
              <div class="col-4">
                <div class="d-flex align-items-center">
                  <hour-picker class="form-control-sm" v-model="scheduleFormData.dailySchedule[key].startHours" />
                  <strong class="mx-2" style="font-size: 1.25rem; line-height: 1; margin-top: -0.25rem">:</strong>
                  <minute-picker class="form-control-sm" v-model="scheduleFormData.dailySchedule[key].startMinutes" />
                </div>
              </div>
              <div class="col-4">
                <div class="d-flex align-items-center">
                  <hour-picker class="form-control-sm" v-model="scheduleFormData.dailySchedule[key].endHours" />
                  <strong class="mx-2" style="font-size: 1.25rem; line-height: 1; margin-top: -0.25rem">:</strong>
                  <minute-picker class="form-control-sm" v-model="scheduleFormData.dailySchedule[key].endMinutes" />
                </div>
              </div>
            </div>
            <div class="text-right mt-4">
              <button class="btn btn-primary btn-sm" @click="handleDailyScheduleAdd"><i class="tio-add" /> Add Day</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" @click="save">Save</button>
      <button class="btn btn-white" @click="$emit('close')">Cancel</button>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"
import DatePicker from "@/components/common/DatePicker"
import dayjs from "dayjs"
import HourPicker from "@/components/common/HourPicker"
import MinutePicker from "@/components/common/MinutePicker"

export default {
  name: "ModalCampaignSchedule",
  components: { MinutePicker, HourPicker, DatePicker, Modal },
  props: {
    schedule: { required: true }
  },
  data() {
    return {
      endDateType: "never",
      dailyScheduleType: "everyDay",
      startDate: null,
      startHours: null,
      startMinutes: null,
      endDate: null,
      endHours: null,
      endMinutes: null,
      scheduleFormData: {
        from: null,
        to: null,
        dailySchedule: null
      }
    }
  },
  methods: {
    save() {
      if (this.endDateType === "never") {
        this.scheduleFormData.to = null
      }
      this.$emit("submit", this.scheduleFormData)
    },
    dateTime({ year, month, day, hours, minutes }) {
      hours = ("0" + hours).slice(-2)
      minutes = ("0" + minutes).slice(-2)
      return `${year}-${month}-${day}T${hours}:${minutes}:00`
    },
    fillScheduleFormStartEndFields() {
      const [startMonth, startDay, startYear] = this.startDate.split("/")
      this.scheduleFormData.from = this.dateTime({
        year: startYear,
        month: startMonth,
        day: startDay,
        hours: this.startHours,
        minutes: this.startMinutes
      })

      const [endMonth, endDay, endYear] = this.endDate.split("/")
      this.scheduleFormData.to = this.dateTime({
        year: endYear,
        month: endMonth,
        day: endDay,
        hours: this.endHours,
        minutes: this.endMinutes
      })
      this.scheduleFormData = { ...this.scheduleFormData }
    },
    handleDailyScheduleAdd() {
      this.scheduleFormData.dailySchedule.push({
        dayOfWeek: new Date().getDay(),

        startHours: "00",
        startMinutes: "00",

        endHours: "23",
        endMinutes: "59"
      })
      this.scheduleFormData = { ...this.scheduleFormData }
    },
    handleDailyScheduleDelete(key) {
      this.scheduleFormData.dailySchedule.splice(key, 1)
      if (this.scheduleFormData.dailySchedule.length < 1) {
        this.dailyScheduleType = "everyDay"
        this.scheduleFormData.dailySchedule = null
      }
      this.scheduleFormData = { ...this.scheduleFormData }
    }
  },
  mounted() {
    this.scheduleFormData = { ...this.schedule }
    if (!Array.isArray(this.scheduleFormData.dailySchedule)) this.dailyScheduleType = "everyDay"
    else this.dailyScheduleType = "specificDay"

    if (this.scheduleFormData.to === null) this.endDateType = "never"
    else this.endDateType = "on"

    if (this.scheduleFormData.from === null) {
      this.startDate = dayjs().format("MM/D/YYYY")
      this.startHours = "00"
      this.startMinutes = "00"
    } else {
      this.startDate = dayjs(this.scheduleFormData.from).format("MM/D/YYYY")
      this.startHours = ("0" + dayjs(this.scheduleFormData.from).hour()).slice(-2)
      this.startMinutes = ("0" + dayjs(this.scheduleFormData.from).minute()).slice(-2)
    }

    if (this.scheduleFormData.to === null) {
      this.endDate = dayjs().format("MM/D/YYYY")
      this.endHours = "23"
      this.endMinutes = "59"
    } else {
      this.endDate = dayjs(this.scheduleFormData.to).format("MM/D/YYYY")
      this.endHours = ("0" + dayjs(this.scheduleFormData.to).hour()).slice(-2)
      this.endMinutes = ("0" + dayjs(this.scheduleFormData.to).minute()).slice(-2)
    }

    this.fillScheduleFormStartEndFields()
  }
}
</script>

<style scoped></style>
