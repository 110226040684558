import ElementManager from "@/lib/marketing-widgets/element-manager"
import TextFieldManager from "@/lib/marketing-widgets/text-field-manager"

class GiftPickerManager extends ElementManager {
  constructor(element) {
    super(
      element,
      {
        "--box-bottom": "#D53938",
        "--box-shadow": "#B6282E",
        "--box-top": "#E85E4D",
        "--box-ribbon-front": "#FCB51D",
        "--box-ribbon-bottom": "#B61F2B",
        "--gift-box-height": "auto",
        "--gift-box-width": "48px",
        "--gift-box-margin-right": "16px"
      },
      "gftpckr"
    )
    this.element = element
    this.titleManager = new TextFieldManager(this.element.querySelector(".gift-picker-title [data-osm-text-field]"))
    this.subtitleManager = new TextFieldManager(this.element.querySelector(".gift-picker-subtitle [data-osm-text-field]"))
  }

  setCoupons(data) {
    const ret = []
    data.forEach(({ coupon, weight, message }) => {
      ret.push({ coupon, weight, message })
    })
    this.element.querySelector(".gift-picker").dataset.gift = JSON.stringify(ret)
  }

  setGiftBoxCount(amount) {
    this.element.querySelectorAll(".gift-box").forEach((el) => el.remove())
    const giftBoxHtml = GiftPickerManager.getGiftBoxHtml().repeat(amount)
    this.element.querySelector(".gift-picker").innerHTML += giftBoxHtml
  }

  getGiftBoxCount() {
    return this.element.querySelectorAll(".gift-box").length
  }

  getCoupons() {
    return JSON.parse(this.element.querySelector(`.gift-picker`).dataset.gift)
  }

  static getGiftBoxHtml() {
    return ` <div class="gift-box">
                <svg viewBox="140 40 80 80.1" xmlns="http://www.w3.org/2000/svg">
                  <rect class="box-bottom" height="61.875%" width="66.2" x="146.8" y="70" />
                  <rect class="box-shadow" height="3.6" width="82.75%" x="146.8" y="72.7" />
                  <rect class="box-top" height="18.875%" width="100%" x="140" y="57.9" />
                  <rect class="box-ribbon-front" height="78.125%" width="12.9" x="184.35" y="57"></rect>
                  <g class="gift-box-svg-ribbon">
                    <path class="box-ribbon-front" d="M190.7 57.2l-14.8-16.4-11.1 11.9 19.6 10.9z" />
                    <path class="box-ribbon-bottom" d="M184.4 63.5l-20.5-2.4.9-8.5z" />
                    <path class="box-ribbon-front" d="M190.5 57.3l14.1-17.1 11.6 11.3-19 11.9z" />
                    <path class="box-ribbon-bottom" d="M197.2 63.4l20.3-3.4-1.3-8.5z" />
                  </g>
                </svg>
              </div> `
  }

  static getInitialHtml() {
    return `<div>
           <div class="gift-picker-title"><div data-osm-text-field><div>Want to win a GIFT?</div></div></div>
           <div class="gift-picker-subtitle"><div data-osm-text-field><div>Enter your email address first to play and pick a gift to see what you’ve won.</div></div></div>
           
            <div
              class="gift-picker"
              data-gift='[{"coupon":"off10","weight":"10","message":"%10 Discount!"},{"coupon":"off20","weight":"20","message":"%20 Discount!"},{"coupon":"","weight":"0","message":""},{"coupon":"","weight":"0","message":""},{"coupon":"","weight":"0","message":""}]'
            >
              <div class="gift-coupon">
                <div class="gift-message-area" style="position: relative">
                  <svg
                    style="enable-background: new 0 0 460 460"
                    viewBox="0 0 460 460"
                    x="0px"
                    xml:space="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    y="0px"
                  >
                    <g>
                      <path
                        d="M280.143,252.768l45.64-60.322c15.885-20.994,24.273-46.027,24.273-72.39 C350.056,53.854,296.201,0,230,0v39.9879c44.228,0,80.067,35.85,80.067,80.067c0,18.125-6.018,34.84-16.175,48.266L115.25,404.444 l8.1,55.556L230,319.042l25.069-33.134l89.681,118.537l55.672,7.309L280.143,252.768z"
                        style="fill: #ef1f4b"
                      />
                      <path
                        d="M149.933,120.056c0,18.125,6.018,34.84,16.175,48.266l38.818,51.309L230,186.488l-32.001-42.299 c-5.289-6.988-8.078-15.336-8.078-24.133c0-22.104,17.975-40.079,40.079-40.079V39.989 C185.772,39.989,149.933,75.838,149.933,120.056z"
                        style="fill: #ef1f4b"
                      />
                      <polygon points="230,319.042 336.65,460 344.75,404.444 255.069,285.908" style="fill: #ea6453" />
                      <path
                        d="M293.892,168.322c10.157-13.426,16.175-30.142,16.175-48.266c0-44.218-35.84-80.067-80.067-80.067 v39.989c22.104,0,40.079,17.975,40.079,40.079c0,8.797-2.789,17.145-8.078,24.133L230,186.488l-25.074,33.143l-38.818-51.309 c-10.157-13.426-16.175-30.142-16.175-48.266c0-44.218,35.84-80.067,80.067-80.067V0c-66.201,0-120.056,53.854-120.056,120.056 c0,26.362,8.388,51.396,24.273,72.39l45.64,60.322L59.578,411.753l55.672-7.309L293.892,168.322z"
                        style="fill: #ea6453"
                      />
                    </g>
                  </svg>
                  <div class="gift-message"></div>
                </div>
              </div>
              ${GiftPickerManager.getGiftBoxHtml().repeat(5)}
            </div>
            </div>`
  }
}

export default GiftPickerManager
