<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div class="d-flex align-items-center">
      <span class="mr-2">The campaign will appear after scrolling down</span>
      <input class="form-control form-control-sm text-center" style="width: 6rem" type="number" min="0" max="100" v-model="value" />
      <span class="ml-2"> percent</span>
    </div>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"

const mixin = targetingRuleModalMixin("when", targetingRuleNames.whenRules.scrollPercentage)

export default {
  name: "ScrollPercentageModal",
  components: { ModalCampaignTargetingRule },
  mixins: [mixin]
}
</script>
