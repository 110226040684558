<template>
  <div class="card card-hover-shadow h-100" href="#">
    <div class="card-body">
      <h6 class="card-subtitle">{{ title }}</h6>
      <div class="row align-items-center gx-2 mb-1">
        <div class="col-12">
          <span class="card-title h2">{{ total }}<template v-if="percentage">%</template>
          </span>
        </div>
<!--        <div class="col-6">-->
<!--          <div class="chartjs-custom" style="height: 3rem">-->
<!--            <canvas class="js-chart" data-hs-chartjs-options=""></canvas>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatisticsMiniCard",
  data: () => ({
    total: 0
  }),
  props: {
    title: String,
    dataset: Object,
    percentage: {
      default: false
    }
  },
  mounted() {
    this.total = Object.values(this.dataset).reduce((partial_sum, a) => partial_sum + a, 0)
    if (this.percentage) {
      this.total = (this.total / Object.keys(this.dataset).length).toFixed(2)
    }

    const labels = Object.keys(this.dataset)
    const values = Object.values(this.dataset)
    this.$nextTick(function() {
      require("@/theme-files/hs.chartjs")
      $.HSCore.components.HSChartJS.init($(this.$el.querySelector(".js-chart")), {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              data: values,
              backgroundColor: ["rgba(55, 125, 255, 0)", "rgba(255, 255, 255, 0)"],
              borderColor: "#377dff",
              borderWidth: 2,
              pointRadius: 0,
              pointHoverRadius: 0
            }
          ]
        },
        options: {
          scales: {
            yAxes: [
              {
                display: false
              }
            ],
            xAxes: [
              {
                display: false
              }
            ]
          },
          hover: {
            mode: "nearest",
            intersect: false
          },
          tooltips: {
            hasIndicator: true,
            intersect: false
          }
        }
      })
    })
  }
}
</script>

<style scoped></style>
