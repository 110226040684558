<template>
  <settings-accordion :show-device-picker="true">
    <template #title>Display</template>
    <div class="pb-5 border-bottom">
      <h4 class="mb-4">Size</h4>

      <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; justify-content: stretch; gap: 1rem">
        <div class="picker" @click="size = 'custom'" :class="{ active: size === 'custom' }">
          <div class="picker-box size-picker-box custom"><span></span></div>
          <div class="picker-text">Custom</div>
        </div>

        <div class="picker" @click="size = 'full-screen'" :class="{ active: size === 'full-screen' }">
          <div class="picker-box size-picker-box full-screen"><span></span></div>
          <div class="picker-text">Full Screen</div>
        </div>
        <div class="picker" @click="size = 'full-height'" :class="{ active: size === 'full-height' }">
          <div class="picker-box size-picker-box full-height"><span></span></div>
          <div class="picker-text">Full Height</div>
        </div>

        <div class="picker" @click="size = 'full-width'" :class="{ active: size === 'full-width' }">
          <div class="picker-box size-picker-box full-width"><span></span></div>
          <div class="picker-text">Full Width</div>
        </div>
      </div>
    </div>

    <div class="py-5 border-bottom">
      <h4 class="mb-4">Position</h4>

      <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; align-items: center; justify-items: stretch; gap: 1rem">
        <template
          v-for="position in [
            'top-left',
            'top-center',
            'top-right',
            'center-left',
            'center-center',
            'center-right',
            'bottom-left',
            'bottom-center',
            'bottom-right'
          ]"
        >
          <div
            class="picker "
            :key="position"
            :class="{ disabled: !getAvailablePositions[position], active: getPositionPickerMap[widgetPosition] === position }"
            @click="!!getAvailablePositions[position] ? (widgetPosition = getPickerPositionClassMap[position]) : null"
          >
            <div class="picker-box position-picker-box" :class="[position]"><span></span></div>
          </div>
        </template>
      </div>
    </div>

    <div v-if="getCurrentPositionData.offsets.length > 0" class="py-5 border-bottom">
      <h4 class="mb-4">Position Offset</h4>
      <div v-for="offset in getCurrentPositionData.offsets" :key="offset">
        <settings-template class="mb-2" v-if="offset === 'X'" title="X Axis" layout="horizontal" :show-device-picker="false">
          <range-input-with-unit v-model="offsetX" :alternative-units="['px', 'vw']" :max="100" :min="0" />
        </settings-template>

        <settings-template class="mt-2" v-if="offset === 'Y'" title="Y Axis" layout="horizontal" :show-device-picker="false">
          <range-input-with-unit v-model="offsetY" :alternative-units="['px', 'vh']" :max="100" :min="0" />
        </settings-template>
      </div>
    </div>

    <template v-if="getCurrentPositionData.overlay !== 'never'">
      <div class="py-5 border-bottom">
        <h4 class="mb-4">Overlay</h4>
        <SettingsVisibility
          :show-device-picker="false"
          v-if="getCurrentPositionData.overlay === 'optional'"
          :variable="overlayManager.getStyleVariableName('display')"
          layout="horizontal"
        />
        <SettingsBlur
          :show-device-picker="false"
          layout="horizontal"
          :variable="overlayManager.getStyleVariableName('backdrop-filter')"
          class="mt-4"
        />
      </div>
      <div class="py-5 border-bottom">
        <h4 class="mb-n3">Overlay Background</h4>
        <SettingsBackground
          title=""
          :show-device-picker="false"
          :variable="overlayManager.getStyleVariableName('background')"
          layout="vertical"
          class="mt-4"
        />
      </div>
    </template>
    <div class="py-5">
      <h4 class="mb-4">Settings</h4>
      <settings-template :show-device-picker="false" layout="horizontal" title="Animation">
        <select v-model="animation" class="form-control form-control-sm">
          <option value="none">None</option>
          <option v-for="a in availableAnimations" :key="a" :value="a">{{ a }}</option>
        </select>
      </settings-template>
      <SettingsFontFamily class="my-3" layout="horizontal" :selector="`${getPageSelector} .layout`" title="Main Font" />
      <SettingsOverflow :show-device-picker="false" layout="horizontal" :variable="layoutManager.getStyleVariableName('overflow')" />
    </div>
  </settings-accordion>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import RangeInputWithUnit from "@/components/common/RangeInputWithUnit"
import { HISTORY_TYPES } from "@/store/builder-history"
import SettingsAccordion from "@/components/campaign-builder/widget-settings/SettingsAccordion"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "Display",
  components: { SettingsTemplate, SettingsAccordion, RangeInputWithUnit },
  mixins: [appearanceChangerMixin],
  props: ["variable", "overlayManager", "layoutManager"],
  data() {
    return {
      size: null,
      positionElement: null,
      currentAnimation: null,
      positions: {
        mobile: {
          "mobile-top-center": {
            name: "Top",
            offsets: ["Y"],
            overlay: "always",
            size: "custom"
          },
          "mobile-center-center": {
            name: "Center",
            offsets: [],
            overlay: "always",
            size: "custom"
          },
          "mobile-bottom-center": {
            name: "Bottom",
            offsets: ["Y"],
            overlay: "always",
            size: "custom"
          },
          "mobile-full-screen": {
            name: "Full Screen",
            offsets: [],
            overlay: "never",
            size: "full-screen"
          },
          "mobile-fullwidth-top": {
            name: "Full Width Top",
            offsets: [],
            overlay: "never",
            size: "full-width"
          },
          "mobile-fullwidth-bottom": {
            name: "Full Width Bottom",
            offsets: [],
            overlay: "never",
            size: "full-width"
          },
          "mobile-sidebar-left": {
            name: "Sidebar Left",
            offsets: [],
            overlay: "optional",
            size: "full-height"
          },
          "mobile-sidebar-right": {
            name: "Sidebar Right",
            offsets: [],
            overlay: "optional",
            size: "full-height"
          }
        },
        desktop: {
          "desktop-top-left": {
            name: "Top Left",
            offsets: ["X", "Y"],
            overlay: "never",
            size: "custom"
          },
          "desktop-top-center": {
            name: "Top Center",
            offsets: ["Y"],
            overlay: "never",
            size: "custom"
          },
          "desktop-top-right": {
            name: "Top Right",
            offsets: ["X", "Y"],
            overlay: "never",
            size: "custom"
          },
          "desktop-center-left": {
            name: "Center Left",
            offsets: ["X"],
            overlay: "never",
            size: "custom"
          },
          "desktop-center-center": {
            name: "Center Center",
            offsets: [],
            overlay: "always",
            size: "custom"
          },
          "desktop-center-right": {
            name: "Center Right",
            offsets: ["X"],
            overlay: "never",
            size: "custom"
          },
          "desktop-bottom-left": {
            name: "Bottom Left",
            offsets: ["X", "Y"],
            overlay: "never",
            size: "custom"
          },
          "desktop-bottom-center": {
            name: "Bottom Center",
            offsets: ["Y"],
            overlay: "never",
            size: "custom"
          },
          "desktop-bottom-right": {
            name: "Bottom Right",
            offsets: ["X", "Y"],
            overlay: "never",
            size: "custom"
          },
          "desktop-full-screen": {
            name: "Full Screen",
            offsets: [],
            overlay: "never",
            size: "full-screen"
          },
          "desktop-sidebar-left": {
            name: "Sidebar Left",
            offsets: [],
            overlay: "optional",
            size: "full-height"
          },
          "desktop-sidebar-right": {
            name: "Sidebar Right",
            offsets: [],
            overlay: "optional",
            size: "full-height"
          },
          "desktop-fullwidth-top": {
            name: "Full Width Top",
            offsets: [],
            overlay: "never",
            size: "full-width"
          },
          "desktop-fullwidth-bottom": {
            name: "Full Width Bottom",
            offsets: [],
            overlay: "never",
            size: "full-width"
          }
        }
      },
      offsetX: {
        value: "0",
        unit: "px"
      },
      offsetY: {
        value: "0",
        unit: "px"
      },
      widgetPosition: null,
      availableAnimations: [
        "bounce",
        "flash",
        "pulse",
        "rubberBand",
        "shakeX",
        "shakeY",
        "headShake",
        "swing",
        "tada",
        "wobble",
        "jello",
        "heartBeat",
        "backInDown",
        "backInLeft",
        "backInRight",
        "backInUp",
        "bounceIn",
        "bounceInDown",
        "bounceInLeft",
        "bounceInRight",
        "bounceInUp",
        "fadeIn",
        "fadeInDown",
        "fadeInDownBig",
        "fadeInLeft",
        "fadeInLeftBig",
        "fadeInRight",
        "fadeInRightBig",
        "fadeInUp",
        "fadeInUpBig",
        "fadeInTopLeft",
        "fadeInTopRight",
        "fadeInBottomLeft",
        "fadeInBottomRight",
        "flip",
        "flipInX",
        "flipInY",
        "lightSpeedInRight",
        "lightSpeedInLeft",
        "rotateIn",
        "rotateInDownLeft",
        "rotateInDownRight",
        "rotateInUpLeft",
        "rotateInUpRight",
        "hinge",
        "jackInTheBox",
        "rollIn",
        "zoomIn",
        "zoomInDown",
        "zoomInLeft",
        "zoomInRight",
        "zoomInUp",
        "slideInDown",
        "slideInLeft",
        "slideInRight",
        "slideInUp"
      ]
    }
  },
  watch: {
    env() {
      this.widgetPosition = [...this.positionElement.classList].filter((p) => p.startsWith(this.env + "-"))[0]
      this.size = this.positions[this.env][this.widgetPosition].size
      this.setOffsets()
    },
    size(newValue, oldValue) {
      if (!oldValue) return
      this.widgetPosition = Object.keys(this.positions[this.env]).find((key) => {
        return this.positions[this.env][key].size === newValue
      })
    },
    offsetY: {
      deep: true,
      handler() {
        this.saveMargins()
      }
    },
    offsetX: {
      deep: true,
      handler() {
        this.saveMargins()
      }
    },
    widgetPosition(newValue, oldValue) {
      if (oldValue === null) {
        return
      }
      const currentClasses = this.positionElement.classList.toString()
      Array.from(this.positionElement.classList)
        .filter((c) => c.startsWith(this.env + "-"))
        .forEach((c) => this.positionElement.classList.remove(c))

      this.startGrouping()
      this.positionElement.classList.add(newValue)
      this.saveMargins()
      this.setOffsets()

      const variable = this.overlayManager.getStyleVariableName("display")
      const value = this.getCurrentPositionData.overlay === "always" ? "block" : "none"
      this.setStyleVariableDirectCurrentEnv({ variable, value })
      this.$store.dispatch("builderHistory/addToHistory", {
        type: HISTORY_TYPES.UPDATE_ELEMENT_CLASS,
        data: {
          oldVal: currentClasses,
          newVal: this.positionElement.classList.toString(),
          selector: "#" + this.positionElement.id
        }
      })
      this.stopGrouping()
    }
  },
  computed: {
    ...mapGetters("builder", ["getPageElement", "getStyleVariableValue", "getPageSelector"]),
    getCurrentPositionData() {
      return this.positions[this.env][this.widgetPosition]
    },
    getPositionPickerMap() {
      const data = {}
      Object.keys(this.positions[this.env]).forEach((className) => {
        data[className] = className
          .replace("desktop-", "")
          .replace("mobile-", "")
          .replace("full-screen", "center-center")
          .replace("sidebar", "center")
          .replace("fullwidth-top", "top-center")
          .replace("fullwidth-bottom", "bottom-center")
      })
      return data
    },
    getPickerPositionClassMap() {
      const data = {}
      Object.keys(this.getPositionPickerMap).forEach((className) => {
        if (this.positions[this.env][className].size === this.size) {
          data[this.getPositionPickerMap[className]] = className
        }
      })
      return data
    },
    getAvailablePositions() {
      const data = {}
      Object.keys(this.positions[this.env]).forEach((className) => {
        const value = this.positions[this.env][className]
        if (value.size === this.size) {
          const positionKey = this.getPositionPickerMap[className]
          data[positionKey] = value
        }
      })
      return data
    },
    animation: {
      get() {
        return this.currentAnimation ?? "none"
      },
      set(value) {
        const currentClasses = this.positionElement.querySelector(".inner").classList.toString()
        Array.from(this.positionElement.querySelector(".inner").classList)
          .filter((c) => c.startsWith("animate__"))
          .forEach((c) => this.positionElement.querySelector(".inner").classList.remove(c))
        this.positionElement.querySelector(".inner").classList.add("animate__animated", `animate__${value}`)
        this.currentAnimation = value
        this.$store.dispatch("builderHistory/addToHistory", {
          type: HISTORY_TYPES.UPDATE_ELEMENT_CLASS,
          data: {
            oldVal: currentClasses,
            newVal: this.positionElement.querySelector(".inner").classList.toString(),
            selector: "#" + this.positionElement.id + " .inner"
          }
        })
      }
    }
  },
  methods: {
    ...mapActions("builderHistory", ["startGrouping", "stopGrouping"]),
    saveMargins() {
      const vals = []
      vals[0] =
        this.widgetPosition.includes("-top") && this.getCurrentPositionData.offsets.includes("Y")
          ? this.offsetY.value + this.offsetY.unit
          : "0px"
      vals[2] =
        this.widgetPosition.includes("-bottom") && this.getCurrentPositionData.offsets.includes("Y")
          ? this.offsetY.value + this.offsetY.unit
          : "0px"
      vals[1] =
        this.widgetPosition.includes("-right") && this.getCurrentPositionData.offsets.includes("X")
          ? this.offsetX.value + this.offsetX.unit
          : "0px"
      vals[3] =
        this.widgetPosition.includes("-left") && this.getCurrentPositionData.offsets.includes("X")
          ? this.offsetX.value + this.offsetX.unit
          : "0px"
      this.setStyleVariableDirectCurrentEnv({ value: vals.join(" "), variable: this.variable })
    },
    setOffsets() {
      const margin = this.getStyleVariableValue(this.env, this.variable, 2)

      if (this.widgetPosition.includes("-top")) this.offsetY = margin[0]
      else if (this.widgetPosition.includes("-bottom")) this.offsetY = margin[2]
      else this.offsetY = { value: 0, unit: "px" }

      if (this.widgetPosition.includes("-right")) this.offsetX = margin[1]
      else if (this.widgetPosition.includes("-left")) this.offsetX = margin[3]
      else this.offsetX = { value: 0, unit: "px" }
    }
  },
  created() {
    this.positionElement = this.getPageElement.querySelector("[data-position]")
    this.widgetPosition = [...this.positionElement.classList].filter((p) => p.startsWith(this.env + "-"))[0]
    console.log({
      widgetPosition: this.widgetPosition
    })
    this.size = this.positions[this.env][this.widgetPosition].size
    this.setOffsets()
  }
}
</script>

<style lang="scss" scoped>
.picker {
  display: flex;
  flex-direction: column;

  .picker-box {
    transition: 200ms all;
    opacity: 0.7;
  }

  &.disabled .picker-box {
    opacity: 0.2;
  }

  &:not(.disabled) {
    cursor: pointer;
  }
  &:not(.disabled):hover .picker-box {
    opacity: 1;
  }

  &.active {
    .picker-box {
      opacity: 1;
      border-color: var(--primary);
      span {
        background: var(--primary);
      }
    }
  }

  .picker-text {
    text-align: center;
    margin-top: 0.25rem;
    font-size: 0.825rem;
    color: var(--dark);
  }

  .position-picker-box {
    height: 4.5rem;
  }
  .size-picker-box {
    aspect-ratio: 4/3;
  }
  .picker-box {
    position: relative;
    width: 100%;
    border: 1px solid var(--secondary);
    overflow: hidden;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 40%;
      aspect-ratio: 4 / 3;
      background: var(--secondary);
    }

    &.full-width span {
      width: 100%;
      height: 40%;
    }

    &.full-height span {
      height: 100%;
    }
    &.full-screen span {
      width: 100%;
      height: 100%;
    }
    &.custom span {
      left: 30%;
      top: 30%;
    }

    &.top-left span {
    }
    &.top-center span {
      top: 0;
      transform: translateX(-50%);
      left: 50%;
    }

    &.top-right span {
      left: auto;
      right: 0;
    }

    &.center-left span {
      top: 50%;
      transform: translateY(-50%);
    }
    &.center-center span {
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
    &.center-right span {
      top: 50%;
      transform: translateY(-50%);
      left: auto;
      right: 0;
    }

    &.bottom-left span {
      top: auto;
      bottom: 0;
    }
    &.bottom-center span {
      top: auto;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &.bottom-right span {
      top: auto;
      left: auto;
      bottom: 0;
      right: 0;
    }
  }
}
</style>
