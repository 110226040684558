<template>
  <div>
    <modal-gallery v-if="showModalGallery" @imageSelected="handleImageSelected" @close="showModalGallery = false" />
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h4 class="mb-0">Block Images</h4>
      <button @click="handleAddImageClick" class="btn btn-ghost-primary">
        <i class="tio-add mr-1"></i>
        Add Block Image
      </button>
    </div>
    <div v-for="({ image }, index) in images" :key="index" class="mb-4">
      <visual-asset-card
        :removable="true"
        :changeable="true"
        :thumbnail-src="image.src"
        @change="handleChangeImageClick(index)"
        @remove="removeImage(index)"
        :id="image.id"
      >
        <template #edit-body>
          <block-image-appearance :img="image" />
        </template>
      </visual-asset-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import ModalGallery from "@/components/modals/ModalGallery"
import VisualAssetCard from "@/components/campaign-builder/widget-settings/VisualAssetCard"
import BlockImageAppearance from "@/components/campaign-builder/widget-settings/BlockImageAppearance"
import randomId from "@/lib/utils/random-id"
import { HISTORY_TYPES } from "@/store/builder-history"

export default {
  name: "BlockImages",
  components: { BlockImageAppearance, VisualAssetCard, ModalGallery },
  data() {
    return {
      images: [],
      showModalGallery: false,
      galleryAction: null,
      changeImageIndex: null
    }
  },
  computed: {
    ...mapGetters("builder", ["getIframeDocument", "getPageElement"]),
    ...mapState("builderHistory", ["history"])
  },
  watch: {
    history() {
      this.fillDataImages()
    }
  },
  methods: {
    fillDataImages() {
      this.images = Array.from(this.getPageElement.querySelectorAll(".visual-asset-container[data-type='image']")).map((imageContainer) => {
        return {
          image: imageContainer.querySelector("img"),
          manager: null
        }
      })
    },
    handleChangeImageClick(index) {
      this.galleryAction = "change"
      this.changeImageIndex = index
      this.showModalGallery = true
    },
    handleAddImageClick() {
      this.galleryAction = "addNew"
      this.showModalGallery = true
    },
    handleImageSelected(imageUrl) {
      this.showModalGallery = false
      if (this.galleryAction === "addNew") {
        const parent = this.getIframeDocument.createElement("div")
        parent.id = randomId()
        parent.classList.add("osm-element", "visual-asset-container")
        parent.dataset.type = "image"
        let desktopPositions = [],
          mobilePositions = [],
          newDesktopPosition = "none",
          newMobilePosition = "none"

        Array.from(this.getPageElement.querySelectorAll(".visual-asset-container")).forEach((e) => {
          desktopPositions.push(e.dataset.desktop)
          mobilePositions.push(e.dataset.mobile)
        })

        Array("bottom", "top", "right", "left").forEach((p) => {
          if (!desktopPositions.includes(p)) newDesktopPosition = p
        })

        Array("right", "left", "bottom", "top").forEach((p) => {
          if (!mobilePositions.includes(p)) newMobilePosition = p
        })

        parent.dataset.desktop = newDesktopPosition
        parent.dataset.mobile = newMobilePosition

        parent.innerHTML = `<picture><source srcset="${imageUrl}"/><img id="${randomId()}" src="${imageUrl}" alt=""/></picture>`
        this.getPageElement.querySelector(".content-wrapper").appendChild(parent)

        this.$store.dispatch("builderHistory/addToHistory", {
          type: HISTORY_TYPES.ADD_VISUAL_ASSET,
          data: {
            selector: "#" + parent.id,
            html: parent.outerHTML,
            pageNumber: this.$store.state.builder.currentPage
          }
        })

        this.fillDataImages()

        setTimeout(() => {
          $(`#editImage${parent.querySelector("img").id}`).collapse("show")
        }, 5)
      } else if (this.galleryAction === "change") {
        console.log("change is not implemented")
        // Object.values(this.getIframeDocuments).forEach((doc) => {
        //   const owner = doc.querySelector(`${this.getPageSelector} .${this.imagesClass}`)
        //   const img = owner.querySelectorAll("img")[this.changeImageId]
        //   img.src = imageUrl
        // })
      }
    },
    removeImage(index) {
      const el = this.images[index].image.closest(".visual-asset-container")
      this.$store.dispatch("builderHistory/addToHistory", {
        type: HISTORY_TYPES.REMOVE_BLOCK_IMAGE,
        data: {
          selector: "#" + el.id,
          html: el.outerHTML,
          pageNumber: this.$store.state.builder.currentPage
        }
      })
      el.remove()
      this.fillDataImages()
    }
  },

  mounted() {
    this.fillDataImages()
  }
}
</script>
