<template>
  <div class="text-left">
    <ul class="nav nav-segment d-flex">
      <li class="nav-item flex-grow-1">
        <a
          class="nav-link justify-content-center"
          @click="updateDevice('desktop')"
          :class="{ active: previewDevice.type === 'desktop' }"
          href="javascript:;"
          data-toggle="preview-device"
          role="tab"
        >

          <i class="tio-monitor dropdown-item-icon"></i> Desktop
        </a>
      </li>
      <li class="nav-item flex-grow-1">
        <a
          class="nav-link justify-content-center"
          @click="updateDevice('mobile')"
          :class="{ active: previewDevice.type === 'mobile' }"
          href="javascript:;"
          data-toggle="preview-device"
          role="tab"
        >
          <i class="tio-android-phone dropdown-item-icon"></i> Mobile
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex"

export default {
  name: "DevicePickerTabs",
  computed: mapState("builder", ["previewDevice"]),
  methods: {
    ...mapMutations("builder", ["setPreviewDevice"]),
    updateDevice(type) {
      const width = type === "mobile" ? 375 : 1366
      const height = type === "mobile" ? 667 : 768
      this.setPreviewDevice({ type, width, height })
    }
  }
}
</script>

<style scoped></style>
