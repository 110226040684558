<template>
  <base-campaign-targeting-rule>
    <div>When a visitor is inactive for {{ data / 1000 }} seconds</div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("when", targetingRuleNames.whenRules.inactivity)

export default {
  name: "TargetingInactivity",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin]
}
</script>
