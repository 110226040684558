<template>
  <full-page-loading />
</template>

<script>
import FullPageLoading from "@/components/common/FullPageLoading"

export default {
  name: "FetchCurrentUser",
  components: { FullPageLoading },
  mounted() {
    const redirect = this.$route.params.redirect
    this.$store
      .dispatch("user/fetchCurrentUser")
      .then(() => {
        this.$router.push({ path: redirect }).catch((error) => {
          console.info(error.message)
        })
      })
      .catch(() => {
        this.$store.commit("user/clear")
        this.$router.push({ name: "Login" }).catch((error) => {
          console.info(error.message)
        })
      })
  }
}
</script>

<style scoped></style>
