import ElementManager from "@/lib/marketing-widgets/element-manager"

class CountDownManager extends ElementManager {
  constructor(element) {
    super(
      element,
      {
        "--direction": "column-reverse",
        "--number-width": {
          desktop: "56px",
          mobile: "40px"
        },
        "--number-height": {
          desktop: "56px",
          mobile: "40px"
        },
        "--number-background": "#000",
        "--number-color": "#fff",
        "--number-font-size": {
          desktop: "24px",
          mobile: "18px"
        },
        "--number-radius": "4px 4px 4px 4px",
        "--number-label-gap": "8px",
        "--label-font-size": "14px",
        "--label-color": "#222222",
        "--box-spacing": "12px",
        "--separator-color": "#000"
      },
      "countdown-"
    )
    this.element = element
  }

  static getInitialHtml() {
    return `
    <div class="osm-countdown" data-osm-count-down data-time-is-up-action="none" data-type="relative" data-relative-date="2021-11-02T15:30:00.000Z" data-constant-seconds="300">
     <div class="osm-countdown__counter days">
      <div>
        <div class="osm-countdown__number"></div>
        <div class="osm-countdown__unit"><div>days</div></div>
      </div>
      <div class="osm-countdown__separator">:</div>
    </div>
     <div class="osm-countdown__counter hours">
      <div>
        <div class="osm-countdown__number"></div>
        <div class="osm-countdown__unit"><div>hours</div></div>
      </div>
      <div class="osm-countdown__separator">:</div>
    </div>
    <div class="osm-countdown__counter minutes">
      <div>
        <div class="osm-countdown__number"></div>
        <div class="osm-countdown__unit"><div>minutes</div></div>
      </div>
      <div class="osm-countdown__separator">:</div>
    </div>
    <div class="osm-countdown__counter seconds">
      <div>
        <div class="osm-countdown__number"></div>
        <div class="osm-countdown__unit"><div>seconds</div></div>
      </div>
      <div class="osm-countdown__separator">:</div>
    </div>
  </div>
    `
  }
}

export default CountDownManager
