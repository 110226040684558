<template>
  <modal title="Update Domain" @close="$emit('close')">
    <div ref="PARENTELEMENT">
      <base-form @submit="submit">
        <div class="modal-body">
          <div class="js-form-message form-group">
            <input
              v-model="domain"
              aria-label="Mark"
              class="form-control form-control-lg"
              data-msg="Please enter your domain."
              placeholder="Your Domain"
              required
              type="text"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button v-if="!loading" class="btn btn-primary" type="submit">Update Your Website</button>
          <button v-if="loading" class="btn btn-primary" disabled type="submit">
            <span aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></span>
            Loading...
          </button>
        </div>
      </base-form>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"
import BaseForm from "@/components/BaseForm"
import { mapActions } from "vuex"
import formMixin from "@/mixins/form-mixin"

export default {
  name: "ModalUpdateSite",
  components: { BaseForm, Modal },
  mixins: [formMixin],
  props: ["site"],
  data() {
    return {
      domain: this.site.domain,
      loading: false
    }
  },
  methods: {
    ...mapActions("sites", ["updateSite"]),
    async submit() {
      this.loading = true
      const newSite = await this.updateSite({ siteId: this.site.id, domain: this.domain })
      this.loading = false
      this.$emit("siteUpdated", newSite)
    }
  }
}
</script>

<style scoped></style>
