<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h4 class="mb-0">Block Videos</h4>
      <button @click="handleAddVideo" class="btn btn-ghost-primary">
        <i class="tio-add mr-1"></i>
        Add Block Video
      </button>
    </div>
    <div v-for="({ video }, index) in videos" :key="index" class="mb-4">
      <visual-asset-card :removable="true" icon="youtube-outlined" @remove="handleRemove(index)">
        <template #edit-body>
          <block-video-appearance :video="video" />
        </template>
      </visual-asset-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import VisualAssetCard from "@/components/campaign-builder/widget-settings/VisualAssetCard"
import randomId from "@/lib/utils/random-id"
import { HISTORY_TYPES } from "@/store/builder-history"
import BlockVideoAppearance from "@/components/campaign-builder/widget-settings/BlockVideoAppearance"

export default {
  name: "BlockVideos",
  components: { BlockVideoAppearance, VisualAssetCard },
  data() {
    return {
      videos: []
    }
  },
  computed: {
    ...mapGetters("builder", ["getIframeDocument", "getPageElement"]),
    ...mapState("builderHistory", ["history"])
  },
  watch: {
    history() {
      this.fillVideoData()
    }
  },
  methods: {
    fillVideoData() {
      this.videos = Array.from(this.getPageElement.querySelectorAll(".visual-asset-container[data-type='video']")).map((assetContainer) => {
        return {
          video: assetContainer.querySelector(".block-video-container")
        }
      })
    },
    handleAddVideo() {
      const parent = this.getIframeDocument.createElement("div")
      parent.id = randomId()
      parent.classList.add("osm-element", "visual-asset-container")
      parent.dataset.type = "video"

      let desktopPositions = [],
        mobilePositions = [],
        newDesktopPosition = "none",
        newMobilePosition = "none"

      Array.from(this.getPageElement.querySelectorAll(".visual-asset-container")).forEach((e) => {
        desktopPositions.push(e.dataset.desktop)
        mobilePositions.push(e.dataset.mobile)
      })

      Array("bottom", "top", "right", "left").forEach((p) => {
        if (!desktopPositions.includes(p)) newDesktopPosition = p
      })

      Array("right", "left", "bottom", "top").forEach((p) => {
        if (!mobilePositions.includes(p)) newMobilePosition = p
      })

      parent.dataset.desktop = newDesktopPosition
      parent.dataset.mobile = newMobilePosition

      parent.innerHTML = `<div class="block-video-container" id="${randomId()}"><div class="block-video-size-wrapper">
        <iframe data-video-url="https://www.youtube.com/embed/xasrVl8IC48" src="https://www.youtube.com/embed/xasrVl8IC48?controls=1&autoplay=0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen> </iframe>
       </div></div>`

      this.getPageElement.querySelector(".content-wrapper").appendChild(parent)

      this.$store.dispatch("builderHistory/addToHistory", {
        type: HISTORY_TYPES.ADD_VISUAL_ASSET,
        data: {
          selector: "#" + parent.id,
          html: parent.outerHTML,
          pageNumber: this.$store.state.builder.currentPage
        }
      })

      this.fillVideoData()

      setTimeout(() => {
        $(`#editImage${parent.querySelector(".block-video-container").id}`).collapse("show")
      }, 5)
    },
    handleRemove(index) {
      const el = this.videos[index].video.closest(".visual-asset-container")
      this.$store.dispatch("builderHistory/addToHistory", {
        type: HISTORY_TYPES.REMOVE_BLOCK_IMAGE,
        data: {
          selector: "#" + el.id,
          html: el.outerHTML,
          pageNumber: this.$store.state.builder.currentPage
        }
      })
      el.remove()
      this.fillVideoData()
    }
  },

  mounted() {
    this.fillVideoData()
  }
}
</script>
