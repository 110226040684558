<template>
  <div>
    <div
      class="position-fixed top-0 left-0 right-0 bottom-0 d-flex flex-column align-items-center justify-content-center bg-light"
      style="width: 100vw; height: 100vh; z-index: 101"
    >
      <div class="spinner-grow text-primary mx-auto" style="width: 4rem; height: 4rem" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <h4 class="text-primary mt-4">Loading</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullPageLoading"
}
</script>
