<template>
  <portal to="modal">
    <transition-group name="fade">
      <div
        key="x"
        style="position: fixed; top: 0; left: 0; z-index: 102; width: 100vw; height: 100vh; background-color: rgba(19, 33, 68, 0.25)"
        v-if="showModal"
      ></div>
      <div
        key="y"
        class="modal show"
        style="display: block"
        v-if="showModal"
        tabindex="-1"
        role="dialog"
        :id="id"
        @click.self="$emit('close')"
      >
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document" :class="getSizeClass">
          <div class="modal-content">
            <div class="modal-header border-bottom py-4">
              <h3 class="modal-title">{{ title }}</h3>
              <button type="button" class="btn btn-xs btn-icon btn-ghost-secondary" @click="$emit('close')">
                <i class="tio-clear tio-lg"></i>
              </button>
            </div>
            <slot />
          </div>
        </div>
      </div>
    </transition-group>
  </portal>
</template>

<script>
import randomId from "@/lib/utils/random-id"

export default {
  name: "Modal",
  props: {
    title: String,
    size: {
      type: String,
      default: ""
    },
    hidden: {
      type: Boolean,
      default: false
    },
    name: String
  },
  data() {
    return {
      id: "modal-id-will-be-come",
      modal: null,
      showModal: false
    }
  },
  computed: {
    getSizeClass() {
      console.log(this.size)
      const sizes = { sm: "modal-sm", default: "", lg: "modal-lg", xl: "modal-xl" }
      return sizes[this.size]
    }
  },
  mounted() {
    this.showModal = true
    document.body.classList.add("modal-open")
  },
  created() {
    this.id = randomId()
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open")
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
