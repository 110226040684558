<template>
  <select class="form-control" :value="value" @input="$emit('input', $event.target.value.toString())" :disabled="disabled">
    <option v-for="i in 24" :key="i" :value="('0' + (i - 1)).slice(-2)">{{ ("0" + (i - 1)).slice(-2) }}</option>
  </select>
</template>

<script>
export default {
  name: "HourPicker",
  props: {
    value: String,
    disabled: {
      default: false
    }
  }
}
</script>
