<template>
  <div class="quill-custom" :class="{ 'no-settings': noSettings }">
    <div class="simple-editor shadow-soft border rounded-lg">
      <div ref="editorNode" class="editor-node"></div>
    </div>
  </div>
</template>

<script>
import Quill from "quill"
import { mapGetters } from "vuex"
import { fontList } from "@/lib/utils/google-font"
import randomId from "@/lib/utils/random-id"
import { HISTORY_TYPES } from "@/store/builder-history"

const fontFamilies = fontList.map((i) => i.replaceAll(" ", "-"))
fontFamilies.unshift(false)
const lineHeightSizes = [
  false,
  "0.8",
  "0.9",
  "1",
  "1.1",
  "1.2",
  "1.3",
  "1.4",
  "1.5",
  "1.6",
  "1.7",
  "1.8",
  "1.9",
  "2",
  "2.5",
  "3",
  "3.5",
  "4",
  "4.5",
  "5"
]
export default {
  props: {
    selector: {
      type: String,
      required: false
    },
    value: {
      type: String,
      required: false
    },
    link: {
      type: Boolean,
      default: true
    },
    fontWeight: {
      type: Boolean,
      default: true
    },
    fontFamilies: {
      type: Boolean,
      default: true
    },
    lineHeight: {
      type: Boolean,
      default: true
    },
    align: {
      type: Boolean,
      default: true
    },
    siu: {
      type: Boolean,
      default: true
    },
    noSettings: {
      type: Boolean,
      default: false
    },
    color: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      colorPickerId: "",
      backgroundColorPickerId: "",
      editorContent: null,
      editorInstance: null,
      editorOpts: {
        modules: {
          toolbar: {
            container: []
          }
        },
        theme: "snow"
      }
    }
  },
  watch: {
    editorContent(newVal) {
      if (newVal !== this.editorContent) {
        this.editorInstance.pasteHTML(newVal)
      }
    }
  },
  computed: mapGetters("builder", ["getIframeDocument"]),
  mounted() {
    this.colorPickerId = randomId()
    this.backgroundColorPickerId = randomId()
    if (!this.noSettings) {
      if (this.fontFamilies) this.editorOpts.modules.toolbar.container.push([{ "font-family": fontFamilies }])
      if (this.fontWeight)
        this.editorOpts.modules.toolbar.container.push([{ "font-weight": ["300", false, "500", "600", "700", "800", "900"] }])
      if (this.lineHeight) this.editorOpts.modules.toolbar.container.push([{ "line-height": lineHeightSizes }])
      const others = []
      if (this.color) others.push("color")
      if (this.backgroundColor) others.push("background")
      if (this.align) others.push({ align: ["", "center", "right"] })
      if (this.siu) others.push("italic", "underline", "strike")
      if (this.link) others.push("link")
      this.editorOpts.modules.toolbar.container.push(others)
    } else {
      this.editorOpts.modules.toolbar = false
    }
    this.initializeEditor()
  },

  beforeDestroy() {
    this.editorInstance.off("text-change")
  },
  methods: {
    initializeEditor() {
      if (typeof this.selector !== "undefined") {
        this.editorContent = this.getIframeDocument.querySelector(this.selector).innerHTML
      } else if (typeof this.value !== "undefined") {
        this.editorContent = this.value
      } else {
        throw new DOMException("Editor error!")
      }
      let Parchment = Quill.import("parchment")
      let FontWeightStyle = new Parchment.Attributor.Style("font-weight", "font-weight", {
        scope: Parchment.Scope.INLINE_ATTRIBUTE,
        whitelist: ["300", false, "500", "600", "700", "800", "900"]
      })
      let LineHeightStyle = new Parchment.Attributor.Style("line-height", "line-height", {
        scope: Parchment.Scope.BLOCK_ATTRIBUTE,
        whitelist: lineHeightSizes
      })
      let FontFamilyStyle = new Parchment.Attributor.Class("font-family", "font-family", {
        scope: Parchment.Scope.INLINE,
        whitelist: fontFamilies
      })
      Quill.register(
        {
          "formats/font-family": FontFamilyStyle,
          "formats/font-weight": FontWeightStyle,
          "formats/line-height": LineHeightStyle
        },
        true
      )

      const Block = Quill.import("blots/block")
      Block.tagName = "DIV"
      Quill.register(Block, true)

      this.editorInstance = new Quill(this.$refs.editorNode, this.editorOpts)
      this.editorInstance.root.innerHTML = this.editorContent.trim()
      this.editorInstance.on("text-change", this.setEditorContent)

      this.editorInstance.on(
        "text-change",
        function (delta) {
          delta.ops.forEach(
            function (operation) {
              if (operation.attributes && "font-family" in operation.attributes) {
                this.$store.dispatch("builder/setEditorFonts")
              }
            }.bind(this)
          )
        }.bind(this)
      )
      // this.setEditorContent(false)
      // clear formatting on paste
      this.editorInstance.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        let ops = []
        delta.ops.forEach((op) => {
          if (op.insert && typeof op.insert === "string") {
            ops.push({
              insert: op.insert
            })
          }
        })
        delta.ops = ops
        return delta
      })

      if (!this.noSettings && this.backgroundColor) {
        const quill = this.editorInstance
        const editor = this.$refs.editorNode
        let picker = document.getElementById(this.backgroundColorPickerId)
        if (!picker) {
          picker = document.createElement("input")
          picker.id = this.backgroundColorPickerId
          picker.type = "color"
          picker.style.width = "0px"
          picker.style.height = "0px"
          picker.style.visibility = "hidden"
          editor.parentElement.querySelector(".ql-toolbar .ql-background").parentElement.appendChild(picker)
          picker.addEventListener(
            "input",
            function () {
              quill.format("background", picker.value)
            },
            false
          )
        }

        picker = document.getElementById(this.backgroundColorPickerId)
        const toolbar = this.editorInstance.getModule("toolbar")
        toolbar.addHandler("background", function () {
          picker.click()
        })
      }

      if (!this.noSettings && this.color) {
        const quill = this.editorInstance
        const editor = this.$refs.editorNode

        let picker = document.getElementById(this.colorPickerId)
        if (!picker) {
          picker = document.createElement("input")
          picker.id = this.colorPickerId
          picker.type = "color"
          picker.style.width = "0px"
          picker.style.height = "0px"
          picker.style.visibility = "hidden"
          editor.parentElement.querySelector(".ql-toolbar .ql-color").parentElement.appendChild(picker)
          picker.addEventListener(
            "input",
            function () {
              quill.format("color", picker.value)
            },
            false
          )
        }

        picker = document.getElementById(this.colorPickerId)
        const toolbar = this.editorInstance.getModule("toolbar")
        toolbar.addHandler("color", function () {
          picker.click()
        })
      }
    },

    setEditorContent(emit = true) {
      if (this.editorInstance.getText().trim()) {
        this.editorContent = this.editorInstance.root.innerHTML.trim()
      } else {
        this.editorContent = ""
      }
      if (emit) {
        this.$emit("input", this.editorContent)
      }
      if (this.selector) {
        const currentValue = this.getIframeDocument.querySelector(this.selector).innerHTML
        this.getIframeDocument.querySelector(this.selector).innerHTML = this.editorContent

        this.$store.dispatch("builderHistory/addToHistory", {
          type: HISTORY_TYPES.CONTENT_UPDATE,
          data: {
            oldVal: currentValue,
            newVal: this.editorContent,
            selector: this.selector
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.ql-align .ql-picker-options {
  .ql-picker-item {
    width: auto;
    height: auto;

    svg {
      min-width: 18px;
      min-height: 18px;
    }
  }
}

.quill-custom .ql-editor {
  min-height: 4rem !important;
}

.quill-custom.no-settings .ql-editor {
  min-height: auto !important;
  padding: .5rem;
}
.quill-custom .ql-toolbar.ql-snow {
  padding: 0.875rem 0.5rem !important;
}

.ql-font-family {
  width: 8rem;

  .ql-picker-label::before,
  .ql-picker-options .ql-picker-item::before {
    content: "Use Parent";
  }

  .ql-picker-label[data-value="Nunito"]::before,
  .ql-picker-options .ql-picker-item[data-value="Nunito"]::before {
    content: "Nunito";
  }

  .ql-picker-label[data-value="Roboto"]::before,
  .ql-picker-options .ql-picker-item[data-value="Roboto"]::before {
    content: "Roboto";
  }

  .ql-picker-label[data-value="Poppins"]::before,
  .ql-picker-options .ql-picker-item[data-value="Poppins"]::before {
    content: "Poppins";
  }

  .ql-picker-label[data-value="Raleway"]::before,
  .ql-picker-options .ql-picker-item[data-value="Raleway"]::before {
    content: "Raleway";
  }

  .ql-picker-label[data-value="Open-Sans"]::before,
  .ql-picker-options .ql-picker-item[data-value="Open-Sans"]::before {
    content: "Open Sans";
  }

  .ql-picker-label[data-value="Lato"]::before,
  .ql-picker-options .ql-picker-item[data-value="Lato"]::before {
    content: "Lato";
  }

  .ql-picker-label[data-value="Montserrat"]::before,
  .ql-picker-options .ql-picker-item[data-value="Montserrat"]::before {
    content: "Montserrat";
  }

  .ql-picker-label[data-value="Source-Sans-Pro"]::before,
  .ql-picker-options .ql-picker-item[data-value="Source-Sans-Pro"]::before {
    content: "Source Sans Pro";
  }

  .ql-picker-label[data-value="Noto-Sans"]::before,
  .ql-picker-options .ql-picker-item[data-value="Noto-Sans"]::before {
    content: "Noto Sans";
  }

  .ql-picker-label[data-value="Ephesis"]::before,
  .ql-picker-options .ql-picker-item[data-value="Ephesis"]::before {
    content: "Ephesis";
  }

  .ql-picker-label[data-value="PT-Sans"]::before,
  .ql-picker-options .ql-picker-item[data-value="PT-Sans"]::before {
    content: "PT Sans";
  }

  .ql-picker-label[data-value="Ubuntu"]::before,
  .ql-picker-options .ql-picker-item[data-value="Ubuntu"]::before {
    content: "Ubuntu";
  }

  .ql-picker-label[data-value="Fira-Sans"]::before,
  .ql-picker-options .ql-picker-item[data-value="Fira-Sans"]::before {
    content: "Fira Sans";
  }

  .ql-picker-label[data-value="Titillium-Web"]::before,
  .ql-picker-options .ql-picker-item[data-value="Titillium-Web"]::before {
    content: "Titillium Web";
  }

  .ql-picker-label[data-value="Oxygen"]::before,
  .ql-picker-options .ql-picker-item[data-value="Oxygen"]::before {
    content: "Oxygen";
  }
}

.ql-font-weight {
  width: 6.5rem;

  .ql-picker-label::before,
  .ql-picker-options .ql-picker-item::before {
    content: "Regular";
  }

  .ql-picker-label[data-value="300"]::before,
  .ql-picker-options .ql-picker-item[data-value="300"]::before {
    content: "Tiny";
  }

  .ql-picker-label[data-value="500"]::before,
  .ql-picker-options .ql-picker-item[data-value="500"]::before {
    content: "Medium";
  }

  .ql-picker-label[data-value="600"]::before,
  .ql-picker-options .ql-picker-item[data-value="600"]::before {
    content: "Semi-Bold";
  }

  .ql-picker-label[data-value="700"]::before,
  .ql-picker-options .ql-picker-item[data-value="700"]::before {
    content: "Bold";
  }

  .ql-picker-label[data-value="800"]::before,
  .ql-picker-options .ql-picker-item[data-value="800"]::before {
    content: "Extra-Bold";
  }

  .ql-picker-label[data-value="900"]::before,
  .ql-picker-options .ql-picker-item[data-value="900"]::before {
    content: "Black";
  }
}

.ql-line-height {
  width: 7rem;

  .ql-picker-options {
    max-height: 12rem;
    overflow-y: auto !important;
  }

  .ql-picker-label::before,
  .ql-picker-options .ql-picker-item::before {
    content: "Line Height";
  }

  .ql-picker-label[data-value="0.8"]::before,
  .ql-picker-options .ql-picker-item[data-value="0.8"]::before {
    content: "0.8";
  }

  .ql-picker-label[data-value="0.9"]::before,
  .ql-picker-options .ql-picker-item[data-value="0.9"]::before {
    content: "0.9";
  }

  .ql-picker-label[data-value="1"]::before,
  .ql-picker-options .ql-picker-item[data-value="1"]::before {
    content: "1";
  }

  .ql-picker-label[data-value="1.1"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.1"]::before {
    content: "1.1";
  }

  .ql-picker-label[data-value="1.2"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.2"]::before {
    content: "1.2";
  }

  .ql-picker-label[data-value="1.3"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.3"]::before {
    content: "1.3";
  }

  .ql-picker-label[data-value="1.4"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.4"]::before {
    content: "1.4";
  }

  .ql-picker-label[data-value="1.5"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.5"]::before {
    content: "1.5";
  }

  .ql-picker-label[data-value="1.6"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.6"]::before {
    content: "1.6";
  }

  .ql-picker-label[data-value="1.7"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.7"]::before {
    content: "1.7";
  }

  .ql-picker-label[data-value="1.8"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.8"]::before {
    content: "1.8";
  }

  .ql-picker-label[data-value="1.9"]::before,
  .ql-picker-options .ql-picker-item[data-value="1.9"]::before {
    content: "1.9";
  }

  .ql-picker-label[data-value="2"]::before,
  .ql-picker-options .ql-picker-item[data-value="2"]::before {
    content: "2";
  }

  .ql-picker-label[data-value="2.5"]::before,
  .ql-picker-options .ql-picker-item[data-value="2.5"]::before {
    content: "2.5";
  }

  .ql-picker-label[data-value="3"]::before,
  .ql-picker-options .ql-picker-item[data-value="3"]::before {
    content: "3";
  }

  .ql-picker-label[data-value="3.5"]::before,
  .ql-picker-options .ql-picker-item[data-value="3.5"]::before {
    content: "3.5";
  }

  .ql-picker-label[data-value="4"]::before,
  .ql-picker-options .ql-picker-item[data-value="4"]::before {
    content: "4";
  }

  .ql-picker-label[data-value="4.5"]::before,
  .ql-picker-options .ql-picker-item[data-value="4.5"]::before {
    content: "4.5";
  }

  .ql-picker-label[data-value="5"]::before,
  .ql-picker-options .ql-picker-item[data-value="5"]::before {
    content: "5";
  }
}
</style>
