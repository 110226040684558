<template>
  <div class="card">
    <!-- Header -->
    <div class="card-header">
      <h4 class="card-header-title">Invoices</h4>
    </div>
    <div v-if="loading" class="card-body">
      <Loading />
    </div>
    <div v-else class="table-responsive">
      <table class="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table">
        <thead class="thead-light">
          <tr>
            <th>Reference</th>
            <th>Billing Reason</th>
            <th class="table-column-right-aligned">Amount</th>
            <th>Period Start</th>
            <th>Period End</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="invoice in invoices" :key="invoice.invoice_id">
            <td>#{{ invoice.invoice_id }}</td>
            <td>{{ getBillingReason(invoice.billing_reason) }}</td>
            <td class="table-column-right-aligned">${{ invoice.amount_paid / 100 }}</td>
            <td>{{ getFormattedDate(invoice.period_start * 1000) }}</td>
            <td>{{ getFormattedDate(invoice.period_end * 1000) }}</td>
            <td class="table-column-right-aligned">
              <a class="btn btn-sm btn-white mr-4" :href="invoice.invoice_pdf"> <i class="tio-download-to mr-1"></i> PDF </a>
              <a class="btn btn-sm btn-white" target="_blank" :href="invoice.hosted_invoice_url">
                <i class="tio-visible-outlined mr-1"></i> Details
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import Loading from "@/components/common/Loading"
import dateFormatterMixin from "@/mixins/date-formatter-mixin"

export default {
  name: "Invoices",
  components: { Loading },
  data() {
    return {
      loading: true,
      invoices: []
    }
  },
  mixins: [dateFormatterMixin],
  methods: {
    ...mapActions("user", ["fetchInvoices"]),
    getBillingReason(billingReason) {
      if (billingReason === "subscription_create") {
        return "New Subscription"
      }
      if (billingReason === "subscription_update") {
        return "Subscription Update"
      }
      return billingReason
    }
  },
  async mounted() {
    this.loading = true
    this.invoices = await this.fetchInvoices()
    this.loading = false
  }
}
</script>
