<template>
  <aside
    class="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered"
  >
    <div class="navbar-vertical-container">
      <div class="navbar-vertical-footer-offset">
        <div class="navbar-brand-wrapper justify-content-between">
          <!-- Logo -->

          <router-link :to="{ name: 'Dashboard' }" aria-label="OSM" class="navbar-brand">
            <span class="navbar-brand-logo">
              <h2 class="my-0 text-primary">OSM</h2>
              <!--              <div class="d-flex align-items-center">-->

              <!--                <icon-osm class="mr-2" style="height: 2.5rem; width: 2.5rem" />-->
              <!--                <span class="font-weight-bold">OSM</span>-->
              <!--              </div>-->
            </span>
            <span class="navbar-brand-logo-mini">
              <h2 class="my-0 w-100 text-center text-primary">O</h2>
<!--              <icon-osm style="height: 1.5rem; width: 1.5rem" />-->
            </span>
          </router-link>
          <!-- End Logo -->

          <!-- Navbar Vertical Toggle -->
          <button
            type="button"
            class="js-navbar-vertical-aside-toggle-invoker navbar-vertical-aside-toggle btn btn-icon btn-xs btn-ghost-dark"
          >
            <i class="tio-clear tio-lg"></i>
          </button>
          <!-- End Navbar Vertical Toggle -->
        </div>

        <!-- Content -->
        <div class="navbar-vertical-content">
          <ul class="navbar-nav navbar-nav-lg nav-tabs">
            <li class="nav-item">
              <router-link
                :to="{ name: 'Dashboard.Campaigns' }"
                class="js-nav-tooltip-link nav-link"
                title="Campaigns"
                data-placement="left"
                active-class="active"
                :disabled="sites.length < 1"
              >
                <i class="tio-dashboard nav-icon"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Campaigns</span>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link
                :to="{ name: 'Dashboard.Leads' }"
                class="js-nav-tooltip-link nav-link"
                active-class="active"
                title="Leads"
                data-placement="left"
                :disabled="sites.length < 1"
              >
                <i class="tio-group-equal nav-icon"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Leads</span>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link
                :to="{ name: 'Dashboard.Statistics' }"
                class="js-nav-tooltip-link nav-link"
                title="Statistics"
                active-class="active"
                data-placement="left"
                :disabled="sites.length < 1"
              >
                <i class="tio-chart-bar-1 nav-icon"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Statistics</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'Dashboard.Sites' }"
                class="js-nav-tooltip-link nav-link"
                title="Sites"
                active-class="active"
                data-placement="left"
              >
                <i class="tio-chart-bar-1 nav-icon"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Sites</span>
              </router-link>
            </li>

            <!-- Apps -->
            <li class="navbar-vertical-aside-has-menu">
              <a class="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle" href="javascript:;" title="Account">
                <i class="tio-apps nav-icon"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Account</span>
              </a>

              <ul class="js-navbar-vertical-aside-submenu nav nav-sub">
                <li class="nav-item">
                  <router-link :to="{ name: 'Dashboard.ProfileSettings' }" class="nav-link" active-class="active">
                    <span class="tio-circle nav-indicator-icon"></span>
                    <span class="text-truncate">Profile Settings</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'Dashboard.PlanAndBilling' }" class="nav-link" active-class="active">
                    <span class="tio-circle nav-indicator-icon"></span>
                    <span class="text-truncate">Plan & Billing</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!-- End Apps -->
          </ul>
        </div>
        <!-- End Content -->
      </div>
    </div>
  </aside>
</template>

<script>
import { mapState } from "vuex"
import Vue from "vue"
// import iconOsm from "@/icons/icon-osm.svg?inline"

export default {
  name: "SidebarNav",
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("sites", ["sites"])
  },
  // components: { iconOsm },
  mounted() {
    Vue.nextTick().then(() => {
      require("@/theme-files/hs-navbar-vertical-aside.min")
      $(".js-navbar-vertical-aside-toggle-invoker").click(function () {
        $(".js-navbar-vertical-aside-toggle-invoker i").tooltip("hide")
      })
      $(".js-navbar-vertical-aside").hsSideNav()
      $(".js-nav-tooltip-link").tooltip({ boundary: "window" })
      $(".js-nav-tooltip-link").on("show.bs.tooltip", function () {
        if (!$("body").hasClass("navbar-vertical-aside-mini-mode")) {
          return false
        }
      })
    })
  }
}
</script>
