<template>
  <div class="alert alert-warning text-center fade show" role="alert">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "DashboardWarning",
  props: {
    message: String
  }
}
</script>

<style scoped></style>
