<template>
  <settings-template layout="horizontal" title="Name" :show-device-picker="false">
    <input type="text" class="form-control form-control-sm" :value="name" @input="changeName" />
  </settings-template>
</template>

<script>
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
export default {
  name: "Name",
  components: { SettingsTemplate },
  props: ["formField"],
  data() {
    return {
      name: ""
    }
  },
  methods: {
    changeName(e) {
      this.formField.name = e.target.value
    }
  },
  created() {
    this.name = this.formField.name
  }
}
</script>
