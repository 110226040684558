<template>
  <settings-template title="Theme" :show-device-picker="showDevicePicker">
    <select
      class="form-control form-control-sm w-100"
      v-if="previewDevice.type === 'desktop'"
      v-model="desktopLookingClass"
      @change="setLooking"
    >
      <option :selected="desktopLookingClass === ''" value="">Default</option>
      <option :selected="desktopLookingClass === 'd-plain'" value="d-plain">Plain</option>
      <option :selected="desktopLookingClass === 'd-paper'" value="d-paper">Paper</option>
    </select>
    <select
      class="form-control form-control-sm w-100"
      v-if="previewDevice.type === 'mobile'"
      v-model="mobileLookingClass"
      @change="setLooking"
    >
      <option :selected="mobileLookingClass === ''" value="">Default</option>
      <option :selected="mobileLookingClass === 'm-plain'" value="m-plain">Plain</option>
      <option :selected="mobileLookingClass === 'm-paper'" value="m-paper">Paper</option>
    </select>
  </settings-template>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"

export default {
  name: "SettingFormLooking",
  components: { SettingsTemplate },
  data() {
    return {
      desktopLookingClass: "",
      mobileLookingClass: ""
    }
  },
  computed: {
    ...mapGetters("builder", ["getIframeDocument", "getPageSelector"]),
    ...mapState("builder", ["previewDevice"])
  },
  mixins: [appearanceChangerMixin],
  methods: {
    setLooking() {
      const f = this.getIframeDocument.querySelector(`${this.getPageSelector} form.form`)
      f.classList.remove("d-plain", "m-plain", "d-paper", "m-paper")
      const classes = [this.mobileLookingClass, this.desktopLookingClass]
      classes.forEach((cl) => (cl.length > 0 ? f.classList.add(cl) : ""))
    }
  },
  mounted() {
    const f = this.getIframeDocument.querySelector(`${this.getPageSelector} form.form`)
    if (f.classList.contains("d-plain")) {
      this.desktopLookingClass = "d-plain"
    } else if (f.classList.contains("d-paper")) {
      this.desktopLookingClass = "d-paper"
    } else {
      this.desktopLookingClass = ""
    }
    if (f.classList.contains("m-plain")) {
      this.mobileLookingClass = "m-plain"
    } else if (f.classList.contains("m-paper")) {
      this.mobileLookingClass = "m-paper"
    } else {
      this.mobileLookingClass = ""
    }
  }
}
</script>
