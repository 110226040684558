<template>
  <div :class="getClasses">
    <input
      class="custom-control-input"
      :id="id"
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      @change="$emit('change', $event)"
      @input="input"
    />
    <label class="custom-control-label w-100 text-dark" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
import randomId from "@/lib/utils/random-id"

export default {
  name: "Checkbox",
  props: {
    value: {
      required: true,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    verticalSpace: {
      default: 3
    }
  },
  data() {
    return {
      id: null
    }
  },
  computed: {
    getClasses() {
      const verticalSpace = "my-" + this.verticalSpace
      return `custom-control custom-checkbox ${verticalSpace}`
    }
  },
  methods: {
    input(event) {
      this.$emit("input", event.target.checked)
    }
  },
  created() {
    this.id = randomId()
  }
}
</script>
