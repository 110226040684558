<template>
  <div>
    <Header />
    <SidebarNav />
    <div class="js-navbar-vertical-aside-toggle-invoker navbar-vertical-aside-mobile-overlay"></div>
    <main id="content" role="main" class="main pointer-event">
      <div class="content container-fluid">
        <router-view :key="getPageKey" />
      </div>
      <Footer />
    </main>
  </div>
</template>

<script>
import SidebarNav from "@/components/dashboard/SidebarNav"
import Header from "@/components/dashboard/Header"
import { mapGetters } from "vuex"
import Footer from "@/components/dashboard/Footer"

export default {
  name: "Dashboard",
  components: { Footer, Header, SidebarNav },
  computed: {
    ...mapGetters("sites", ["getIdOfSelectedSite"]),
    getPageKey() {
      if (this.$route.name === "Dashboard.Sites") {
        return "sites"
      }
      return this.getIdOfSelectedSite
    }
  },
  async mounted() {
    document.body.classList.add("has-navbar-vertical-aside", "navbar-vertical-aside-show-xl", "footer-offset")
    document.body.style.backgroundColor = "#f9fafc"
  },
  beforeDestroy() {
    document.body.classList.remove("has-navbar-vertical-aside", "navbar-vertical-aside-show-xl", "footer-offset")
    document.body.style.removeProperty("background-color")
  }
}
</script>
