import FormFieldInputManager from "@/lib/marketing-widgets/form-field-input-manager"

class FormFieldRadioManager extends FormFieldInputManager {
  constructor(radiosParent) {
    super(radiosParent)
  }

  ensureStyleVariablesDefinedOnElement() {
    const radioButtons = this.element.querySelectorAll("label.radio-option")
    Object.keys(this.availableStyles).forEach((cssAttr) => {
      radioButtons.forEach((radioButton) => {
        if (!radioButton.style[cssAttr]) {
          radioButton.style[cssAttr] = `var(${this.getStyleVariableName(cssAttr)})`
        }
      })
    })
  }
}

export default FormFieldRadioManager
