<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div class="d-flex align-items-center">empty content</div>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"

const mixin = targetingRuleModalMixin("when", targetingRuleNames.whenRules.exitIntent)

export default {
  name: "ExitIntentModal",
  components: { ModalCampaignTargetingRule },
  mixins: [mixin]
}
</script>
