import { mapGetters, mapMutations } from "vuex"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const targetingRuleMixin = (typeOfRule, nameOfRule) => ({
  data() {
    return {
      typeOfRule: typeOfRule,
      nameOfRule: nameOfRule
    }
  },
  props: {
    editable: {
      default: true
    }
  },
  provide() {
    return {
      nameOfRule: this.nameOfRule,
      typeOfRule: this.typeOfRule,
      editable: this.editable
    }
  },
  computed: {
    ...mapGetters("campaignSettings", ["getRule"]),
    data() {
      return this.getRule(this.typeOfRule, this.nameOfRule)
    },
    targetingRuleNames() {
      return targetingRuleNames
    }
  },
  methods: {
    ...mapMutations("campaignSettings", ["removeRule"]),
    handleRemove() {
      this.removeRule({ typeOfRule: this.typeOfRule, name: this.nameOfRule })
    }
  }
})
export default targetingRuleMixin
