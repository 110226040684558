<template>
  <div>
    <settings-title>Closing Button & Actions</settings-title>
    <device-picker-tabs />
    <settings-accordion :show-device-picker="true">
      <template #title> X Settings</template>
      <template>
        <div class="pb-5 border-bottom">
          <h4 class="mb-4">Visibility</h4>
          <settings-visibility
            title=""
            :show-device-picker="false"
            :can-be-delayed="true"
            :selector="`${getPageSelector} .close-button`"
            :variable="manager.getStyleVariableName('display')"
            visible-display="flex"
          />
        </div>
        <div class="py-5 border-bottom">
          <h4 class="mb-4">Style</h4>
          <settings-font-family :selector="`${getPageSelector} .close-button`" layout="horizontal" class="mb-2" />
          <settings-template title="Font Weight" layout="horizontal" :show-device-picker="false" class="mb-2">
            <select v-model="fontWeight" class="form-control form-control-sm">
              <option value="400">Normal</option>
              <option value="700">Bold</option>
            </select>
          </settings-template>

          <SettingsColor
            class="mb-2"
            :show-device-picker="false"
            :variable="manager.getStyleVariableName('color')"
            layout="horizontal"
            title="X Color"
          />
        </div>
        <div class="py-5 border-bottom">
          <h4 class="mb-4">Size</h4>
          <settings-font-size
            :variable="manager.getStyleVariableName('font-size')"
            title="X Size"
            layout="horizontal"
            :show-device-picker="false"
          />
          <settings-template title="Background Ratio" layout="horizontal" :show-device-picker="false">
            <input v-model="outerSpaceRatio" class="form-control-range" max="5" min="1" step="0.1" type="range" />
          </settings-template>
        </div>
        <div class="py-5 ">
          <h4 class="mb-4">Position</h4>
          <SettingsTwoDirectionPosition
            title=""
            :show-device-picker="false"
            :variable="{
              top: manager.getStyleVariableName('top'),
              left: manager.getStyleVariableName('left'),
              right: manager.getStyleVariableName('right'),
              bottom: manager.getStyleVariableName('bottom')
            }"
            layout="vertical"
          />
        </div>
      </template>
    </settings-accordion>
    <SettingsBackgroundColor :variable="manager.getStyleVariableName('background')" layout="accordion" title="Background" />
    <settings-accordion :show-device-picker="true">
      <template #title>Border</template>
      <template>
        <SettingsBorder
          class="pb-3 border-bottom"
          :color-variable="manager.getStyleVariableName('border-color')"
          :style-variable="manager.getStyleVariableName('border-style')"
          :width-variable="manager.getStyleVariableName('border-width')"
        />
        <SettingsBorderRadius class="py-3" layout="vertical" :variable="manager.getStyleVariableName('border-radius')" />
      </template>
    </settings-accordion>
    <settings-accordion :show-device-picker="false">
      <template #title>Closing Actions</template>
      <template>
        <Checkbox v-model="closeWhenEscIsPressed"> Close when ESC is pressed.</Checkbox>
        <Checkbox v-model="closeWhenOverlayIsClicked"> Close on click Overlay.</Checkbox>
      </template>
    </settings-accordion>
  </div>
</template>

<script>
import SettingsTitle from "@/components/campaign-builder/widget-settings/SettingsTitle"
import { mapGetters } from "vuex"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import Checkbox from "@/components/common/Checkbox"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsAccordion from "@/components/campaign-builder/widget-settings/SettingsAccordion"
import DevicePickerTabs from "@/components/campaign-builder/widget-settings/DevicePickerTabs"

export default {
  name: "CloseButtonSettings",
  components: { DevicePickerTabs, SettingsAccordion, Checkbox, SettingsTemplate, SettingsTitle },
  mixins: [appearanceChangerMixin],
  props: ["manager"],
  computed: {
    ...mapGetters("builder", ["getPageSelector", "getStyleVariableValueDirectCurrentEnv", "getIframeDocument"]),
    outerSpaceRatio: {
      get() {
        return this.getStyleVariableValueDirectCurrentEnv(this.manager.getStyleVariableName("width")).replace("em", "")
      },
      set(newValue) {
        const value = newValue + "em"
        this.setStyleVariableDirectCurrentEnv({ variable: this.manager.getStyleVariableName("width"), value })
        this.setStyleVariableDirectCurrentEnv({ variable: this.manager.getStyleVariableName("height"), value })
      }
    },
    fontWeight: {
      get() {
        return this.getStyleVariableValueDirectCurrentEnv(this.manager.getStyleVariableName("font-weight"))
      },
      set(newValue) {
        this.setStyleVariableDirectCurrentEnv({
          variable: this.manager.getStyleVariableName("font-weight"),
          value: newValue
        })
      }
    },
    closeWhenEscIsPressed: {
      get() {
        return this.getIframeDocument.querySelector(`${this.getPageSelector}`).hasAttribute("data-esc-close")
      },
      set(value) {
        if (value) {
          this.getIframeDocument.querySelector(`${this.getPageSelector}`).setAttribute("data-esc-close", "true")
        } else {
          this.getIframeDocument.querySelector(`${this.getPageSelector}`).removeAttribute("data-esc-close")
        }
      }
    },
    closeWhenOverlayIsClicked: {
      get() {
        return this.getIframeDocument.querySelector(`${this.getPageSelector} .overlay`).hasAttribute("data-close")
      },
      set(value) {
        if (value) {
          this.getIframeDocument.querySelector(`${this.getPageSelector} .overlay`).setAttribute("data-close", "true")
        } else {
          this.getIframeDocument.querySelector(`${this.getPageSelector} .overlay`).removeAttribute("data-close")
        }
      }
    }
  }
}
</script>

<style scoped></style>
