<template>
  <div>
    <modal-delete-confirmation
      v-if="showDeleteModal"
      @close="showDeleteModal = false"
      @delete="handleDeleteCampaignIntegration"
      @cancel="showDeleteModal = false"
    />
    <div class="card">
      <div class="card-pinned">
        <div class="hs-unfold">
          <a
            class="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
            href="javascript:"
            :data-hs-unfold-options="`{'target': '#integration${campaignIntegration.id}', 'type': 'css-animation'}`"
          >
            <i class="tio-more-vertical"></i>
          </a>
          <div
            class="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-sm dropdown-menu-right"
            :id="`integration${campaignIntegration.id}`"
          >
            <button class="dropdown-item text-danger" @click="showDeleteModal = true">Delete</button>
          </div>
        </div>
      </div>
      <div class="card-body text-center" @click="$emit('openMatchModalToUpdate')">
        <div class="avatar avatar-xl avatar-circle avatar-border-lg avatar-centered mb-3">
          <integration-img :integration-type="userIntegration['type']" />
        </div>
        <h3 class="mb-1">
          <a class="text-dark">{{ userIntegration["name"] }}</a>
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import unfoldMixin from "@/mixins/unfold-mixin"
import IntegrationImg from "@/components/common/IntegrationImg"
import ModalDeleteConfirmation from "@/components/modals/ModalDeleteConfirmation"
import { mapState } from "vuex"

export default {
  name: "CampaignSettingsIntegrationsListItem",
  components: { ModalDeleteConfirmation, IntegrationImg },
  props: ["campaignIntegration"],
  data() {
    return {
      showDeleteModal: false
    }
  },
  computed: {
    ...mapState("sites", ["selectedSite"]),
    userIntegration() {
      return this.campaignIntegration["third_party_integration"]
    }
  },
  mixins: [unfoldMixin],
  methods: {
    async handleDeleteCampaignIntegration() {
      this.showDeleteModal = false
      this.$emit("deleted")
    }
  }
}
</script>

<style scoped></style>
