<template>
  <settings-template :title="title" :show-device-picker="false" :layout="layout">
    <select class="form-control form-control-sm" v-model="currentFont" @change="set">
      <option value="Parent Font">Parent Font</option>
      <option v-for="font in fontList" :key="font" :selected="font === currentFont" :value="font">{{ font }}</option>
    </select>
  </settings-template>
</template>

<script>
import { fontList } from "@/lib/utils/google-font"
import { mapActions, mapGetters } from "vuex"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import { HISTORY_TYPES } from "@/store/builder-history"

export default {
  name: "SettingsFontFamily",
  components: { SettingsTemplate },
  mixins: [appearanceChangerMixin],
  props: {
    selector: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "Font Family"
    },
    parentFontAvailable: {
      default: false
    }
  },
  data() {
    return {
      fontList: fontList,
      element: null,
      currentFont: ""
    }
  },
  computed: {
    ...mapGetters("builder", ["getIframeDocument"])
  },
  methods: {
    set() {
      const currentClasses = this.element.classList.toString()
      this.element.classList.forEach((c) => {
        if (c.includes("font-family")) {
          this.element.classList.remove(c)
        }
      })
      if (this.currentFont !== "Parent Font") {
        this.element.classList.add("font-family-" + this.currentFont.replaceAll(" ", "-"))
      }
      this.setEditorFonts()
      this.addToHistory({
        type: HISTORY_TYPES.UPDATE_ELEMENT_CLASS,
        data: {
          newVal: this.element.classList.toString(),
          oldVal: currentClasses,
          selector: this.selector
        }
      })
    },
    ...mapActions("builder", ["setEditorFonts"]),
    ...mapActions("builderHistory", ["addToHistory"])
  },
  created() {
    this.element = this.getIframeDocument.querySelector(this.selector)
    this.currentFont = [...this.element.classList].find((i) => i.includes("font-family-"))

    if (this.currentFont) {
      this.currentFont = this.currentFont.replace("font-family-", "").replaceAll("-", " ")
    } else {
      this.currentFont = "Parent Font"
    }
  }
}
</script>

<style scoped></style>
