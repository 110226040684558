import randomId from "@/lib/utils/random-id"

import whatsappStyleVariables from "@/lib/marketing-widgets/whatsapp/style-variables"
import { whatsappHtml } from "@/lib/marketing-widgets/whatsapp-html"

import satisMeterStyleVariables from "@/lib/marketing-widgets/satis-meter/style-variables"
import { satisMeterHtml } from "@/lib/marketing-widgets/satis-meter-html"

import wheelOfFortuneStyleVariables from "@/lib/marketing-widgets/wheel-of-fortune/style-variables"
import { wheelOfFortuneHtml } from "@/lib/marketing-widgets/wheel-of-fortune-html"

import giftPickerStyleVariables from "@/lib/marketing-widgets/gift-picker/style-variables"
import { giftPickerHtml } from "@/lib/marketing-widgets/gift-picker-html"

import flatBarStyleVariables from "@/lib/marketing-widgets/flat-bar/style-variables"
import { flatBarHtml } from "@/lib/marketing-widgets/flat-bar-html"

import multiPurposeHtml from "@/lib/marketing-widgets/multi-purpose-html"

import imageButtonStyleVariables from "@/lib/marketing-widgets/image-button/style-variables"
import imageButtonHtml from "!raw-loader!./image-button/html.html"

import justImageStyleVariables from "@/lib/marketing-widgets/just-image/style-variables"
import justImageHtml from "!raw-loader!./just-image/html.html"
import multiPurposeStyleVariables from "@/lib/marketing-widgets/multipurpose/style-variables"

class HtmlGenerator {
  constructor(pageNumber, config) {
    this.parentID = randomId()
    this.pageNumber = pageNumber
    this.config = config
    this.coreCss = process.env.VUE_APP_WIDGETS_CORE_CSS_PATH
    this.orchestratorJs = process.env.VUE_APP_WIDGETS_ORCHESTARTOR_JS_PATH
  }

  get() {
    const data = this.getData()
    data.pageOrCampaign = "campaign"
    data.parentId = this.parentID
    return this.return(data)
  }

  getPage(parentId) {
    const data = this.getData()
    data.pageOrCampaign = "page"
    data.parentId = parentId
    return this.return(data)
  }

  return({ pageOrCampaign, parentId, html, styleVariables }) {
    const widgetType = this.config.type
    console.log(this.config.typeData)

    html = `<div class="page page${this.pageNumber}"
            data-ma-type="${widgetType}"
            data-ma-sub-type="${this.config.typeData.type ?? widgetType}"
            style="display:none;" 
            data-page="${this.pageNumber}"
            data-total-page="${this.pageNumber}">
      ${html}
    </div>`

    if (pageOrCampaign === "campaign") {
      html = this.campaignHead(parentId) + `<div id="${this.parentID}" data-parent>${html}</div>` + this.campaignScript()
    }

    return { parentId, html, styleVariables }
  }

  getData() {
    const widgetType = this.config.type
    const widgetData = this.config.typeData
    let html = ""
    let styleVariables = null
    if (widgetType === "multi-purpose") {
      html = new multiPurposeHtml(widgetData.type).get()
      styleVariables = multiPurposeStyleVariables()
    } else if (widgetType === "flat-bar") {
      html = new flatBarHtml().get()
      styleVariables = flatBarStyleVariables()
    } else if (widgetType === "whatsapp") {
      html = new whatsappHtml().get()
      styleVariables = whatsappStyleVariables()
    } else if (widgetType === "satis-meter") {
      html = new satisMeterHtml().get()
      styleVariables = satisMeterStyleVariables()
    } else if (widgetType === "wheel-of-fortune") {
      html = new wheelOfFortuneHtml().get()
      styleVariables = wheelOfFortuneStyleVariables()
    } else if (widgetType === "gift-picker") {
      html = new giftPickerHtml().get()
      styleVariables = giftPickerStyleVariables()
    } else if (widgetType === "image-button") {
      html = imageButtonHtml.replace("PARENT_ID", randomId())
      styleVariables = imageButtonStyleVariables()
    } else if (widgetType === "just-image") {
      html = justImageHtml.replace("PARENT_ID", randomId())
      styleVariables = justImageStyleVariables()
    }
    return { html, styleVariables }
  }

  campaignHead() {
    return `<meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link href="" rel="stylesheet" class="osm-editor-fonts">
        <link rel="stylesheet" href="${this.coreCss}" class="osm-core"> 
        <style id="osm-user"></style>
        <style id="osm-images"></style>
        <style id="osm-custom"></style>`
  }

  campaignScript() {
    return `
<!--    <script data-osm-function="setOsmInitializePage">window.osmInitializePage = 1;</script>-->
<script src="${this.orchestratorJs}" onload="window.osmOrchestrator('#${this.parentID}')"></script>`
  }
}

export default HtmlGenerator
