<template>
  <span></span>
</template>

<script>
export default {
  name: "GoogleOAuth",
  created() {
    try {
      const redirectTo = window.localStorage.getItem("oauth-redirect")
      if (!redirectTo) throw "Redirect address not found"

      let { data } = this.$route.query
      if (!data) throw "Data not found"
      data = JSON.parse(data)
      window.localStorage.setItem("oauth-data", JSON.stringify(data))

      return this.$router.push(redirectTo)
    } catch (e) {
      console.log(e)
    }
  }
}
</script>

<style scoped></style>
