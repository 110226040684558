<template>
  <modal size="xl" title="Image Library" @close="$emit('close')">
    <div class="modal-body">
      <div :id="id" class="js-dropzone dropzone-custom custom-file-boxed mb-4" style="padding: 1rem">
        <div class="dz-message custom-file-boxed-label d-flex justify-content-center align-items-center">
          <img class="avatar avatar-xl avatar-4by3 mr-6" :src="browseSvg" />
          <div class="d-flex flex-column">
            <h5 class="mb-0">Drag and drop your file here</h5>
            <span class="btn btn-sm btn-primary mt-2">Or Browser Files</span>
          </div>
        </div>
      </div>
      <div class="row">
        <a
          href="javascript:;"
          class="col-12 col-md-4 col-lg-3 d-flex mb-3"
          v-for="image in images"
          :key="image.id"
          @click="$emit('imageSelected', image['access_url'])"
        >
          <div class="d-flex align-items-center border shadow rounded w-100">
            <img style="width: 100%; height: 160px; object-fit: contain" :src="image.access_url" alt="" />
          </div>
        </a>
      </div>
    </div>
  </modal>
</template>

<script>
import imageAPI from "@/api/image"
import browseSvg from "@/theme-files/assets/svg/illustrations/browse.svg"
import Modal from "@/components/modals/Modal"
import randomId from "@/lib/utils/random-id"

export default {
  name: "ModalGallery",
  components: { Modal },
  data() {
    return {
      loading: false,
      images: [],
      id: "modal-gallery-" + randomId()
    }
  },
  computed: {
    browseSvg() {
      return browseSvg
    }
  },
  async created() {
    this.loading = true
    const response = await imageAPI.fetchImages()
    this.images = response.data.data.images
    this.loading = false
  },
  mounted() {
    const vm = this
    this.$nextTick().then(() => {
      window.Dropzone = require("@/theme-files/dropzone.min")
      require("@/theme-files/hs.dropzone")
      const dropzone = $.HSCore.components.HSDropzone.init("#" + vm.id, {
        url: imageAPI.getUploadEndpoint(),
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("access_token")
        },
        acceptedFiles: "image/*",
        maxFilesize: 4
      })
      dropzone.on("success", function (file, resp) {
        const image = resp.data.image
        vm.images.unshift(image)
        vm.images = [...vm.images]
        setTimeout(() => {
          dropzone.removeFile(file)
        }, 1000)
      })
    })
  }
}
</script>
