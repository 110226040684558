import ElementManager from "@/lib/marketing-widgets/element-manager"

class DividerManager extends ElementManager {
  constructor(dividerParent) {
    if (!dividerParent.querySelector(".divider-line")) {
      dividerParent.innerHTML = `<div class="divider-line"></divu>`
    }
    const element = dividerParent.querySelector(".divider-line")
    new ElementManager(
      dividerParent,
      {
        display: "flex",
        "flex-direction": "column"
      },
      "divider-parent"
    )

    super(
      element,
      {
        "align-self": "center",
        width: "100%",
        "border-style": "solid",
        "border-width": "4px 0px 0px 0px",
        "border-color": "#000"
      },
      "divider"
    )
  }
}

export default DividerManager
