<template>
  <div>
    <modal-campaign-type-selector
      v-if="showCampaignTypeSelector"
      @close="showCampaignTypeSelector = false"
      :next="change"
      :show-update-warning="true"
    />

    <div class="d-inline-flex align-items-center">
      <button @click="showCampaignTypeSelector = true" class="btn btn-white btn-sm font-weight-bolder">
        <i class="tio-sync mr-1"></i> {{ type }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import ModalCampaignTypeSelector from "@/components/modals/ModalCampaignTypeSelector"

export default {
  name: "PageType",
  components: { ModalCampaignTypeSelector },
  data: () => ({
    showCampaignTypeSelector: false
  }),
  computed: {
    ...mapGetters("builder", ["getPageElement"]),
    type() {
      if (this.getPageElement.dataset.maSubType === "panel") {
        return "Panel"
      }

      if (this.getPageElement.dataset.maSubType === "popup") {
        return "Pop Up"
      }

      return this.getPageElement.dataset.maSubType
    }
  },
  methods: {
    ...mapActions("builder", ["changePageType"]),
    change(data) {
      this.showCampaignTypeSelector = false
      this.changePageType(data)
    }
  }
}
</script>

<style scoped></style>
