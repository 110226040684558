<template>
  <div>
    <dashboard-title title="Campaign Summary" />
    <campaign-variants-table :campaign-id="$route.params.id" />
    <campaign-summary-settings />
    <campaign-summary-leads-table :campaign-id="$route.params.id" />
  </div>
</template>

<script>
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import CampaignVariantsTable from "@/components/dashboard/CampaignVariantsTable"
import CampaignSummarySettings from "@/components/dashboard/CampaignSummarySettings"
import CampaignSummaryLeadsTable from "@/components/dashboard/CampaignSummaryLeadsTable"
import ensureHasSite from "@/mixins/ensure-has-site"

export default {
  name: "CampaignSummary",
  mixins: [ensureHasSite],
  components: { CampaignSummaryLeadsTable, CampaignSummarySettings, CampaignVariantsTable, DashboardTitle }
}
</script>

<style scoped></style>
