const stringCompareOperators = {
  equals: "Equals",
  notEquals: "Not Equals",
  contains: "Contains",
  notContains: "Not Contains",
  startsWith: "Starts With",
  notStartsWith: "Not Starts With",
  endsWith: "Ends With",
  notEndsWith: "Not Ends With"
}
export default stringCompareOperators
