const advancedSelectMixin = {
  methods: {
    initAdvancedSelect() {
      require("@/theme-files/select2.full.min")
      require("@/theme-files/hs.select2")
      const parent = this.$refs.PARENTELEMENT ? this.$refs.PARENTELEMENT : this.$el
      parent.querySelectorAll(".js-select2-custom").forEach(function (item) {
        $.HSCore.components.HSSelect2.init($(item))
      })
    }
  }
}
export default advancedSelectMixin
