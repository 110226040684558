<template>
  <settings-template :layout="layout" :title="title" :show-device-picker="showDevicePicker">
    <div class="d-flex">
      <div class="btn-group-toggle">
        <label class="btn btn-ghost-secondary btn-sm" :class="{ active: fontWeight }">
          <input type="checkbox" v-model="fontWeight" /> <i class="tio-bold"></i>
        </label>
      </div>

      <div class="btn-group-toggle ml-1">
        <label class="btn btn-ghost-secondary btn-sm" :class="{ active: fontStyle }">
          <input type="checkbox" v-model="fontStyle" /> <i class="tio-italic"></i>
        </label>
      </div>

      <div class="btn-group-toggle ml-1">
        <label class="btn btn-ghost-secondary btn-sm" :class="{ active: textDecoration }">
          <input type="checkbox" v-model="textDecoration" /> <i class="tio-underline"></i>
        </label>
      </div>
    </div>
  </settings-template>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "SettingsTextStyle",
  props: {
    title: {
      type: String,
      default: "Text Style"
    }
  },
  mixins: [appearanceChangerMixin],
  components: { SettingsTemplate },
  computed: {
    fontWeight: {
      get() {
        return this.getStyleVariableValueDirectCurrentEnv(this.variable.fontWeight) === "bold"
      },
      set(value) {
        this.setStyleVariableDirectCurrentEnv({
          variable: this.variable.fontWeight,
          value: value ? "bold" : "normal"
        })
      }
    },
    fontStyle: {
      get() {
        return this.getStyleVariableValueDirectCurrentEnv(this.variable.fontStyle) === "italic"
      },
      set(value) {
        this.setStyleVariableDirectCurrentEnv({
          variable: this.variable.fontStyle,
          value: value ? "italic" : "normal"
        })
      }
    },
    textDecoration: {
      get() {
        return this.getStyleVariableValueDirectCurrentEnv(this.variable.textDecoration) === "underline"
      },
      set(value) {
        this.setStyleVariableDirectCurrentEnv({
          variable: this.variable.textDecoration,
          value: value ? "underline" : "normal"
        })
      }
    }
  }
}
</script>
