import "@/theme-files/icon-set.css"
import "@/theme-files/select2.min.css"
import "@/theme-files/Chart.min.css"
import "@/theme-files/flatpickr.min.css"
import "daterangepicker/daterangepicker.css"
import "@/theme-files/theme.css"
import "quill/dist/quill.snow.css"
import "@/assets/scss/main.scss"

window.$ = require("jquery")
require("jquery-migrate")
require("bootstrap/dist/js/bootstrap.bundle.min")
require("jquery-validation")
require("@/theme-files/jquery.dataTables.min")
require("@/theme-files/datatables.net.extensions/select/select.min.js")
require("daterangepicker/daterangepicker")
require("@/theme-files/Chart.min")
require("@/theme-files/flatpickr.min")
require("@/theme-files/chartjs-extensions")

require("@/theme-files/pwstrength-bootstrap.min")
