function toString(parentId, styleVariables) {
  let styleText = ""
  const deviceMap = {
    mobile: { prefix: "@media (max-width: 959px) {", suffix: "}" },
    desktop: { prefix: "@media (min-width: 960px) {", suffix: "}" },
    all: { prefix: "", suffix: "" }
  }
  Object.keys(styleVariables).forEach((pageNumber) => {
    Object.keys(deviceMap).forEach((device) => {
      const pageStyleVariables = styleVariables[pageNumber]
      if (device in pageStyleVariables) {
        const { prefix, suffix } = deviceMap[device]
        styleText += `${prefix} #${parentId} .page${pageNumber}{`
        Object.keys(pageStyleVariables[device]).forEach((key) => (styleText += `${key}:${pageStyleVariables[device][key]};`))
        styleText += `} ${suffix}`
      }
    })
  })
  return styleText
}

const campaignStyleVariablesGenerator = { toString }
export default campaignStyleVariablesGenerator
