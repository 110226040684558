<template>
  <modal title="Your Domain" @close="$emit('close')">
    <div ref="PARENTELEMENT">
      <base-form @submit="submit">
        <div class="modal-body">
          <div class="js-form-message form-group">
            <input
              type="text"
              class="form-control form-control-lg"
              placeholder="Your Domain"
              aria-label="Mark"
              v-model="domain"
              required
              data-msg="Please enter your domain."
            />
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" v-if="!loading">Add Your Website</button>
          <button class="btn btn-primary" type="submit" disabled v-if="loading">
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
        </div>
      </base-form>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"
import BaseForm from "@/components/BaseForm"
import { mapActions } from "vuex"
import formMixin from "@/mixins/form-mixin"

export default {
  name: "ModalAddNewSite",
  components: { BaseForm, Modal },
  mixins: [formMixin],
  data() {
    return {
      domain: "",
      loading: false
    }
  },
  methods: {
    ...mapActions("sites", ["addSite"]),
    async submit() {
      this.loading = true
      const newSite = await this.addSite(this.domain)
      this.loading = false
      this.$emit("siteCreated", newSite)
    }
  }
}
</script>

<style scoped></style>
