import FormFieldRadioManager from "@/lib/marketing-widgets/form-field-radios-manager"
import FormFieldInputManager from "@/lib/marketing-widgets/form-field-input-manager"
import FormFieldRatingManager from "@/lib/marketing-widgets/form-field-rating-manager"
import FormFieldCheckboxesManager from "@/lib/marketing-widgets/form-field-checkboxes-manager"

function formFieldInputManagerFactory(element) {
  if (element.classList.contains("osm-form-radios")) {
    return new FormFieldRadioManager(element)
  }
  if (element.classList.contains("osm-form-checkboxes")) {
    return new FormFieldCheckboxesManager(element)
  }
  if (element.classList.contains("osm-form-rating")) {
    return new FormFieldRatingManager(element)
  }
  return new FormFieldInputManager(element)
}

export default formFieldInputManagerFactory
