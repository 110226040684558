<template>
  <settings-template :title="title" :layout="layout">
    <two-direction-position :variable="variable" />
  </settings-template>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import TwoDirectionPosition from "@/components/campaign-builder/appearance-changers/TwoDirectionPosition"

export default {
  name: "SettingsSize",
  components: { TwoDirectionPosition, SettingsTemplate },
  props: {
    variables: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: "Size"
    }
  },

  computed: {
    value: {
      get() {
        return this.getStyleVariableValue(this.env, this.variables[0], 1)
      },
      set(value) {
        this.variables.forEach((variable) => {
          this.setStyleVariableDirect({
            env: this.env,
            variable: variable,
            value: value.value + value.unit
          })
        })
      }
    }
  },
  mixins: [appearanceChangerMixin]
}
</script>
