import dayjs from "dayjs"

const timezone = require("dayjs/plugin/timezone")
const utc = require("dayjs/plugin/utc")

const dateFormatterMixin = {
  methods: {
    getFormattedDate(date) {
      dayjs.extend(timezone)
      dayjs.extend(utc)
      const guess = dayjs.tz.guess()
      return dayjs(date).tz(guess).format("MMMM D, YYYY")
    },
    getFormattedDateTime(date) {
      dayjs.extend(timezone)
      dayjs.extend(utc)
      const guess = dayjs.tz.guess()
      return dayjs(date).tz(guess).format("MMMM D, YYYY@HH:mm")
    }
  }
}

export default dateFormatterMixin
