<template>
  <div>
    <select
      ref="item"
      class="js-select2-custom custom-select"
      size="1"
      style="opacity: 0"
      :data-hs-select2-options="optionsString"
      @change="handleChange"
    >
      <slot />
    </select>
  </div>
</template>

<script>
import advancedSelectMixin from "@/mixins/advanced-select-mixin"

export default {
  name: "SearchableDropdown",
  data() {
    return {
      optionsString: ""
    }
  },
  props: {
    value: String,
    placeholder: String,
    searchInputPlaceholder: String,
  },
  mixins: [advancedSelectMixin],
  methods: {
    handleChange(e) {
      console.log({ e })
    }
  },
  created() {
    this.optionsString = `{
        "placeholder": "${this.placeholder}",
        "searchInputPlaceholder": "${this.searchInputPlaceholder}"
      }`
  },
  mounted() {
    const item = this.$refs["item"]
    this.initAdvancedSelect()
    $(item).on("change.select2", () => {
      const data = $(item).select2("data")
      this.$emit("input", data[0].id === "null" ? null : data[0].id)
    })
  }
}
</script>

<style scoped></style>
