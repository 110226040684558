async function getScreenshot({ url, width, height }) {
  const requestUrl = "https://vercel-ss.emincanozcan.com/api/get-screenshot-image"
  const body = { url, width, height }
  const res = await window.axios.post(requestUrl, body, { headers: null })
  if (res.status > 300 || res.status < 200 || !res.data.data) {
    throw new Error(res)
  }
  return res.data.data
}

export default getScreenshot
