<template>
  <router-view />
</template>

<script>
import userAPI from "@/api/user"

export default {
  name: "Admin",
  async beforeRouteEnter(to, from, next) {
    const resp = await userAPI.fetchCurrentUser()
    if (!resp.data.data.user["is_admin"]) {
      return next({ name: "NotFound" })
    }
    next()
  }
}
</script>

<style scoped></style>
