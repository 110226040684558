<template>
  <div>
    <device-picker-tabs />
    <settings-accordion>
      <template #title>Timer</template>
      <template>
        <div class="pb-5 border-bottom">
          <h4 class="mb-4">Actions</h4>
          <div>
            <settings-template title="Timer Type" layout="horizontal" :show-device-picker="false">
              <select v-model="type" class="form-control form-control-sm">
                <option value="constant">Constant</option>
                <option value="relative">Relative</option>
              </select>
            </settings-template>
          </div>
          <div v-if="type === 'relative'">
            <settings-template class="mt-3" layout="horizontal" :show-device-picker="false" title="End Date">
              <date-picker class="form-control-sm" v-model="relativeDateInput" :enable-time="true" />
            </settings-template>
          </div>
          <div class="" v-if="type === 'constant'">
            <settings-template class="mt-3" :show-device-picker="false" title="Days" layout="horizontal">
              <input v-model.number="constantDaysInput" :min="0" :max="365" class="form-control-range" type="range" />
            </settings-template>
            <settings-template class="mt-3" :show-device-picker="false" title="Hours" layout="horizontal">
              <input v-model.number="constantHoursInput" :min="0" :max="23" class="form-control-range" type="range" />
            </settings-template>
            <settings-template class="mt-3" :show-device-picker="false" title="Minutes" layout="horizontal">
              <input v-model.number="constantMinutesInput" :min="0" :max="59" class="form-control-range" type="range" />
            </settings-template>
            <settings-template class="mt-3" :show-device-picker="false" title="Seconds" layout="horizontal">
              <input v-model.number="constantSecondsInput" :min="0" :max="59" class="form-control-range" type="range" />
            </settings-template>
          </div>
        </div>
        <div class="py-5">
          <h4 class="mb-4">Settings</h4>
          <settings-font-family layout="horizontal" :selector="`#${manager.elementId}`" :parent-font-available="true" />
          <settings-template class="mt-3" layout="horizontal" :show-device-picker="false" title="Label Position">
            <select class="form-control form-control-sm" v-model="direction">
              <option value="column">Bottom</option>
              <option value="column-reverse">Top</option>
            </select>
          </settings-template>

          <settings-template class="mt-3" layout="horizontal" :show-device-picker="false" title="Time Up Action">
            <select v-model="timeIsUpAction" class="form-control form-control-sm">
              <option value="none">None</option>
              <option value="close">Close The Popup</option>
            </select>
          </settings-template>
        </div>
      </template>
    </settings-accordion>
    <settings-accordion>
      <template #title>Spacing</template>
      <template>
        <settings-template class="mb-2" layout="horizontal" :show-device-picker="false" title="Box-Label Gap">
          <settings-range-with-unit
            :variable="manager.getStyleVariableName('--number-label-gap')"
            :min="0"
            :max="100"
            :alternative-units="['px']"
          />
        </settings-template>
        <settings-template layout="horizontal" :show-device-picker="false" title="Space Between Boxes">
          <settings-range-with-unit
            :variable="manager.getStyleVariableName('--box-spacing')"
            :min="0"
            :max="100"
            :alternative-units="['px']"
          />
        </settings-template>
      </template>
    </settings-accordion>

    <settings-accordion>
      <template #title>Number</template>
      <template>
        <div class="pb-5 border-bottom">
          <h4 class="mb-4">Size</h4>
          <settings-font-size
            :show-device-picker="false"
            class="mb-2"
            layout="horizontal"
            :variable="manager.getStyleVariableName('--number-font-size')"
          />
          <settings-width
            :show-device-picker="false"
            class="mb-2"
            layout="horizontal"
            :variable="manager.getStyleVariableName('--number-width')"
          />
          <settings-height
            :show-device-picker="false"
            class="mb-2"
            layout="horizontal"
            :variable="manager.getStyleVariableName('--number-height')"
          />
        </div>
        <div class="py-5 border-bottom">
          <h4 class="mb-4">Style</h4>
          <settings-color
            :show-device-picker="false"
            layout="horizontal"
            :variable="manager.getStyleVariableName('--number-color')"
            title="Number Color"
          />
        </div>

        <div class="py-5 border-bottom">
          <h4 class="mb-4">Background</h4>
          <settings-background
            layout="vertical"
            :show-device-picker="false"
            :variable="manager.getStyleVariableName('--number-background')"
            title=""
          />
        </div>

        <div class="py-5">
          <settings-border-radius :variable="manager.getStyleVariableName('--number-radius')" title="Number Radius" />
        </div>
      </template>
    </settings-accordion>

    <settings-accordion>
      <template #title>Label</template>
      <template>
        <div class="pb-5 border-bottom">
          <h4 class="mb-4">Text</h4>
          <settings-template class="mb-2" title="Days" layout="horizontal" :show-device-picker="false">
            <mini-editor :no-settings="true" :selector="`#${manager.elementId} .days .osm-countdown__unit`" />
          </settings-template>
          <settings-template class="mb-2" title="Hours" layout="horizontal" :show-device-picker="false">
            <mini-editor :no-settings="true" :selector="`#${manager.elementId} .hours .osm-countdown__unit`" />
          </settings-template>
          <settings-template class="mb-2" title="Minutes" layout="horizontal" :show-device-picker="false">
            <mini-editor :no-settings="true" :selector="`#${manager.elementId} .minutes .osm-countdown__unit`" />
          </settings-template>
          <settings-template class="mb-2" title="Seconds" layout="horizontal" :show-device-picker="false">
            <mini-editor :no-settings="true" :selector="`#${manager.elementId} .seconds .osm-countdown__unit`" />
          </settings-template>
        </div>
        <div class="py-5 border-bottom">
          <h4 class="mb-4">Size</h4>
          <settings-font-size
            :variable="manager.getStyleVariableName('--label-font-size')"
            :show-device-picker="false"
            layout="horizontal"
          />
        </div>
        <div class="py-5">
          <h4 class="mb-4">Style</h4>

          <settings-color :variable="manager.getStyleVariableName('--label-color')" layout="horizontal" :show-device-picker="false" />
        </div>
      </template>
    </settings-accordion>

    <settings-accordion>
      <template #title>Separator</template>
      <template>
        <div>
          <h4 class="mb-4">Style</h4>
          <settings-color :variable="manager.getStyleVariableName('--separator-color')" :show-device-picker="false" layout="horizontal" />
        </div>
      </template>
    </settings-accordion>
  </div>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"
import DatePicker from "@/components/common/DatePicker"
import dayjs from "dayjs"
import SettingsAccordion from "@/components/campaign-builder/widget-settings/SettingsAccordion"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import DevicePickerTabs from "@/components/campaign-builder/widget-settings/DevicePickerTabs"

export default {
  name: "CountDownSettings",
  components: { DevicePickerTabs, SettingsTemplate, SettingsAccordion, DatePicker, MiniEditor },
  data() {
    return {
      type: this.manager.element.dataset.type,
      relativeDate: "",
      constantSeconds: ""
    }
  },
  props: ["manager"],
  mixins: [appearanceChangerMixin],
  watch: {
    type(newValue) {
      this.manager.element.dataset.type = newValue
    },
    relativeDate(newValue) {
      this.manager.element.dataset.relativeDate = newValue
    },
    constantSeconds(newValue) {
      this.manager.element.dataset.constantSeconds = newValue
    }
  },
  computed: {
    direction: {
      get() {
        return this.getStyleVariableValueDirectCurrentEnv(this.manager.getStyleVariableName("--direction"))
      },
      set(value) {
        this.setStyleVariableDirectCurrentEnv({
          variable: this.manager.getStyleVariableName("--direction"),
          value: value
        })
      }
    },
    relativeDateInput: {
      get() {
        const i = dayjs(this.relativeDate)
        return `${i.month() + 1}/${("0" + i.date()).slice(-2)}/${i.year()} ${i.hour()}:${i.minute()}`
      },
      set(value) {
        const parsed = dayjs(value, "MM/D/YYYY H:i")
        this.relativeDate = parsed.toISOString()
      }
    },
    timeIsUpAction: {
      get() {
        return this.manager.element.dataset.timeIsUpAction
      },
      set(value) {
        this.manager.element.dataset.timeIsUpAction = value
      }
    },
    constantSecondsInput: {
      get() {
        return this.constantSeconds % 60
      },
      set(value) {
        this.constantSeconds += value - this.constantSecondsInput
      }
    },
    constantMinutesInput: {
      get() {
        return Math.floor((this.constantSeconds % 3600) / 60)
      },
      set(value) {
        this.constantSeconds += (value - this.constantMinutesInput) * 60
      }
    },
    constantHoursInput: {
      get() {
        return Math.floor((this.constantSeconds % (3600 * 24)) / 3600)
      },
      set(value) {
        this.constantSeconds += (value - this.constantHoursInput) * 60 * 60
      }
    },

    constantDaysInput: {
      get() {
        return Math.floor(this.constantSeconds / (3600 * 24))
      },
      set(value) {
        this.constantSeconds += (value - this.constantDaysInput) * 60 * 60 * 24
      }
    }
  },
  created() {
    this.relativeDate = this.manager.element.dataset.relativeDate
    this.constantSeconds = parseInt(this.manager.element.dataset.constantSeconds)
  }
}
</script>

<style scoped></style>
