<template>
  <base-campaign-targeting-rule>
    <div>
      <strong>Visitors who are using:</strong>
      <div>{{ data.map((d) => osMap[d]).join(" or ") }}</div>
    </div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("who", targetingRuleNames.whoRules.operatingSystem)

const osMap = {
  iOS: "iOS",
  Android: "Android",
  Windows: "Windows",
  MacOS: "macOS",
  Linux: "Linux",
  WindowsPhone: "Windows Phone",
  WebOS: "WebOS",
  BlackBerry: "BlackBerry",
  Bada: "Bada",
  Tizen: "Tizen",
  ChromeOS: "Chrome OS",
  PlayStation4: "PlayStation 4",
  Roku: "Roku"
}
export default {
  name: "TargetingOperatingSystem",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin],
  data() {
    return {
      osMap: osMap
    }
  }
}
</script>
