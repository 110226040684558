<template>
  <div v-if="this.manager">
    <checkbox :value="formInputData.label !== false" @input="toggleLabel"> Use Label </checkbox>
    <settings-accordion v-if="formInputData.label !== false">
      <template #title>Label Settings</template>
      <template #default>
        <MiniEditor :selector="`${getPageSelector} #${formInputData.label.id}`" />
        <SettingsFontSize layout="horizontal" class="mt-4" :variable="manager.getStyleVariableName('font-size')" />
        <SettingsBackgroundColor :variable="manager.getStyleVariableName('background')" />
        <SettingsMargin :variable="manager.getStyleVariableName('margin')" />
        <SettingsPadding :variable="manager.getStyleVariableName('padding')" />
        <SettingsBorderRadius :variable="manager.getStyleVariableName('border-radius')" />
        <SettingsBorder
          :color-variable="manager.getStyleVariableName('border-color')"
          :style-variable="manager.getStyleVariableName('border-style')"
          :width-variable="manager.getStyleVariableName('border-width')"
        />
      </template>
    </settings-accordion>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"
import randomId from "@/lib/utils/random-id"
import SettingsAccordion from "@/components/campaign-builder/widget-settings/SettingsAccordion"
import Checkbox from "@/components/common/Checkbox"
import FormFieldLabelManager from "@/lib/marketing-widgets/form-field-label-manager"

export default {
  name: "Label",
  components: { Checkbox, SettingsAccordion, MiniEditor },
  computed: {
    ...mapGetters("builder", ["getPageSelector", "getIframeDocument"])
  },
  props: ["formInputData"],
  data() {
    return {
      manager: null
    }
  },
  methods: {
    toggleLabel(v) {
      if (v) {
        this.addLabel()
      } else {
        this.removeLabel()
      }
    },
    addLabel() {
      const field = this.formInputData.field
      const label = this.getIframeDocument.createElement("label")
      label.setAttribute("for", field.id)
      label.id = randomId()
      label.innerHTML = "New Label"
      field.closest(".form-field").prepend(label)
      this.$emit("updateFormInputData")
    },
    removeLabel() {
      this.formInputData.label.remove()
      this.$emit("updateFormInputData")
    }
  },
  created() {
    this.manager = new FormFieldLabelManager(this.formInputData.label)
  }
}
</script>
