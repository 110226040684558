<template>
  <button :class="active ? 'active' : ''" class="btn btn-soft-secondary" @click="(e) => $emit('click', e)">
    <slot />
  </button>
</template>

<script>
export default {
  name: "CampaignTypeSelectButton",
  props: {
    active: {
      default: false
    }
  }
}
</script>
