<template>
  <gradient-color-picker v-model="color" />
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import GradientColorPicker from "@/components/campaign-builder/appearance-changers/base/GradientColorPicker"

export default {
  name: "ColorGradient",
  mixins: [appearanceChangerMixin],
  components: { GradientColorPicker },
  data() {
    return {
      color: ""
    }
  },
  watch: {
    color(newValue) {
      this.setStyleVariableDirect({
        env: this.env,
        value: newValue,
        variable: this.variable
      })
    }
  },
  created() {
    this.color = this.getStyleVariableValue(this.env, this.variable, 0)
  }
}
</script>
