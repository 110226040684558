<template>
  <div>
    <settings-title>{{ getTitle }}</settings-title>
    <SettingsDeviceSelector />
    <div :key="appearanceDevice">
      <Layout :device="appearanceDevice" v-if="setAreaTopic === 'layout'" />

      <template v-if="setAreaTopic === 'close-button'">
        <SettingsColor title="Color" variable="--close-button-color" />
        <SettingsBackgroundColor variable="--close-button-background" />
        <SettingsSize :variables="['--close-button-size']" title="Icon Size" />
        <SettingsVisibility variable="--close-button-display" />
        <SettingsPadding variable="--close-button-padding" />
        <SettingsBorderRadius variable="--close-button-border-radius" />
        <SettingsHorizontalPosition left-variable="--close-button-left" right-variable="--close-button-right" />
      </template>

      <template v-if="setAreaTopic === 'message'">
        <SettingsTextColor variable="--message-color" />
        <SettingsFontSize variable="--message-font-size" />
      </template>

      <template v-if="setAreaTopic === 'bar-button'">
        <SettingsTextColor variable="--button-color" />
        <SettingsBackgroundColor variable="--button-background" />
        <SettingsFontSize variable="--button-font-size" />
        <SettingsMargin variable="--button-margin" />
        <SettingsPadding variable="--button-padding" />
        <SettingsBorderRadius variable="--button-border-radius" />
        <SettingsBorder
          color-variable="--button-border-color"
          style-variable="--button-border-style"
          width-variable="--button-border-width"
        />
      </template>

      <Images v-if="setAreaTopic === 'images'" images-class="images" />
    </div>
  </div>
</template>

<script>
import Layout from "@/components/campaign-builder/widget-settings/flat-bar/Appearance/Layout"
import SettingsDeviceSelector from "@/components/campaign-builder/SettingsDeviceSelector"
import Images from "@/components/campaign-builder/appearance-changers/Images"
import appearanceSettingsMixin from "@/mixins/appearance-settings-mixin"
import SettingsTitle from "@/components/campaign-builder/widget-settings/SettingsTitle"
import { mapMutations } from "vuex"

export default {
  name: "Appearance",
  components: {
    SettingsTitle,
    Images,
    Layout,
    SettingsDeviceSelector
  },
  mixins: [appearanceSettingsMixin],
  data() {
    return {
      selectorMap: {
        layout: ".layout",
        "close-button": ".layout .close-button",
        message: ".layout .message",
        "bar-button": ".layout .form-and-buttons button"
      }
    }
  },
  computed: {
    getTitle() {
      return {
        layout: "Layout Appearance",
        "close-button": "Close Button Appearance",
        message: "Message Appearance",
        "bar-button": "Button Appearance",
        images: "Images Appearance"
      }[this.setAreaTopic]
    }
  },
  methods: mapMutations("builder", ["setAppearanceDevice"]),
  created() {
    this.setAppearanceDevice("mobile")
  }
}
</script>
