import ElementManager from "@/lib/marketing-widgets/element-manager"

class FloatImageManager extends ElementManager {
  constructor(floatImage) {
    super(
      floatImage,
      {
        width: "200px",
        height: "200px",
        top: "0px",
        left: "0px",
        bottom: "unset",
        right: "unset",
        "object-fit": "cover",
        opacity: "1",
        transform: "none"
      },
      "float-image-"
    )
  }
}

export default FloatImageManager
