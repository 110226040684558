<template>
  <settings-template :title="title" :layout="layout" :show-device-picker="false">
    <div class="row align-items-center flex-fill">
      <div :class="[canBeDelayed ? 'col-5' : 'col-12 ']" class="">
        <select class="form-control form-control-sm" v-model="current" @change="set">
          <option :value="visibleDisplay">Visible</option>
          <option value="none">Hidden</option>
        </select>
      </div>
      <div class="col-7" v-if="canBeDelayed">
        <label class="mb-0 d-flex align-items-center" v-if="showAfter && current === visibleDisplay">
          <span class="mr-1">After:</span>
          <input-with-unit v-model="showAfter" :alternative-units="['sec']" size="full" @updated="setDelay" />
        </label>
      </div>
    </div>
  </settings-template>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import { mapGetters } from "vuex"
import InputWithUnit from "@/components/common/InputWithUnit"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "SettingsVisibility",
  components: { SettingsTemplate, InputWithUnit },
  props: {
    title: {
      type: String,
      default: "Visibility"
    },
    canBeDelayed: {
      default: false,
      type: Boolean
    },
    selector: {
      required: false,
      type: String
    },
    visibleDisplay: {
      default: "block"
    }
  },
  mixins: [appearanceChangerMixin],
  data() {
    return {
      showAfter: null,
      current: ""
    }
  },
  computed: {
    ...mapGetters("builder", ["getIframeDocument"]),
    visibilityDelayAttrKey() {
      return `data-${this.env}-visibility-delay`
    }
  },
  watch: {
    env() {
      this.init()
    }
  },
  methods: {
    set(event) {
      this.setStyleVariableValue({
        env: this.env,
        variable: this.variable,
        value: event.target.value
      })
    },
    setDelay() {
      this.getIframeDocument.querySelector(this.selector).setAttribute(this.visibilityDelayAttrKey, this.showAfter.value)
    },
    init() {
      if (this.canBeDelayed) {
        this.showAfter = {
          unit: "sec",
          value: this.getIframeDocument.querySelector(this.selector).getAttribute(this.visibilityDelayAttrKey)
        }
      }
      this.current = this.getStyleVariableValue(this.env, this.variable, 0)
    }
  },
  created() {
    this.init()
  }
}
</script>
