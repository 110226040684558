<template>
  <div>
    <device-picker-tabs class="mb-3" />
    <div class="pt-3 pb-5 border-bottom">
      <h4 class="mb-4">Position</h4>
      <div class="" style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; gap: 0.5rem" v-show="env === 'desktop'">
        <div class="picker" @click="desktopPosition = 'left'" :class="{ active: desktopPosition === 'left' }">
          <div class="picker-box left"><span></span></div>
        </div>

        <div class="picker" @click="desktopPosition = 'right'" :class="{ active: desktopPosition === 'right' }">
          <div class="picker-box right"><span></span></div>
        </div>

        <div class="picker" @click="desktopPosition = 'top'" :class="{ active: desktopPosition === 'top' }">
          <div class="picker-box top"><span></span></div>
        </div>
        <div class="picker" @click="desktopPosition = 'bottom'" :class="{ active: desktopPosition === 'bottom' }">
          <div class="picker-box bottom"><span></span></div>
        </div>

        <div class="picker" @click="desktopPosition = 'none'" :class="{ active: desktopPosition === 'none' }">
          <div class="picker-box none">
            <small class="text-dark">Hide on {{ env }}</small>
          </div>
        </div>
      </div>
      <div class="" style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; gap: 0.5rem" v-show="env === 'mobile'">
        <div class="picker" @click="mobilePosition = 'left'" :class="{ active: mobilePosition === 'left' }">
          <div class="picker-box left"><span></span></div>
        </div>

        <div class="picker" @click="mobilePosition = 'right'" :class="{ active: mobilePosition === 'right' }">
          <div class="picker-box right"><span></span></div>
        </div>

        <div class="picker" @click="mobilePosition = 'top'" :class="{ active: mobilePosition === 'top' }">
          <div class="picker-box top"><span></span></div>
        </div>
        <div class="picker" @click="mobilePosition = 'bottom'" :class="{ active: mobilePosition === 'bottom' }">
          <div class="picker-box bottom"><span></span></div>
        </div>

        <div class="picker" @click="mobilePosition = 'none'" :class="{ active: mobilePosition === 'none' }">
          <div class="picker-box none">
            <small class="text-dark">Hide on {{ env }}</small>
          </div>
        </div>
      </div>
    </div>

    <div v-if="position !== 'none'">
      <div class="py-5 border-bottom">
        <h4 class="mb-4">Youtube Video Settings</h4>
        <input type="url" class="form-control form-control-sm" v-model="url" placeholder="Video URL" />
        <Checkbox v-model="autoPlay"> Autoplay </Checkbox>
        <Checkbox v-model="controls"> Show Controls </Checkbox>
      </div>
      <div class="py-5 border-bottom">
        <h4 class="mb-4">Size</h4>
        <SettingsWidth :show-device-picker="false" layout="horizontal" :variable="getCssVariableName('width')" />
      </div>

      <div class="py-5">
        <h4 class="mb-4">Outer Space</h4>
        <SettingsMargin title="" :variable="getCssVariableName('margin')" :show-device-picker="false" layout="vertical" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import { HISTORY_TYPES } from "@/store/builder-history"
import DevicePickerTabs from "@/components/campaign-builder/widget-settings/DevicePickerTabs"
import Checkbox from "@/components/common/Checkbox"

export default {
  name: "BlockVideoAppearance",
  components: { Checkbox, DevicePickerTabs },
  mixins: [appearanceChangerMixin],
  props: ["video"],
  data() {
    return {
      desktopPosition: null,
      mobilePosition: null
    }
  },
  watch: {
    desktopPosition(newValue) {
      const el = this.video.closest(".visual-asset-container")
      const curr = el.dataset.desktop
      el.dataset.desktop = newValue

      this.$store.dispatch("builderHistory/addToHistory", {
        type: HISTORY_TYPES.UPDATE_ELEMENT_DATA,
        data: {
          dataAttribute: "desktop",
          selector: "#" + el.id,
          oldVal: curr,
          newVal: newValue
        }
      })
    },
    mobilePosition(newValue) {
      const el = this.video.closest(".visual-asset-container")
      const curr = el.dataset.mobile
      el.dataset.mobile = newValue

      this.$store.dispatch("builderHistory/addToHistory", {
        type: HISTORY_TYPES.UPDATE_ELEMENT_DATA,
        data: {
          dataAttribute: "mobile",
          selector: "#" + el.id,
          oldVal: curr,
          newVal: newValue
        }
      })
    }
  },
  computed: {
    ...mapGetters("builder", ["getIframeDocument", "getPageSelector", "getStyleVariableValueDirect"]),
    ...mapState("builder", ["previewDevice"]),
    url: {
      get() {
        return this.video.querySelector("iframe").dataset.videoUrl
      },
      set(url) {
        const iframe = this.video.querySelector("iframe")
        iframe.dataset.videoUrl = url
        const videoId = url.slice(url.indexOf("?v=") + 3)
        this.video.querySelector("iframe").src = `https://www.youtube.com/embed/${videoId}?controls=1&autoplay=0`
      }
    },
    autoPlay: {
      get() {
        const currentUrl = this.video.querySelector("iframe").src
        const val = currentUrl.slice(currentUrl.indexOf("autoplay=") + 9, currentUrl.indexOf("autoplay=") + 10)
        return val === "1"
      },
      set(val) {
        val = val ? "1" : "0"
        const currentUrl = this.video.querySelector("iframe").src
        this.video.querySelector("iframe").src = currentUrl.replace(/autoplay=([0-9])/, `autoplay=${val}`)
      }
    },
    controls: {
      get() {
        const currentUrl = this.video.querySelector("iframe").src
        const val = currentUrl.slice(currentUrl.indexOf("controls=") + 9, currentUrl.indexOf("controls=") + 10)
        return val === "1"
      },
      set(val) {
        val = val ? "1" : "0"
        const currentUrl = this.video.querySelector("iframe").src
        this.video.querySelector("iframe").src = currentUrl.replace(/controls=([0-9])/, `controls=${val}`)
      }
    },
    env() {
      return this.previewDevice.type
    },
    position() {
      return { desktop: this.desktopPosition, mobile: this.mobilePosition }[this.env]
    }
  },
  methods: {
    getCssVariableName(property) {
      return `--visual-asset-${this.position}-video-${property}`
    },
    setPositions() {
      const { desktop, mobile } = this.video.closest(".visual-asset-container").dataset
      this.desktopPosition = desktop
      this.mobilePosition = mobile
    }
  },
  created() {
    this.setPositions()
  }
}
</script>

<style scoped lang="scss">
.picker {
  display: flex;
  flex-direction: column;

  .picker-box {
    transition: 200ms all;
    opacity: 0.7;
    height: 3rem;
  }

  &.disabled .picker-box {
    opacity: 0.2;
  }

  &:not(.disabled) {
    cursor: pointer;
  }

  &:not(.disabled):hover .picker-box {
    opacity: 1;
  }

  &.active {
    .picker-box {
      opacity: 1;
      border-color: var(--primary);

      span {
        background: var(--primary);
      }

      small {
        color: var(--primary) !important;
      }
    }
  }

  .picker-box {
    position: relative;
    width: 100%;
    border: 1px solid var(--secondary);
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    small {
      text-align: center;
      margin: 0 0.25rem;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: var(--secondary);
    }

    &.left span {
    }

    &.right span {
      right: 0;
      left: auto;
    }

    &.top span {
      width: 100%;
      height: 50%;
    }

    &.bottom span {
      width: 100%;
      height: 50%;
      top: auto;
      bottom: 0;
    }
  }
}
</style>
