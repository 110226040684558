<template>
  <div>
    <settings-title>Body Settings</settings-title>
    <device-picker-tabs />
    <settings-accordion :show-device-picker="true">
      <template #title>Display</template>
      <div class="pb-5 border-bottom">
        <h4 class="mb-4">Size</h4>
        <SettingsWidth layout="horizontal" :show-device-picker="false" :variable="bodyManager.getStyleVariableName('width')" />
      </div>
      <div class="py-5">
        <h4 class="mb-4">Alignment</h4>
        <SettingsAlign title="" :variable="bodyManager.getStyleVariableName('align-self')" />
      </div>
    </settings-accordion>

    <settings-accordion class="my-4" :show-device-picker="true">
      <template #title>Spacing</template>
      <h4 class="mb-4">Inner Space</h4>
      <SettingsPadding :show-device-picker="false" title="" layout="vertical" :variable="bodyManager.getStyleVariableName('padding')" />
    </settings-accordion>

    <SettingsBackground :variable="bodyManager.getStyleVariableName('background')" />
    <settings-accordion :show-device-picker="true">
      <template #title>Border</template>
      <template>
        <SettingsBorder
          class="pb-3 border-bottom"
          :color-variable="bodyManager.getStyleVariableName('border-color')"
          :style-variable="bodyManager.getStyleVariableName('border-style')"
          :width-variable="bodyManager.getStyleVariableName('border-width')"
        />
        <SettingsBorderRadius class="py-3" layout="vertical" :variable="bodyManager.getStyleVariableName('border-radius')" />
      </template>
    </settings-accordion>
  </div>
</template>

<script>
import SettingsTitle from "@/components/campaign-builder/widget-settings/SettingsTitle"
import SettingsAccordion from "@/components/campaign-builder/widget-settings/SettingsAccordion"
import DevicePickerTabs from "@/components/campaign-builder/widget-settings/DevicePickerTabs"

export default {
  name: "BodySettings",
  components: { DevicePickerTabs, SettingsAccordion, SettingsTitle },
  props: ["bodyManager"]
}
</script>

<style scoped></style>
