<template>
  <settings-template title="Visibility" :layout="layout">
    <Checkbox vertical-space="0" v-model="isHidden" @change="toggleHidden">Hide on {{ env }}</Checkbox>
  </settings-template>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import { mapGetters } from "vuex"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import Checkbox from "@/components/common/Checkbox"

export default {
  name: "SettingsHidden",
  props: {
    selector: {
      type: String,
      required: true
    }
  },
  components: { Checkbox, SettingsTemplate },
  data() {
    return {
      className: null,
      isHidden: false
    }
  },
  computed: mapGetters("builder", ["getIframeDocument"]),
  watch: {
    env() {
      this.init()
    }
  },
  mixins: [appearanceChangerMixin],
  methods: {
    toggleHidden() {
      this.getIframeDocument.querySelectorAll(this.selector).forEach((element) => {
        element.classList.toggle(this.className)
      })
    },
    init() {
      if (this.env === "desktop") {
        this.className = "desktop-hidden"
      } else if (this.env === "mobile") {
        this.className = "mobile-hidden"
      }
      this.isHidden = this.getIframeDocument.querySelector(this.selector).classList.contains(this.className)
    }
  },
  created() {
    this.init()
  }
}
</script>
