var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3 card mb-lg-5"},[_vm._m(0),_c('datatable-table-element',{attrs:{"multiselect":false,"paginated":false,"heads":[
      { class: '', text: '' },
      { class: '', text: 'Name' },
      { class: 'table-column-right-aligned', text: 'Impressions' },
      { class: 'table-column-right-aligned', text: 'Submits' },
      { class: 'table-column-right-aligned', text: 'Date Created' },
      { class: '', text: '' }
    ]}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row justify-content-between align-items-center flex-grow-1"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Campaign Variants")])])])])}]

export { render, staticRenderFns }