<template>
  <base-campaign-targeting-rule>
    <div>Visitors who are using: {{ data.map((d) => deviceMap[d]).join(" or ") }}</div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("who", targetingRuleNames.whoRules.device)

const deviceMap = {
  desktop: "Desktop",
  mobile: "Mobile",
  tablet: "Tablet",
  tv: "TV"
}
export default {
  name: "TargetingDevice",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin],
  data() {
    return {
      deviceMap: deviceMap
    }
  }
}
</script>
