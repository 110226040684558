import getAxiosInstance from "@/api/get-axios-instance"

const register = async ({ first_name, last_name, email, password, password_confirmation }) => {
  const params = { first_name, last_name, email, password, password_confirmation }
  return await getAxiosInstance().post("auth/create-account", params)
}

const fetchGoogleOAuthUrl = async () => await getAxiosInstance().get("auth/google")

const login = async ({ email, password }) => {
  return await getAxiosInstance().post("auth/access-token", {
    email,
    password
  })
}

const update = async ({ first_name, last_name }) => await getAxiosInstance().put("user", { first_name, last_name })
const updatePassword = async ({ current_password, password, password_confirmation }) =>
  await getAxiosInstance().put("user/password", { current_password, password, password_confirmation })

const logout = async () => await getAxiosInstance().delete("auth/access-token")

const fetchAddressAndTaxId = async () => await getAxiosInstance().get("user/customer-address")
const fetchAvailablePlans = async () => await getAxiosInstance().get("plans")
const fetchCurrentUser = async () => await getAxiosInstance().get("user")
const saveAddress = async ({ address, taxId }) => {
  return await getAxiosInstance().post("user/customer-address", {
    address,
    tax_id: taxId
  })
}

const cancelSubscriptionChangeRequests = async () => await getAxiosInstance().delete("user/subscription/change-requests")

const updateUserPlan = async ({ planId }) => {
  return await getAxiosInstance().put("user/subscription", {
    plan: planId
  })
}

// stripe
const fetchStripePublicApiKey = async () => await getAxiosInstance().get("stripe-key")
const createAndFetchStripeSetupIntent = async () => await getAxiosInstance().get("user/setup-intent")
const addPaymentMethod = async (paymentMethod) => await getAxiosInstance().post("user/payment-methods", { payment_method: paymentMethod })
const updateDefaultPaymentMethod = async (paymentMethod) =>
  await getAxiosInstance().put("user/payment-methods/default", { payment_method: paymentMethod })
const fetchPaymentMethods = async () => await getAxiosInstance().get("user/payment-methods")
const deletePaymentMethod = async (paymentMethodId) => await getAxiosInstance().delete(`user/payment-methods/${paymentMethodId}`)
const fetchInvoices = async () => await getAxiosInstance().get("user/invoices")

const userAPI = {
  login,
  register,
  logout,
  update,
  cancelSubscriptionChangeRequests,
  updateDefaultPaymentMethod,
  fetchGoogleOAuthUrl,
  fetchAddressAndTaxId,
  saveAddress,
  fetchAvailablePlans,
  fetchCurrentUser,
  updateUserPlan,
  fetchStripePublicApiKey,
  addPaymentMethod,
  createAndFetchStripeSetupIntent,
  fetchPaymentMethods,
  deletePaymentMethod,
  fetchInvoices,
  updatePassword
}
export default userAPI
