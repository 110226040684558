import { mapMutations } from "vuex"

const settingsMixin = {
  methods: {
    ...mapMutations("builder", ["setIsSetAreaActive", "setSetAreaTopic"]),
    activateSetArea(topic) {
      this.setIsSetAreaActive(true)
      this.setSetAreaTopic(topic)
    }
  }
}

export default settingsMixin
