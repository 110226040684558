<template>
  <div>
    <div v-if="layout === 'vertical'" class="w-100">
      <div class="mb-1 d-flex align-items-center" v-if="title.length > 0">
        <label class="mb-1 input-label">{{ title }}</label>
        <settings-device-picker v-if="showDevicePicker" />
      </div>
      <div>
        <slot />
      </div>
    </div>

    <div class="" v-else-if="layout === 'horizontal'">
      <div class="row align-items-center">
        <div class="col-4 d-flex align-items-center">
          <label class="mb-0 input-label">{{ title }}</label>
          <settings-device-picker v-if="showDevicePicker" />
        </div>
        <div class="col-8">
          <slot />
        </div>
      </div>
    </div>

    <div class="w-100" v-else-if="layout === 'accordion'">
      <settings-accordion :show-device-picker="showDevicePicker">
        <template #title>
          {{ title }}
        </template>
        <div>
          <slot />
        </div>
      </settings-accordion>
    </div>
  </div>
</template>

<script>
import SettingsDevicePicker from "@/components/campaign-builder/SettingsDevicePicker"
import SettingsAccordion from "@/components/campaign-builder/widget-settings/SettingsAccordion"

export default {
  name: "SettingsTemplate",
  components: { SettingsAccordion, SettingsDevicePicker },
  props: {
    title: {
      type: String,
      default: ""
    },
    layout: {
      type: String,
      default: "vertical"
    },
    showDevicePicker: {
      type: Boolean,
      default: true
    }
  }
}
</script>
