import ElementManager from "@/lib/marketing-widgets/element-manager"

class FormFieldInputManager extends ElementManager {
  constructor(formFieldInput) {
    super(
      formFieldInput,
      {
        color: "#2b2b2b",
        background: "transparent",
        "border-radius": "0px 0px 0px 0px",
        padding: "8px 12px 8px 12px",
        margin: "0px 0px 16px 0px",
        "font-size": "16px",
        "border-style": "solid",
        "border-width": "1px 1px 1px 1px",
        "border-color": "#2b2b2b",

        "text-decoration": "none",
        "font-weight": "normal",
        "font-style": "normal",

        "text-align": "start"
      },
      "form-field-"
    )
  }
}

export default FormFieldInputManager
