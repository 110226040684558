import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
// import "@/assets/tailwind.css"
// import "@/assets/variables.css"
// import "@/assets/scss/main.scss"

require("./bootstrap/theme")

require("./bootstrap/axios")
require("./bootstrap/vueToastNotification")
// Vue.config.productionTip = false
require("./bootstrap/globalComponents")

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
