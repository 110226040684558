<template>
  <div class="input-group input-group-merge">
    <div class="input-group-prepend">
      <span class="input-group-text">
        <i class="tio-date-range"></i>
      </span>
    </div>
    <input
      type="text"
      class="js-daterangepicker form-control daterangepicker-custom-input"
      placeholder="Select dates"
      data-hs-daterangepicker-options='{
            "autoUpdateInput": false,
            "locale": {
              "cancelLabel": "Clear"
            }
            }'
    />
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "DateRangePicker",
  mounted() {
    const vm = this
    this.$nextTick(() => {
      require("@/theme-files/hs.daterangepicker")
      const pickerSelector = $(".js-daterangepicker")
      pickerSelector.each(function () {
        $.HSCore.components.HSDaterangepicker.init($(this), {
          startDate: moment().subtract(29, "days"),
          endDate: moment(),
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
          }
        })
      })
      pickerSelector.val(moment().subtract(29, "days").format("MM/DD/YYYY") + " - " + moment().format("MM/DD/YYYY"))

      pickerSelector.on("apply.daterangepicker", function (ev, picker) {
        $(this).val(picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY"))
        vm.$emit("change", {
          startDate: picker.startDate,
          endDate: picker.endDate
        })
      })

      pickerSelector.on("cancel.daterangepicker", function () {
        $(this).val("")
        vm.$emit("change", {
          startDate: null,
          endDate: null
        })
      })
    })
  }
}
</script>

<style scoped></style>
