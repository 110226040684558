<template>
  <div :style="`width: ${this.getSize}`">
    <div class="input-group">
      <input ref="value" :disabled="disabled" :value="value.value" class="form-control form-control-sm" type="number" @input="input" />
      <div class="input-group-append">
        <select
          ref="unit"
          v-model="value.unit"
          class="form-control form-control-sm"
          style="border-top-left-radius: 0; border-bottom-left-radius: 0"
          @input="input"
        >
          <option v-for="u in alternativeUnits" :key="u" :value="u">
            {{ u.toUpperCase() }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputWithUnit",
  props: {
    value: {
      required: true,
      type: Object
    },
    alternativeUnits: {
      required: true,
      type: Array
    },
    size: {
      default: "normal"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getSize() {
      if (this.size === "normal") {
        return "9rem"
      } else if (this.size === "small") {
        return "7rem"
      } else if (this.size === "full") {
        return "100%"
      }
      return "9rem"
    }
  },
  methods: {
    input() {
      this.$emit("input", {
        value: this.$refs.value.value,
        unit: this.$refs.unit.value
      })
      this.$emit("updated")
    }
  }
}
</script>
