const sectorsDefault = {
  "--sector-1-fill-color": "#7036A4",
  "--sector-2-fill-color": "#f41a06",
  "--sector-3-fill-color": "#ff6202",
  "--sector-4-fill-color": "#7036A4",
  "--sector-5-fill-color": "#f41a06",
  "--sector-6-fill-color": "#ff6202",
  "--sector-7-fill-color": "#7036A4",
  "--sector-8-fill-color": "#f41a06",
  "--sector-9-fill-color": "#ff6202",
  "--sector-10-fill-color": "#7036A4",
  "--sector-11-fill-color": "#f41a06",
  "--sector-12-fill-color": "#ff6202",
  "--label-1-fill-color": "#ffffff",
  "--label-2-fill-color": "#ffffff",
  "--label-3-fill-color": "#ffffff",
  "--label-4-fill-color": "#ffffff",
  "--label-5-fill-color": "#ffffff",
  "--label-6-fill-color": "#ffffff",
  "--label-7-fill-color": "#ffffff",
  "--label-8-fill-color": "#ffffff",
  "--label-9-fill-color": "#ffffff",
  "--label-10-fill-color": "#ffffff",
  "--label-11-fill-color": "#ffffff",
  "--label-12-fill-color": "#ffffff"
}
const closeButtonDefault = {
  "--close-button-width": "24px",
  "--close-button-height": "24px",
  "--close-button-padding": "4px 4px 4px 4px",
  "--close-button-border-radius": "0px 0px 0px 0px",
  "--close-button-top": "10px",
  "--close-button-right": "10px",
  "--close-button-bottom": "unset",
  "--close-button-left": "unset",
  "--close-button-display": "block",
  "--close-button-background": "transparent",
  "--close-button-color": "#2b2b2b"
}
const formPrimaryButtonDefault = {
  "--form-primary-button-background": "linear-gradient(270deg, #f9a219, rgb(238, 74, 155))",
  "--form-primary-button-border-radius": "6px 6px 6px 6px",
  "--form-primary-button-padding": "16px 16px 16px 16px",
  "--form-primary-button-margin": "6px 0px 6px 0px",
  "--form-primary-button-font-size": "18px",
  "--form-primary-button-color": "#ffffff",
  "--form-primary-button-border-style": "none",
  "--form-primary-button-border-width": "0px 0px 0px 0px",
  "--form-primary-button-border-color": "#f00"
}
const formSecondaryButtonDefault = {
  "--form-primary-button-background": "linear-gradient(270deg, #f9a219, rgb(238, 74, 155))",
  "--form-primary-button-border-radius": "6px 6px 6px 6px",
  "--form-primary-button-padding": "16px 16px 16px 16px",
  "--form-primary-button-margin": "6px 0px 6px 0px",
  "--form-primary-button-font-size": "18px",
  "--form-primary-button-color": "#ffffff",
  "--form-primary-button-border-style": "none",
  "--form-primary-button-border-width": "0px 0px 0px 0px",
  "--form-primary-button-border-color": "#f00"
}
const formFieldDefault = {
  "--form-field-margin": "20px 0px 0px 0px",
  "--form-field-padding": "0px 0px 0px 0px",
  "--form-field-color": "30, 30, 30",
  "--form-field-font-size": "16px"
}
const formLabelDefault = {
  "--form-label-font-size": "15px",
  "--form-label-color": "#eee",
  "--form-label-margin": "0px 0px 8px 0px"
}
const titleDefault = {
  "--title-margin": "0px 0px 16px 0px",
  "--title-font-size": "24px",
  "--title-color": "#ffffff",
  "--title-background": "transparent"
}
const titleMobileDefault = {
  "--title-margin": "0px 0px 12px 0px",
  "--title-font-size": "28px",
  "--title-color": "#ffffff",
  "--title-background": "transparent"
}
const descriptionDefault = {
  "--description-margin": "0px 0px 16px 0px",
  "--description-font-size": "16px",
  "--description-color": "#eee",
  "--description-background": "transparent"
}
const descriptionMobileDefault = {
  "--description-margin": "0px 0px 16px 0px",
  "--description-font-size": "15px",
  "--description-color": "#eee",
  "--description-background": "transparent"
}
const subtitleDefault = {
  "--subtitle-margin": "0px 0px 16px 0px",
  "--subtitle-font-size": "16px",
  "--subtitle-color": "#666",
  "--subtitle-background": "transparent"
}
const subtitleMobileDefault = {
  "--subtitle-margin": "0px 0px 16px 0px",
  "--subtitle-font-size": "15px",
  "--subtitle-color": "#666",
  "--subtitle-background": "transparent"
}
const notesDefault = {
  "--notes-font-size": "14px",
  "--notes-color": "rgba(40, 45, 50, 0.5)",
  "--notes-background": "transparent",
  "--notes-margin": "32px 0px 0px 0px"
}
const overlayDefault = {
  "--overlay-backdrop-blur": "4px",
  "--overlay-color": "rgba(0,0,0,.2)",
  "--overlay-display": "block"
}

export default function wheelOfFortuneStyleVariables() {
  return {
    style: {
      desktop: {
        ...sectorsDefault,
        ...overlayDefault,
        "--layout-font-family": "Montserrat",
        "--layout-background": "#7036A4",
        "--image-width": "100%",
        "--image-height": "100%",
        "--image-top": "0",
        "--image-right": "0",
        "--image-bottom": "unset",
        "--image-left": "unset",
        "--image-object-fit": "cover",
        "--image-opacity": "1",
        "--image-transform": "none",
        ...closeButtonDefault,
        "--body-padding": "50px 50px 50px 50px",
        "--body-background": "transparent",
        ...titleDefault,
        ...descriptionDefault,
        ...subtitleDefault,
        "--form-margin": "0px 0px 16px 0px",
        "--form-padding": "0px 0px 0px 0px",
        ...formPrimaryButtonDefault,
        ...formSecondaryButtonDefault,
        ...formFieldDefault,
        ...formLabelDefault,
        ...notesDefault
      },
      mobile: {
        ...sectorsDefault,
        ...overlayDefault,
        "--layout-font-family": "Montserrat",
        "--layout-background": "#7036A4",
        "--image-width": "100%",
        "--image-height": "100%",
        "--image-top": "0",
        "--image-right": "unset",
        "--image-bottom": "unset",
        "--image-left": "0",
        "--image-object-fit": "cover",
        "--image-opacity": "1",
        "--image-transform": "none",
        ...closeButtonDefault,
        "--body-padding": "40px 40px 40px 40px",
        "--body-background": "transparent",
        ...titleMobileDefault,
        ...descriptionMobileDefault,
        ...subtitleMobileDefault,
        "--form-margin": "0px 0px 16px 0px",
        "--form-padding": "0px 0px 0px 0px",
        ...formPrimaryButtonDefault,
        ...formSecondaryButtonDefault,
        ...formFieldDefault,
        ...formLabelDefault,
        ...notesDefault
      }
    }
  }
}
