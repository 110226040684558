<template>
  <div class="w-100">
    <modal-campaign-type-selector :next="addNewPage" v-if="showCampaignTypeSelector" @close="showCampaignTypeSelector = false" />
    <modal-update-campaign-page-name
      v-if="showUpdatePageNameModal"
      @close="showUpdatePageNameModal = false"
      @update="handleUpdatePageName"
      :current-name="updatePageNameName"
    />
    <div class="d-flex align-items-center justify-content-between w-100">
      <div class="overflow-x-auto whitespace-nowrap flex-shrink-0">
        <draggable handle=".handle" @end="dragEnd">
          <span class="page" v-for="page in totalPage" :key="key + page" :class="currentPage === page ? 'active' : ''">
            <button
              class="btn btn-sm whitespace-nowrap btn-outline-secondary font-weight-bold dropdown"
              :class="{ active: currentPage === page }"
              @click="changePage(page)"
            >
              <div class="handle">
                <i class="tio-drag mr-1"></i>
                {{ pageNameMap[page] }}

                <div class="dropdown-menu px-3 w-100" style="min-width: min-content !important; width: auto !important">
                  <div class="d-flex align-items-center justify-content-around" :style="{ width: totalPage > 1 ? '19rem' : '13rem' }">
                    <button class="btn btn-secondary btn-xs flex-shrink-0 font-weight-bolder" @click.stop="duplicate(page)">
                      <i class="tio-copy mr-1"></i>
                      Duplicate
                    </button>
                    <button class="btn btn-secondary btn-xs flex-shrink-0 font-weight-bolder" v-if="totalPage > 1" @click.stop="removePage(page)">
                      <i class="tio-clear mr-1"></i>
                      Remove
                    </button>
                    <button class="btn btn-secondary btn-xs flex-shrink-0 font-weight-bolder" @click.stop="openUpdatePageNameModal(page)">
                      <i class="tio-new-message mr-1"></i>
                      Rename
                    </button>
                  </div>
                </div>
              </div>
            </button>
            <span class="page-arrow">
              <i class="tio tio-chevron-right mx-2"></i>
            </span>
          </span>
        </draggable>
      </div>

      <button class="btn btn-white btn-sm ml-2 flex-shrink-0 font-weight-bolder" @click="showCampaignTypeSelector = true">
        <i class="tio-add mr-1"></i> Add New Page
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import ModalCampaignTypeSelector from "@/components/modals/ModalCampaignTypeSelector"
import ModalUpdateCampaignPageName from "@/components/modals/ModalUpdateCampaignPageName"
import draggable from "vuedraggable"
import randomId from "@/lib/utils/random-id"

export default {
  name: "PageOperations",
  components: { ModalUpdateCampaignPageName, ModalCampaignTypeSelector, draggable },
  data() {
    return {
      showCampaignTypeSelector: false,
      pageNameMap: {},
      showUpdatePageNameModal: false,
      updatePageNameNumber: null,
      updatePageNameName: "",
      key: "kk"
    }
  },
  computed: {
    ...mapState("builder", ["totalPage", "currentPage"]),
    ...mapGetters("builder", ["getIframeDocument"])
  },
  watch: {
    totalPage() {
      this.generatePageNames()
    },
    currentPage() {
      this.generatePageNames()
    }
  },
  methods: {
    dragEnd(event) {
      const { newIndex, oldIndex } = event
      const oldPageNumber = oldIndex + 1
      const newPageNumber = newIndex + 1
      this.$store.dispatch("builder/changeOrderOfPage", { oldPageNumber, newPageNumber })
      this.generatePageNames()
    },
    openUpdatePageNameModal(pageNumber) {
      this.updatePageNameNumber = pageNumber
      this.updatePageNameName = this.pageNameMap[pageNumber]
      this.showUpdatePageNameModal = true
    },
    handleUpdatePageName(value) {
      const page = this.getIframeDocument.querySelector(`.page${this.updatePageNameNumber}`)
      page.dataset.pageName = value
      this.pageNameMap[page.dataset.page] = page.dataset.pageName
      this.pageNameMap = { ...this.pageNameMap }
      this.showUpdatePageNameModal = false
    },
    addNewPage(data) {
      this.$store.dispatch("builder/addNewPage", data)
      this.showCampaignTypeSelector = false
    },
    changePage(pageNumber) {
      this.$store.dispatch("builder/changePage", pageNumber)
    },
    removePage(pageNumber) {
      this.$store.dispatch("builder/removePage", pageNumber)
    },
    duplicate(pageNumber) {
      this.$store.dispatch("builder/duplicatePage", pageNumber)
    },
    generatePageNames() {
      this.getIframeDocument.querySelectorAll(".page").forEach((page, key) => {
        if (!page.dataset.pageName) {
          page.dataset.pageName = `Page ${key + 1}`
        }
        this.pageNameMap[page.dataset.page] = page.dataset.pageName
      })
      this.pageNameMap = { ...this.pageNameMap }
      this.key = randomId()
    }
  },
  mounted() {
    this.generatePageNames()
  }
}
</script>
<style scoped>
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}
.page:last-of-type .page-arrow {
  display: none;
}
</style>
