import { mapGetters, mapMutations } from "vuex"
import targetingRuleDefaultValues from "@/components/campaign-targeting-rules/targetingRuleDefaultValues"

const targetingRuleModalMixin = (typeOfRule, nameOfRule) => ({
  data() {
    return {
      typeOfRule: typeOfRule,
      nameOfRule: nameOfRule,
      value: null,
      targetingRuleDefaultValues: targetingRuleDefaultValues
    }
  },
  props: ["ruleType", "ruleName"],
  provide: {
    typeOfRule: typeOfRule,
    nameOfRule: nameOfRule
  },
  computed: {
    ...mapGetters("campaignSettings", ["getRule", "ruleExists"]),
    data: {
      get: function () {
        return this.getRule(this.typeOfRule, this.nameOfRule)
      },
      set: function (value) {
        return this.setRule({ typeOfRule: this.typeOfRule, name: this.nameOfRule, value })
      }
    }
  },
  methods: {
    ...mapMutations("campaignSettings", ["setRule"]),
    close() {
      this.$emit("close")
    },
    save() {
      this.$store.commit("campaignSettings/setRule", {
        typeOfRule: this.typeOfRule,
        name: this.nameOfRule,
        value: this.value
      })
      this.$emit("save")
      this.close()
    }
  },
  created() {
    if (this.ruleExists(this.typeOfRule, this.nameOfRule)) {
      // edit mode
      this.value = this.getRule(this.typeOfRule, this.nameOfRule)
      if (typeof this.value === "object") {
        this.value = JSON.parse(JSON.stringify(this.value))
      }
    } else {
      // new add mode
      this.value = JSON.parse(JSON.stringify(this.targetingRuleDefaultValues[this.typeOfRule][this.nameOfRule]))
    }
  }
})
export default targetingRuleModalMixin
