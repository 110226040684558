function imageButtonStyleVariables() {
  return {
    style: {
      desktop: {
        "--image-width": "96px",
        "--image-height": "96px",
        "--image-top": "unset",
        "--image-right": "24px",
        "--image-bottom": "24px",
        "--image-left": "unset",
        "--image-object-fit": "contain",
        "--image-opacity": "1",
        "--image-transform": "none"
      },
      mobile: {
        "--image-width": "64px",
        "--image-height": "64px",
        "--image-top": "unset",
        "--image-right": "16px",
        "--image-bottom": "16px",
        "--image-left": "unset",
        "--image-object-fit": "contain",
        "--image-opacity": "1",
        "--image-transform": "none"
      }
    }
  }
}

export default imageButtonStyleVariables
