import Vue from "vue"
import Vuex from "vuex"
import user from "@/store/user"
import sites from "@/store/sites"
import campaigns from "@/store/campaigns"
import builder from "@/store/builder"
import campaignSettings from "@/store/campaign-settings"
import builderHistory from "@/store/builder-history"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    builder,
    builderHistory,
    user,
    sites,
    campaigns,
    campaignSettings
  }
})
