import parseUnit from "@/lib/utils/parse-unit"
import HtmlGenerator from "@/lib/marketing-widgets/html-generator"
import { getImportUrl } from "@/lib/utils/google-font"
import { parseLongHandCss } from "@/lib/utils/parse-long-hand-css"
import getScreenshot from "@/api/get-screenshot"
import campaignAPI from "@/api/campaign"
import parseWidgetHtml from "@/lib/utils/parse-widget-html"
import LayoutManager from "@/lib/marketing-widgets/layout-manager"
import BodyManager from "@/lib/marketing-widgets/body-manager"
import GamificationManager from "@/lib/marketing-widgets/gamification-manager"
import OverlayManager from "@/lib/marketing-widgets/overlay-manager"
import CloseButtonManager from "@/lib/marketing-widgets/close-button-manager"
import { cloneDeep } from "lodash"
import { HISTORY_TYPES } from "@/store/builder-history"

// TODO: this file becomes bad day by day... It needs a great refactor.

function initialState() {
  return {
    isNewCampaign: false,
    parentId: "",
    setAreaTopic: null,
    activeSettings: "content",
    isSetAreaActive: false,
    appearanceDevice: "mobile",
    initialHtml: "",
    html: "",
    styleVariables: { 1: { style: {} } },
    previewDeviceLoading: true,
    currentPage: 1,
    totalPage: 1,
    previewDevice: { type: "desktop", width: 1366, height: 768 },
    iframes: {},
    iframe: null,
    iframeBackgroundCache: {},
    outlinedSelectors: []
  }
}

const builder = {
  state: initialState,
  getters: {
    getCurrentStyleVariables: (state) => state.styleVariables[state.currentPage],
    getIframeDocument: (state) => state.iframe?.contentDocument,
    getPageElement: (state) => state.iframe?.contentDocument.querySelector(`.page${state.currentPage}`),
    getPageSelector: (state) => `.page${state.currentPage}`,
    getPreviewDevicesLoading: (state) => state.previewDeviceLoading,
    getStyleVariableValue: (state) => (env, variable, type) => {
      // type 0 for just value, type 1 for value with unit, type 2 for long hand data and units
      if (type === 0) return state.styleVariables[state.currentPage].style[env][variable]
      else if (type === 1) return parseUnit(state.styleVariables[state.currentPage].style[env][variable])
      else if (type === 2) return parseLongHandCss(state.styleVariables[state.currentPage].style[env][variable])
      else console.error("UNRECOGNIZED TYPE!")
    },
    getStyleVariableValueDirect: (state) => (env, variable) => {
      return state.styleVariables[state.currentPage].style[env][variable]
    },
    getStyleVariableValueDirectCurrentEnv: (state) => (variable) => {
      const env = state.previewDevice.type
      return state.styleVariables[state.currentPage].style[env][variable]
    },
    getStyleVariablesText(state) {
      let styleText = ""
      Object.keys(state.styleVariables).forEach((page) => {
        const pageStyleVariables = state.styleVariables[page].style
        const data = {
          mobile: { prefix: "@media (max-width: 959px) {", suffix: "}" },
          desktop: { prefix: "@media (min-width: 960px) {", suffix: "}" },
          all: { prefix: "", suffix: "" }
        }
        Object.keys(data).forEach((device) => {
          if (device in pageStyleVariables) {
            const { prefix, suffix } = data[device]
            styleText += `${prefix} #${state.parentId} .page${page}{`
            Object.keys(pageStyleVariables[device]).forEach((key) => (styleText += `${key}:${pageStyleVariables[device][key]};`))
            styleText += `} ${suffix}`
          }
        })
      })
      return styleText
    }
  },
  mutations: {
    resetState(state) {
      Object.assign(state, initialState())
    },
    setIsNewCampaign(state, payload) {
      state.isNewCampaign = payload
    },
    setIframe(state, payload) {
      state.iframe = payload
    },
    setPreviewDevice(state, payload) {
      state.previewDevice = payload
    },
    setAppearanceDevice(state, payload) {
      state.appearanceDevice = payload
    },
    setIsSetAreaActive(state, payload) {
      state.isSetAreaActive = payload
    },
    setSetAreaTopic(state, payload) {
      state.setAreaTopic = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage = payload
    },
    setPreviewDevicesLoading(state, payload) {
      state.previewDeviceLoading = payload
    },
    addPage(state) {
      state.styleVariables[Object.keys(state.styleVariables).length + 1] = {
        style: {}
      }
      state.styleVariables = { ...state.styleVariables }
      state.totalPage += 1
      state.currentPage = state.totalPage
    },
    removeStyleOfPage(state, pageNumber) {
      let newStyleVars = {}
      Object.keys(state.styleVariables).forEach((key) => {
        key = parseInt(key)
        if (key >= pageNumber && state.styleVariables[key + 1] !== undefined) {
          newStyleVars[key] = state.styleVariables[key + 1]
        } else if (key < pageNumber) {
          newStyleVars[key] = state.styleVariables[key]
        }
      })
      state.styleVariables = newStyleVars
    },
    setTotalPage(state, payload) {
      state.totalPage = payload
    },
    initStyleVariables(state, payload) {
      state.styleVariables = payload
    },
    setHtml(state, payload) {
      state.html = payload
    },
    setInitialHtml(state, payload) {
      state.initialHtml = payload
    },
    addIframe(state, payload) {
      state.iframes = { ...state.iframes, ...payload }
    },
    addOutline(state, { device, selector }) {
      Object.values(state.iframes).forEach((frame) => {
        state.outlinedSelectors.forEach((value) => {
          frame.contentWindow.document.querySelectorAll(value).forEach((element) => {
            element.style.removeProperty("outline")
            element.style.removeProperty("outline-offset")
          })
        })
      })
      state.iframes[device].contentWindow?.document.querySelectorAll(selector).forEach((element) => {
        state.outlinedSelectors.push(selector)
        element.style.outline = "2px dashed blue"
        element.style.outlineOffset = "-1px"
      })
    },
    clearOutlines(state) {
      Object.values(state.iframes).forEach((frame) => {
        state.outlinedSelectors.forEach((value) => {
          frame.contentWindow.document.querySelectorAll(value).forEach((element) => {
            element.style.removeProperty("outline")
            element.style.removeProperty("outline-offset")
          })
        })
      })
    },
    setBulkStyleVariableWithoutAddingHistory(state, data) {
      data.forEach(({ variable, value, env }) => {
        state.styleVariables[state.currentPage].style[env][variable] = value
        state.styleVariables = { ...state.styleVariables }
      })
    },
    setStyleVariableDirectWithoutAddingHistory(state, { pageNumber, env, variable, value }) {
      state.styleVariables[pageNumber].style[env][variable] = value
      state.styleVariables = { ...state.styleVariables }
    },
    setParentId(state, payload) {
      state.parentId = payload
    },
    addIframeBackgroundCache(state, { key, url }) {
      state.iframeBackgroundCache[key] = url
    },
    setStyleVariables(state, payload) {
      state.styleVariables = payload
    },
    addStyleVariables(state, payload) {
      state.styleVariables = { ...state.styleVariables, ...payload }
    }
  },
  actions: {
    setStyleVariableDirect({ commit, state, dispatch }, { env, variable, value }) {
      dispatch(
        "builderHistory/addToHistory",
        {
          type: HISTORY_TYPES.UPDATE_STYLE_VARIABLE,
          data: {
            env,
            variable,
            oldVal: state.styleVariables[state.currentPage].style[env][variable],
            newVal: value,
            pageNumber: state.currentPage
          }
        },
        { root: true }
      )

      const styleVariables = cloneDeep({ ...state.styleVariables })
      styleVariables[state.currentPage].style[env][variable] = value

      // TODO: optimize
      commit("setStyleVariables", styleVariables)
    },

    setStyleVariableDirectCurrentEnv({ state, dispatch }, { variable, value }) {
      const env = state.previewDevice.type
      dispatch("setStyleVariableDirect", { env, variable, value })
    },

    setStyleVariableValue({ state, dispatch }, { env, variable, value }) {
      const parsed = parseUnit(state.styleVariables[state.currentPage].style[env][variable])
      if (parsed) {
        value = value.toString() + parsed.unit
      }
      dispatch("setStyleVariableDirect", { env, variable, value })
    },
    async setBackgroundOfIframe({ commit, state }, url) {
      if (!url.startsWith("https://")) url = "https://" + url
      const { width, height } = state.previewDevice
      const iframe = state.iframe
      const iframeBody = iframe.contentDocument.querySelector("body")
      iframeBody.style.backgroundImage = "unset"
      try {
        let key = url + width + height
        let screenShotURL = ""
        if (key in state.iframeBackgroundCache) {
          screenShotURL = state.iframeBackgroundCache[key]
        } else {
          screenShotURL = await getScreenshot({ url, width, height })
          commit("addIframeBackgroundCache", { key, url: screenShotURL })
        }
        iframeBody.style.backgroundImage = `url('${screenShotURL}')`
      } catch (error) {
        console.log("screenshot error" + error)
      }
    },
    reloadIframe({ state }) {
      state.iframe.contentWindow.dispatchEvent(
        new CustomEvent("dashboardReload", {
          detail: {
            page: state.currentPage
          }
        })
      )
    },
    changePage({ commit, dispatch }, pageNumber) {
      commit("setCurrentPage", pageNumber)
      commit("clearOutlines")
      dispatch("reloadIframe")
    },
    changeOrderOfPage({ getters, dispatch, state, commit }, { oldPageNumber, newPageNumber }) {
      if (oldPageNumber === newPageNumber) return

      dispatch(
        "builderHistory/addToHistory",
        {
          type: HISTORY_TYPES.REORDER_PAGE,
          data: { newPageNumber: newPageNumber, oldPageNumber: oldPageNumber }
        },
        { root: true }
      )

      const doc = getters.getIframeDocument

      if (newPageNumber > 1) {
        const pageToMove = doc.querySelector(`.page${oldPageNumber}`)
        const pageToChange = doc.querySelector(`.page${newPageNumber}`)
        if (newPageNumber > oldPageNumber) {
          pageToChange.after(pageToMove)
        } else {
          pageToChange.before(pageToMove)
        }
      } else {
        doc.querySelector(`.page1`).before(doc.querySelector(`.page${oldPageNumber}`))
      }

      const styleVariables = { ...state.styleVariables }

      if (newPageNumber < oldPageNumber) {
        let tmp = styleVariables[oldPageNumber]
        for (let i = newPageNumber; i < oldPageNumber; i++) {
          commit("addStyleVariables", { [i + 1]: styleVariables[i] })
        }
        commit("addStyleVariables", { [newPageNumber]: tmp })
      } else {
        let tmp = styleVariables[oldPageNumber]
        for (let i = oldPageNumber; i < newPageNumber; i++) {
          commit("addStyleVariables", { [i]: styleVariables[i + 1] })
        }
        commit("addStyleVariables", { [newPageNumber]: tmp })
      }

      const pages = doc.querySelectorAll(`#${state.parentId} .page`)
      let pageNumber = 1
      pages.forEach((page) => {
        const classNameToRemove = `page${page.dataset.page}`
        page.classList.remove(classNameToRemove)
        page.classList.add(`page${pageNumber}`)
        page.setAttribute("data-page", pageNumber)
        pageNumber += 1
      })

      if (state.currentPage === oldPageNumber) {
        commit("setCurrentPage", newPageNumber)
      } else if (state.currentPage === newPageNumber) {
        if (newPageNumber > oldPageNumber) {
          commit("setCurrentPage", state.currentPage - 1)
        } else {
          commit("setCurrentPage", state.currentPage + 1)
        }
      }
    },
    duplicatePage({ getters, state, commit, dispatch }, pageNumber) {
      const selector = `.page${pageNumber}`
      const newPageNumber = state.totalPage + 1
      commit("setTotalPage", newPageNumber)

      const page = getters.getIframeDocument.querySelector(selector)
      pageNumber = page.dataset.page
      const cloneNode = page.cloneNode(true)

      cloneNode.classList.remove(`page${pageNumber}`)
      cloneNode.classList.add(`page${state.totalPage}`)
      cloneNode.dataset.pageName = cloneNode.dataset.pageName + " Copy"
      cloneNode.dataset.page = newPageNumber.toString()

      getters.getIframeDocument.querySelector("[data-parent]").insertAdjacentElement("beforeend", cloneNode)

      commit("addStyleVariables", { [newPageNumber]: cloneDeep(state.styleVariables[pageNumber]) })

      dispatch(
        "builderHistory/addToHistory",
        {
          type: HISTORY_TYPES.ADD_PAGE,
          data: {
            pageNumber: newPageNumber,
            pageContent: cloneNode.outerHTML,
            pageStyleVariables: cloneDeep(state.styleVariables[pageNumber])
          }
        },
        { root: true }
      )

      dispatch("reloadIframe")
    },
    removePage({ commit, state, getters, dispatch }, pageNumber) {
      const selector = `.page${pageNumber}`
      const doc = getters.getIframeDocument

      dispatch(
        "builderHistory/addToHistory",
        {
          type: HISTORY_TYPES.REMOVE_PAGE,
          data: {
            pageNumber: pageNumber,
            pageContent: doc.querySelector(`${selector}`).outerHTML,
            pageStyleVariables: state.styleVariables[pageNumber]
          }
        },
        { root: true }
      )

      commit("removeStyleOfPage", pageNumber)
      commit("clearOutlines")
      doc.querySelector(`${selector}`).remove()
      const pages = doc.querySelectorAll(`#${state.parentId} .page`)
      const totalPage = pages.length
      let loopPageNum = 1
      pages.forEach((page) => {
        page.classList.remove(page.classList[1])
        page.classList.add(`page${loopPageNum}`)
        page.setAttribute("data-page", loopPageNum.toString())
        page.setAttribute("data-total-page", pages.length.toString())
        loopPageNum += 1
      })
      commit("setTotalPage", totalPage)

      if (state.currentPage > state.totalPage) {
        commit("setCurrentPage", state.totalPage)
      }

      dispatch("reloadIframe")
      dispatch("setEditorFonts")
    },
    addNewPage({ commit, getters, state, dispatch }, { type, typeData }) {
      commit("clearOutlines")
      const newPageNumber = state.totalPage + 1
      const parentId = state.parentId
      const config = { type, typeData }
      let { styleVariables, html } = new HtmlGenerator(newPageNumber, config).getPage(parentId)
      commit("addPage")
      const doc = getters.getIframeDocument
      doc.querySelector(`#${parentId} .page${newPageNumber - 1}`).insertAdjacentHTML("afterend", html)
      doc.querySelectorAll(`#${parentId} .page`).forEach((page) => {
        page.setAttribute("data-total-page", newPageNumber.toString())
      })
      commit("addStyleVariables", { [newPageNumber]: styleVariables })

      dispatch(
        "builderHistory/addToHistory",
        {
          type: HISTORY_TYPES.ADD_PAGE,
          data: {
            pageNumber: newPageNumber,
            pageContent: html,
            pageStyleVariables: styleVariables
          }
        },
        { root: true }
      )

      dispatch("reloadIframe")
    },

    changePageType({ state, getters, dispatch }, { type, typeData }) {
      dispatch("builderHistory/startGrouping", null, { root: true })
      const currentPage = state.currentPage
      const pageName = getters.getPageElement.dataset.pageName
      dispatch("addNewPage", { type, typeData })
      dispatch("changeOrderOfPage", {
        oldPageNumber: state.totalPage,
        newPageNumber: currentPage
      })
      dispatch("removePage", currentPage + 1)
      dispatch("builderHistory/stopGrouping", null, { root: true })
      dispatch("reloadIframe")
      getters.getPageElement.dataset.pageName = pageName
    },

    setEditorFonts({ getters }) {
      let fonts = []
      getters.getIframeDocument.querySelectorAll("*").forEach((element) => {
        if (element.className.toString().includes("font-family")) {
          const classList = element.className.toString().split(/\s+/)
          classList.forEach((className) => {
            if (className.includes("font-family")) {
              fonts.push(className.replace("font-family-", "").replaceAll("-", " "))
            }
          })
        }
      })
      const url = getImportUrl([...new Set(fonts)])
      const link = getters.getIframeDocument.querySelector(`link.osm-editor-fonts`)
      link.setAttribute("href", url)
    },
    async initBuilder({ commit }, { siteId, campaignId, campaignVariantId }) {
      const resp = await campaignAPI.fetchCampaignVariant({ siteId, campaignId, campaignVariantId })
      const variantData = resp.data.data["campaign_variant"]
      const accessUrl = variantData["access_url"]
      let variantHtml = await fetch(accessUrl)
      variantHtml = await variantHtml.text()
      const { styleVariables, parentId, totalPage } = parseWidgetHtml(variantHtml)

      commit("setHtml", variantHtml)
      commit("setParentId", parentId)
      commit("setTotalPage", totalPage)
      commit("initStyleVariables", styleVariables)
    },
    initializeWidgets({ getters, commit }) {
      // initialize
      getters.getIframeDocument.querySelectorAll(".page").forEach((page) => {
        if (page.dataset.maType === "multi-purpose") {
          new LayoutManager(page.querySelector(`.layout`))
          new BodyManager(page.querySelector(`.body-wrapper`))
          new GamificationManager(page.querySelector(`.gamification-container`))
          new OverlayManager(page.querySelector(`.overlay`))
          if (!page.querySelector(".position").classList.contains("panel")) {
            new CloseButtonManager(getters.getIframeDocument.querySelector(`button.close-button`))
          }
        }
      })

      commit("setInitialHtml", getters.getIframeDocument.documentElement.innerHTML)
    }
  },
  namespaced: true
}
export default builder
