import getAxiosInstance from "@/api/get-axios-instance"

const fetchStatistics = async ({ siteId, startDate, endDate }) =>
  await getAxiosInstance().get(`sites/${siteId}/campaign-statistics`, {
    params: {
      start_date: startDate,
      end_date: endDate
    }
  })

const statisticsAPI = {
  fetchStatistics
}
export default statisticsAPI
