import ElementManager from "@/lib/marketing-widgets/element-manager"

class TextFieldManager extends ElementManager {
  constructor(textField) {
    super(
      textField,
      {
        background: "transparent",
        "border-radius": "0px 0px 0px 0px",
        padding: "0px 0px 0px 0px",
        margin: "0px 0px 16px 0px",
        "font-size": "16px",
        color: "#2b2b2b",
        "border-style": "none",
        "border-width": "0px 0px 0px 0px",
        "border-color": "#f00"
      },
      "text-"
    )
  }
}

export default TextFieldManager
