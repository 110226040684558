<template>
  <div>
    <Checkbox v-model="isRequired" @change="changeIsRequired"> Required</Checkbox>
    <Checkbox v-model="placeholderActive" @change="setPlaceHolder"> Placeholder</Checkbox>
    <input
      v-if="placeholderActive"
      v-model="placeholderText"
      class="form-control form-control-sm mb-3"
      placeholder="Placeholder text"
      type="text"
      @input="setPlaceHolder"
    />
    <div class="select">
      <h4 class="mb-2">Options</h4>
      <draggable handle=".handle" @end="dragEnd" :key="key">
        <div
          v-for="(option, key) in options"
          v-show="!option.disabled"
          :key="key"
          class="position-relative handle border rounded-md px-2 py-2 mb-2"
        >
          <button class="btn btn-icon btn-soft-danger btn-xs position-absolute" style="top: 0; right: 0" @click="removeOption(key)">
            <i class="tio-clear"></i>
          </button>
          <span class="position-absolute" style="left: -1.15rem; top: 50%; transform: translateY(-50%)">
            <i class="tio-drag tio-lg"></i>
          </span>
          <div>
            <div class="form-group mb-2 mt-0">
              <label class="input-label mb-0">Text</label>
              <input
                type="text"
                class="form-control form-control-sm"
                :value="option.innerHTML"
                @input="(e) => changeOptionText(e.target.value, key)"
              />
            </div>
            <div class="form-group mb-0">
              <label class="input-label mb-0">Value</label>
              <input
                type="text"
                class="form-control form-control-sm"
                :value="option.value"
                @input="(e) => changeOptionValue(e.target.value, key)"
              />
            </div>
          </div>
        </div>
      </draggable>
      <div class="d-flex justify-content-center">
        <button class="btn btn-soft-primary btn-icon rounded-circle" @click="addOption">
          <i class="tio-add" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import Checkbox from "@/components/common/Checkbox"
import randomId from "@/lib/utils/random-id"

export default {
  name: "Select",
  components: {
    Checkbox,
    draggable
  },
  props: ["element"],

  data() {
    return {
      isRequired: "",
      placeholderActive: false,
      placeholderText: "",
      options: [],
      key: null
    }
  },
  methods: {
    addOption() {
      const option = this.element.ownerDocument.createElement("option")
      option.value = "Value"
      option.text = "Text"
      this.element.appendChild(option)
      this.generateOptions()
    },
    removeOption(key) {
      const option = this.element.options[key]
      const owner = option.parentElement
      owner.removeChild(option)
      this.generateOptions()
    },
    changeOptionText(text, key) {
      this.element.options[key].innerHTML = text
    },
    changeOptionValue(text, key) {
      this.element.options[key].value = text
    },
    dragEnd(event) {
      const { newIndex, oldIndex } = event
      const newFields = [...this.element.querySelectorAll("option")]

      const tmp = newFields[oldIndex]
      newFields.splice(oldIndex, 1)
      newFields.splice(newIndex, 0, tmp)

      this.element.innerHTML = ""
      newFields.forEach((nf) => this.element.appendChild(nf))
      this.element.selectedIndex = 0
    },
    setPlaceHolder() {
      if (!this.placeholderActive) {
        const opt = this.element.options[0]
        opt.remove()
      } else {
        const opt = this.element.options[0]
        if (opt && opt.disabled) {
          opt.innerText = this.placeholderText
        } else {
          this.element.innerHTML = `<option selected disabled value="">${this.placeholderText}</option>` + this.element.innerHTML
        }
      }
    },
    changeIsRequired(event) {
      this.element.required = event.target.checked
    },
    generateOptions() {
      this.options = this.element.options
      this.key = randomId()
    }
  },
  mounted() {
    this.isRequired = this.element.required
    if (this.element.options[0] && this.element.options[0].disabled) {
      this.placeholderActive = true
      this.placeholderText = this.element.options[0].innerText
    }
    this.generateOptions()
  }
}
</script>
