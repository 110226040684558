<template>
  <div>
    <full-page-loading v-if="loading" />
    <dashboard-title title="Choose a template">
      <template #right>
        <button class="btn btn-white" @click="create">Start From Scratch</button>
      </template>
    </dashboard-title>
    <template-gallery />
  </div>
</template>

<script>
import { mapState } from "vuex"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import HtmlGenerator from "@/lib/marketing-widgets/html-generator"
import campaignAPI from "@/api/campaign"
import campaignStyleVariablesGenerator from "@/lib/utils/campaign-style-variables-generator"
import FullPageLoading from "@/components/common/FullPageLoading"
import ensureHasSite from "@/mixins/ensure-has-site"
import "@/mixins/date-formatter-mixin"
import dateFormatterMixin from "@/mixins/date-formatter-mixin"
import TemplateGallery from "@/components/dashboard/TemplateGallery"

export default {
  name: "CreateCampaign",
  components: { TemplateGallery, FullPageLoading, DashboardTitle },
  mixins: [ensureHasSite, dateFormatterMixin],
  data() {
    return {
      loading: false
    }
  },
  computed: mapState("sites", ["selectedSite"]),
  methods: {
    async create() {
      const data = {
        type: "multi-purpose",
        typeData: {
          type: "popup"
        }
      }
      this.loading = true
      const htmlData = new HtmlGenerator(1, data).get()
      const parentId = htmlData.parentId
      const parser = new DOMParser().parseFromString(htmlData.html, "text/html")

      const pageStyleVariables = htmlData.styleVariables.style
      const styleText = campaignStyleVariablesGenerator.toString(parentId, { 1: pageStyleVariables })
      parser.querySelector("style#osm-user").innerHTML = styleText
      const newCampaignRequest = await campaignAPI.createCampaign({
        siteId: this.selectedSite.id,
        name: "New Campaign " + this.getFormattedDateTime(new Date()),
        settings: {
          whenRules: {
            exitIntent: true
          },
          whoRules: {
            frequency: { betweenMilliseconds: 3600000, maxCount: 2 }
          }
        },
        schedule: [],
        body: parser.documentElement.innerHTML
      })
      const newCampaign = newCampaignRequest.data.data.campaign
      this.loading = false
      return this.$router.push({
        name: "CampaignBuilder",
        query: { new: true },
        params: { site: newCampaign.site_id, campaign: newCampaign.id, variant: newCampaign.variants[0].id }
      })
    }
  }
}
</script>
