import Login from "@/views/Auth/Login"
import Register from "@/views/Auth/Register"
import GoogleOAuth from "@/views/Auth/GoogleOAuth"
import Logout from "@/views/Auth/Logout"

const authRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout
  },
  {
    path: "/google-oauth",
    name: "GoogleOAuth",
    component: GoogleOAuth
  }
]
export default authRoutes
