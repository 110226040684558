<template>
  <settings-template title="Blur" :layout="layout" :show-device-picker="showDevicePicker">
    <input v-model.number="value" type="range" min="0" max="24" class="form-control-range" />
  </settings-template>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "SettingsBlur",
  components: { SettingsTemplate },
  props: {
    variable: {
      type: String,
      required: true
    }
  },
  mixins: [appearanceChangerMixin],
  computed: {
    value: {
      get() {
        const regex = /blur\((.*)px\)/
        return this.getStyleVariableValueDirectCurrentEnv(this.variable).match(regex)[1]
      },
      set(value) {
        this.setStyleVariableDirectCurrentEnv({
          variable: this.variable,
          value: "blur(" + value + "px)"
        })
      }
    }
  }
}
</script>
