<template>
  <div class="text-center">
    <div class="btn-group my-1">
      <button
        type="button"
        @click="handleClick"
        class="btn btn-soft-primary rounded btn-xs"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="tio-add"></i> Add Element
      </button>
      <div class="dropdown-menu" ref="dropdown-menu">
        <h4 class="dropdown-item-text">Basic Fields</h4>
        <a
          href="javascript:"
          class="dropdown-item"
          @click="addNewField(item.type)"
          v-for="item in [
            { type: 'text-field', name: 'Text', icon: 'text' },
            { type: 'button', name: 'Button', icon: 'mouse-outlined' },
            { type: 'spacer', name: 'Spacer', icon: 'enter' },
            { type: 'divider', name: 'Divider', icon: 'reflect-vertical' },
            { type: 'count-down', name: 'Countdown Timer', icon: 'timer' },
            { type: 'social-button-group', name: 'Social Button Group', icon: 'share' }
          ]"
          :key="item.type"
        >
          <i :class="`dropdown-item-icon tio-${item.icon}`"></i>
          {{ item.name }}
        </a>
        <div class="dropdown-divider"></div>
        <h4 class="dropdown-item-text">Form Fields</h4>
        <a
          v-for="(field, type) in formFields"
          class="dropdown-item"
          :key="type"
          @click="addNewField('form-field', field.type)"
          href="javascript:;"
        >
          <i :class="`dropdown-item-icon tio-${field.icon}`"></i>
          {{ field.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import FormElementHelper from "@/components/campaign-builder/content-changers/general-form-fields/form-fields/FormElementHelper"
import randomId from "@/lib/utils/random-id"
import CountDownManager from "@/lib/marketing-widgets/count-down-manager"
import SocialButtonGroupManager from "@/lib/marketing-widgets/social-button-group-manager"
import { HISTORY_TYPES } from "@/store/builder-history"

export default {
  name: "BodyAddElementMenu",
  props: {
    beforeElement: {
      default: null
    },
    parentElement: {
      default: null
    }
  },
  data() {
    return {
      formFields: [
        { type: "email", name: "Email", icon: "email-outlined" },
        { type: "input-text", name: "Text Input", icon: "text" },
        { type: "text-area", name: "Text Area", icon: "text-center" },
        { type: "input-number", name: "Number", icon: "double-caret-vertical" },
        { type: "input-tel", name: "Phone Number", icon: "call-talking" },
        { type: "input-url", name: "Url", icon: "link" },
        { type: "input-date", name: "Date", icon: "calendar-note" },
        { type: "radios", name: "Radio", icon: "adjust" },
        { type: "checkboxes", name: "Checkboxes", icon: "checkmark-square-outlined" },
        { type: "select", name: "Dropdown", icon: "arrow-drop-down-circle-outlined" },
        // { type: "checkbox", name: "Checkbox", icon: "checkmark-square-outlined" },
        // { type: "rating", name: "Rating", icon: "star-outlined" }
      ]
    }
  },

  computed: mapGetters("builder", ["getPageSelector", "getIframeDocument"]),
  methods: {
    handleClick() {
      setTimeout(() => {
        this.$refs["dropdown-menu"].scrollIntoView({
          behavior: "smooth",
          block: "end"
        })
      }, 1)
    },
    addNewField(type, additionalData) {
      const parentDiv = this.getIframeDocument.createElement("div")
      parentDiv.id = randomId()
      parentDiv.classList.add("osm-element")

      if (type === "form-field") {
        const formElements = new FormElementHelper(this.getIframeDocument)
        parentDiv.appendChild(formElements.getNewFormElement(additionalData))
      } else if (type === "spacer") {
        parentDiv.innerHTML = `<div id="spacer-${randomId()}" data-osm-element-send data-osm-spacer></div>`
      } else if (type === "divider") {
        parentDiv.innerHTML = `<div id="divider-${randomId()}" data-osm-element-send data-osm-divider></div>`
      } else if (type === "text-field") {
        parentDiv.innerHTML = `<div id="text-field-${randomId()}" data-osm-element-send data-osm-text-field><div>Write Your Text Here.</div></div>`
      } else if (type === "button") {
        parentDiv.innerHTML = `<button id="btn${randomId()}" type="button" data-osm-button data-submit="true" data-count-as="conversion"><div style="font-weight: 700;">BUTTON</div></button>`
      } else if (type === "count-down") {
        parentDiv.innerHTML = CountDownManager.getInitialHtml()
      } else if (type === "social-button-group") {
        parentDiv.innerHTML = SocialButtonGroupManager.getInitialHtml()
      }

      let index = 0
      if (this.beforeElement) {
        this.beforeElement.before(parentDiv)
        index = this.beforeElement.parentElement.indexOf(this.beforeElement)
      } else {
        this.parentElement.appendChild(parentDiv)
        index = 0
      }

      this.$emit("added")
      this.$store.dispatch("builderHistory/addToHistory", {
        type: HISTORY_TYPES.ADD_BODY_ELEMENT,
        data: {
          pageNumber: this.$store.state.builder.currentPage,
          html: parentDiv.outerHTML,
          index: index,
          selector: "#" + parentDiv.id
        }
      })
      this.$store.dispatch("builder/reloadIframe")
    }
  }
}
</script>
