<template>
  <div>
    <div class="vc-sketch-color-wrap btn open-picker-btn rounded-0" ref="picker-button" @click="showPicker = !showPicker">
      <div class="vc-sketch-active-color" :style="{ background: bgColor }"></div>
      <div
        class="vc-checkerboard"
        style="
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADBJREFUOE9jfPbs2X8GPEBSUhKfNAPjqAHDIgz+//+PNx08f/4cfzoYNYCBceiHAQC5flV5JzgrxQAAAABJRU5ErkJggg==');
        "
      ></div>
    </div>

    <div :class="!showPicker ? 'd-none' : ''" ref="picker-parent" style="z-index: 9999">
      <Sketch v-model="sketchValue" :disable-alpha="!showOpacity" ref="sketch" />
    </div>
  </div>
</template>

<script>
import Sketch from "vue-color/src/components/Sketch.vue"
import { createPopper } from "@popperjs/core"

export default {
  name: "ColorPicker",
  data() {
    return {
      sketchValue: this.value,
      showPicker: false,
      popperInstance: null
    }
  },
  computed: {
    bgColor() {
      if (typeof this.sketchValue === "string") return this.sketchValue
      return "rgba(" + [this.sketchValue.rgba.r, this.sketchValue.rgba.g, this.sketchValue.rgba.b, this.sketchValue.rgba.a].join(",") + ")"
    }
  },
  components: { Sketch },
  props: {
    value: { required: true },
    showOpacity: { default: true }
  },
  watch: {
    sketchValue(newValue) {
      const { rgba } = newValue
      const newColor = "rgba(" + [rgba.r, rgba.g, rgba.b, rgba.a].join(",") + ")"
      this.$emit("change", newColor)
      this.$emit("input", newColor)
    },
    showPicker: function(newValue) {
      this.popperInstance.update()
      setTimeout(() => {
        if (newValue) {
          this.addListener()
        } else {
          this.removeListener()
        }
      })
    }
  },
  methods: {
    eventListener: function(e) {
      const sketch = this.$refs["sketch"].$el
      if (!sketch.contains(e.target)) {
        this.showPicker = false
      }
    },
    addListener() {
      document.addEventListener("click", this.eventListener)
    },
    removeListener() {
      document.removeEventListener("click", this.eventListener)
    }
  },
  mounted() {
    this.popperInstance = createPopper(this.$refs["picker-button"], this.$refs["picker-parent"])
  },
  beforeDestroy() {
    this.removeListener()
  }
}
</script>

<style scoped>
.open-picker-btn {
  font-size: 0.8125rem;
  height: calc(1.5em + 1rem - 2px);
  width: 100%;
  margin-left: 0;
}
</style>
