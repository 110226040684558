<template>
  <settings-template :title="title">
    <button :class="[value === 'flex-start' ? 'btn-soft-primary' : 'btn-ghost-primary']"
            class="btn btn-sm " @click="value = 'flex-start'">Left
    </button>
    <button :class="[value === 'center' ? 'btn-soft-primary' : 'btn-ghost-primary']"
            class="btn btn-sm " @click="value = 'center'">Center
    </button>
    <button :class="[value === 'flex-end' ? 'btn-soft-primary' : 'btn-ghost-primary']"
            class="btn btn-sm " @click="value = 'flex-end'">Right
    </button>
  </settings-template>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "SettingsAlign",
  props: {
    variable: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "Align"
    }
  },
  mixins: [appearanceChangerMixin],
  components: { SettingsTemplate },
  computed: {
    value: {
      get() {
        return this.getStyleVariableValue(this.env, this.variable, 0)
      },
      set(value) {
        this.setStyleVariableValue({
          env: this.env,
          variable: this.variable,
          value: value
        })
      }
    }
  }
}
</script>
