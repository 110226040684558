import store from "@/store/index"

export default function auth({ next, to }) {
  const token = window.localStorage.getItem("access_token")
  const user = store.state.user.user
  if (!token) {
    return next({ name: "Login" })
  }

  if (!user) {
    return next({ name: "FetchCurrentUser", params: { redirect: to.fullPath } })
  }

  return next()
}
