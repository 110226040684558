<template>
  <div>
    <div class="row align-items-center">
      <div class="col-4"><span>Name</span></div>
      <div class="col-8"><input class="form-control form-control-sm" :value="name" @input="changeName" /></div>
    </div>
    <div class="row align-items-center">
      <div class="col">
        <checkbox :value="isRequired" @input="changeIsRequired">Required</checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/common/Checkbox"

export default {
  name: "Rating",
  components: { Checkbox },
  props: ["element"],
  data() {
    return {
      isRequired: "",
      name: ""
    }
  },
  methods: {
    changeName(e) {
      const newName = e.target.value
      const inputs = this.element.querySelectorAll("input")
      const labels = this.element.querySelectorAll("label")
      inputs.forEach((input, k) => {
        input.name = newName
        const id = newName + (5 - k).toString()
        input.id = id
        labels[k].htmlFor = id
      })
    },
    changeIsRequired(e) {
      const inputs = this.element.querySelectorAll("input")
      inputs.forEach((input) => {
        input.required = e.target.checked
      })
    }
  },
  mounted() {
    this.isRequired = this.element.querySelector("input").required
    this.name = this.element.querySelector("input").name
  }
}
</script>
