function parseUnit(data) {
  if (!data) {
    return false
  }
  if (data.includes("px")) {
    return {
      value: data.replace("px", ""),
      unit: "px"
    }
  } else if (data.includes("em")) {
    return {
      value: data.replace("em", ""),
      unit: "em"
    }
  } else if (data.includes("%")) {
    return {
      value: data.replace("%", ""),
      unit: "%"
    }
  } else if (data.includes("vw")) {
    return {
      value: data.replace("vw", ""),
      unit: "vw"
    }
  } else if (data.includes("vh")) {
    return {
      value: data.replace("vh", ""),
      unit: "vh"
    }
  } else if (data.includes("unset")) {
    return {
      value: "unset",
      unit: null
    }
  } else if (data === "0" || data === 0) {
    return {
      value: 0,
      unit: "px"
    }
  } else {
    return false
  }
}

export default parseUnit
