<template>
  <div>
    <h4 class="mb-4">Border Settings</h4>
    <settings-template layout="horizontal" class="mb-2" title="Side" :show-device-picker="false">
      <select class="form-control form-control-sm" v-model="currentValues.position">
        <option value="1 1 1 1">All</option>
        <option value="1 0 1 0">Top - Bottom</option>
        <option value="0 1 0 1">Left - Right</option>
        <option value="1 0 0 0">Top</option>
        <option value="0 1 0 0">Right</option>
        <option value="0 0 1 0">Bottom</option>
        <option value="0 0 0 1">Left</option>
        <option value="0 0 0 0">None</option>
      </select>
    </settings-template>
    <template v-if="currentValues.position !== '0 0 0 0'">
      <settings-template layout="horizontal" class="mb-2" title="Style" :show-device-picker="false">
        <select class="form-control form-control-sm" v-model="currentValues.style">
          <option v-for="style in styleOptions" :key="style" :selected="currentValues.style === style" :value="style">
            {{ style.charAt(0).toUpperCase() + style.slice(1, style.length) }}
          </option>
        </select>
      </settings-template>
      <settings-template layout="horizontal" class="mb-2" title="Size" :show-device-picker="false">
        <range-input-with-unit :min="0" :max="25" :alternative-units="['px']" v-model="currentValues.width" />
      </settings-template>
      <settings-template layout="horizontal" title="Color" :show-device-picker="false">
        <Color :variable="colorVariable"></Color>
      </settings-template>
    </template>
  </div>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import Color from "@/components/campaign-builder/appearance-changers/Color"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import RangeInputWithUnit from "@/components/common/RangeInputWithUnit"
import parseUnit from "@/lib/utils/parse-unit"

export default {
  name: "SettingsBorder",
  props: {
    colorVariable: {
      type: String,
      required: true
    },
    styleVariable: {
      type: String,
      required: true
    },
    widthVariable: {
      type: String,
      required: true
    },
    layout: {
      type: String,
      default: "accordion"
    }
  },
  components: { RangeInputWithUnit, SettingsTemplate, Color },
  mixins: [appearanceChangerMixin],
  data() {
    return {
      styleOptions: ["dotted", "dashed", "solid", "double", "groove", "ridge", "inset", "outset", "none"],
      currentValues: {
        style: null,
        width: null,
        color: null,
        position: null
      }
    }
  },
  watch: {
    currentValues: {
      deep: true,
      handler: function (newVal) {
        if (newVal.width !== "0 0 0 0" && newVal.style === "none") {
          newVal.style = 'solid'
        }
        if (newVal.width !== "0 0 0 0" && parseInt(newVal.width.value) === 0) {
          newVal.width.value = 1
        }

        this.setStyleVariableDirectCurrentEnv({
          variable: this.styleVariable,
          value: newVal.style
        })

        const w = newVal.position
          .split(" ")
          .map((p) => parseInt(p) * parseInt(newVal.width.value) + "px")
          .join(" ")

        this.setStyleVariableDirectCurrentEnv({
          variable: this.widthVariable,
          value: w
        })
      }
    }
  },
  methods: {
    init() {
      this.currentValues.style = this.getStyleVariableValueDirectCurrentEnv(this.styleVariable)
      this.currentValues.width = parseUnit(this.getStyleVariableValueDirectCurrentEnv(this.widthVariable).split(" ")[0])

      let positionMatrix = ["0", "0", "0", "0"]
      this.getStyleVariableValueDirectCurrentEnv(this.widthVariable)
        .replaceAll("px", "")
        .split(" ")
        .forEach((w, k) => {
          if (parseInt(w) > 0) {
            positionMatrix[k] = "1"
          }
        })
      this.currentValues.position = positionMatrix.join(" ")
    }
  },
  created() {
    this.init()
  }
}
</script>
