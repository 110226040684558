<template>
  <div class="card mb-3 overflow-hidden">
    <div class="row no-gutters">
      <div class="col-md-2">
        <img class="img-fluid" style="width: 100%; height: 100%" src="https://picsum.photos/seed/picsum/120/80" />
      </div>
      <div class="col-md-10">
        <div class="card-body d-flex items-center justify-content-between">
          <div class="flex-fill text-left d-flex flex-column justify-content-center">
            <h3 class="card-title">{{ title }}</h3>
            <p class="card-text">{{ description }}</p>
          </div>

          <a class="btn btn-icon btn-no-focus btn-ghost-primary rounded-circle" href="javascript:" @click="handleClick">
            <i class="tio-edit tio-xl" v-if="ruleExists(typeOfRule, name)"></i>
            <i class="tio-add-circle-outlined tio-xl" v-else></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import targetingRuleTexts from "@/components/campaign-targeting-rules/targetingRuleTexts"

export default {
  name: "TargetingAddButton",
  props: ["name", "typeOfRule", "defaultValue"],
  data: () => ({
    targetingRuleTexts: targetingRuleTexts,
    title: "",
    description: ""
  }),
  computed: {
    ...mapGetters("campaignSettings", ["ruleExists"])
  },
  methods: {
    ...mapMutations("campaignSettings", ["setRule", "openRuleOption"]),
    handleClick() {
      this.$emit("picked", {
        typeOfRule: this.typeOfRule,
        nameOfRule: this.name
      })
    }
  },
  mounted() {
    const { title, description } = this.targetingRuleTexts[this.typeOfRule][this.name]
    this.title = title
    this.description = description
  }
}
</script>

<style scoped></style>
