<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text input-group-text-sm" v-if="showDomain">{{ selectedSite.domain }}/</span>
    </div>
    <input class="form-control form-control-sm" :value="value" @input="(e) => $emit('input', e)" />
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "TargetingUrlInput",
  props: {
    value: String,
    showDomain: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState("sites", ["selectedSite"])
  }
}
</script>

<style scoped></style>
