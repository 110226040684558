<template>
  <base-campaign-targeting-rule>
    <div>Visitors who have spent at least {{ data / 1000 }} seconds on the site will see your message.</div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("who", targetingRuleNames.whoRules.timeSpentOnSite)

export default {
  name: "TimeSpentOnSite",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin]
}
</script>
