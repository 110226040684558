function multiPurposeTemplates() {
  return JSON.parse(
    JSON.stringify({
      popup: {
        name: "Standard Popup",
        key: "popup"
      },
      panel: {
        name: "Panel",
        key: "panel"
      }
    })
  )
}

export default multiPurposeTemplates
