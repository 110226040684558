<template>
  <base-campaign-targeting-rule>
    <div>
      <div v-for="(andSentences, key1) in getDescription" :key="key1">
        <div class="my-2" v-for="(sentence, key2) in andSentences" :key="key2">
          <div style="line-height: 1.2">{{ sentence }}</div>
        </div>
        <strong v-if="data.length - 1 > key1">OR</strong>
      </div>
    </div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import { mapState } from "vuex"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("who", targetingRuleNames.whoRules.linking)

export default {
  name: "TargetingLinking",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin],
  computed: {
    ...mapState("campaigns", ["campaigns"]),
    ...mapState("sites", ["selectedSite"]),
    getDescription() {
      let wordMap = {
        impress: "seen",
        "not-impress": "not seen",
        submit: "submitted",
        "not-submit": "not submitted"
      }
      let sentences = []
      this.data.forEach((andRows) => {
        sentences.push(
          andRows.map((row) => {
            const campaign = this.campaigns.find((campaign) => parseInt(campaign.id) === parseInt(row.campaignId))
            return `If the visitor has ${wordMap[row.action]} ${campaign.name} campaign in this session.`
          })
        )
      })
      return sentences
    }
  }
}
</script>
