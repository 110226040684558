<template>
  <select class="form-control form-control-sm w-auto" :value="value" @input="(e) => $emit('input', e)" @change="(e) => $emit('change', e)">
    <option :value="stringOperator" :key="stringOperator" v-for="stringOperator in Object.keys(stringCompareOperators)">
      {{ stringCompareOperators[stringOperator] }}
    </option>
  </select>
</template>

<script>
import stringCompareOperators from "@/components/campaign-targeting-rules/stringCompareOperators"

export default {
  name: "TargetingStringCompareOperatorSelector",
  data() {
    return {
      stringCompareOperators: stringCompareOperators
    }
  },
  props: ["value"]
}
</script>

<style scoped></style>
