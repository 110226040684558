import ElementManager from "@/lib/marketing-widgets/element-manager"

class FormFieldRatingManager extends ElementManager {
  constructor(ratingParent) {
    super(
      ratingParent,
      {
        width: "100%",
        "border-radius": "0px 0px 0px 0px",
        padding: "8px 0px 8px 0px",
        margin: "0px 0px 16px 0px",
        "border-style": "none",
        "border-width": "1px 1px 1px 1px",
        "border-color": "#2b2b2b"
      },
      "form-rating"
    )

    ratingParent.querySelectorAll("svg").forEach((el) => {
      if (!el.style.getPropertyValue("--fill-color")) {
        this.setFillColor("#2b2b2b")
      }
      if (!el.style.getPropertyValue("--stroke-color")) {
        this.setStrokeColor("#2b2b2b")
      }
    })
  }

  setFillColor(color) {
    this.element.querySelectorAll("svg").forEach((el) => el.style.setProperty("--fill-color", color))
  }

  getFillColor() {
    return this.element.querySelectorAll("svg")[0].style.getPropertyValue("--fill-color")
  }

  setStrokeColor(color) {
    this.element.querySelectorAll("svg").forEach((el) => el.style.setProperty("--stroke-color", color))
  }

  getStrokeColor() {
    return this.element.querySelectorAll("svg")[0].style.getPropertyValue("--stroke-color")
  }
}

export default FormFieldRatingManager
