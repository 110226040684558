const toggleSwitchMixin = {
  methods: {
    async initToggleSwitch() {
      await this.$nextTick()
      const HSToggleSwitch = require("@/theme-files/hs-toggle-switch.min")
      if (this.$refs.PARENTELEMENT) {
        this.$refs.PARENTELEMENT.querySelectorAll(".js-toggle-switch").forEach((item) => {
          new HSToggleSwitch($(item)).init()
        })
      } else {
        this.$el.querySelectorAll(".js-toggle-switch").forEach((item) => {
          new HSToggleSwitch($(item)).init()
        })
      }
    }
  }
}
export default toggleSwitchMixin
