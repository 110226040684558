<template>
  <div>
    <div class="text-left mb-3">
      <ul class="nav nav-segment d-flex">
        <li class="nav-item flex-grow-1">
          <a
            class="nav-link justify-content-center"
            @click="type = 'transparent'"
            :class="{ active: type === 'transparent' }"
            href="javascript:;"
            data-toggle="bg-color-type"
            role="tab"
            >Transparent</a
          >
        </li>
        <li class="nav-item flex-grow-1">
          <a
            class="nav-link justify-content-center"
            @click="type = 'solid'"
            :class="{ active: type === 'solid' }"
            href="javascript:;"
            data-toggle="bg-color-type"
            role="tab"
            >Solid</a
          >
        </li>
        <li class="nav-item flex-grow-1">
          <a
            class="nav-link justify-content-center"
            @click="type = 'linear-gradient'"
            href="javascript:;"
            :class="{ active: type === 'linear-gradient' }"
            data-toggle="bg-color-type"
            role="tab"
            >Gradient</a
          >
        </li>
        <li class="nav-item flex-grow-1">
          <a
            class="nav-link justify-content-center"
            @click="type = 'image'"
            href="javascript:;"
            :class="{ active: type === 'image' }"
            data-toggle="bg-color-type"
            role="tab"
            >Image</a
          >
        </li>
      </ul>
    </div>
    <div :key="type">
      <div class="color" v-if="type === 'solid'">
        <settings-template :show-device-picker="false" title="Color" layout="horizontal">
            <Color :env="env" :variable="variable" />
        </settings-template>
      </div>
      <div v-if="type === 'linear-gradient'">
        <color-gradient :env="env" :variable="variable" />
      </div>
      <div v-if="type === 'image'">
        <BackgroundImage :env="env" :variable="variable" />
      </div>
      <div></div>
    </div>
  </div>
</template>
<script>
import Color from "@/components/campaign-builder/appearance-changers/Color"
import ColorGradient from "@/components/campaign-builder/appearance-changers/ColorGradient"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import BackgroundImage from "@/components/campaign-builder/appearance-changers/BackgroundImage"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "Background",
  components: { SettingsTemplate, BackgroundImage, ColorGradient, Color },
  mixins: [appearanceChangerMixin],
  computed: {
    type: {
      get() {
        const data = this.getStyleVariableValueDirect(this.env, this.variable).trim()
        if (data.includes("url")) {
          return "image"
        } else if (data === "transparent") {
          return "transparent"
        } else if (data.includes("linear-gradient")) {
          return "linear-gradient"
        } else {
          return "solid"
        }
      },
      set(type) {
        let currentColor = this.getStyleVariableValueDirect(this.env, this.variable)
        let deg = "90deg",
          color1 = "#fff",
          color2 = "#fff"

        if (currentColor.includes("linear-gradient")) {
          [deg, color1, color2] = currentColor
            .substring(currentColor.indexOf("linear-gradient(") + 16, currentColor.lastIndexOf(")"))
            .split(/,(?![^(]*\))(?![^"']*["'](?:[^"']*["'][^"']*["'])*[^"']*$)/)
        } else {
          color1 = color2 = currentColor
        }

        if (type === "transparent") {
          this.setVariable("transparent")
        } else if (type === "linear-gradient") {
          if (currentColor === "transparent") {
            color1 = "rgb(0,0,0,0)"
            color2 = "rgb(0,0,0,0)"
          }
          this.setVariable(`linear-gradient(${deg}, ${color1}, ${color2})`)
        } else if (type === "solid") {
          if (color1.trim() === "transparent") color1 = "#fff"
          this.setVariable(color1)
        } else if (type === "image") {
          /*eslint-disable-next-line*/
          this.setVariable(`url(\"\") no-repeat center center / cover, linear-gradient(${deg}, ${color1}, ${color2})`)
        }
      }
    }
  },
  methods: {
    setVariable(c) {
      this.setStyleVariableDirect({
        env: this.env,
        value: c,
        variable: this.variable
      })
    }
  }
}
</script>
