import ElementManager from "@/lib/marketing-widgets/element-manager"

class CloseButtonManager extends ElementManager {
  constructor(closeButton) {
    super(
      closeButton,
      {
        "font-size": "24px",
        width: "1.25em",
        height: "1.25em",
        "font-weight": "400",
        "border-radius": "0px 0px 0px 0px",
        top: "10px",
        right: "10px",
        bottom: "unset",
        left: "unset",
        display: "flex",
        background: "transparent",
        "border-color": "rgb(0,0,0)",
        "border-style": "solid",
        "border-width": "0px 0px 0px 0px",
        color: "rgb(0,0,0)"
      },
      "close-btn"
    )
  }
}

export default CloseButtonManager
