import ElementManager from "@/lib/marketing-widgets/element-manager"
// TODO: refactor unnecessary static property and methods
class SocialButtonGroupManager extends ElementManager {
  constructor(element) {
    super(
      element,
      {
        "justify-content": "center",
        "--direction": "column-reverse",
        "--icon-size": "56px",
        "--icon-label-gap": "8px",
        "--label-font-size": "14px",
        "--label-color": "rgba(20,20,20,1)",
        "--button-spacing": "12px"
      },
      "social-button-group-"
    )
    this.element = element
  }

  static youtubeSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310" xml:space="preserve"><path d="M297.917 64.645c-11.19-13.302-31.85-18.728-71.306-18.728H83.386c-40.359 0-61.369 5.776-72.517 19.938C0 79.663 0 100.008 0 128.166v53.669c0 54.551 12.896 82.248 83.386 82.248h143.226c34.216 0 53.176-4.788 65.442-16.527C304.633 235.518 310 215.863 310 181.835v-53.669c0-29.695-.841-50.16-12.083-63.521zm-98.896 97.765-65.038 33.991a9.991 9.991 0 0 1-4.632 1.137c-1.798 0-3.592-.484-5.181-1.446a10.001 10.001 0 0 1-4.819-8.554v-67.764c0-3.492 1.822-6.732 4.808-8.546 2.987-1.814 6.702-1.938 9.801-.328l65.038 33.772c3.309 1.718 5.387 5.134 5.392 8.861.004 3.73-2.065 7.151-5.369 8.877z"/></svg>`
  static linkedinSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310"  xml:space="preserve"><path d="M72.16 99.73H9.927c-2.762 0-5 2.239-5 5v199.928c0 2.762 2.238 5 5 5H72.16c2.762 0 5-2.238 5-5V104.73c0-2.761-2.238-5-5-5zM41.066.341C18.422.341 0 18.743 0 41.362 0 63.991 18.422 82.4 41.066 82.4c22.626 0 41.033-18.41 41.033-41.038C82.1 18.743 63.692.341 41.066.341zM230.454 94.761c-24.995 0-43.472 10.745-54.679 22.954V104.73c0-2.761-2.238-5-5-5h-59.599c-2.762 0-5 2.239-5 5v199.928c0 2.762 2.238 5 5 5h62.097c2.762 0 5-2.238 5-5V205.74c0-33.333 9.054-46.319 32.29-46.319 25.306 0 27.317 20.818 27.317 48.034v97.204c0 2.762 2.238 5 5 5H305c2.762 0 5-2.238 5-5V194.995c0-49.565-9.451-100.234-79.546-100.234z"/></svg>`
  static twitterSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310"  xml:space="preserve"><path d="M302.973 57.388a117.512 117.512 0 0 1-14.993 5.463c6.057-6.85 10.675-14.91 13.494-23.73.632-1.977-.023-4.141-1.648-5.434-1.623-1.294-3.878-1.449-5.665-.39-10.865 6.444-22.587 11.075-34.878 13.783-12.381-12.098-29.197-18.983-46.581-18.983-36.695 0-66.549 29.853-66.549 66.547 0 2.89.183 5.764.545 8.598C101.163 99.244 58.83 76.863 29.76 41.204c-1.036-1.271-2.632-1.956-4.266-1.825a5.0013 5.0013 0 0 0-3.93 2.467c-5.896 10.117-9.013 21.688-9.013 33.461 0 16.035 5.725 31.249 15.838 43.137a56.37 56.37 0 0 1-8.907-3.977c-1.529-.851-3.395-.838-4.914.033a4.9994 4.9994 0 0 0-2.513 4.224c-.007.295-.007.59-.007.889 0 23.935 12.882 45.484 32.577 57.229a57.3724 57.3724 0 0 1-5.063-.735c-1.732-.331-3.513.276-4.681 1.597a4.9962 4.9962 0 0 0-1.018 4.84c7.29 22.76 26.059 39.501 48.749 44.605-18.819 11.787-40.34 17.961-62.932 17.961-4.714 0-9.455-.277-14.095-.826-2.305-.274-4.509 1.087-5.294 3.279-.785 2.193.047 4.638 2.008 5.895 29.023 18.609 62.582 28.445 97.047 28.445 67.754 0 110.139-31.95 133.764-58.753 29.46-33.421 46.356-77.658 46.356-121.367 0-1.826-.028-3.67-.084-5.508 11.623-8.757 21.63-19.355 29.773-31.536 1.237-1.85 1.103-4.295-.33-5.998-1.431-1.704-3.816-2.255-5.852-1.353z"/></svg>`
  static facebookSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.124 96.123" xml:space="preserve"><path d="M72.089.02 59.624 0C45.62 0 36.57 9.285 36.57 23.656v10.907H24.037c-1.083 0-1.96.878-1.96 1.961v15.803c0 1.083.878 1.96 1.96 1.96H36.57v39.876c0 1.083.877 1.96 1.96 1.96h16.352c1.083 0 1.96-.878 1.96-1.96V54.287h14.654c1.083 0 1.96-.877 1.96-1.96l.006-15.803c0-.52-.207-1.018-.574-1.386a1.9603 1.9603 0 0 0-1.387-.575H56.842v-9.246c0-4.444 1.059-6.7 6.848-6.7l8.397-.003c1.082 0 1.959-.878 1.959-1.96V1.98c0-1.081-.876-1.958-1.957-1.96z"/></svg>`
  static pinterestSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M8.617 13.227C8.091 15.981 7.45 18.621 5.549 20c-.586-4.162.861-7.287 1.534-10.605-1.147-1.93.138-5.812 2.555-4.855 2.975 1.176-2.576 7.172 1.15 7.922 3.891.781 5.479-6.75 3.066-9.199C10.369-.275 3.708 3.18 4.528 8.245c.199 1.238 1.478 1.613.511 3.322-2.231-.494-2.897-2.254-2.811-4.6.138-3.84 3.449-6.527 6.771-6.9 4.201-.471 8.144 1.543 8.689 5.494.613 4.461-1.896 9.293-6.389 8.945-1.218-.095-1.728-.699-2.682-1.279z"/></svg>`
  static instagramSvg = `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>`
  static serviceBaseUrls = {
    twitter: "https://twitter.com/",
    facebook: "https://facebook.com/",
    linkedin: "https://linkedin.com/",
    pinterest: "https://pinterest.com/",
    instagram: "https://instagram.com/",
    youtube: "https://youtube.com/"
  }
  static getFacebookShareUrl(url) {
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
  }
  static getTwitterShareUrl(url) {
    return `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`
  }

  static getLinkedinShareUrl(url) {
    return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`
  }
  static getSocialMediaButtonHtml(service, text, url) {
    const svg = SocialButtonGroupManager[service + "Svg"]
    return `
    <a class="osm-social-button" data-service="${service}" href="${url}" target="_blank">
      <div class="osm-social-button-icon">
        ${svg}
      </div>
      <div class="osm-social-button-text">${text}</div>
    </a>`
  }

  draw() {
    const { action, share, follow } = this.element.dataset
    let html
    if (action === "share") {
      const { url, label, services } = JSON.parse(share)
      html = services
        .map((service) => {
          let shareUrl
          if (service === "facebook") shareUrl = SocialButtonGroupManager.getFacebookShareUrl(url)
          else if (service === "linkedin") shareUrl = SocialButtonGroupManager.getLinkedinShareUrl(url)
          else if (service === "twitter") shareUrl = SocialButtonGroupManager.getTwitterShareUrl(url)
          return SocialButtonGroupManager.getSocialMediaButtonHtml(service, label, shareUrl)
        })
        .join(" ")
    } else if (action === "follow") {
      html = JSON.parse(follow)
        .map(({ service, label, urlSuffix }) => {
          const url = SocialButtonGroupManager.serviceBaseUrls[service] + urlSuffix
          return SocialButtonGroupManager.getSocialMediaButtonHtml(service, label, url)
        })
        .join(" ")
    }

    this.element.innerHTML = html
  }

  static getInitialHtml() {
    return `
    <div class="osm-social-button-group" data-osm-social-button-group data-style="filled"
       data-action="share"
       data-share='${JSON.stringify({
         url: "https://www.google.com",
         label: "Share",
         services: ["facebook", "twitter", "linkedin"]
       })}' 
       data-follow='${JSON.stringify([{ service: "youtube", label: "Follow", urlSuffix: "c/JonGjengset" }])}'>
        ${SocialButtonGroupManager.getSocialMediaButtonHtml(
          "facebook",
          "Share",
          SocialButtonGroupManager.getFacebookShareUrl("https://www.google.com")
        )} 
        ${SocialButtonGroupManager.getSocialMediaButtonHtml(
          "twitter",
          "Share",
          SocialButtonGroupManager.getTwitterShareUrl("https://www.google.com")
        )} 
        ${SocialButtonGroupManager.getSocialMediaButtonHtml(
          "linkedin",
          "Share",
          SocialButtonGroupManager.getLinkedinShareUrl("https://www.google.com")
        )} 
    </div>  
    `
  }
}

export default SocialButtonGroupManager
