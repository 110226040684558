<template>
  <modal title="Edit Campaign Template" size="xl" @close="$emit('close')">
    <div class="modal-body">
      <loading v-if="loading" />
      <campaign-template-form
        v-else
        :is_active="templateData.is_active"
        :name="templateData.name"
        :tags="templateData.tags"
        @submit="handleSubmit"
      />
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"
import adminAPI from "@/api/admin"
import CampaignTemplateForm from "@/components/dashboard/Admin/CampaignTemplateForm"
import Loading from "@/components/common/Loading"

export default {
  name: "ModalEditCampaignTemplate",
  components: { Loading, CampaignTemplateForm, Modal },
  props: ["campaignTemplateId"],
  data() {
    return {
      loading: false,
      templateData: {}
    }
  },
  methods: {
    async handleSubmit(data) {
      this.loading = true
      await adminAPI.updateCampaignTemplate(this.campaignTemplateId, data)
      this.$emit("updated")
    }
  },
  async mounted() {
    this.loading = true
    const resp = await adminAPI.fetchCampaignTemplate(this.campaignTemplateId)
    this.templateData = resp.data.data.campaign_template
    this.loading = false
  }
}
</script>

<style scoped></style>
