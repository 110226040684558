<template>
  <div>
    <dashboard-title title="Create Campaign Template" />
    <loading v-if="loading" />
    <div class="card card-body" v-else>
      <campaign-template-form @submit="handleSubmit" :is_active="false" name="New Template" :tags="[]" />
    </div>
  </div>
</template>

<script>
import adminAPI from "@/api/admin"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import Loading from "@/components/common/Loading"
import CampaignTemplateForm from "@/components/dashboard/Admin/CampaignTemplateForm"
import { mapGetters } from "vuex"

export default {
  name: "CreateCampaignTemplate",
  components: { CampaignTemplateForm, Loading, DashboardTitle },
  computed: mapGetters("sites", ['getIdOfSelectedSite']),
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async handleSubmit({ name, is_active, tags }) {
      this.loading = true
      await adminAPI.createCampaignTemplate({
        name,
        is_active,
        tags,
        variantId: this.$route.query.campaignVariantId
      })
      await this.$router.push({ name: "Dashboard.Admin.CampaignTemplates" })
    }
  },
}
</script>

<style scoped></style>
