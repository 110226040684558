<template>
  <div class="card mb-3 mb-lg-5">
    <modal-delete-confirmation
      v-if="showDeleteModal"
      @close="showDeleteModal = false"
      @cancel="showDeleteModal = false"
      @delete="
        deleteCampaigns()
        showDeleteModal = false
      "
    />
    <modal-edit-campaign-template
      v-if="showEditModal"
      :campaign-template-id="editCampaignTemplateId"
      @close="showEditModal = false"
      @updated="handleUpdated"
    />
    <div class="card-header">
      <div class="row justify-content-between align-items-center flex-grow-1">
        <div class="col-6">
          <div class="row align-items-sm-center">
            <div class="col-sm-auto">
              <div class="d-flex align-items-center mr-2">
                <span class="text-secondary mr-2">Status:</span>

                <!-- Select -->
                <select
                  class="js-select2-custom js-datatable-filter custom-select"
                  size="1"
                  style="opacity: 0"
                  data-target-name="is_active"
                  data-hs-select2-options='{
                                "minimumResultsForSearch": "Infinity",
                                "dropdownAutoWidth": true,
                                "width": true
                              }'
                >
                  <option value="">All</option>
                  <option value="true">Active</option>
                  <option value="false">Not Active</option>
                </select>
                <!-- End Select -->
              </div>
            </div>

            <div class="col-md">
              <form>
                <!-- Search -->
                <div class="input-group input-group-merge">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="tio-search"></i>
                    </div>
                  </div>
                  <input
                    id="searchInput"
                    @keyup="search"
                    type="search"
                    class="form-control"
                    placeholder="Search"
                    aria-label="Search users"
                  />
                </div>
                <!-- End Search -->
              </form>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="d-flex justify-content-end">
            <div id="datatableCounterInfo" style="display: none">
              <div class="d-flex align-items-center">
                <span class="font-size-sm mr-3"> <span id="datatableCounter">0</span> Selected</span>
                <a class="btn btn-sm btn-outline-danger" href="javascript:;" @click="showDeleteModal = true">
                  <i class="tio-delete-outlined"></i> Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <datatable-table-element
      :multiselect="true"
      :heads="[
        { class: 'table-column-right-aligned', text: '' },
        { class: '', text: 'Name' },
        { class: 'table-column-right-aligned', text: 'Date Created' },
        { class: '', text: '' }
      ]"
    />
  </div>
</template>

<script>
import { mapState } from "vuex"
import advancedSelectMixin from "@/mixins/advanced-select-mixin"
import dateFormatterMixin from "@/mixins/date-formatter-mixin"
import campaignAPI from "@/api/campaign"
import { debounce } from "lodash"
import ModalDeleteConfirmation from "@/components/modals/ModalDeleteConfirmation"
import DatatableTableElement from "@/components/common/DatatableTableElement"
import adminAPI from "@/api/admin"
import ModalEditCampaignTemplate from "@/components/modals/ModalEditCampaignTemplate"

export default {
  name: "CampaignTemplates",
  components: { ModalEditCampaignTemplate, DatatableTableElement, ModalDeleteConfirmation },
  computed: mapState("sites", ["selectedSite"]),
  data() {
    return {
      datatable: null,
      showDeleteModal: false,
      editCampaignTemplateId: null,
      showEditModal: false
    }
  },
  mixins: [advancedSelectMixin, dateFormatterMixin],
  methods: {
    handleEditBtnClick(btn) {
      this.editCampaignTemplateId = btn.dataset.editTemplate
      this.showEditModal = true
    },
    handleUpdated() {
      this.showEditModal = false
      this.datatable.ajax.reload()
    },
    async toggleActive(inp) {
      inp.disabled = true
      const templateId = inp.dataset["templateId"]
      const checked = inp.checked
      try {
        await adminAPI.updateCampaignTemplate(templateId, { is_active: checked })
      } catch (e) {
        inp.checked = !inp.checked
      } finally {
        inp.disabled = false
      }
    },
    async deleteCampaigns() {
      const ids = []
      this.datatable.rows({ selected: true }).every(function () {
        const id = this.data().id
        ids.push(id)
      })

      await campaignAPI.deleteCampaigns({ siteId: this.selectedSite.id, campaignIds: ids })
      this.datatable.ajax.reload()
      $("#datatableCounterInfo").hide()
      $("#datatableCheckAll").prop("checked", false)
    },
    search: debounce(function (e) {
      this.datatable.column(2).search(e.target.value).draw()
    }, 200)
  },
  async mounted() {
    const vm = this
    this.initAdvancedSelect()
    const url = adminAPI.getCampaignTemplatesDatatablesEndpoint()
    require("@/theme-files/hs.datatables")
    this.datatable = $.HSCore.components.HSDatatables.init($("#datatable"), {
      processing: true,
      serverSide: true,
      select: {
        style: "multi",
        selector: "td:first-child input[type='checkbox']",
        classMap: {
          checkAll: "#datatableCheckAll",
          counter: "#datatableCounter",
          counterInfo: "#datatableCounterInfo"
        }
      },
      drawCallback: function () {
        vm.$el.querySelectorAll(".toggle-switch-input").forEach((inp) => {
          inp.addEventListener("change", () => vm.toggleActive(inp))
        })

        vm.$el.querySelectorAll("[data-edit-template]").forEach((btn) => {
          btn.addEventListener("click", () => vm.handleEditBtnClick(btn))
        })
      },
      ajax: {
        url: url,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("access_token")
        },
        data: function (params) {
          return params
        },
        dataSrc: function (json) {
          for (var i = 0, ien = json.data.length; i < ien; i++) {
            json.data[i]["checkbox"] = `<div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="campaign${json.data[i].id}">
                <label class="custom-control-label" for="campaign${json.data[i].id}"></label>
              </div>`

            json.data[i]["is_active"] = `<label class="toggle-switch toggle-switch-sm d-flex align-items-center" for="campaignStatusToggle${
              json.data[i].id
            }">
              <input type="checkbox" id="campaignStatusToggle${json.data[i].id}" data-template-id="${
              json.data[i].id
            }" class="toggle-switch-input" ${json.data[i].is_active ? "checked" : ""}>
              <span class="toggle-switch-label">
                <span class="toggle-switch-indicator"></span>
              </span>
            </label>`

            const name = json.data[i].name
            json.data[i]["name"] = `<a href="javascript:;" class="media align-items-center" data-campaign-id="${json.data[i].id}">`
            if (json.data[i].screenshot_url) {
              json.data[i][
                "name"
              ] += `<div class="avatar avatar-xl mr-3" style="width: auto;aspect-ratio: 16 / 9;"><img class="avatar-img border" src="${json.data[i].screenshot_url}"></div>`
            } else {
              json.data[i][
                "name"
              ] += `<div class="d-flex justify-content-center align-items-center avatar-xl mr-3"  style="width: auto;aspect-ratio: 16 / 9;"><div class="spinner-border text-primary mx-auto" role="status"><span class="sr-only">Loading...</span></div></div>`
            }
            json.data[i]["name"] += `<div class="media-body"><span class="h5 text-hover-primary mb-0">${name}</span></div>
            </a>`
            json.data[i]["created_at"] = `<div class="text-right">${vm.getFormattedDate(json.data[i]["created_at"])}</div>`
            json.data[i]["actions"] = `<button data-edit-template="${json.data[i].id}" class="btn p-0">Edit Settings</button>`
          }

          return json.data
        }
      },
      columns: [{ data: "checkbox" }, { data: "is_active" }, { data: "name" }, { data: "created_at" }, { data: "actions" }],
      columnDefs: [
        { orderable: false, targets: [0, 1, 2, -1] },
        { className: "table-column-pr-0", targets: [0, 1] }
      ],
      order: [[3, "desc"]],
      language: {
        zeroRecords: `No templates!`,
        processing: `<div class="d-flex justify-content-center my-4">
  <div class="spinner-border text-info mx-auto" role="status">
    <span class="sr-only">Loading...</span> </div></div>`
      }
    })

    $(".js-datatable-filter").on("change", function () {
      var $this = $(this)
      let search = $this.val()
      if (search === "true") search = true
      if (search === "false") search = false

      vm.datatable.column(1).search(search).draw()
    })
  }
}
</script>
