const targetingRuleNames = {
  whenRules: {
    afterMilliseconds: "afterMilliseconds",
    exitIntent: "exitIntent",
    inactivity: "inactivity",
    scrollPercentage: "scrollPercentage",
    clickTo: "clickTo"
  },
  whoRules: {
    returningOrNew: "userReturningOrNew",
    linking: "linking",
    currentUrl: "currentUrl",
    ipAllowed: "ipAllowed",
    ipBlocked: "ipBlocked",
    adblock: "adblock",
    visitedUrl: "visitedUrl",
    lastVisitedUrl: "lastVisitedUrl",
    excludeByVisit: "excludeByVisit",
    device: "device",
    source: "source",
    operatingSystem: "os",
    numberOfVisitedPages: "numberOfVisitedPages",
    timeSpentOnPage: "timeSpentOnPage",
    timeSpentOnSite: "timeSpentOnSite",
    cart: "cart",
    frequency: "frequency",
    browserLanguage: "browserLanguage",
    location: "location"
  }
}
export default targetingRuleNames
