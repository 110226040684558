const axios = require("axios")
const getAxiosInstance = () =>
  axios.create({
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + window.localStorage.getItem("access_token")
    },
    baseURL: process.env.VUE_APP_API_BASE
  })

export default getAxiosInstance
