<template>
  <div>
    <div class="table-responsive datatable-custom">
      <table
        id="datatable"
        class="leads-table table table-thead-bordered table-nowrap table-align-middle card-table"
        style="width: 100%"
        :data-hs-datatables-options="getConfig"
      >
        <datatable-thead :multiselect="multiselect" :heads="heads" />
      </table>
    </div>
    <datatable-footer v-if="paginated" />
    <div v-else class="py-2"></div>
  </div>
</template>

<script>
import DatatableThead from "@/components/common/DatatableThead"
import DatatableFooter from "@/components/common/DatatableFooter"

export default {
  name: "DatatableTableElement",
  components: { DatatableFooter, DatatableThead },
  props: {
    multiselect: Boolean,
    heads: Array,
    paginated: {
      default: true
    }
  },
  computed: {
    getConfig() {
      if (this.paginated) {
        return `{"order": [],
              "pagination": "datatablePagination",
              "entries": "#datatableEntries",
              "info" : {
              "totalQty": "#datatableWithPaginationInfoTotalQty"
            },
            "pageLength": 10
          }`
      } else {
        return `{"order": [],
              "pagination": "datatablePagination",
              "entries": "#datatableEntries",
              "info" : {
              "totalQty": "#datatableWithPaginationInfoTotalQty"
            },
            "pageLength": 1000,
            "isShowPaging": false,
            "isResponsive": false
          }`
      }
    }
  }
}
</script>

<style scoped></style>
