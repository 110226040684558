<template>
  <div>
    <h4 class="mb-3">Settings</h4>
    <div v-for="(availableValues, key) in settingsData" :key="key">
      <campaign-integration-match-setting
        :label="getSettingLabel(key)"
        :required="isSettingRequired(key)"
        :available-values="availableValues"
        :value="selectedData[key]"
        @input="handleInput(key, $event)"
      />
    </div>
  </div>
</template>

<script>
import CampaignIntegrationMatchSetting from "@/components/campaign-integration-match/CampaignIntegrationMatchSetting"

export default {
  name: "CampaignIntegrationMatchSettings",
  components: { CampaignIntegrationMatchSetting },
  data() {
    return {
      selectedData: {}
    }
  },
  props: {
    settingsData: {},
    settingsMustHave: {
      default: []
    },
    currentSettings: {}
  },
  methods: {
    getSettingLabel(inp) {
      if (inp === "listId") return "List"
      if (inp === "formId") return "Form"
      if (inp === "tagId") return "Tag"
      if (inp === "campaignId") return "Campaign"
      return inp
    },
    isSettingRequired(key) {
      return this.settingsMustHave.includes(key)
    },
    handleInput(key, value) {
      this.selectedData[key] = value
      this.$emit("input", this.selectedData)
    }
  },
  created() {
    Object.keys(this.settingsData).forEach((v) => {
      if (v in this.currentSettings) {
        this.selectedData[v] = this.currentSettings[v]
      } else {
        this.selectedData[v] = null
      }
    })
  }
}
</script>
