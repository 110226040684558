function justImageStyleVariables() {
  const closeButtonDefault = {
    "--close-button-width": "24px",
    "--close-button-height": "24px",
    "--close-button-padding": "4px 4px 4px 4px",
    "--close-button-border-radius": "0px 0px 0px 0px",
    "--close-button-top": "10px",
    "--close-button-right": "10px",
    "--close-button-bottom": "unset",
    "--close-button-left": "unset",
    "--close-button-display": "block",
    "--close-button-background": "transparent",
    "--close-button-color": "#2b2b2b"
  }
  return {
    style: {
      desktop: {
        "--overlay-backdrop-blur": "0px",
        "--overlay-color": "rgba(0,0,0,.2)",
        "--overlay-display": "block",
        "--image-width": "800px",
        "--image-height": "450px",
        "--image-object-fit": "cover",
        "--image-opacity": "1",
        "--image-border-radius": "24px 24px 24px 24px",
        ...closeButtonDefault
      },
      mobile: {
        "--overlay-backdrop-blur": "0px",
        "--overlay-color": "rgba(0,0,0,.2)",
        "--overlay-display": "block",
        "--image-width": "90vw",
        "--image-height": "70vh",
        "--image-object-fit": "cover",
        "--image-opacity": "1",
        "--image-border-radius": "16px 16px 16px 16px",
        ...closeButtonDefault
      }
    }
  }
}

export default justImageStyleVariables
