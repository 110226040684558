<template>
  <div class="card-footer">
    <div class="row justify-content-center justify-content-sm-between align-items-sm-center">
      <div class="col-sm mb-2 mb-sm-0">
        <div class="d-flex justify-content-center justify-content-sm-start align-items-center">
          <span class="mr-2">Showing:</span>

          <select
            id="datatableEntries"
            class="js-select2-custom"
            data-hs-select2-options='{
                            "minimumResultsForSearch": "Infinity",
                            "customClass": "custom-select custom-select-sm custom-select-borderless",
                            "dropdownAutoWidth": true,
                            "width": true
                          }'
          >
            <option value="10" selected>10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>

          <span class="text-secondary mr-2">of</span>
          <span id="datatableWithPaginationInfoTotalQty"></span>
        </div>
      </div>

      <div class="col-sm-auto">
        <div class="d-flex justify-content-center justify-content-sm-end">
          <nav id="datatablePagination" aria-label="Activity pagination"></nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import advancedSelectMixin from "@/mixins/advanced-select-mixin"

export default {
  name: "DatatableFooter",
  mixins: [advancedSelectMixin]
}
</script>

<style scoped></style>
