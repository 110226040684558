<template>
  <base-campaign-template-tab title="Increase Conversion">
    <p>This is one the best ways to increase your conversion buddy!</p>
  </base-campaign-template-tab>
</template>

<script>
import BaseCampaignTemplateTab from "@/components/campaign-template-tabs/BaseCampaignTemplateTab"
export default {
  name: "IncreaseConversion",
  components: { BaseCampaignTemplateTab }
}
</script>

<style scoped></style>
