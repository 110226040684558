<template>
  <div>
    <settings-template class="mb-3" :title="color1Title" layout="horizontal" :show-device-picker="false">
      <ColorPicker :value="color1" @change="(c) => (this.color1 = c)" />
    </settings-template>

    <settings-template class="mb-3" :title="color2Title" layout="horizontal" :show-device-picker="false">
      <ColorPicker :value="color2" @change="(c) => (this.color2 = c)" />
    </settings-template>

    <settings-template title="Direction" layout="horizontal" :show-device-picker="false">
      <div class="d-flex align-items-center">
        <input class="form-control-range mr-4" style="width: 100%" v-model="degree" max="360" min="0" type="range" @input="setData" />
        <input
          class="form-control form-control-sm"
          style="width: 4rem; padding-right: 0.35rem"
          v-model="degree"
          max="360"
          min="0"
          type="number"
          @input="setData"
        />
      </div>
    </settings-template>
  </div>
</template>

<script>
import ColorPicker from "@/components/campaign-builder/appearance-changers/base/ColorPicker"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "GradientColorPicker",
  props: {
    value: String,
    color1Title: {
      type: String,
      default: "Color 1"
    },
    color2Title: {
      type: String,
      default: "Color 2"
    }
  },
  components: { SettingsTemplate, ColorPicker },
  data() {
    return {
      color1: null,
      color2: null,
      degree: 0
    }
  },
  watch: {
    color1() {
      this.setData()
    },
    color2() {
      this.setData()
    }
  },
  methods: {
    setData() {
      const str = `linear-gradient(${this.degree}deg, ${this.color1}, ${this.color2})`
      this.$emit("input", str)
    }
  },
  created() {
    let data = this.value
    data = data.substring(data.indexOf("(") + 1, data.lastIndexOf(")"))
    data = data.split(/,(?![^(]*\))(?![^"']*["'](?:[^"']*["'][^"']*["'])*[^"']*$)/)
    this.degree = parseInt(data[0].replace("deg", ""))
    this.color1 = data[1].trim()
    this.color2 = data[2].trim()
  }
}
</script>
