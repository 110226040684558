<template>
  <settings-template :show-device-picker="showDevicePicker" :layout="layout" :title="title">
    <settings-four-direction-base
      label1="Top"
      label2="Right"
      label3="Bottom"
      label4="Left"
      :variable="variable"
      :odd-units="['px', 'vh', '%']"
      :even-units="['px', 'vw', '%']"
    />
  </settings-template>
</template>
<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import SettingsFourDirectionBase from "@/components/campaign-builder/settings-elements/SettingsFourDirectionBase"

export default {
  name: "SettingsMargin",
  props: {
    variable: {
      type: String,
      required: true
    },
    layout: {
      type: String,
      default: "accordion"
    },
    title: {
      type: String,
      default: "Outer Space"
    }
  },
  components: { SettingsFourDirectionBase, SettingsTemplate },
  mixins: [appearanceChangerMixin]
}
</script>
