import randomId from "@/lib/utils/random-id"
import { getImportUrl } from "@/lib/utils/google-font"
import html from "!raw-loader!./gift-picker/html.html"

class giftPickerHtml {
  get() {
    const fontUrl = getImportUrl(["Montserrat"])
    return html.replace("FONT_URL", fontUrl).replaceAll("PARENT_ID", randomId())
  }

  getGiftboxHtml() {
    return `<div class="ma-gift-box">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="140 40 80 80.1">
<rect x="146.8" y="70" width="66.2" height="61.875%" class="box-bottom" />
<rect x="146.8" y="72.7" width="82.75%" height="3.6" class="box-shadow" />
<rect x="140" y="57.9" height="18.875%" width="100%" class="box-top" />
<rect x="184.35" y="57" width="12.9" height="78.125%" class="box-ribbon-front"></rect>
<g class="ma-gift-box-svg-ribbon">
<path class="box-ribbon-front" d="M190.7 57.2l-14.8-16.4-11.1 11.9 19.6 10.9z" />
<path class="box-ribbon-bottom" d="M184.4 63.5l-20.5-2.4.9-8.5z" />
<path class="box-ribbon-front" d="M190.5 57.3l14.1-17.1 11.6 11.3-19 11.9z" />
<path class="box-ribbon-bottom" d="M197.2 63.4l20.3-3.4-1.3-8.5z" />
</g>
</svg>
</div>`
  }
}

export { giftPickerHtml }
