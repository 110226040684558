<template>
  <settings>
    <template #buttons>
      <settings-selector-button icon="fullscreen-1-1" @click="activateSetArea('widgetWindow')">Frame Settings</settings-selector-button>
      <div class="ml-3">
        <settings-selector-button v-if="!isPanel" icon="clear-circle-outlined" @click="activateSetArea('close-button')">
          Closing Button & Actions
        </settings-selector-button>
        <settings-selector-button icon="material" @click="activateSetArea('gamification')">Gamification</settings-selector-button>
        <settings-selector-button icon="photo-square-outlined" @click="activateSetArea('images')">Visual Assets</settings-selector-button>
        <settings-selector-button v-if="isPanel" @click="activateSetArea('panel-title')">Panel Title</settings-selector-button>
        <settings-selector-button icon="canvas-text" @click="activateSetArea('body')">Body Settings</settings-selector-button>
      </div>
      <div class="ml-6 mb-3">
        <h5 class="mt-3">Body Elements</h5>
        <draggable handle=".handle" @end="handleBodyItemsDragEnd">
          <div v-for="(bodyElement, index) in bodyElements" :key="index + randomId()">
            <div class="d-flex align-items-center handle">
              <span class="btn btn-ghost-dark btn-icon mr-1">
                <i class="tio-drag"></i>
              </span>
              <settings-selector-button :icon="bodyElement.icon || 'alarm'" @click="activateSetArea(`body-element-${index}`)">
                <span v-html="bodyElement.buttonText"></span>
              </settings-selector-button>
              <button class="btn btn-ghost-danger btn-icon ml-1" @click="removeBodyElement(index)">
                <i class="tio-clear"></i>
              </button>
            </div>
          </div>
        </draggable>
        <body-add-element-menu :parent-element="getIframeDocument.querySelector(`${getPageSelector} .body`)" @added="setBodyElements" />
      </div>
    </template>

    <template #content>
      <template v-if="setAreaTopic === 'gamification'">
        <gamification @change="emitEditableAreas" />
      </template>

      <template v-if="setAreaTopic === 'widgetWindow'">
        <settings-title>Frame Settings</settings-title>
        <device-picker-tabs class="mb-3" />
        <Display
          v-if="!isPanel"
          class="mb-4"
          variable="--position-margin"
          :overlay-manager="overlayManager"
          :layout-manager="layoutManager"
        />
        <settings-accordion class="my-4" :show-device-picker="true">
          <template #title>Spacing</template>
          <h4 class="mb-4">Inner Space</h4>
          <SettingsPadding
            :show-device-picker="false"
            title=""
            layout="vertical"
            :variable="layoutManager.getStyleVariableName('padding')"
          />
        </settings-accordion>

        <SettingsBackground layout="accordion" :variable="layoutManager.getStyleVariableName('background')" />
        <settings-accordion :show-device-picker="true">
          <template #title>Border</template>
          <template>
            <div class="pb-5 border-bottom">
              <SettingsBorder
                :color-variable="layoutManager.getStyleVariableName('border-color')"
                :style-variable="layoutManager.getStyleVariableName('border-style')"
                :width-variable="layoutManager.getStyleVariableName('border-width')"
              />
            </div>

            <div class="pt-5">
              <SettingsBorderRadius layout="vertical" :variable="layoutManager.getStyleVariableName('border-radius')" />
            </div>
          </template>
        </settings-accordion>
      </template>

      <close-button-settings v-if="setAreaTopic === 'close-button'" :manager="closeButtonManager" />

      <template v-if="setAreaTopic === 'images'">
        <settings-title>Visual Assets</settings-title>
        <BlockImages />
        <BlockVideos class="mt-4" />
        <FloatImages class="mt-4" images-class="images" />
      </template>

      <template v-if="setAreaTopic === 'panel-title'">
        <settings-title>Panel Title</settings-title>
        <mini-editor :no-settings="true" :selector="`${getPageSelector} .panel-title-text`" />
      </template>

      <body-settings v-if="setAreaTopic === 'body'" :body-manager="bodyManager" />

      <div v-for="(bodyElement, index) in bodyElements" :key="index">
        <!-- text field -->
        <template v-if="setAreaTopic === `body-element-${index}` && bodyElement.type === 'text'">
          <settings-title>Text Field</settings-title>
          <MiniEditor :selector="`${getPageSelector} #${bodyElement.element.id}`" />
          <DevicePickerTabs class="mt-4"/>
          <SettingsFontSize :variable="bodyElement.manager.getStyleVariableName('font-size')" class="mt-4" layout="horizontal" />
          <SettingsBackground :variable="bodyElement.manager.getStyleVariableName('background')" />

          <settings-accordion class="my-4" :show-device-picker="true">
            <template #title>Spacing</template>
            <div class="pb-4 border-bottom">
              <h4 class="mb-3">Outer Space</h4>
              <SettingsMargin :show-device-picker="false" title="" layout="vertical" :variable="bodyElement.manager.getStyleVariableName('margin')" />
            </div>
            <div class="pt-4">
              <h4 class="mb-3">Inner Space</h4>
              <SettingsPadding :show-device-picker="false" title="" layout="vertical" :variable="bodyElement.manager.getStyleVariableName('padding')" />
            </div>
          </settings-accordion>

          <settings-accordion :show-device-picker="true">
            <template #title>Border</template>
            <template>
              <SettingsBorder
                :color-variable="bodyElement.manager.getStyleVariableName('border-color')"
                :style-variable="bodyElement.manager.getStyleVariableName('border-style')"
                :width-variable="bodyElement.manager.getStyleVariableName('border-width')"
              />
            </template>
          </settings-accordion>
        </template>

        <template v-if="setAreaTopic === `body-element-${index}` && bodyElement.type === 'count-down'">
          <settings-title>Count Down</settings-title>
          <count-down-settings :manager="bodyElement.manager" />
        </template>

        <template v-if="setAreaTopic === `body-element-${index}` && bodyElement.type === 'social-button-group'">
          <settings-title>Social Button Group</settings-title>
          <social-button-group-settings :manager="bodyElement.manager" />
        </template>

        <template v-if="setAreaTopic === `body-element-${index}` && bodyElement.type === 'divider'">
          <settings-title>Divider</settings-title>
          <divider-settings :manager="bodyElement.manager" />
        </template>

        <template v-if="setAreaTopic === `body-element-${index}` && bodyElement.type === 'spacer'">
          <settings-title>Spacer</settings-title>
          <settings-height :variable="bodyElement.manager.getStyleVariableName('height')" />
        </template>

        <!-- button -->
        <template v-if="setAreaTopic === `body-element-${index}` && bodyElement.type === 'button'">
          <settings-title>Button</settings-title>
          <settings-button-editor :button="bodyElement.element" />
        </template>

        <!-- form-field -->
        <template v-if="setAreaTopic === `body-element-${index}` && bodyElement.type === 'form-input'">
          <settings-title>Form Field</settings-title>
          <form-field-content-editor :element="bodyElement.element" />
        </template>
      </div>
    </template>
  </settings>
</template>

<script>
import SettingsSelectorButton from "@/components/SettingsSelectorButton"
import settingsMixin from "@/mixins/settings-mixin"
import { mapGetters, mapState } from "vuex"
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"
import Settings from "@/components/campaign-builder/widget-settings/Settings"
import SettingsTitle from "@/components/campaign-builder/widget-settings/SettingsTitle"
import FloatImages from "@/components/campaign-builder/widget-settings/FloatImages"
import Display from "@/components/campaign-builder/widget-settings/multi-purpose/Display"
import BlockImages from "@/components/campaign-builder/widget-settings/BlockImages"
import SettingsButtonEditor from "@/components/campaign-builder/SettingsButtonEditor"
import FormFieldContentEditor from "@/components/campaign-builder/content-changers/general-form-fields/FormFieldContentEditor"
import BodyAddElementMenu from "@/components/campaign-builder/widget-settings/multi-purpose/BodyAddElementMenu"
import draggable from "vuedraggable"
import randomId from "@/lib/utils/random-id"
import GamificationManager from "@/lib/marketing-widgets/gamification-manager"
import CloseButtonManager from "@/lib/marketing-widgets/close-button-manager"
import Gamification from "@/components/campaign-builder/widget-settings/multi-purpose/Gamification"
import SettingsHeight from "@/components/campaign-builder/settings-elements/SettingsHeight"
import DividerSettings from "@/components/campaign-builder/widget-settings/multi-purpose/DividerSettings"
import CloseButtonSettings from "@/components/campaign-builder/widget-settings/multi-purpose/CloseButtonSettings"
import BodyManager from "@/lib/marketing-widgets/body-manager"
import LayoutManager from "@/lib/marketing-widgets/layout-manager"
import CountDownSettings from "@/components/campaign-builder/widget-settings/multi-purpose/CountDownSettings"
import OverlayManager from "@/lib/marketing-widgets/overlay-manager"
import SocialButtonGroupSettings from "@/components/campaign-builder/widget-settings/multi-purpose/SocialButtonGroupSettings"
import { HISTORY_TYPES } from "@/store/builder-history"
import SettingsAccordion from "@/components/campaign-builder/widget-settings/SettingsAccordion"
import DevicePickerTabs from "@/components/campaign-builder/widget-settings/DevicePickerTabs"
import BlockVideos from "@/components/campaign-builder/widget-settings/BlockVideos"
import BodySettings from "@/components/campaign-builder/widget-settings/multi-purpose/BodySettings"

export default {
  name: "MultiPurposeSettings",
  components: {
    BodySettings,
    BlockVideos,
    DevicePickerTabs,
    SettingsAccordion,
    SocialButtonGroupSettings,
    CountDownSettings,
    CloseButtonSettings,
    DividerSettings,
    SettingsHeight,
    Gamification,
    BodyAddElementMenu,
    FormFieldContentEditor,
    SettingsButtonEditor,
    BlockImages,
    Display,
    FloatImages,
    SettingsTitle,
    Settings,
    SettingsSelectorButton,
    MiniEditor,
    draggable
  },
  mixins: [settingsMixin],
  data() {
    return {
      bodyElements: [],
      bodyManager: null,
      overlayManager: null,
      closeButtonManager: null,
      layoutManager: null,
      gamificationManager: null,
      key: null
    }
  },
  computed: {
    ...mapGetters("builder", ["getIframeDocument", "getPageSelector"]),
    ...mapState("builderHistory", ["history"]),
    setAreaTopic() {
      return this.$store.state.builder.setAreaTopic
    },
    isPanel() {
      return this.getIframeDocument.querySelector(`${this.getPageSelector} .position`).classList.contains("panel")
    },
    previewDevice() {
      return this.$store.state.builder.previewDevice.type
    },
    currentlyEditing() {
      if (!this.$store.state.builder.isSetAreaActive) {
        return false
      }

      return this.$store.state.builder.setAreaTopic
    }
  },
  methods: {
    randomId() {
      return randomId()
    },
    setBodyElements() {
      this.bodyElements = this.bodyManager.getChildrenData()
    },
    removeBodyElement(index) {
      const element = this.bodyElements[index].element.closest(".osm-element")

      this.$store.dispatch("builderHistory/addToHistory", {
        type: HISTORY_TYPES.REMOVE_BODY_ELEMENT,
        data: {
          pageNumber: this.$store.state.builder.currentPage,
          html: element.outerHTML,
          index: index,
          selector: "#" + element.id
        }
      })

      this.bodyElements[index].element.closest(".osm-element").remove()
      this.setBodyElements()
    },
    workspaceElementClickedHandler(e) {
      const { selector } = e.detail
      if (selector === `${this.getPageSelector} [data-overlay]`) {
        this.activateSetArea("overlay")
      } else if (selector === `${this.getPageSelector} .body-wrapper`) {
        this.activateSetArea("body")
      } else if (selector === `${this.getPageSelector} .gamification-widget`) {
        this.activateSetArea("gamification")
      } else if (selector === `${this.getPageSelector} .images img` || selector === `${this.getPageSelector} .block-image img`) {
        this.activateSetArea("images")
      } else {
        if (selector.includes(`${this.getPageSelector} .body .osm-element`)) {
          const elementIndex = selector.replace(`${this.getPageSelector} .body .osm-element:nth-of-type(`, "").replace(")", "")
          this.activateSetArea(`body-element-${parseInt(elementIndex) - 1}`)
        }
      }
    },
    emitEditableAreas() {
      const editableAreasAndDescriptions = {
        [`${this.getPageSelector} [data-overlay]`]: "Overlay",
        [`${this.getPageSelector} .body-wrapper`]: "Body",
        [`${this.getPageSelector} .images img`]: "Float Image",
        [`${this.getPageSelector} .gamification-widget`]: "Gamification Widget",
        [`${this.getPageSelector} .block-image img`]: "Block Image"
      }
      this.bodyElements.forEach((bodyElement, index) => {
        const selector = `${this.getPageSelector} .body .osm-element:nth-of-type(${index + 1})`
        editableAreasAndDescriptions[selector] = {
          button: "Button",
          text: "Text Field",
          "form-input": "Form Field",
          spacer: "Spacer",
          divider: "Divider",
          "social-button-group": "Social Button Group",
          "count-down": "Count Down"
        }[bodyElement.type]
      })

      this.getIframeDocument.dispatchEvent(
        new CustomEvent("editable-areas", {
          detail: {
            editableAreas: editableAreasAndDescriptions
          }
        })
      )
    },
    handleBodyItemsDragEnd(event) {
      const { newIndex, oldIndex } = event
      const field = this.bodyElements[oldIndex].element.closest(".osm-element")

      if (newIndex === 0) {
        this.bodyElements[0].element.closest(".osm-element").before(field)
      } else {
        if (newIndex > oldIndex) {
          this.bodyElements[newIndex].element.closest(".osm-element").after(field)
        } else {
          this.bodyElements[newIndex - 1].element.closest(".osm-element").after(field)
        }
      }

      this.setBodyElements()
    }
  },
  watch: {
    history() {
      this.setBodyElements()
    },
    bodyElements() {
      this.emitEditableAreas()
    },
    currentlyEditing(newValue) {
      let selector = ""

      if (newValue === false) {
        this.setBodyElements()
      } else {
        if (newValue === "body") {
          selector = ".body"
        } else {
          if (newValue.includes("body-element-")) {
            const elementIndex = newValue.replace(`body-element-`, "")
            selector = `.body .osm-element:nth-of-type(${parseInt(elementIndex) + 1})`
          }
        }
      }
      if (selector.length > 0) selector = this.getPageSelector + " " + selector
      this.getIframeDocument.dispatchEvent(
        new CustomEvent("workspace-currently-editing", {
          detail: {
            selector
          }
        })
      )
    }
  },
  beforeDestroy() {
    this.getIframeDocument.removeEventListener("workspace-element-clicked", this.workspaceElementClickedHandler)
  },
  mounted() {
    this.setIsSetAreaActive(false)
    this.getIframeDocument.addEventListener("workspace-element-clicked", this.workspaceElementClickedHandler)

    if (!this.isPanel) {
      this.closeButtonManager = new CloseButtonManager(this.getIframeDocument.querySelector(`${this.getPageSelector} button.close-button`))
    }
    this.layoutManager = new LayoutManager(this.getIframeDocument.querySelector(`${this.getPageSelector} .layout`))
    this.bodyManager = new BodyManager(this.getIframeDocument.querySelector(`${this.getPageSelector} .body-wrapper`))
    this.setBodyElements()
    new GamificationManager(this.getIframeDocument.querySelector(`${this.getPageSelector} .gamification-container`))
    this.overlayManager = new OverlayManager(this.getIframeDocument.querySelector(`${this.getPageSelector} .overlay`))
    this.emitEditableAreas()
  }
}
</script>
<style>
.body-elements-drag .add-new-element-menu {
  visibility: hidden;
}
</style>
