<template>
  <div>
    <device-picker-tabs class="mb-3" />
    <div class="pb-5 pt-3 border-bottom">
      <h4 class="mb-4">Position</h4>
      <SettingsTwoDirectionPosition
        title=""
        :show-device-picker="false"
        :variable="{
          top: manager.getStyleVariableName('top'),
          left: manager.getStyleVariableName('left'),
          right: manager.getStyleVariableName('right'),
          bottom: manager.getStyleVariableName('bottom')
        }"
        layout="vertical"
      />
    </div>

    <div class="py-5 border-bottom">
      <h4 class="mb-4">Size</h4>
      <SettingsWidth :show-device-picker="false" layout="horizontal" :variable="manager.getStyleVariableName('width')" />
      <SettingsHeight :show-device-picker="false" layout="horizontal" :variable="manager.getStyleVariableName('height')" class="mt-4" />
    </div>

    <div class="py-5">
      <h4 class="mb-4">Image Settings</h4>
      <settings-template title="Object Fit" :show-device-picker="false" layout="horizontal">
        <select class="form-control form-control-sm" v-model="objectFit">
          <option value="cover">Cover</option>
          <option value="contain">Contain</option>
          <option value="fill">Fill</option>
          <option value="none">None</option>
        </select>
      </settings-template>
      <settings-template class="mt-4" title="Opacity" :show-device-picker="false" layout="horizontal">
        <range-input-with-unit v-model="opacity" :alternative-units="['%']" size="full" :min="0" :max="100" />
      </settings-template>
    </div>
  </div>
</template>

<script>
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"
import { mapGetters, mapState } from "vuex"
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import RangeInputWithUnit from "@/components/common/RangeInputWithUnit"
import DevicePickerTabs from "@/components/campaign-builder/widget-settings/DevicePickerTabs"

export default {
  name: "FloatImageAppearance",
  components: { DevicePickerTabs, RangeInputWithUnit, SettingsTemplate },
  props: ["manager"],
  mixins: [appearanceChangerMixin],
  computed: {
    ...mapGetters("builder", ["getIframeDocument"]),
    ...mapState("builder", ["currentPage", "previewDevice"]),
    env() {
      return this.previewDevice.type
    },
    opacity: {
      get() {
        return {
          value: this.getCssValue("opacity") * 100,
          unit: "%"
        }
      },
      set({ value }) {
        this.setCssValue("opacity", value / 100)
      }
    },
    objectFit: {
      get() {
        return this.getCssValue("object-fit")
      },
      set(value) {
        this.setCssValue("object-fit", value)
      }
    }
  },
  methods: {
    getCssValue(property) {
      return this.getStyleVariableValueDirect(this.env, this.manager.getStyleVariableName(property))
    },
    setCssValue(property, value) {
      this.setStyleVariableDirect({
        env: this.env,
        variable: this.manager.getStyleVariableName(property),
        value: value
      })
    }
  }
}
</script>

<style scoped></style>
