<template>
  <modal :title="title" size="lg" @close="$emit('close')">
    <div class="modal-body">
      <div v-html="description"></div>
      <form @submit.prevent="(e) => $emit('save', e)">
        <div class="border-t border-b">
          <slot />
        </div>
        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-white mr-2" @click="$emit('close')">Cancel</button>
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </form>
      <h4 class="mt-3">How It Works?</h4>
      <div v-html="howItWorks"></div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"
import targetingRuleTexts from "@/components/campaign-targeting-rules/targetingRuleTexts"

export default {
  name: "ModalCampaignTargetingRule",
  data: () => ({
    title: "",
    description: "",
    howItWorks: "",
    targetingRuleTexts: targetingRuleTexts,
  }),
  inject: ["typeOfRule", "nameOfRule"],
  components: { Modal },
  mounted() {
    const { title, description, howItWorks } = this.targetingRuleTexts[this.typeOfRule][this.nameOfRule]
    this.title = title
    this.description = description
    this.howItWorks = howItWorks
  }
}
</script>
