var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('div',{staticClass:"btn-group my-1"},[_c('button',{staticClass:"btn btn-soft-primary rounded btn-xs",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"},on:{"click":_vm.handleClick}},[_c('i',{staticClass:"tio-add"}),_vm._v(" Add Element ")]),_c('div',{ref:"dropdown-menu",staticClass:"dropdown-menu"},[_c('h4',{staticClass:"dropdown-item-text"},[_vm._v("Basic Fields")]),_vm._l(([
          { type: 'text-field', name: 'Text', icon: 'text' },
          { type: 'button', name: 'Button', icon: 'mouse-outlined' },
          { type: 'spacer', name: 'Spacer', icon: 'enter' },
          { type: 'divider', name: 'Divider', icon: 'reflect-vertical' },
          { type: 'count-down', name: 'Countdown Timer', icon: 'timer' },
          { type: 'social-button-group', name: 'Social Button Group', icon: 'share' }
        ]),function(item){return _c('a',{key:item.type,staticClass:"dropdown-item",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.addNewField(item.type)}}},[_c('i',{class:("dropdown-item-icon tio-" + (item.icon))}),_vm._v(" "+_vm._s(item.name)+" ")])}),_c('div',{staticClass:"dropdown-divider"}),_c('h4',{staticClass:"dropdown-item-text"},[_vm._v("Form Fields")]),_vm._l((_vm.formFields),function(field,type){return _c('a',{key:type,staticClass:"dropdown-item",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.addNewField('form-field', field.type)}}},[_c('i',{class:("dropdown-item-icon tio-" + (field.icon))}),_vm._v(" "+_vm._s(field.name)+" ")])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }