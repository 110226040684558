<template>
  <aside class="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand navbar-bordered">
    <div class="navbar-vertical-container">
      <div class="navbar-vertical-footer-offset">
        <div class="navbar-brand-wrapper justify-content-between border-bottom">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <router-link :to="{ name: 'Dashboard' }" aria-label="OSM" class="navbar-brand">
<!--              <icon-osm style="height: 2.5rem; width: 2.5rem" />-->
              <h2 class="my-0 text-primary">OSM</h2>
            </router-link>
            <page-type />
            <div class="undo-redo-buttons">
              <button @click="undo" class="btn btn-icon btn-sm btn-outline-secondary mr-2" :disabled="history.length === 0">
                <i class="tio-undo"></i>
              </button>
              <button @click="redo" class="btn btn-icon btn-sm btn-outline-secondary" :disabled="future.length === 0">
                <i class="tio-redo"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="navbar-vertical-content position-relative" style="padding-top: 1rem">
          <div style="padding: 0 1rem" :key="key">
            <multi-purpose-settings v-if="pageType === 'multi-purpose'" />
            <flat-bar-settings v-if="pageType === 'flat-bar'" />
            <whatsapp-settings v-if="pageType === 'whatsapp'" />
            <satis-meter-settings v-if="pageType === 'satis-meter'" />
            <image-button-settings v-if="pageType === 'image-button'" />
            <just-image-settings v-if="pageType === 'just-image'" />
          </div>
          <css-editor class="css-editor-wrapper" v-if="showCssEditor" />
        </div>

        <div class="navbar-vertical-footer row align-items-center" style="padding-left: 1rem; padding-right: 1rem; height: 3.75rem">
          <div class="col-12">
            <button class="btn btn-outline-primary btn-sm font-weight-bolder" @click="showCssEditor = !showCssEditor">
              <span><i class="tio-code mr-1"></i> Css Editor</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import MultiPurposeSettings from "@/components/campaign-builder/widget-settings/multi-purpose/MultiPurposeSettings"
import FlatBarSettings from "@/components/campaign-builder/widget-settings/flat-bar/FlatBarSettings"
import WhatsappSettings from "@/components/campaign-builder/widget-settings/whatsapp/WhatsappSettings"
import SatisMeterSettings from "@/components/campaign-builder/widget-settings/satis-meter/SatisMeterSettings"
import ImageButtonSettings from "@/components/campaign-builder/widget-settings/image-button/ImageButtonSettings"
import JustImageSettings from "@/components/campaign-builder/widget-settings/just-image/JustImageSettings"
import { mapActions, mapGetters, mapState } from "vuex"
// import iconOsm from "@/icons/icon-osm.svg?inline"
import CssEditor from "@/components/campaign-builder/CssEditor"
import PageType from "@/components/campaign-builder/PageType"

export default {
  name: "Sidebar",
  components: {
    PageType,
    CssEditor,
    JustImageSettings,
    ImageButtonSettings,
    SatisMeterSettings,
    WhatsappSettings,
    FlatBarSettings,
    MultiPurposeSettings,
    // iconOsm
  },
  data() {
    return {
      showCssEditor: false,
      historyCount: 0
    }
  },
  computed: {
    ...mapState("builder", ["currentPage", "totalPage"]),
    ...mapState("builderHistory", ["history", "future"]),
    ...mapGetters("builder", ["getIframeDocument", "getPageSelector", "getPreviewDevicesLoading", "getPageElement"]),
    pageType() {
      return this.getPageElement?.dataset.maType ?? "not-found"
    },
    key() {
      return (
        this.getPageElement?.dataset.maSubType +
        this.getPageSelector +
        this.totalPage +
        this.getPageElement?.querySelector("[data-page-parent]").id
      )
    }
  },
  methods: {
    ...mapActions("builderHistory", ["undo", "redo"])
  }
}
</script>

<style scoped>
.css-editor-wrapper {
  z-index: 15;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.undo-redo-buttons button:disabled {
  opacity: 0.4;
}
</style>
