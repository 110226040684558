import { mapActions, mapGetters } from "vuex"

const appearanceChangerMixin = {
  props: {
    variable: [String, Object, Array],
    showDevicePicker: {
      default: true,
      type: Boolean
    },
    layout: {
      default: "vertical",
      type: String
    }
  },
  computed: {
    ...mapGetters("builder", ["getStyleVariableValue", "getStyleVariableValueDirect", "getStyleVariableValueDirectCurrentEnv"]),
    env() {
      return this.$store.state.builder.previewDevice.type
    }
  },
  methods: {
    ...mapActions("builder", ["setStyleVariableDirect", "setStyleVariableDirectCurrentEnv", "setStyleVariableValue"])
  }
}
export default appearanceChangerMixin
