<template>
  <modal title="Are you sure you want to delete it?" size="md" @close="$emit('close')">
    <div class="modal-body row">
      <div class="col">
        <button @click="$emit('delete', backData)" class="btn btn-danger w-100 text-center">Delete</button>
      </div>
      <div class="col">
        <button @click="$emit('cancel', backData)" class="btn btn-secondary w-100 text-center">Cancel</button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"

export default {
  name: "ModalDeleteConfirmation",
  props: {
    backData: {
      default: ""
    }
  },
  components: { Modal }
}
</script>
