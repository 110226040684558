<template>
  <range-input-with-unit v-model="value" v-bind="$attrs" />
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import RangeInputWithUnit from "@/components/common/RangeInputWithUnit"

export default {
  name: "SettingsRangeWithUnit",
  props: {
    variable: {
      type: String,
      required: true
    },
  },
  mixins: [appearanceChangerMixin],
  computed: {
    value: {
      get() {
        return this.getStyleVariableValue(this.env, this.variable, 1)
      },
      set(newValue) {
        this.setStyleVariableDirectCurrentEnv({
          variable: this.variable,
          value: newValue.value + newValue.unit
        })
      }
    }
  },
  components: { RangeInputWithUnit  }
}
</script>