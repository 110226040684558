<template>
  <base-campaign-targeting-rule>
    <div>Visitors who {{ wordMap[data] }} AdBlock, AdBlock Plus, etc. will show the campaign.</div>
  </base-campaign-targeting-rule>
</template>

<script>
import BaseCampaignTargetingRule from "@/components/campaign-targeting-rules/BaseCampaignTargetingRule"
import targetingRuleMixin from "@/mixins/targeting-rule-mixin"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"

const mixin = targetingRuleMixin("who", targetingRuleNames.whoRules.adblock)
const wordMap = {
  detected: "use",
  "not-detected": "don't use"
}
export default {
  name: "TargetingAdblock",
  components: { BaseCampaignTargetingRule },
  mixins: [mixin],
  data() {
    return {
      wordMap: wordMap
    }
  }
}
</script>
