<template>
  <auth-layout>
    <base-form @submit="submit">
      <div class="text-center">
        <div class="mb-5">
          <h1 class="display-4">Sign in</h1>
          <p>
            Don't have an account yet?
            <router-link :to="{ name: 'Register' }">Sign up here</router-link>
          </p>
        </div>
        <google-sign-in-button redirect-route-name="Login" message="Sign in with Google" />
        <span class="divider text-muted mb-4">OR</span>
      </div>

      <!-- Form Group -->
      <div class="js-form-message form-group">
        <label class="input-label" for="signinSrEmail">Your email</label>

        <input
          type="email"
          class="form-control form-control-lg"
          name="email"
          id="signinSrEmail"
          tabindex="1"
          v-model="email"
          placeholder="email@address.com"
          aria-label="email@address.com"
          required
          data-msg="Please enter a valid email address."
        />
      </div>
      <!-- End Form Group -->

      <!-- Form Group -->
      <div class="js-form-message form-group">
        <label class="input-label" for="signupSrPassword" tabindex="0">
          <span class="d-flex justify-content-between align-items-center">
            Password
            <a class="input-label-secondary" href="authentication-reset-password-basic.html">Forgot Password? (!!!)</a>
          </span>
        </label>

        <div class="input-group input-group-merge">
          <input
            type="password"
            class="js-toggle-password form-control form-control-lg"
            name="password"
            id="signupSrPassword"
            placeholder="8+ characters required"
            aria-label="8+ characters required"
            required
            data-msg="Your password is invalid. Please try again."
            v-model="password"
            data-hs-toggle-password-options='{
                                 "target": "#changePassTarget",
                                 "defaultClass": "tio-hidden-outlined",
                                 "showClass": "tio-visible-outlined",
                                 "classChangeTarget": "#changePassIcon"
                               }'
          />
          <div id="changePassTarget" class="input-group-append">
            <a class="input-group-text" href="javascript:;">
              <i id="changePassIcon" class="tio-visible-outlined"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- End Form Group -->

      <button type="submit" class="btn btn-lg btn-block btn-primary">Sign in</button>
    </base-form>
  </auth-layout>
</template>

<script>
import { mapActions, mapMutations } from "vuex"
import AuthLayout from "@/layout/AuthLayout"
import GoogleSignInButton from "@/components/auth/GoogleSignInButton"
import BaseForm from "@/components/BaseForm"
import formMixin from "@/mixins/form-mixin"

export default {
  name: "Login",
  components: { BaseForm, GoogleSignInButton, AuthLayout },
  data() {
    return {
      email: "",
      password: ""
    }
  },
  mixins: [formMixin],
  methods: {
    ...mapActions({ login: "user/login" }),
    ...mapMutations({ userLogin: "user/userLogin", setError: "user/setAuthError" }),
    submit() {
      this.login(this.$data)
        .then(() => {
          this.setError(null)
          this.$router.push({ name: "Dashboard.Campaigns" })
        })
        .catch((err) => this.setError(err))
    }
  },
  created() {
    let oauth = window.localStorage.getItem("oauth-data")
    if (oauth) {
      this.setError(null)
      oauth = JSON.parse(oauth)
      window.localStorage.removeItem("oauth-data")
      window.localStorage.removeItem("oauth-redirect")
      if (!oauth["already_registered"]) {
        this.setError("The user is not found!")
      } else {
        this.userLogin(oauth["osm_data"])
        this.$router.push({ name: "Dashboard.Campaigns" })
      }
    }
  }
}
</script>
