class htmlExporter {
  static getHtml(documentElement) {
    /* All pages' display must be none on initialize */
    documentElement.querySelectorAll(".page").forEach((page) => (page.style.display = "none"))
    documentElement.querySelector("body").removeAttribute("style")

    /* SET CURRENT PAGE TO 1 */
    // const pageInitializeScript = documentElement.documentElement.querySelector("script[data-osm-function='setOsmInitializePage']")
    // pageInitializeScript.innerHTML = `window.osmInitializePage = 1;`

    // documentElement.querySelector("script#workspace-js").remove()
    // documentElement.querySelector("style#workspace-css").remove()

    const intlUtil = documentElement.querySelector(
      `script[src="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.min.js"]`
    )
    if (intlUtil) {
      intlUtil.remove()
    }
    documentElement.querySelectorAll(`script[class='osm-remote-script']`).forEach((script) => {
      script.remove()
    })
    return documentElement.documentElement.innerHTML
  }

  static getFormFields(documentElement) {
    const formFields = {}
    documentElement.querySelectorAll(".page").forEach((page) => {
      page.querySelectorAll(".satismeter-tabs .satismeter-tab").forEach((tab) => {
        const question = tab.querySelector(".question").innerText
        if (tab.querySelector('.answer-options[data-type="number"]') || tab.querySelector('.answer-options[data-type="text"]')) {
          formFields[question] = {
            type: "select",
            required: true,
            options: Array.from(tab.querySelectorAll(".answer-options .answer-button")).map((btn) => btn.dataset.value)
          }
        } else if (tab.querySelector('.answer-options[data-type="free-text"]')) {
          formFields[question] = {
            type: "textarea",
            required: false
          }
        }
      })

      const form = page.querySelector("form")
      if (!form) return
      form
        .querySelectorAll(`input[type=text], input[type=email], input[type=number], input[type=tel], input[type=url], input[type=checkbox]`)
        .forEach((input) => {
          formFields[input.name] = {
            type: input.type,
            required: input.required
          }
        })
      form.querySelectorAll("textarea").forEach((textarea) => {
        formFields[textarea.name] = {
          type: "textarea",
          required: textarea.required
        }
      })
      form.querySelectorAll("select").forEach((select) => {
        const fields = []
        select.querySelectorAll("option").forEach((opt) => fields.push(opt.value))
        formFields[select.name] = {
          type: "select",
          required: select.required,
          options: fields
        }
      })
      form.querySelectorAll("input[type=radio]").forEach((radio) => {
        if (!formFields[radio.name]) {
          formFields[radio.name] = {
            type: "radio",
            required: radio.required,
            options: []
          }
        }
        formFields[radio.name].options.push(radio.value)
      })
    })
    return formFields
  }
}

export default htmlExporter
