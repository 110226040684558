<template>
  <div>
    <settings-flex-column-align-self :variable="manager.getStyleVariableName('align-self')" />
    <settings-width :alternative-units="['px', '%']" :variable="manager.getStyleVariableName('width')" />
    <div class="d-flex">
        <span class="mr-4">Height:</span>
        <input type="range" class="form-control form-control-sm" min="1" max="20" v-model="height">
    </div>
    <div>
      <button
        :class="[borderStyle === 'dashed' ? 'btn-soft-primary' : 'btn-ghost-primary']"
        class="btn btn-sm"
        @click="borderStyle = 'dashed'"
      >
        Dashed
      </button>
      <button
        :class="[borderStyle === 'solid' ? 'btn-soft-primary' : 'btn-ghost-primary']"
        class="btn btn-sm"
        @click="borderStyle = 'solid'"
      >
        Solid
      </button>
      <button
        :class="[borderStyle === 'dotted' ? 'btn-soft-primary' : 'btn-ghost-primary']"
        class="btn btn-sm"
        @click="borderStyle = 'dotted'"
      >
        Dotted
      </button>
    </div>
    <div>
      <SettingsColor :variable="manager.getStyleVariableName('border-color')" />
    </div>
  </div>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"

export default {
  name: "DividerSettings",
  props: ["manager"],
  mixins: [appearanceChangerMixin],
  data: () => ({}),
  computed: {
    height: {
      get() {
        const borderWidth = this.getStyleVariableValueDirect(this.env, this.manager.getStyleVariableName("border-width"))
        return borderWidth.split(" ")[0].replace('px', '')
      },
      set(value) {
        this.setStyleVariableDirect({
          env: this.env,
          variable: this.manager.getStyleVariableName("border-width"),
          value: value +  "px 0px 0px 0px"
        })
      }
    },

    borderStyle: {
      get() {
        return this.getStyleVariableValueDirect(this.env, this.manager.getStyleVariableName("border-style"))
      },
      set(value) {
        this.setStyleVariableDirect({
          env: this.env,
          variable: this.manager.getStyleVariableName("border-style"),
          value: value
        })
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped></style>
