import ElementManager from "@/lib/marketing-widgets/element-manager"

class OverlayManager extends ElementManager {
  constructor(element) {
    super(
      element,
      {
        display: "block",
        "backdrop-filter": "blur(5px)",
        background: "rgba(10,10,10,0.25)"
      },
      "overlay"
    )
  }
}

export default OverlayManager
