function flatBarStyleVariables() {
  return {
    style: {
      desktop: {
        "--image-width": "48px",
        "--image-height": "80%",
        "--image-top": "10%",
        "--image-right": "unset",
        "--image-bottom": "unset",
        "--image-left": "8px",
        "--image-object-fit": "cover",
        "--image-opacity": "1",
        "--image-transform": "none",
        "--layout-font-family": "Poppins",
        "--body-background": "linear-gradient(60deg, rgba(174, 24, 183, 1), rgba(211, 54, 147, 1))",
        "--body-padding": "16px 36px 16px 64px",
        "--message-color": "#ffffff",
        "--message-font-size": "15px",
        "--button-border-radius": "4px 4px 4px 4px",
        "--button-font-size": "15px",
        "--button-margin": "0px 0px 0px 16px",
        "--button-padding": "6px 12px 6px 12px",
        "--button-color": "#fff",
        "--button-background": "linear-gradient(90deg, rgba(86, 78, 237, 1), rgba(39, 113, 191, 1))",
        "--button-border-style": "solid",
        "--button-border-width": "2px 2px 2px 2px",
        "--button-border-color": "#fff",
        "--close-button-color": "#fff",
        "--close-button-border-radius": "0px 0px 0px 0px",
        "--close-button-background": "transparent",
        "--close-button-padding": "4px 4px 4px 4px",
        "--close-button-size": "28px",
        "--close-button-right": "8px",
        "--close-button-left": "unset",
        "--close-button-display": "block"
      },
      mobile: {
        "--image-width": "48px",
        "--image-height": "80%",
        "--image-top": "10%",
        "--image-right": "unset",
        "--image-bottom": "unset",
        "--image-left": "8px",
        "--image-object-fit": "cover",
        "--image-opacity": "1",
        "--image-transform": "none",
        "--layout-font-family": "Poppins",
        "--body-background": "linear-gradient(60deg, rgba(174, 24, 183, 1), rgba(211, 54, 147, 1))",
        "--body-padding": "16px 36px 16px 64px",
        "--message-color": "#ffffff",
        "--message-font-size": "15px",
        "--button-border-radius": "4px 4px 4px 4px",
        "--button-font-size": "15px",
        "--button-margin": "0px 0px 0px 16px",
        "--button-padding": "6px 12px 6px 12px",
        "--button-color": "#fff",
        "--button-background": "linear-gradient(90deg, rgba(86, 78, 237, 1), rgba(39, 113, 191, 1))",
        "--button-border-style": "solid",
        "--button-border-width": "2px 2px 2px 2px",
        "--button-border-color": "#fff",
        "--close-button-color": "#fff",
        "--close-button-border-radius": "0px 0px 0px 0px",
        "--close-button-background": "transparent",
        "--close-button-padding": "4px 4px 4px 4px",
        "--close-button-size": "28px",
        "--close-button-right": "8px",
        "--close-button-left": "unset",
        "--close-button-display": "block"
      }
    }
  }
}

export default flatBarStyleVariables
