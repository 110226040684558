import integrationAPI from "@/api/integration"
import campaignAPI from "@/api/campaign"
import randomId from "@/lib/utils/random-id"
import generateAPIErrorMessage from "@/api/generate-api-error-message"

const getRuleTitleByType = (type) => {
  if (type === "who") return "whoRules"
  else if (type === "when") return "whenRules"
  else throw Error("UNKNOWN TYPE!")
}

function initialState() {
  return {
    loading: false,
    submitLoading: false,

    campaign: {},
    schedule: {},
    availableIntegrations: [],
    campaignIntegrations: [],
    userIntegrations: [],

    whenRules: {},
    whoRules: {},
    geotargetingLocations: {},

    name: "",

    openRuleOptions: []
  }
}
const campaignSettings = {
  state: initialState,
  getters: {
    getRule: (state) => (typeOfRule, ruleName) => {
      return state[getRuleTitleByType(typeOfRule)][ruleName]
    },
    ruleExists: (state) => (typeOfRule, ruleName) => {
      return ruleName in state[getRuleTitleByType(typeOfRule)]
    },
    isRuleOptionOpen: (state) => (ruleName) => {
      return state.openRuleOptions.includes(ruleName)
    }
  },
  mutations: {
    resetState(state) {
      Object.assign(state, initialState())
    },
    setRule(state, { typeOfRule, name, value }) {
      const key = getRuleTitleByType(typeOfRule)
      state[key][name] = value
      state[key] = { ...state[key] }
    },
    openRuleOption(state, name) {
      if (!state.openRuleOptions.includes(name)) {
        state.openRuleOptions.push(name)
      }
    },
    closeRuleOption(state, name) {
      state.openRuleOptions = state.openRuleOptions.filter((v) => v !== name)
    },
    toggleRuleOptions(state, name) {
      if (state.openRuleOptions.includes(name)) {
        state.openRuleOptions = state.openRuleOptions.filter((v) => v !== name)
      } else {
        state.openRuleOptions.push(name)
      }
    },
    removeRule(state, { typeOfRule, name }) {
      const key = getRuleTitleByType(typeOfRule)
      delete state[key][name]
      state[key] = { ...state[key] }
      state.openRuleOptions = state.openRuleOptions.filter((v) => v !== name)
    },
    setName(state, payload) {
      state.name = payload
    },
    setSchedule(state, payload) {
      state.schedule = payload
    },
    removeCampaignIntegration(state, campaignIntegrationId) {
      delete state.campaignIntegrations[campaignIntegrationId]
      state.campaignIntegrations = { ...state.campaignIntegrations }
    },
    updateCampaignIntegration(state, { id, settings, formBindings }) {
      state.campaignIntegrations[id]["settings"] = settings
      state.campaignIntegrations[id]["form_bindings"] = formBindings
      state.campaignIntegrations = { ...state.campaignIntegrations }
    },
    addCampaignIntegration(state, { thirdPartyIntegration, settings, formBindings }) {
      const id = randomId()
      state.campaignIntegrations[id] = {}
      state.campaignIntegrations[id]["id"] = id
      state.campaignIntegrations[id]["isNew"] = true
      state.campaignIntegrations[id]["third_party_integration"] = thirdPartyIntegration
      state.campaignIntegrations[id]["settings"] = settings
      state.campaignIntegrations[id]["form_bindings"] = formBindings
      state.campaignIntegrations = { ...state.campaignIntegrations }
    },
    setUserIntegrations(state, payload) {
      state.userIntegrations = payload
    },
    setCampaignIntegrations(state, payload) {
      state.campaignIntegrations = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setSubmitLoading(state, payload) {
      state.submitLoading = payload
    },
    init(state, { campaign, campaignIntegrations, userIntegrations, availableIntegrations, geotargetingLocations }) {
      state.campaign = campaign
      state.availableIntegrations = availableIntegrations
      state.userIntegrations = userIntegrations
      state.campaignIntegrations = campaignIntegrations
      state.geotargetingLocations = geotargetingLocations
      state.name = campaign.name
      state.schedule = campaign.schedule

      state.whenRules = campaign.settings.whenRules ?? {}
      state.whoRules = campaign.settings.whoRules ?? {}
    }
  },
  actions: {
    async updateSettings({ commit, state, rootGetters }) {
      commit("setSubmitLoading", true)
      const updateData = {
        siteId: rootGetters["sites/getSelectedSite"].id,
        campaignId: state.campaign.id,
        formData: {
          name: state.name,
          schedule: state.schedule,
          settings: {
            whenRules: state.whenRules,
            whoRules: state.whoRules
          },
          campaign_third_party_integrations: Object.values(state.campaignIntegrations).map((i) => {
            if (i.isNew) {
              delete i["isNew"]
              delete i["id"]
            }
            return i
          })
        }
      }
      await campaignAPI.updateCampaign(updateData)
      commit("setSubmitLoading", false)
    },
    fetchUserIntegrations({ commit }) {
      commit("setLoading", true)

      return integrationAPI
        .fetchUserIntegrations()
        .then((resp) => {
          commit("setUserIntegrations", resp.data.data["third_party_integrations"])
        })
        .catch((e) => {
          throw generateAPIErrorMessage(e)
        })
        .finally(() => {
          commit("setLoading", false)
        })
    },
    async init({ commit, dispatch, rootGetters }, campaignId) {
      commit("setLoading", true)
      await dispatch("campaigns/fetchCampaignsOfCurrentSite", null, { root: true })
      const campaignReq = await campaignAPI.fetchCampaign({
        siteId: rootGetters["sites/getSelectedSite"].id,
        campaignId: campaignId
      })
      const campaign = campaignReq.data.data["campaign"]

      const userIntegrationsReq = await integrationAPI.fetchUserIntegrations()
      const userIntegrations = userIntegrationsReq.data.data["third_party_integrations"]

      const availableIntegrationsReq = await integrationAPI.fetchAvailableIntegrations()
      const availableIntegrations = availableIntegrationsReq.data.data["available_integrations"]

      const campaignIntegrationsReq = await integrationAPI.fetchCampaignIntegrations(rootGetters["sites/getSelectedSite"].id, campaignId)

      const geotargetingLocationsReq = await campaignAPI.fetchGeotargetingLocations()
      const geotargetingLocations = geotargetingLocationsReq.data

      let campaignIntegrations = {}
      campaignIntegrationsReq.data.data["campaign_third_party_integrations"].forEach((el) => {
        campaignIntegrations[el.id] = el
      })

      commit("init", {
        campaign,
        campaignIntegrations,
        availableIntegrations,
        userIntegrations,
        geotargetingLocations
      })

      commit("setLoading", false)
    }
  },
  namespaced: true
}
export default campaignSettings
