<template>
  <div>
    <settings-accordion>
      <template #title>On Click Action</template>
      <template>
        <Checkbox v-model="buttonActions.close.status">Close Campaign</Checkbox>
        <Checkbox v-model="buttonActions.openUrl.status" :disabled="buttonActions.whatsapp.status || buttonActions.phone.status">
          Open URL
        </Checkbox>
        <div v-if="buttonActions.openUrl.status" class="mb-6 ml-4">
          <div class="row align-items-center">
            <div class="col-3">
              <span>URL:</span>
            </div>
            <div class="col-9">
              <input type="text" v-model="buttonActions.openUrl.url" class="form-control form-control-sm" />
            </div>
          </div>
          <Checkbox v-model="buttonActions.openUrl.newTab">Open the page in a new tab</Checkbox>
        </div>
        <Checkbox v-model="buttonActions.whatsapp.status" :disabled="buttonActions.openUrl.status || buttonActions.phone.status">
          Redirect To WhatsApp
        </Checkbox>
        <div v-if="buttonActions.whatsapp.status" class="mb-6 ml-4">
          <div class="row align-items-center">
            <div class="col-3">
              <span>Number:</span>
            </div>
            <div class="col-9">
              <input type="text" v-model="buttonActions.whatsapp.number" class="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <Checkbox v-model="buttonActions.phone.status" :disabled="buttonActions.openUrl.status || buttonActions.whatsapp.status"
        >Redirect To Phone
        </Checkbox>
        <div v-if="buttonActions.phone.status" class="mb-6 ml-4">
          <div class="row align-items-center">
            <div class="col-3">
              <span>Number:</span>
            </div>
            <div class="col-9">
              <input type="text" v-model="buttonActions.phone.number" class="form-control form-control-sm" />
            </div>
          </div>
        </div>
        <Checkbox v-model="buttonActions.submit.status">Open Next Page / Submit Form</Checkbox>
        <Checkbox class="pt-4 mt-4 border-top" v-model="buttonActions.goalReached.status">Campaign Goal Reached</Checkbox>
      </template>
    </settings-accordion>
    <mini-editor class="mb-4" :link="false" :selector="`${getPageSelector} #${button.id}`" />
    <div>
      <DevicePickerTabs/>
      <SettingsFontSize class="mt-4" :show-device-picker="true" layout="horizontal" :variable="variables.fontSize" />
      <settings-accordion :show-device-picker="true">
        <template #title>Spacing</template>
        <SettingsMargin layout="vertical" :show-device-picker="false" :variable="variables.margin" />
        <SettingsPadding layout="vertical" class="mt-4" :show-device-picker="false" :variable="variables.padding" />
      </settings-accordion>
      <SettingsBackground :variable="variables.background" />
      <SettingsBackground :variable="variables.hoverBackground" title="Hover Background" />
      <settings-accordion :show-device-picker="true">
        <template #title>Border</template>
        <SettingsBorder
          layout="vertical"
          :show-device-picker="false"
          :color-variable="variables.borderColor"
          :style-variable="variables.borderStyle"
          :width-variable="variables.borderWidth"
        />
        <SettingsBorderRadius class="mt-4" layout="vertical" :show-device-picker="false" :variable="variables.borderRadius" />
      </settings-accordion>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Checkbox from "@/components/common/Checkbox"
import MiniEditor from "@/components/campaign-builder/content-changers/MiniEditor"
import SettingsAccordion from "@/components/campaign-builder/widget-settings/SettingsAccordion"
import FormButtonManager from "@/lib/marketing-widgets/form-button-manager"
import DevicePickerTabs from "@/components/campaign-builder/widget-settings/DevicePickerTabs"

export default {
  name: "SettingsButtonEditor",
  components: { DevicePickerTabs, SettingsAccordion, MiniEditor, Checkbox },
  props: ["button"],
  data() {
    return {
      manager: null,
      variables: {},
      buttonActions: {
        openUrl: {
          status: false,
          url: "https://",
          newTab: false
        },
        whatsapp: {
          status: false,
          number: ""
        },
        phone: {
          status: false,
          number: ""
        },
        submit: {
          status: false
        },
        goalReached: {
          status: false
        },
        close: {
          status: false
        }
      }
    }
  },
  computed: mapGetters("builder", ["getIframeDocument", "getPageSelector"]),
  watch: {
    buttonActions: {
      handler: "buttonWatchHandler",
      deep: true
    }
  },
  methods: {
    buttonWatchHandler() {
      this.setButtonData()
    },
    analyzeButton() {
      this.manager = new FormButtonManager(this.button)
      this.variables = {
        background: this.manager.getStyleVariableName("background"),
        hoverBackground: this.manager.getStyleVariableName("--hover-background"),
        borderRadius: this.manager.getStyleVariableName("border-radius"),
        padding: this.manager.getStyleVariableName("padding"),
        margin: this.manager.getStyleVariableName("margin"),
        fontSize: this.manager.getStyleVariableName("font-size"),
        color: this.manager.getStyleVariableName("color"),
        borderStyle: this.manager.getStyleVariableName("border-style"),
        borderWidth: this.manager.getStyleVariableName("border-width"),
        borderColor: this.manager.getStyleVariableName("border-color")
      }
      if (this.button.dataset["close"]) {
        this.buttonActions.close.status = true
      }
      if (this.button.dataset["whatsapp"]) {
        this.buttonActions.whatsapp.status = true
        this.buttonActions.whatsapp.number = this.button.dataset["whatsapp"]
      }
      if (this.button.dataset["phone"]) {
        this.buttonActions.phone.status = true
        this.buttonActions.phone.number = this.button.dataset["phone"]
      }
      if (this.button.dataset["url"]) {
        this.buttonActions.openUrl.status = true
        this.buttonActions.openUrl.url = this.button.dataset["url"]
        if (this.button.dataset["newTab"]) {
          this.buttonActions.openUrl.newTab = true
        }
      }
      if (this.button.dataset["submit"]) {
        this.buttonActions.submit.status = true
      }
      if (this.button.dataset["goalReached"]) {
        this.buttonActions.goalReached.status = true
      }
    },
    setButtonData() {
      const attrs = ["data-url", "data-new-tab", "data-submit", "data-goal-reached", "data-whatsapp", "data-phone", "data-close"]
      attrs.forEach((attr) => this.button.removeAttribute(attr))
      if (this.buttonActions.openUrl.status === true) {
        this.button.setAttribute("data-url", this.buttonActions.openUrl.url)
        this.button.setAttribute("data-new-tab", this.buttonActions.openUrl.newTab)
      }
      if (this.buttonActions.whatsapp.status === true) {
        this.button.setAttribute("data-whatsapp", this.buttonActions.whatsapp.number)
      }
      if (this.buttonActions.phone.status === true) {
        this.button.setAttribute("data-phone", this.buttonActions.phone.number)
      }
      if (this.buttonActions.submit.status === true) {
        this.button.setAttribute("data-submit", this.buttonActions.submit.status)
      }
      if (this.buttonActions.close.status === true) {
        this.button.setAttribute("data-close", this.buttonActions.close.status)
      }
      if (this.buttonActions.goalReached.status === true) {
        this.button.setAttribute("data-goal-reached", this.buttonActions.goalReached.status)
      }
    }
  },
  created() {
    this.analyzeButton()
    this.setButtonData()
  }
}
</script>

<style scoped></style>
