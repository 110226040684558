<template>
  <div>
    <base-accordion group="campaignTemplateTabs" :close-group-on-open="true" :open-at-start="true">
      <template #title>{{ title }}</template>
      <template>
        <slot />
      </template>
    </base-accordion>
  </div>
</template>

<script>
import BaseAccordion from "@/components/dashboard/BaseAccordion"
export default {
  name: "BaseCampaignTemplateTab",
  components: { BaseAccordion },
  props: {
    title: String
  }
}
</script>

<style scoped></style>
