<template>
  <settings-template :title="title">
    <div class="flex items-center">
      <select class="form-control mr-4" v-model="horizontalAttribute" @change="setPositionAfterChange">
        <option value="right">Right</option>
        <option value="left">Left</option>
      </select>
      <input-with-unit v-model="horizontal" :alternative-units="['px']" size="small" @updated="setPositionAfterChange" />
    </div>
  </settings-template>
</template>
<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import InputWithUnit from "@/components/common/InputWithUnit"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "SettingsHorizontalPosition",
  components: { SettingsTemplate, InputWithUnit },
  props: {
    leftVariable: {
      type: String,
      required: true
    },
    rightVariable: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "Position"
    }
  },
  mixins: [appearanceChangerMixin],
  data() {
    return {
      horizontalAttribute: "right",
      horizontal: null
    }
  },
  methods: {
    setPositionAfterChange() {
      if (this.horizontalAttribute === "right") {
        this.set(this.leftVariable, "unset")
        this.set(this.rightVariable, this.horizontal.value + this.horizontal.unit)
      } else {
        this.set(this.rightVariable, "unset")
        this.set(this.leftVariable, this.horizontal.value + this.horizontal.unit)
      }
    },
    set(variable, value) {
      this.setStyleVariableDirect({
        env: this.env,
        variable: variable,
        value
      })
    }
  },
  created() {
    const leftValue = this.getStyleVariableValue(this.env, this.leftVariable, 1)
    const rightValue = this.getStyleVariableValue(this.env, this.rightVariable, 1)

    if (leftValue.value === "unset") {
      this.horizontalAttribute = "right"
      this.horizontal = rightValue
    } else {
      this.horizontalAttribute = "left"
      this.horizontal = leftValue
    }
  }
}
</script>
