<template>
  <settings-content-appearance>
    <template #content>
      <settings-template title="Choose Your Image">
        <float-images :allow-only-change="true" images-class="images"></float-images>
      </settings-template>
      <settings-template title="Image Click Actions">
        <single-button
          :button-area-selector="`${getPageSelector} .images`"
          :inner-editable="false"
          :show-title="false"
          button-class="just-image"
        ></single-button>
      </settings-template>
      <settings-template title="Overlay Click">
        <Checkbox v-model="overlayClose">Close Widget When Click To Overlay</Checkbox>
      </settings-template>
    </template>
    <template #appearance>
      <settings-device-selector></settings-device-selector>
      <div :key="appearanceDevice">
        <div class="text-center">
          <div class="nav nav-segment nav-pills w-100 d-flex mt-4">
            <div class="nav-item flex-grow-1">
              <a
                class="nav-link btn justify-content-center"
                data-toggle="device"
                role="tab"
                @click="appearanceActiveTab = 'image'"
                :class="appearanceActiveTab === 'image' ? 'active' : ''"
              >
                Image
              </a>
            </div>
            <div class="nav-item flex-grow-1">
              <a
                class="nav-link btn justify-content-center"
                data-toggle="device"
                role="tab"
                @click="appearanceActiveTab = 'close-button'"
                :class="appearanceActiveTab === 'close-button' ? 'active' : ''"
              >
                Close Button
              </a>
            </div>
            <div class="nav-item flex-grow-1">
              <a
                class="nav-link btn justify-content-center"
                data-toggle="device"
                role="tab"
                @click="appearanceActiveTab = 'overlay'"
                :class="appearanceActiveTab === 'overlay' ? 'active' : ''"
              >
                Overlay
              </a>
            </div>
          </div>
        </div>
        <div v-if="appearanceActiveTab === 'image'" :key="appearanceActiveTab">
          <settings-template>
            <appearance-images :show-position="false" :show-title="false" images-class="images"></appearance-images>
          </settings-template>
          <settings-border-radius variable="--image-border-radius"></settings-border-radius>
        </div>
        <div v-if="appearanceActiveTab === 'close-button'" :key="appearanceActiveTab">
          <settings-color title="Foreground Color" variable="--close-button-color"></settings-color>
          <settings-background-color variable="--close-button-background"></settings-background-color>
          <settings-size :variables="['--close-button-width', '--close-button-height']" title="Icon Size"></settings-size>
          <settings-visibility :can-be-delayed="true" selector=".close-button" variable="--close-button-display"></settings-visibility>
          <settings-padding variable="--close-button-padding"></settings-padding>
          <settings-border-radius variable="--close-button-border-radius"></settings-border-radius>
          <settings-template title="Position">
            <two-direction-position
              :variable="{
                top: '--close-button-top',
                left: '--close-button-left',
                right: '--close-button-right',
                bottom: '--close-button-bottom'
              }"
            ></two-direction-position>
          </settings-template>
        </div>
        <div v-if="appearanceActiveTab === 'overlay'" :key="appearanceActiveTab">
          <SettingsVisibility variable="--overlay-display"></SettingsVisibility>
          <SettingsColor variable="--overlay-color"></SettingsColor>
          <SettingsBlur variable="--overlay-backdrop-blur"></SettingsBlur>
        </div>
      </div>
    </template>
  </settings-content-appearance>
</template>

<script>
import FloatImages from "@/components/campaign-builder/widget-settings/FloatImages"
import AppearanceImages from "@/components/campaign-builder/appearance-changers/Images"
import SettingsContentAppearance from "@/components/campaign-builder/SettingsContentAppearance"
import SettingsDeviceSelector from "@/components/campaign-builder/SettingsDeviceSelector"
import SingleButton from "@/components/campaign-builder/content-changers/SingleButton"
import TwoDirectionPosition from "@/components/campaign-builder/appearance-changers/TwoDirectionPosition"
import Checkbox from "@/components/common/Checkbox"
import { mapGetters, mapMutations, mapState } from "vuex"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "JustImageSettings",
  components: {
    SettingsTemplate,
    Checkbox,
    TwoDirectionPosition,
    SingleButton,
    SettingsDeviceSelector,
    SettingsContentAppearance,
    FloatImages,
    AppearanceImages
  },
  data() {
    return {
      appearanceActiveTab: "image",
      overlayClose: false
    }
  },
  computed: {
    ...mapGetters("builder", ["getIframeDocument", "getPageSelector"]),
    ...mapState("builder", ["appearanceDevice"])
  },
  watch: {
    overlayClose(newV) {
      if (newV) this.getIframeDocument.querySelector(`${this.getPageSelector} .overlay`).setAttribute("data-close", "true")
      else this.getIframeDocument.querySelector(`${this.getPageSelector} .overlay`).removeAttribute("data-close")
    }
  },
  mounted() {
    if (this.getIframeDocument.querySelector(`${this.getPageSelector} .overlay`).getAttribute("data-close")) {
      this.overlayClose = true
    }
  },
  methods: mapMutations("builder", ["setAppearanceDevice"]),
  created() {
    this.setAppearanceDevice("mobile")
  }
}
</script>

<style scoped></style>
