<template>
  <modal :title="`Installation Code For ${site.domain}`" @close="$emit('close')" size="lg">
    <div class="modal-body" ref="PARENTELEMENT">
      <p>
        To install On Site Message, add this code to every page of your website just before the closing &lt;/body> tag. You only need to
        include the code once per page.
      </p>
      <div class="input-group input-group-merge">
        <input type="text" id="insertCode" class="form-control" :value="getInsertCode(site)" />
        <a
          class="js-clipboard input-group-append"
          href="javascript:;"
          data-hs-clipboard-options='{
       "contentTarget": "#insertCode",
       "classChangeTarget": "#insertCodeLinkIcon",
       "defaultClass": "tio-copy",
       "successClass": "tio-done"
     }'
        >
          <span class="input-group-text">
            <span id="insertCodeLinkIcon" class="tio-copy"></span>
          </span>
        </a>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-white" @click="$emit('close')">Close</button>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/modals/Modal"
import clipboardMixin from "@/mixins/clipboard-mixin"
import { mapGetters } from "vuex"

export default {
  name: "ModalInsertCode",
  components: { Modal },
  props: ["site"],
  data() {
    return {}
  },
  computed: mapGetters("sites", ["getInsertCode"]),
  mixins: [clipboardMixin]
}
</script>
