const formFields = [
  {
    selector: ".osm-form-radios",
    type: "radios",
    icon: "adjust",
    name: "Radios",
    nameSelector: ".osm-form-radios input",
    component: "Radio"
  },
  {
    selector: ".osm-form-checkboxes",
    type: "checkboxes",
    icon: "checkmark-square-outlined",
    name: "Checkboxes",
    nameSelector: '.osm-form-checkboxes input[type="checkbox"]',
    component: "Checkboxes"
  },
  {
    selector: ".osm-form-rating",
    type: "rating",
    icon: "star-outlined",
    name: "Rating",
    nameSelector: ".osm-form-rating input",
    component: "Rating"
  },
  {
    selector: ".osm-input-tel",
    type: "tel",
    icon: "call-talking",
    name: "Phone Number",
    nameSelector: ".osm-input-tel",
    component: "InputTel"
  },
  {
    selector: 'input[type="text"]',
    type: "text",
    icon: "text",
    name: "Single Line Text",
    nameSelector: 'input[type="text"]',
    component: "InputText"
  },
  {
    selector: ".osm-input-date",
    type: "date",
    icon: "calendar-note",
    name: "Date",
    nameSelector: ".osm-input-date",
    component: "InputText"
  },
  {
    selector: 'input[type="url"]',
    type: "url",
    icon: "link",
    name: "URL",
    nameSelector: 'input[type="url"]',
    component: "InputText"
  },
  {
    selector: 'input[type="number"]',
    type: "number",
    icon: "double-caret-vertical",
    name: "Number",
    nameSelector: 'input[type="number"]',
    component: "InputText"
  },
  {
    selector: 'input[type="email"]',
    type: "email",
    icon: "email-outlined",
    name: "Email",
    nameSelector: 'input[type="email"]',
    component: "InputText"
  },
  {
    selector: "textarea",
    type: "textarea",
    icon: "text-center",
    name: "Multi Line Text",
    nameSelector: "textarea",
    component: "InputText"
  },
  {
    selector: ".osm-form-checkbox",
    type: "checkbox",
    icon: "checkmark-square-outlined",
    name: "Checkbox",
    nameSelector: 'input[type="checkbox"]',
    component: "Checkbox"
  },
  {
    selector: "select",
    type: "select",
    icon: "arrow-drop-down-circle-outlined",
    name: "Dropdown Selector",
    nameSelector: "select",
    component: "Dropdown"
  }
]
export default formFields
