<template>
  <settings-template :layout="layout" :title="title" :show-device-picker="showDevicePicker">
    <div class="d-flex">
      <div class="btn-group-toggle">
        <label class="btn btn-ghost-secondary btn-sm" :class="{ active: value === 'start' }">
          <input type="radio" v-model="value" value="start" /> <i class="tio-text-left"></i>
        </label>

        <label class="btn btn-ghost-secondary btn-sm ml-1" :class="{ active: value === 'center' }">
          <input type="radio" v-model="value" value="center" /> <i class="tio-text-center"></i>
        </label>

        <label class="btn btn-ghost-secondary btn-sm ml-1" :class="{ active: value === 'end' }">
          <input type="radio" v-model="value" value="end" /> <i class="tio-text-right"></i>
        </label>
      </div>
    </div>
  </settings-template>
</template>

<script>
import appearanceChangerMixin from "@/mixins/campaign-builder/appearance-changer-mixin"
import SettingsTemplate from "@/components/campaign-builder/settings-elements/SettingsTemplate"

export default {
  name: "SettingsTextAlign",
  props: {
    title: {
      type: String,
      default: "Text Align"
    }
  },
  mixins: [appearanceChangerMixin],
  components: { SettingsTemplate },
  computed: {
    value: {
      get() {
        return this.getStyleVariableValueDirectCurrentEnv(this.variable)
      },
      set(value) {
        this.setStyleVariableDirectCurrentEnv({
          variable: this.variable,
          value: value
        })
      }
    }
  }
}
</script>
