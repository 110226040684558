<template>
  <modal-campaign-targeting-rule @save="save" @close="close">
    <div class="d-flex align-items-center">
      <span class="mr-2 flex-shrink-0">The visitors who are</span>
      <select class="form-control form-control-sm" v-model="value">
        <option value="returning">Returning Visitors</option>
        <option value="new">New Visitors</option>
      </select>
    </div>
  </modal-campaign-targeting-rule>
</template>

<script>
import ModalCampaignTargetingRule from "@/components/modals/ModalCampaignTargetingRule"
import targetingRuleNames from "@/components/campaign-targeting-rules/targetingRuleNames"
import targetingRuleModalMixin from "@/components/campaign-targeting-rules/modals/targeting-rule-modal-mixin"

const mixin = targetingRuleModalMixin("who", targetingRuleNames.whoRules.returningOrNew)

export default {
  name: "ReturningOrNewModal",
  components: { ModalCampaignTargetingRule },
  mixins: [mixin]
}
</script>
