<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title">Billing address</h4>
    </div>
    <div class="card-body">
      <loading v-if="loading" />
      <div v-else>
        <modal-billing-address v-if="showBillingAddressPopup" @close="showBillingAddressPopup = false" />
        <div class="row">
          <div v-if="address && address.line1.length > 0" class="col-sm-12 mb-3 mb-sm-3">
            <div class="w-100 h-100">
              <span class="d-block mb-3">
                <span class="d-block mb-1"><strong>Line 1:</strong> {{ address.line1 }}</span>
                <span class="d-block mb-1"><strong>Line 2:</strong> {{ address.line2 }}</span>
                <span class="d-block mb-1"><strong>Country:</strong> {{ address.country }}</span>
                <span class="d-block mb-1"><strong>City:</strong> {{ address.city }}</span>
                <span class="d-block mb-1"><strong>Postal Code:</strong> {{ address.postal_code }}</span>
                <span class="d-block mb-1"><strong>State:</strong> {{ address.state }}</span>
                <span class="d-block mb-1"><strong>Tax ID:</strong> {{ taxId }}</span>
              </span>

              <button class="btn btn-sm btn-white" type="button" @click="showBillingAddressPopup = true">
                <i class="tio-edit mr-1"></i> Edit address
              </button>
            </div>
          </div>

          <div v-else class="col-sm-12 mb-3 mb-sm-3">
            <!-- Card -->
            <a class="card card-dashed h-100" style="cursor: pointer" @click="showBillingAddressPopup = true">
              <a class="card-body card-body-centered card-dashed-body">
                <img class="avatar avatar-xl avatar-4by3 mb-2" :src="bookSvg" alt="Image Description" />
                <span class="text-primary text-hover-primary">
                  <i class="tio-add"></i>
                  Add a new address
                </span>
              </a>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import bookSvg from "@/theme-files/assets/svg/illustrations/book.svg"
import ModalBillingAddress from "@/components/modals/ModalBillingAddress"
import Loading from "@/components/common/Loading"

export default {
  name: "AccountBillingAddress",
  components: { Loading, ModalBillingAddress },
  data() {
    return {
      loading: false,
      showBillingAddressPopup: false
    }
  },
  computed: {
    ...mapState("user", ["address", "taxId"]),
    bookSvg() {
      return bookSvg
    }
  },
  methods: {
    ...mapActions("user", ["fetchAddress"])
  },
  async mounted() {
    this.loading = true
    await this.fetchAddress()
    this.loading = false
  }
}
</script>

<style scoped></style>
