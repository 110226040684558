<template>
  <div class="position-relative mx-2">
    <div class="buttons" v-show="!isSetAreaActive">
      <slot name="buttons" />
    </div>
    <div class="content" :class="isSetAreaActive ? 'active' : ''">
      <div :key="contentsKey">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import randomId from "@/lib/utils/random-id"
import { mapState } from "vuex"

export default {
  name: "Settings",
  data() {
    return {
      contentsKey: "c"
    }
  },
  computed: {
    ...mapState("builder", ["isSetAreaActive", "activeSettings"])
  },
  created() {
    this.$store.watch(
      () => this.$store.state.builder.currentPage,
      () => {
        this.$store.state.builder.setAreaTopic = ""
        this.$store.state.builder.isSetAreaActive = false
        this.contentsKey = randomId()
      }
    )
  }
}
</script>
<style scoped>
.close-button {
  width: 1.5rem;
  height: 1.5rem;
}

.buttons {
  z-index: 5;
  height: 100%;
  width: 100%;
}

.content {
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(calc(-100% - 3.5rem));
  transition: 0.5s all;
}

.content.active {
  transform: translateX(0%);
}
</style>
